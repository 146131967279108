import React from 'react';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import usePermission from '../../../../hooks/usePermission';
import {statusColor} from '../../../../utils';
import Chip from '@mui/material/Chip';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const permission = usePermission();

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('field.title.label'),
      flex: 1,
      minWidth: 320,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2">
            {row.title}
          </Typography>
        );
      }
    },
    {
      field: 'description',
      headerName: t('field.description.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body2">
            {row.description}
          </Typography>
        );
      }
    },
    {
      field: 'comment',
      headerName: t('field.comment.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body2">
            {row.comment}
          </Typography>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.label'),
      flex: 0,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const status = row?.status?.toLowerCase()
        const chipColor = statusColor(status);
        return (
          <Chip
            label={t(`request.status.${status}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        )
      }
    },
  ];

  if(!permission('super-admin')) {
    return (
      <Navigate to="/dashboard/support/create"/>
    )
  }

  return (
    <Content title={t('supports.list.title', {ns: 'pages'})}>
      <ResourceList
        name="support"
        title={t('supports.list.subtitle', {ns: 'pages'})}
        columns={columns}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={Link}
            to="/dashboard/support/create"
          >
            {t('add.value')}
          </Button>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        transformer={(entities: any):any => {
          return entities;
        }}
        onRowClick={(params) => {
          const {row} = params;
          navigate(`/dashboard/support/edit/${row.id}`)
        }}
        rowActions={permission(['super-admin']) ? undefined : false}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
