import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {SxPropsObject} from '../../../../../types';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {Translation, useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {alpha, useTheme} from '@mui/material';
import NumericValue from '../../../../../components/NumericValue/NumericValue';
import API from '../../../../../api';
import {format} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import slices from '../../../../../redux/notices/slice';
import {useSearchParams} from 'react-router-dom';
import appSlice from '../../../../../redux/app/slice';

interface StatisticsData {
  CommonSum: number;
  GroupedSum: number;
  IndividualSum: number;
  Total: number;
  expensesSum: number;
}

const Statistics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {t} = useTranslation();
  const theme = useTheme();
  const user = useSelector(userSelector);
  const [,setLoading] = useState(true);
  const dispatch = useDispatch();

  const periodParam = searchParams.get('period') || format(Date.now(), 'yyyy-MM');


  const {
    noticeOpen,
  } =  slices.actions;
  const {setPaymentsDate} = appSlice.actions;

  const [statistic, setStatistic] = useState<StatisticsData>({
    CommonSum: 0,
    GroupedSum: 0,
    IndividualSum: 0,
    Total: 0,
    expensesSum: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await API.invoices.getStatistic(`/kinder-garden/${user?.schoolId}`, periodParam);
        if(response.status === 200) {
          setStatistic(response.data)
        }
      } catch (err) {
        dispatch(noticeOpen({
          message: (
            <Translation>
              {(t) => t('messages.fetch.statistics.failed')}
            </Translation>
          ),
          options: {
            key: `invoice-fetch-statistics-error`,
            variant: 'error',
          },
        }))
      } finally {
        setLoading(false)
      }
    })()
  }, [dispatch, noticeOpen, periodParam, user?.schoolId]);


  const data = [
    {
      name: 'total',
      label: t('payments.statistics.total', {ns: 'pages'}),
      value: statistic.Total - statistic.expensesSum,
      percent: null,
    },
    {
      name: 'expenses',
      label: t('payments.statistics.expenses', {ns: 'pages'}),
      value: statistic.expensesSum,
      percent: null,
    },
    {
      name: 'subscription',
      label: t('payments.statistics.subscription', {ns: 'pages'}),
      value: statistic.CommonSum,
      percent: null,
    },
    {
      name: 'groups',
      label: t('payments.statistics.groups', {ns: 'pages'}),
      value: statistic.GroupedSum,
      percent: null,
    },
    {
      name: 'individual',
      label: t('payments.statistics.individual', {ns: 'pages'}),
      value: statistic.IndividualSum,
      percent: null,
    },
  ];

  return (
    <Paper elevation={2} sx={styles.container}>
      <Grid container spacing={{lg: 3, xs: 2}}>
        <Grid item xs={12} md={3} lg={3}>
          <DatePicker
            openTo="month"
            views={['year', 'month']}
            label={t('field.month.label')}
            value={new Date(periodParam)}
            onChange={(value) => {
              if(value) {
                searchParams.set('period', format(value, 'yyyy-MM'));
              } else {
                searchParams.set('period', '');
              }
              setSearchParams(searchParams);
              dispatch(setPaymentsDate(value));
            }}
            slots={{
              textField: TextField,
            }}
            slotProps={{
              textField: {
                onKeyDown: (event) => { event.preventDefault()},
                margin: "normal",
                required: true,
                fullWidth: true,
                InputLabelProps: {
                  shrink: true
                },
                InputProps: {
                  readOnly: true,
                  sx: {
                    textTransform: 'capitalize',
                    '& .MuiInputBase-input': {
                      textTransform: 'inherit'
                    }
                  }
                },
                size: "medium",
                autoComplete: 'off'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={{lg: 3, xs: 2}}>
            {data.map((el, index) => {
              let chipColor = theme.palette.success.main;
              let valueColor = theme.palette.text.primary;
              let percent = null;
              const hasPercent = el.percent !== null && el.percent !== undefined;
              if(hasPercent) {
                percent = Number(el?.percent) * 100;
                if(percent <= 0) {
                  chipColor = theme.palette.error.main;
                }
              }

              if(el.name === 'total') {
                valueColor = el.value >= 0 ? theme.palette.success.main : theme.palette.error.main
              }

              if(el.name === 'expenses') {
                valueColor = el.value <= 0 ? theme.palette.success.main : theme.palette.error.main
              }

              return (
                <Grid key={el.name + index} item xs={12} sm={4} lg  sx={{display: 'flex'}}>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      borderLeftWidth: {
                        lg: index > 0 ? 1 : 0,
                        xs: 0,
                      },
                      borderLeftStyle: 'solid',
                      borderLeftColor: theme.palette.divider,
                      paddingLeft: {
                        lg: theme.spacing(3),
                        xs: 0,
                      },
                    })}
                  >
                    <Typography variant="body3" color="text.gray">
                      {el.label}
                    </Typography>
                    <Typography variant="h5" sx={{mb: 1}} color={valueColor}>
                      <NumericValue
                        formatType="price"
                        value={el.value}
                        displayType="text"
                        decimalScale={2}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=" "
                      />
                    </Typography>
                    {hasPercent && (
                      <Chip
                        label={(
                          <NumericValue
                            formatType="percentage"
                            value={percent}
                            displayType="text"
                          />
                        )}
                        variant="filled"
                        size="small"
                        sx={{
                          color: chipColor,
                          fontWeight: 500,
                          background: alpha(chipColor, 0.2),
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles: SxPropsObject = {
  container: {
    borderRadius: 2,
    marginBottom: 3,
    px: 3,
    py: 2,
  },
}

export default React.memo(Statistics);
