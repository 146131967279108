import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .required('required')
    .nullable(true),
  quantity: Yup.mixed()
    .when(['type'], {
      is: (type: string) => {
        return ['group-temporary', 'individual-temporary'].includes(type);
      },
      then: Yup.number()
        .typeError('number.format')
        .required('required')
        .nullable(true),
      otherwise: Yup.number()
        .typeError('number.format')
        .nullable(true),
    }),
  startAt: Yup.string().required('required').nullable(true),
  Schedule: Yup.array()
    .min(1, 'schedule.min')
    .of(Yup.object().shape({
      day: Yup.string()
        .oneOf(['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'], 'format')
        .required('required')
        .nullable(true),
      time: Yup.string().required('required').nullable(true),
    }))
    .required('required')
    .nullable(true),
  Teacher: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
    }),
  Groups: Yup.mixed()
    .when(['type', 'Children'], {
      is: (type: string, Children: any[]) => {
        return type === 'main' || !(Children?.length && Children?.length >=0);
      },
      then: Yup.array()
        .of(Yup.object().shape({
          id: Yup.number().required('required').nullable(true),
        }))
        .min(1, 'required')
        .nullable(true)
        .required('required'),
      otherwise: Yup.mixed().nullable(true),
    }),
  Lesson: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
    })
    .required('required').nullable(true),
  Children: Yup.mixed()
    .when(['type', 'Groups'], {
      is: (type: string, Groups: any[]) => {
        return ['individual-permanent', 'individual-temporary'].includes(type) || !(Groups?.length && Groups?.length >=0);
      },
      then: Yup.array()
        .of(Yup.object().shape({
          id: Yup.number().required('required').nullable(true),
        }))
        .min(1, 'required')
        .nullable(true)
        .required('required'),
      otherwise: Yup.mixed().nullable(true),
    }),
}, [['Groups', 'Children']]);

export default validationSchema;
