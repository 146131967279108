import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Trans, useTranslation} from 'react-i18next';
import {Autoplay, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import {SxPropsObject} from '../../../../types';
import {APPS, POSSIBILITIES_ITEMS} from '../../../../constants';
import Card from './components/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material';
import {Swiper as SwiperClass} from 'swiper/types';
import {ReactComponent as AppleIcon} from '../../../../assets/icons/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/icons/android.svg';
import {ReactComponent as ArrowRightIcon} from '../../../../assets/icons/arrowRight.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../../assets/icons/arrowLeft.svg';


const Possibilities = () => {
  const {t} = useTranslation('pages');
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const ref = useRef<SwiperClass>();

  return (
    <Box id="possibilities" sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Box>
          <Grid container spacing={0.25} justifyContent="center">
            <Grid item>
              <IconButton
                component="a"
                href={APPS.apple.link}
                aria-label={t('apps.apple')}
                target="_blank"
                rel="noreferrer noopener"
                sx={{
                  color: '#BBBBC0'
                }}
              >
                <SvgIcon
                  fontSize={isDownSm ? 'small' : 'medium'}
                  viewBox="0 0 18 20"
                  component={AppleIcon}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href={APPS.android.link}
                aria-label={t('apps.android')}
                target="_blank"
                rel="noreferrer noopener"
                sx={{
                  color: '#BBBBC0'
                }}
              >
                <SvgIcon
                  fontSize={isDownSm ? 'small' : 'medium'}
                  viewBox="0 0 18 20"
                  component={AndroidIcon}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            component="p"
            color="#AFB0B5"
            mb={2}
            textAlign="center"
          >
            <Trans t={t} i18nKey="home.possibilities.app" />
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={styles.heading}
            variant="h1"
            component="h2"
            color="text.primary"
            fontWeight={700}
          >
            <Trans t={t} i18nKey="home.possibilities.heading" />
          </Typography>
          <Box sx={styles.carousel}>
            <Box sx={styles.controls}>
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item>
                  <IconButton
                    aria-label={'Prev slide'}
                    sx={styles.controlsButton}
                    onClick={() => ref?.current?.slidePrev()}
                  >
                    <SvgIcon
                      fontSize={isDownSm ? 'small' : 'medium'}
                      viewBox="0 0 24 24"
                      component={ArrowLeftIcon}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label={'Next slide'}
                    sx={styles.controlsButton}
                    onClick={() => ref?.current?.slideNext()}
                  >
                    <SvgIcon
                      fontSize={isDownSm ? 'small' : 'medium'}
                      viewBox="0 0 24 24"
                      component={ArrowRightIcon}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Swiper
              onBeforeInit={(swiper) => {
                ref.current = swiper;
              }}
              modules={[Autoplay, Scrollbar]}
              autoplay={{
                delay: 3600,
                disableOnInteraction: false,
              }}
              speed={600}
              spaceBetween={32}
              loop
              grabCursor
              scrollbar={{ draggable: true }}
              pagination={false}
              breakpoints={{
                [theme.breakpoints.values.lg]:{
                  slidesPerView: 4,
                },
                [theme.breakpoints.values.md]:{
                  slidesPerView: 3,
                },
                [theme.breakpoints.values.sm]:{
                  slidesPerView: 2,
                },
                [theme.breakpoints.values.xs]:{
                  slidesPerView: 1,
                },
              }}
            >
              {POSSIBILITIES_ITEMS.map((item, index) => {
                return (
                  <SwiperSlide key={`slide-${index}`} style={{height: 'auto'}}>
                    <Card entity={item}/>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: () => ({
    py: {
      sm: 15,
      xs: 10
    },
    position: 'relative',
    zIndex: 6,
    maxWidth: '100%',
  }),
  heading: {
    textAlign: 'center',
    mb: 8,
  },
  carousel: {},
  controls: ({
    mb: 4,
  }),
  controlsButton: () => ({
    color: '#BBBBC0',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'currentColor'
  }),
}

export default Possibilities;
