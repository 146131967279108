import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  currency: Yup.string().required('required').nullable(true),
  paymentSystem: Yup.string().required('required').nullable(true),
  Bank: Yup.object()
    .shape({
      name: Yup.string().required('required').nullable(true),
      token: Yup.string().required('required').nullable(true),
      edrpou: Yup.string().required('required').nullable(true),
      iban: Yup.string().required('required').nullable(true),
      receiver: Yup.string().required('required').nullable(true),
    }),
});

export default validationSchema;
