import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {Gender} from '../../../../types';
import {teacherStateToRequest} from '../../../../utils';
import {FormValues} from '../types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {
  const user = useSelector(userSelector);

  return (
    <ResourceCreate
      name="teachers"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = teacherStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues:FormValues = {
          name: null,
          surname: null,
          patronymic: null,
          email: null,
          phone: null,
          gender: 'male' as Gender,
          birthday: null,
          position: null,
          media: null,
          rateGroupLesson: null,
          rateIndividualLesson: null,
          salary: null,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
