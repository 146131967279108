import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {SxPropsObject} from '../../../../types';
import {SvgIcon, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {ListBox, TypographyDecorated} from '../../../../styled';
import {getAssets, getSxStyle} from '../../../../utils';
import {Trans, useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import { scroller } from 'react-scroll';
import {ReactComponent as CheckCircleIcon} from '../../../../assets/icons/checkCircle.svg';

const Advantages = () => {
  const {t, i18n} = useTranslation('pages');
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const currentLocale = i18n.language?.split('-')[0];

  const assets = getAssets(currentLocale)

  const handleScrollToPricing = () => {
    scroller.scrollTo('pricing', {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  const handleScrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <Box id="advantages" sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Typography
          variant="h1"
          component="h2"
          fontWeight={700}
          color="text.primary"
          sx={styles.heading}
        >
          <Trans t={t} i18nKey="home.advantages.heading" />
        </Typography>

      </Container>

      <Box sx={styles.section}>
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row-reverse'}>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Box
                  component="img"
                  src={assets.screenFirstImage}
                  alt="Schedule preview image"
                  sx={styles.screenFirstImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pr: 4,
                    [theme.breakpoints.down('lg')]: {
                      pr: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  <Trans t={t} i18nKey="home.advantages.0.heading"/>
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.0.text.0')}
                </Typography>
                <ListBox component="ul">
                  {[0, 1].map((id) => (
                    <Box
                      key={id}
                      component="li"
                    >
                      <SvgIcon
                        viewBox="0 0 21 21"
                        component={CheckCircleIcon}
                        sx={{
                          mr: 1.5,
                          verticalAlign: 'middle',
                        }}
                      />
                      <Typography variant="body2" component="span">
                        {t(`home.advantages.0.list.${id}`)}
                      </Typography>
                    </Box>
                  ))}
                </ListBox>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.0.text.1')}
                </Typography>

                {/*<Box sx={styles.details}>*/}
                {/*  <Grid*/}
                {/*    key="details"*/}
                {/*    container*/}
                {/*    spacing={3}*/}
                {/*    sx={{*/}
                {/*      position: 'relative',*/}
                {/*      zIndex: 2*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <Grid item sx={styles.detailsItem}>*/}
                {/*      <Box sx={styles.detailsContent}>*/}
                {/*        <Typography*/}
                {/*          variant="h6"*/}
                {/*          component="div"*/}
                {/*          color="text.primary"*/}
                {/*          sx={styles.detailsNumber}*/}
                {/*        >*/}
                {/*          12K+*/}
                {/*        </Typography>*/}
                {/*        <Typography variant="body3" component="p" color="text.primary" >*/}
                {/*          <Trans t={t} i18nKey="home.advantages.1.items.0.text" />*/}
                {/*        </Typography>*/}
                {/*      </Box>*/}
                {/*    </Grid>*/}
                {/*    <Grid item sx={styles.detailsItem}*/}
                {/*    >*/}
                {/*      <Box sx={styles.detailsContent}>*/}
                {/*        <Typography*/}
                {/*          variant="h6"*/}
                {/*          component="div"*/}
                {/*          color="text.primary"*/}
                {/*          sx={styles.detailsNumber}*/}
                {/*        >*/}
                {/*          1947*/}
                {/*        </Typography>*/}
                {/*        <Typography variant="body3" component="p" color="text.primary">*/}
                {/*          <Trans t={t} i18nKey="home.advantages.1.items.1.text" />*/}
                {/*        </Typography>*/}
                {/*      </Box>*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*</Box>*/}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={styles.section}>
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row'}>
            <Grid item xs={12} sm={6} md={6}>
              <Box  sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Box
                  component="img"
                  src={assets.screenSecondImageSrc}
                  alt="Lessons create preview"
                  sx={styles.screenSecondImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pl: 4,
                    [theme.breakpoints.down('lg')]: {
                      pl: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  <Trans t={t} i18nKey="home.advantages.1.heading"/>
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.1.text.0')}
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  color="dark"
                  onClick={handleScrollToPricing}
                  sx={{
                    borderRadius: 2,
                    alignSelf: 'flex-start'
                  }}
                >
                  {t('home.advantages.1.button.price')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={styles.section}>
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row-reverse'}>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Box
                  component="img"
                  src={assets.screenThirdImageSrc}
                  alt="Schedule preview image"
                  sx={styles.screenThirdImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pr: 4,
                    [theme.breakpoints.down('lg')]: {
                      pr: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  <Trans t={t} i18nKey="home.advantages.2.heading">
                    Функціональний <TypographyDecorated
                    variant="h2"
                    fontWeight="inherit"
                    component="span"
                    sx={{
                      '&::after': {
                        background: 'linear-gradient(90deg, #44BCFF -0.55%, #44B0FF 22.86%, #FF44EC 48.36%, #FF44EC 73.33%, #FF675E 99.34%)',
                      }
                    }}
                  >календар</TypographyDecorated>
                  </Trans>
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  <Trans t={t} i18nKey="home.advantages.2.text.0">
                    {' '}<Typography variant="body2" component="strong" color="text.primary" fontWeight={700}>Власник</Typography>
                    садочка зможе формувати та переглядати два календарі:
                  </Trans>
                </Typography>
                <Box component="ul" sx={styles.list}>
                  {[0, 1].map((id) => (
                    <Box
                      key={id}
                      component="li"
                      sx={{
                        mb: 3,
                        '&:last-of-type': {
                          mb: 0,
                        }
                      }}>
                      <SvgIcon
                        viewBox="0 0 21 21"
                        component={CheckCircleIcon}
                        sx={{
                          mr: 1.5,
                          verticalAlign: 'middle',
                        }}
                      />
                      <Typography variant="body2" component="span">
                        {t(`home.advantages.2.list.${id}`)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Typography variant="body2" color="text.primary" mb={3}>
                  <Trans t={t} i18nKey="home.advantages.2.text.1">
                    А кожен <Typography variant="body2" component="strong" color="text.primary" fontWeight={700}>адміністратор</Typography>
                    матиме лише доступ до перегляду календаря розкладу своїх занять та календаря про дні народження дітей.
                  </Trans>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={(theme) => {
          return {
            ...getSxStyle(styles.section, theme),
            background: '#FAFAFA'
          }
        }}
      >
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row-reverse'}>
            <Grid item xs={12} sm={6} md={6} >
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Box
                  component="img"
                  src={assets.screenForthImageSrc}
                  alt="Screen 6"
                  sx={styles.screenForthImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pr: 4,
                    [theme.breakpoints.down('lg')]: {
                      pr: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >

                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={3}
                >
                  {t('home.advantages.3.heading')}
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.3.text.0')}
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.3.text.1')}
                </Typography>
                <Box sx={{mt: 3}}>
                  <Grid container spacing={3}>
                    <Grid item xs="auto">
                      <Button
                        size="large"
                        variant="contained"
                        color="dark"
                        onClick={handleScrollToContact}
                        sx={{
                          borderRadius: 2,
                        }}
                      >
                        {t('home.advantages.3.button.contact')}
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        size="large"
                        variant="outlined"
                        color="dark"
                        onClick={handleScrollToPricing}
                        sx={{
                          borderRadius: 2,
                        }}
                      >
                        {t('home.advantages.3.button.price')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={styles.section}>
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row'}>
            <Grid item xs={12} sm={6} md={6}>
              <Box  sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Box
                  component="img"
                  src={assets.screenFifthImageSrc}
                  alt="Fifth screen"
                  sx={styles.screenFifthImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pl: 4,
                    [theme.breakpoints.down('lg')]: {
                      pl: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  <Trans t={t} i18nKey="home.advantages.4.heading">
                    Створення <TypographyDecorated
                    variant="h2"
                    fontWeight="inherit"
                    component="span"
                    sx={{
                      '&::after': {
                        background: 'linear-gradient(90deg, #44BCFF -0.55%, #FF675E 99.34%)',
                      }
                    }}
                  >карток</TypographyDecorated> для вчителів та адміністраторів садка
                  </Trans>
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.4.text.0')}
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  color="dark"
                  onClick={handleScrollToPricing}
                  sx={{
                    borderRadius: 2,
                    alignSelf: 'flex-start'
                  }}
                >
                  {t('home.advantages.4.button.price')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={styles.section}>
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row-reverse'}>
            <Grid item xs={12} sm={6} md={6} >
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Box
                  component="img"
                  src={assets.screenSixthImageSrc}
                  alt="Screen sixth image"
                  sx={styles.screenSixthImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pr: 4,
                    [theme.breakpoints.down('lg')]: {
                      pr: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  <Trans t={t} i18nKey="home.advantages.5.heading">
                    Впорядкований список дітей зі <TypographyDecorated
                    variant="h2"
                    fontWeight="inherit"
                    component="span"
                    sx={{
                      '&::after': {
                        background: 'linear-gradient(90deg, #44BCFF -0.55%, #FF675E 99.34%)',
                      }
                    }}
                  >зручним</TypographyDecorated>  керуванням особливостями оплати
                  </Trans>
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.5.text.0')}
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.5.text.1')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={(theme) => {
          return {
            ...getSxStyle(styles.section, theme),
            background: '#FAFAFA'
          }
        }}
      >
        <Container>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column-reverse' : 'row'}>
            <Grid item xs={12} sm={6} md={6}>
              <Box  sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Box
                  component="img"
                  src={assets.screenSeventhImageSrc}
                  alt="Seventh screen"
                  sx={styles.screenSeventhImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    pl: 4,
                    [theme.breakpoints.down('lg')]: {
                      pl: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography
                  variant="h2"
                  color="text.primary"
                  fontWeight={700}
                  mb={2}
                >
                  {t('home.advantages.6.heading')}
                </Typography>
                <Typography variant="body2" color="text.primary" mb={3}>
                  {t('home.advantages.6.text')}
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  color="dark"
                  onClick={handleScrollToContact}
                  sx={{
                    borderRadius: 2,
                    alignSelf: 'flex-start'
                  }}
                >
                  {t('home.advantages.6.button.contact')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: () => ({
    pt: {
      sm: 15,
      xs: 10
    },
    position: 'relative',
    zIndex: 6,
    overflow: 'hidden',
    maxWidth: '100%',
  }),
  heading: {
    textAlign: 'center',
    mb: {
      sm: 4,
      xs: 2,
    },
  },
  section: {
    pt: {
      sm: 8,
      xs: 4,
    },
    pb: {
      sm: 8,
      x: 0,
    },
    overflow: 'hidden',
  },
  content: () => ({
    py: 3,
    flexGrow: 1,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',
  }),
  screenFirstImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenSecondImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenThirdImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenForthImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-12vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenFifthImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenSixthImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  screenSeventhImage: (theme) => ({
    position: 'relative',
    width: '48.6vw',
    marginBottom: '-2vw',
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
  details: () => ({
    position: 'relative',
    zIndex: 10,
    maxWidth: 440,

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'block',
      opacity: 0.4,
      filter: 'blur(34px)',
      background: 'linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%)',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    }
  }),
  detailsItem: () => ({
  }),
  detailsContent: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
  detailsNumber: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    mr: 2,
    fontSize: '2.625rem',
    lineHeight: 1,
    fontWeight: 700,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      mr: 1,
    },
  }),
}

export default Advantages;
