import React, {useCallback, useEffect, useLayoutEffect, useReducer, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Spinner from '../../../../../components/Spinner';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import localeRu from '@fullcalendar/core/locales/ru';
import localeUa from '@fullcalendar/core/locales/uk';
import localeEn from '@fullcalendar/core/locales/en-gb';
import Link from '@mui/material/Link';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import format from 'date-fns/format';
import {FullCalendarContainer} from '../../../../../styled';
import {DATE_FORMAT, DATE_TIME_FORMAT, SERVER_DATE_FORMAT, TASK_TYPES} from '../../../../../constants';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {PopoverPosition} from '@mui/material/Popover/Popover';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import slices from '../../../../../redux/resource/slice';
import {CalendarViewType, DayValue, Lesson, Person, SxPropsObject} from '../../../../../types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  useTheme
} from '@mui/material';
import {parseISO} from 'date-fns';
import {renderFullName, statusColor} from '../../../../../utils';
import {DatesSetArg, EventClickArg, EventContentArg} from '@fullcalendar/core';
import Controls from '../../../../../components/calendar/Controls';
import {animateScroll} from 'react-scroll';
import CalendarPopover from '../../../../../components/calendar/Popover';
import Chip from '@mui/material/Chip';

interface Props {
  entities: Array<any>;
  loading: boolean;
}

interface initialValues {
  name: string;
  date: string;
  day: DayValue;
}

const initialEvent: initialValues = {
  name: '',
  date: '',
  day: {
    label: '',
    value: 'mon'
  }
};

const eventReducer = (state: any, action: { type: any; payload?: any }) => {
  switch (action.type) {
    case 'ON_CHANGE_FIELD':
      return {
        ...state,
        [action.payload.name]: action?.payload?.value,
      };
    case 'ON_CHANGE_DATE':
      return {
        ...state,
        date: action?.payload?.date,
        day: action?.payload?.day,
      };
    case 'ON_RESET':
      return {
        ...initialEvent
      };
    default:
      return state;
  }
};

const Calendar = (props: Props) => {
  const {entities, loading} = props;
  const ref = useRef<FullCalendar>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const {t, i18n} = useTranslation();
  let calendarLocale = i18n.language?.split('-')[0];
  if (calendarLocale === 'ua') {
    calendarLocale = 'uk';
  }

  const [viewType, setViewType] = useState<CalendarViewType>('dayGridMonth');
  const [autoHeight, setAutoHeight] = useState<boolean>(false);
  const [viewTitle, setViewTitle] = useState<string>('');

  const [anchorPosition, setAnchorPosition] = React.useState<PopoverPosition | undefined>(undefined);
  const [currentItemId, setCurrentItemId] = React.useState<number | null>(null);
  const [period, setPeriod] = useState<Array<string> | null>(null);

  const [openCreateDialog, setCreateOpenDialog] = React.useState(false);
  const [event, dispatchEvent] = useReducer(eventReducer, initialEvent);

  const [checkedTypes, setCheckedTypes] = useState<Array<string>>([...TASK_TYPES.map(el => el.value)]);

  const handleChangeEventField = (name: any, value: any) => {
    dispatchEvent({
      type: 'ON_CHANGE_FIELD',
      payload: {
        name,
        value,
      },
    });
  };

  const {
    changeParams,
  } = slices.tasks.actions;

  useEffect(() => {
    if (period) {
      //@ts-ignore
      dispatch(changeParams({
        from: period[0],
        to: period[1],
      }));
    }
  }, [dispatch, period, changeParams]);

  useLayoutEffect(() => {
    if((viewType === 'timeGridDay')) {
      const indicator = document.querySelector('.fc-timegrid-now-indicator-line');
      const {top} = indicator?.getBoundingClientRect() || {};
      animateScroll.scrollTo(Number(top));
    }
  }, [viewType]);

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  const onSetPeriod = useCallback(
    (start: string, end: string) => {
      const currentPeriod = [
        format(new Date(start), SERVER_DATE_FORMAT),
        format(new Date(end), SERVER_DATE_FORMAT)
      ];

      setPeriod(prevPeriod => {
        if (prevPeriod === null || (prevPeriod.toString() !== currentPeriod.toString())) {
          return currentPeriod;
        }
        return prevPeriod;
      });
    }, []);

  const onChangeView = (value: CalendarViewType): void => {
    ref?.current?.getApi().changeView(value);
    setViewType(value);
  };

  const onSetViewTitle = (title: string): void => {
    setViewTitle(title);
  };

  const handleEventClick = (info: EventClickArg): void => {
    info.jsEvent.preventDefault();
    info.jsEvent.stopPropagation();
    setCurrentItemId(info?.event?.id ? Number(info?.event?.id) : null);
    setAnchorPosition({
      left: info.jsEvent.clientX,
      top: info.jsEvent.clientY,
    });
  };


  const handleCloseDialog = () => {
    setCreateOpenDialog(false);
    dispatchEvent({
      type: 'ON_RESET',
    });
  };

  const handleRedirectToCreate = useCallback(() => {
    navigate('/dashboard/tasks/create', {
      replace: true,
      state: {
        date: event.date,
        name: event.name,
        day: event.day,
      },
    });
  }, [event.date, event.day, event.name, navigate]);

  const handleSetCheckedTypes = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: string) => {
    setCheckedTypes((state) => {
      if(state.includes(name)) {
        return state.filter((el) => el !== name)
      } else {
        return [...state, name]
      }
    });
  },[]);

  const currentEntities = entities.filter((el) => {
    const field = el?.extendedProps.type === 'task' ? 'status' : 'category';
    return checkedTypes.includes(el?.extendedProps[field]);
  });

  const entity = currentEntities?.find((el) => el.id === currentItemId);
  const entityItems: Array<{ half: boolean, content: React.ReactNode }> = [];
  const {
    type: entityType,
    description: entityDescription,
    category: entityCategory,
    Users: entityUsers,
    birthday: entityBirthday,
    status: entityStatus,
  } = entity?.extendedProps || {};

  let entityStatusColorName = statusColor(entityStatus);

  if (entityType === 'birthday') {
    let personType = 'person';

    if(entityCategory === 'parents') {
      personType = 'parent';
    }

    if(entityCategory === 'children') {
      personType = 'child';
    }

    entityItems.push({
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.${personType}`, {ns: 'pages'})}{':'}
          </Typography>
          <Link
            to={entity?.url}
            target="_blank"
            component={RouterLink}
            underline="hover"
            rel="noopener noreferrer"
            replace
            color="text.primary"
            variant="subtitle2"
            fontWeight={700}
          >
            {entity?.title}
          </Link>
        </>
      ),
    }, {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.birthdate`, {ns: 'pages'})}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {entityBirthday ? format(parseISO(entityBirthday), DATE_FORMAT) : null}
          </Typography>
        </>
      ),
    });
  }

  if(entityType === 'task') {
    entityItems.push( {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.title`, {ns: 'pages'})}{':'}
          </Typography>
          <Link
            component={RouterLink}
            to={`/dashboard/tasks/edit/${entity.id}`}
            target="_blank"
            underline="hover"
            rel="noopener noreferrer"
            replace
          >
            {entity?.title}
          </Link>
        </>
      ),
    },  {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.description`, {ns: 'pages'})}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {entityDescription}
          </Typography>
        </>
      ),
    }, {
      half: false,
      content: (
        <>
          {entityUsers?.map((el: Partial<Person>, index: number) => (
            <>
              <Typography variant="body3" component="p" color="gray">
                {t(`tasks.calendar.popover.event.field.users`, {ns: 'pages'})}{':'}
              </Typography>
              <Link
                component={RouterLink}
                to={`/dashboard/admins/${el.id}`}
                target="_blank"
                underline="hover"
                rel="noopener noreferrer"
                replace
                variant="body2"
              >
                {renderFullName(el, false)}
              </Link>
              {entityUsers.length - 1 !== index && (
                <>{', '}<br/></>
              )}
            </>
          ))}
        </>
      ),
    }, {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.start`, {ns: 'pages'})}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {entity?.start ? format(entity.start, DATE_TIME_FORMAT) : null}
          </Typography>
        </>
      ),
    }, {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.end`, {ns: 'pages'})}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {entity?.end ? format(entity.end, DATE_TIME_FORMAT) : null}
          </Typography>
        </>
      ),
    }, {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t(`tasks.calendar.popover.event.field.status`, {ns: 'pages'})}{':'}
          </Typography>
          <Chip
            label={t(`task.status.${entityStatus}`)}
            color={entityStatusColorName}
            variant="filled"
            sx={{
              mt: 0.5,
              borderRadius: 2
            }}
          />
        </>
      ),
    });
  }

  let entityPath = `/dashboard/tasks/edit/${entity?.id}`;
  if(entity?.extendedProps?.type === 'birthday') {
    entityPath = `/dashboard/${entity?.extendedProps?.category}/edit/${entity?.id}`;
  }

  const eventContent = (eventInfo: EventContentArg): JSX.Element => {
    const {timeText, event, view} = eventInfo;
    const {icon, color, status} = event.extendedProps;
    const isDayGridMonthView = view.type === 'dayGridMonth';
    const isListWeekView = view.type === 'listWeek';
    const isTimeDisplayed = isDayGridMonthView && !event.allDay;
    const shadowStyle = !isTimeDisplayed ? {boxShadow: `inset 4px 0px 0px ${color}`} : undefined;
    const eventTitle = event.title;

    const colorName = statusColor(status);

    const statusBox = status && (
      <Box
        className="fc-event-label"
        sx={(theme) => ({
          width: 4,
          height: 16,
          backgroundColor: colorName === 'default' ? theme.palette.divider : theme.palette[colorName].main,
          borderRadius: 2,
          marginLeft: 'auto',
        })}
      />
    )

    return (
      <>
        {
          event?.end ? (
            <Box className="fc-event-main-frame" style={isListWeekView ? undefined : shadowStyle}>
              {isDayGridMonthView && (
                <Box className="fc-daygrid-event-dot" style={{borderColor: color}}/>
              )}
              <Box className="fc-event-time">
                {timeText}
              </Box>
              <Box className="fc-event-title-container">
                <Box className="fc-event-title fc-sticky">
                  {icon && (
                    <Box component="span" className="icon" style={{color}}>
                      {icon}
                    </Box>
                  )}
                  {eventTitle}
                </Box>
                {statusBox}
              </Box>
            </Box>
          ) : (
            <Box className="fc-event-box" style={isListWeekView ? undefined : shadowStyle}>
              <>
                {isTimeDisplayed && (
                  <Box className="fc-daygrid-event-dot" style={{borderColor: color}}/>
                )}
                <Box className="fc-event-time">
                  {timeText}
                </Box>
              </>
              <Box className="fc-event-title">
                {icon && !isTimeDisplayed && <span className="icon" style={{color}}>{icon}</span>}
                {eventTitle}
              </Box>
              {statusBox}
            </Box>
          )
        }
      </>
    )
  }

  return (
    <FullCalendarContainer>
      <Typography variant="subtitle2" component="p" mb={1}>
        {t('tasks.calendar.colors.title', {ns: 'pages'})}{':'}
      </Typography>
      <Grid
        container
        spacing={1}
        component="ul"
        sx={{
          p: 0,
          listStyle: 'none',
        }}
      >
        {TASK_TYPES.map((el, index) => {
          const checked = checkedTypes.includes(el.value as Lesson['type']);

          return (
            <Grid item xs={12} sm="auto" key={index} component="li">
              <Button
                sx={(theme) => ({
                  borderRadius: 10,
                  color: checked ? theme.palette.text.primary : theme.palette.divider,
                  borderColor: checked ? theme.palette.primary.main : theme.palette.divider,
                })}
                size="small"
                color="primary"
                onClick={(event) => {
                  handleSetCheckedTypes(event, el.value)
                }}
                variant="outlined"
                startIcon={(
                  <Box
                    sx={{
                      borderRadius: '50%',
                      display: 'inline-block',
                      width: 10,
                      height: 10,
                      background: checked ? el.color : theme.palette.divider,
                      verticalAlign: 'middle',
                    }}
                    component="span"
                  />
                )}
              >
                {el.label}
              </Button>
            </Grid>
          )
        })}
      </Grid>
      <Divider sx={{mx: -3, my: 3}}/>
      <Controls
        calendar={ref?.current}
        viewTitle={viewTitle}
        viewType={viewType}
        onChangeView={onChangeView}
      />
      <Box sx={{position: 'relative', overflowX: 'auto'}}>
        {loading && (
          <Spinner sx={{
            position: 'absolute',
            height: '100%',
          }}/>
        )}
        <Box sx={{minWidth: {xs: theme.breakpoints.values.md, md: 'auto'}}} >
          <FullCalendar
            ref={ref}
            height={autoHeight ? 'auto' : undefined}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            windowResizeDelay={0}
            navLinks
            eventMaxStack={3}
            dayMaxEventRows={3}
            dayMaxEvents={3}
            headerToolbar={{
              left: '',
              center: '',
              right: ''
            }}
            initialView="dayGridMonth"
            editable={false}
            locales={[localeRu, localeUa, localeEn]}
            locale={calendarLocale}
            selectMirror={true}
            weekends={true}
            nowIndicator={true}
            eventOrderStrict
            events={currentEntities}
            eventContent={eventContent}
            eventClick={handleEventClick}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              hour12: false
            }}
            navLinkDayClick={(date) => {
              ref?.current?.getApi()?.gotoDate(date);
              onChangeView('timeGridDay');
            }}
            datesSet={(info: DatesSetArg) => {
              //@ts-ignore
              const title = info.view?.getCurrentData().viewTitle;
              onSetPeriod(info.startStr, info.endStr);
              onSetViewTitle(title);
              setAutoHeight(info.view.type !== 'dayGridMonth');
            }}
            noEventsContent={() => (
              <NoData/>
            )}
          />
        </Box>
      </Box>
      <CalendarPopover
        anchorPosition={anchorPosition}
        setAnchorPosition={setAnchorPosition}
        items={entityItems}
        footer={(
          <Button
            fullWidth
            component={RouterLink}
            to={entityPath}
            variant="outlined"
            sx={{
              mt: 2
            }}
          >
            {t('edit.value')}
          </Button>
        )}
      />
      <Dialog fullWidth maxWidth="sm" open={openCreateDialog} onClose={handleCloseDialog}>
        <DialogTitle variant="subtitle2">
          {t('tasks.dialog.create.title', {ns: 'pages'})}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText variant="body2" mb={2}>
            {t('tasks.dialog.create.text', {
              ns: 'pages',
              date: event.date ? format(parseISO(event.date), DATE_TIME_FORMAT) : '',
              day: event.day?.label.toLowerCase() || '',
            })}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            sx={styles.field}
            name="patronymic"
            value={event.name}
            onChange={(event) => {
              handleChangeEventField('name', event.target.value);
            }}
            margin="normal"
            label={t('field.name.lesson.label')}
            placeholder={t('field.name.lesson.placeholder')}
            InputLabelProps={{shrink: true}}
            size="medium"
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions sx={{py: 2, px: 3}}>
          <Button variant="outlined" onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleRedirectToCreate}>
            {t('add.lesson')}
          </Button>
        </DialogActions>
      </Dialog>
    </FullCalendarContainer>
  )
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
};

export default React.memo(Calendar);
