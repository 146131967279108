import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {SOCIAL_ITEMS} from '../../constants';

interface Props {
  justify: 'flex-start' | 'flex-end' | 'center'
}

const Socials:React.FC<Props> = (props) => {
  const {justify = 'flex-start'} = props;

  return (
    <Grid container spacing={1} justifyContent={justify}>
      {SOCIAL_ITEMS.map((el, index) => {
        return (
          <Grid item key={index}>
            <IconButton
              aria-label={el.name}
              size="medium"
              sx={{
                color: 'text.primary'
              }}
            >
              <SvgIcon
                fontSize="medium"
                viewBox={el.icon.viewBox}
                component={el.icon.component}
              />
            </IconButton>
          </Grid>
        )
      })}
    </Grid>
  );
};

export default Socials;
