import React from 'react';
import {SxPropsObject} from '../../types';
import Box from '@mui/material/Box';
import {Link, Outlet} from 'react-router-dom';
import {Logo} from '../../styled';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import {grey} from '@mui/material/colors';
import {ReactComponent as LogoImage} from '../../assets/images/logo.svg';

type Props = {
  children?: React.ReactNode;
}

const Default:React.FC<Props> = () => {
  return (
    <Box sx={styles.container}>
      <AppBar
        position="absolute"
        color="transparent"
        elevation={0}
        component="header"
        sx={styles.header}>
        <Container>
          <Toolbar sx={styles.toolbar}>
            <Logo
              sx={{
                mx: 'auto'
              }}
              component={Link}
              to="/"
              variant="h6"
            >
              <LogoImage/>
            </Logo>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet/>
    </Box>
  );
};


const styles:SxPropsObject = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: grey[50],
    backgroundSize: 'cover',
    flex: 1,
    minHeight: '100vh',
  },
  toolbar: (theme) => ({
    py: 0,
    px: {
      xs: 0,
    },
    minHeight: {
      sm: theme.spacing(10),
    },
    justifyContent: 'space-between',
    position: 'relative',
  }),
  header: (theme) => ({
    top: 0,
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  }),
  logo: {
    my: 3,
    mx: 'auto'
  },
}

export default Default;
