import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import {Payment as PaymentType, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import {getPaymentStatus, getSxStyle, statusColor} from '../../../../../utils';

interface Props {
  sx?: SxProps<Theme>
  name: string;
  payment: PaymentType,
}

const Payment = (props: Props) => {
  const {name, sx, payment} = props;
  const {t} = useTranslation();

  const {
    value,
    left,
    status
  } = getPaymentStatus(payment.date.current, payment.date.expiry);

  const chipColor = statusColor(status);
  const subtitle =  t(`children.content.lessons.payment.text.${left < 0 ? 'overdue' : 'next'}`, {ns: 'pages'});

  const visibleProgress = !(status === 'complete' && left === 0);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={(theme) => {
        return {
          ...styles.container,
          ...(getSxStyle(sx || {}, theme))
        };
      }}
    >
      <Box sx={styles.head}>
        <Typography variant="subtitle1">
          {name}
        </Typography>
        <Chip
          label={t(`payment.status.${status}`)}
          color={chipColor}
          variant="filled"
          sx={{
            borderRadius: 2
          }}
        />
      </Box>
      {visibleProgress && (
        <>
          <Box sx={styles.paymentInfo}>
            <Typography variant="subtitle2" component="span">
              {subtitle}
            </Typography>
            <Typography variant="subtitle2" component="span">
              {t('measure.days.plural', {count: left})}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={value}
            color={chipColor === 'default' ? undefined : chipColor}
            sx={styles.progress}
          />
        </>
      )}
    </Paper>
  );
};

const styles: SxPropsObject = {
  container: {
    py: 1,
    px: 2,
    borderRadius: 1,
    minHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  head: {
    mb: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  paymentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progress: (theme) => ({
    height: theme.spacing(1),
    mt: 1,
    borderRadius: 0.5,

    '&.MuiLinearProgress-bar': {
      backgroundColor: '#F95C00',
    }
  }),
};

export default Payment;
