import axios from '../config/axios';
import {AuthResponsePayload, SchoolResponsePayload} from './types';
import {AxiosResponse} from 'axios';
import {AddressByIp, ResourcePathModifier, ResourceType} from '../types';

const auth = {
  login: async (email: string, password: string):Promise<AxiosResponse<AuthResponsePayload>> => {
    return axios.post<AuthResponsePayload>('/login', {
      email,
      password
    });
  },
   refresh: async ():Promise<any> => {
     return axios.post('/refresh-token');
   },
   logout: async ():Promise<any> => {
     return axios.post('/logout')
   },
};

type APIResourceType = Omit<ResourceType, 'owners' | 'news'> | 'managers' | 'posts';

function getResourceUrl(pathModifier: ResourcePathModifier, url:string): string {
  if(pathModifier) {
    if(typeof pathModifier === 'function') {
      return pathModifier(url);
    }
    return pathModifier + url;
  }

  return url;
}

const resources = {
  create: async (pathModifier: ResourcePathModifier, type: APIResourceType, values: any) => {
    return axios.post(getResourceUrl(pathModifier,`/${type}`), values);
  },
  get: async (pathModifier: ResourcePathModifier, type: APIResourceType, id: number,) => {
    return axios.get(getResourceUrl(pathModifier,`/${type}/${id}`));
  },
  getAll: async (pathModifier: ResourcePathModifier, type: APIResourceType, params: any) => {
    return axios.get(getResourceUrl(pathModifier,`/${type}`), {
      params,
    });
  },
  update: async (pathModifier: ResourcePathModifier, type: APIResourceType, values: any, id: number):Promise<any> => {
    return axios.patch(getResourceUrl(pathModifier,`/${type}/${id}`), values);
  },
  delete: async (pathModifier: ResourcePathModifier, type: APIResourceType, id: number):Promise<any> => {
    return axios.delete(getResourceUrl(pathModifier,`/${type}/${id}`));
  },
};

const teachers = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'teachers', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'teachers', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'teachers', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/teachers-handles`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'teachers', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'teachers', teacherId),
  schedule: (pathModifier: ResourcePathModifier, id: number, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/teachers/${id}/schedule`), {
      ...(params ? {params} : {})
    });
  },
};

const children = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'children', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'children', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'children', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/children-handles`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'children', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'children', teacherId),
};

const admins = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'admins', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'admins', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'admins', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/admins-handles`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'admins', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'admins', teacherId),
};

const posts = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'posts', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'posts', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'posts', params),
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier,  'posts', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'posts', teacherId,),
};

const parents = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'parents', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'parents', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'parents', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/parents-handles`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'parents', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'parents', teacherId),
};

const groups = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'groups', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'groups', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'groups', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/groups-handles`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'groups', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'groups', teacherId),
};

const lessons = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'lessons', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'lessons', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'lessons', params),
  getAllSimple: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/lessons-handles`), {
      ...(params ? {params} : {})
    });
  },
  getAllExtra: (pathModifier: ResourcePathModifier, params?: any) => {
    return axios.get(getResourceUrl(pathModifier,`/lessons-extra`), {
      ...(params ? {params} : {})
    });
  },
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'lessons', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'lessons', teacherId),
};

const invoices = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'invoices', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'invoices', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'invoices', params),
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'invoices', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'invoices', teacherId),
  generateDocument: (pathModifier: ResourcePathModifier, id: number,  params?: any) => {
    return axios.post(getResourceUrl(pathModifier,`/invoices/${id}`), {
      ...(params ? {params} : {})
    });
  },
  getStatistic: (pathModifier: ResourcePathModifier,  date: string) => {
    return axios.get(getResourceUrl(pathModifier,`/invoices/widget`), {
      params: {
        date,
      }
    });
  },
};

const schools = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'kinder-gardens', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'kinder-gardens', id),
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'kinder-gardens', data, id),
  updateSettings: async (pathModifier: ResourcePathModifier, data: any, id: number) => {
    return  axios.patch(`kinder-gardens/${id}/settings`, data);
  },
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'kinder-gardens', teacherId),
  getAll: async ():Promise<AxiosResponse<Array<SchoolResponsePayload>>> => {
    return axios.get<Array<SchoolResponsePayload>>('kinder-gardens/handles');
  },
}

const schedules = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'schedules', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'schedules', id),
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'schedules', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'schedules', teacherId),
}

const expenses = {
  create: (pathModifier: ResourcePathModifier, data: any) => resources.create(pathModifier, 'expenses', data),
  get: (pathModifier: ResourcePathModifier, id: number) => resources.get(pathModifier,'expenses', id),
  getAll: (pathModifier: ResourcePathModifier, params: any) => resources.getAll(pathModifier, 'expenses', params),
  update: async (pathModifier: ResourcePathModifier, data: any, id: number) => resources.update(pathModifier, 'expenses', data, id),
  delete: async (pathModifier: ResourcePathModifier, teacherId: number) => resources.delete(pathModifier,'expenses', teacherId),
};

const files = {
  upload: async (pathModifier: ResourcePathModifier, media: Array<any>) => {
    let formData = new FormData();

    media.forEach((file) => {
      formData.append("media", file);
    })

    return axios.post(getResourceUrl(pathModifier,`/media`), formData, {
      headers: {
        "Accept": "*/*",
        'Content-Type':  `multipart/form-data`,
      }
    });
  }
}

const tags = {
  attach:  async(pathModifier: ResourcePathModifier, values: any) => {
    return axios.post(getResourceUrl(pathModifier,'/tags/attach'), values);
  },
  detach:  async(pathModifier: ResourcePathModifier, values: any) => {
    return axios.post(getResourceUrl(pathModifier,'/tags/detach'), values);
  },
  getAll: async (pathModifier: ResourcePathModifier) => {
    return axios.get(getResourceUrl(pathModifier,'/tags'));
  },
}

const landing = {
  contact: (value: any) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, 1000)
  })
}


const app = {
  getAddress: () :Promise<AxiosResponse<AddressByIp>> => {
    return axios.get('https://ipapi.co/json/')
  }
}

const API = {
  app,
  auth,
  resources,
  teachers,
  posts,
  files,
  parents,
  groups,
  children,
  lessons,
  invoices,
  expenses,
  schools,
  admins,
  tags,
  landing,
  schedules,
}

export default API;
