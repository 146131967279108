import React, {useCallback, useEffect, useState} from 'react';
import {schoolResponseToState, schoolStateToRequest} from '../../../utils';
import Form from './components/Form';
import API from '../../../api';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../../../redux/auth/selectors';
import Spinner from '../../../components/Spinner';
import {Nullable, School} from '../../../types';
import {SchoolRequestPayload, SchoolResponsePayload} from '../../../api/types';
import {Translation} from 'react-i18next';
import slices from '../../../redux/notices/slice';

const Settings = () => {
  const user = useSelector(userSelector);

  const [entity, setEntity] = useState<Nullable<School>>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    noticeOpen,
  } =  slices.actions;

  const fetchSchool = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const response = await API.schools.get('', id);
      const school = schoolResponseToState(response.data as SchoolResponsePayload);
      setEntity(school);
    } catch (e) {
      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.fetch.data.failed')}
          </Translation>
        ),
        options: {
          key: `settings-data-error`,
          variant: 'error',
        },
      }))
    } finally {
      setLoading(false);
    }
  }, [dispatch, noticeOpen]);

  const updateSchool = useCallback(async (id: number, data: Partial<SchoolRequestPayload>) => {
    try {
      setLoading(true);
      const response = await API.schools.updateSettings('', data, id);
      const school = schoolResponseToState(response.data as SchoolResponsePayload);
      setEntity(school);
    } catch (e) {
      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.update.data.failed')}
          </Translation>
        ),
        options: {
          key: `settings-data-error`,
          variant: 'error',
        },
      }))
    } finally {
      setLoading(false);
    }
  }, [dispatch, noticeOpen]);

  useEffect(() => {
    if(user?.schoolId) {
      fetchSchool(user?.schoolId).then(r => console.log(r));
    }
  }, [fetchSchool, user?.schoolId])

  const onSubmit = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
    const transformed = schoolStateToRequest(values);
    updateSchool(user?.schoolId as number, transformed).then(r => console.log(r));
  }


  const initialValues = {
    currency: entity?.currency || null,
    paymentSystem: entity?.paymentSystem || null,
    Bank: {
      iban: entity?.Bank?.iban || null,
      receiver: entity?.Bank?.receiver || null,
      edrpou: entity?.Bank?.edrpou || null,
      name: entity?.Bank?.name || null,
      token: entity?.Bank?.token || null,
    },
  }

  if(loading) {
    return  <Spinner/>;
  }

  return (
    <Form
      submitting={false}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  )
};


export default React.memo(Settings);
