import React, {MouseEventHandler} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useMediaQuery, useTheme} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {OverridableStringUnion} from '@mui/types';
import {ButtonPropsColorOverrides} from '@mui/material/Button/Button';

type Buttons = 'submit' | 'cancel' | 'delete' | string;

interface Action {
  label: React.ReactNode,
  disabled: boolean,
  loading?: boolean,
  action?: MouseEventHandler<HTMLElement> | undefined;
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >
}

interface Props {
  mode: 'create' | 'edit';
  buttons: {
    [key in Buttons]: Action
  }
}

const Actions = (props: Props) => {
  const {mode, buttons} = props;
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const actions: {[key: string]: Action} = Object.keys(buttons)
  .filter((key) => !['submit', 'cancel', 'delete'].includes(key))
  .reduce((cur, key) => { return Object.assign(cur, { [key]: buttons[key] })}, {});

  return (
    <Grid container spacing={{xs: 2}} justifyContent="space-between" alignItems="center">
      {'cancel' in buttons && (
        <Grid item xs={12} sm={6} md="auto">
          <Button
            size="medium"
            variant="outlined"
            disabled={buttons.cancel.disabled}
            fullWidth={isDownMd}
            onClick={buttons.cancel.action}
          >
            {buttons.cancel.label}
          </Button>
        </Grid>
      )}
      {mode === 'edit' && 'delete' in buttons && (
        <Grid item xs={12} sm={6} md="auto">
          <Button
            size="medium"
            variant="text"
            color="error"
            disabled={buttons.delete.disabled}
            fullWidth={isDownMd}
            onClick={buttons.delete.action}
          >
            {buttons.delete.label}
          </Button>
        </Grid>
      )}
      {Object.keys(actions).map((el, index) => {
        const action = actions[el];
        return (
          <Grid item xs={12} sm={6} md="auto" key={el + index} sx={{ml: {md: index === 0 ? 'auto' : 0, xs: 0}}}>
            <LoadingButton
              type="button"
              size="medium"
              color={action?.color}
              variant="contained"
              loading={action.loading}
              disabled={action.disabled}
              fullWidth={isDownMd}
              onClick={action.action}
            >
              {action.label}
            </LoadingButton>
          </Grid>
        )
      })}
      {'submit' in buttons && (
        <Grid item xs={12} sm={6} md="auto" sx={{ml: {md: Object.keys(actions).length === 0 ? 'auto' : 0, xs: 0}}}>
          <LoadingButton
            type="submit"
            size="medium"
            variant="contained"
            loading={buttons.submit.loading}
            disabled={buttons.submit.disabled}
            fullWidth={isDownMd}
            onClick={buttons.submit.action}
          >
            {buttons.submit.label}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

export default Actions;
