import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SnackbarKey, useSnackbar} from 'notistack';
import {noticesSelector} from '../redux/notices/selectors';
import slice from '../redux/notices/slice';

let displayed:Array<SnackbarKey> = [];

const UseNotices = () => {
  const dispatch = useDispatch();
  const notices = useSelector(noticesSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {noticeRemove} = slice.actions;

  const storeDisplayed = (id:SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notices.forEach(({ message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(options.key);
        return;
      }
      if (displayed.includes(options.key)) return;
      enqueueSnackbar(message, {
        ...options,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(noticeRemove(myKey));
          removeDisplayed(myKey);
        },
      });
      storeDisplayed(options.key);
    });
  }, [notices, closeSnackbar, enqueueSnackbar, dispatch, noticeRemove]);
};

export default UseNotices;
