import React, {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import theme from './config/theme';
import {Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter, useLocation} from 'react-router-dom';
import DefaultRoute from './routes/Default';
import AuthRoute from './routes/Auth';
import HomeRoute from './routes/Home';
import DashBoardRoute from './routes/Dashboard';
import DefaultLayout from './layouts/Default';
import BaseLayout from './layouts/Base';
import MainLayout from './layouts/Main';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {Provider, useDispatch} from 'react-redux';
import store from './redux/store';
import {authCheck} from './redux/auth/slice';
import {SnackbarProvider} from 'notistack';
import useNotices from './hooks/useNotices';
import {createBrowserHistory} from 'history';
import {getDateFnsLocale} from './utils';
import {useTranslation} from 'react-i18next';
import appSlice from './redux/app/slice';
import API from './api';

export const history = createBrowserHistory({ window });

const AppRoutes = () => {
  useNotices();
  const location = useLocation();
  const dispatch = useDispatch();

  const {setCountry, setPrice} = appSlice.actions

  useEffect(() => {
    // Auto sign in
    if(localStorage.getItem('token')) {
      dispatch(authCheck())
    }
  }, [dispatch]);

  useEffect(() => {
    const getAddressData = async () => {
      const response = await API.app.getAddress();
      const countryCode = response.data.country_code || 'UA';
      dispatch(setCountry(countryCode));
      if(['IT', 'ES'].includes(countryCode)) {
        dispatch(setPrice({
          discount: 0.5,
          value: 30,
        }))
      } else if(['AE'].includes(countryCode)) {
        dispatch(setPrice({
          discount: 0.5,
          value: 40,
        }))
      } else {
        dispatch(setPrice({
          discount: 0.5,
          value: 20,
        }))
      }
    }
    getAddressData().finally(() => console.log(''))
  }, [dispatch, setCountry, setPrice]);


  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<BaseLayout/>}>
          <Route index element={<HomeRoute />} />
        </Route>
        <Route element={<MainLayout/>}>
          <Route path="/dashboard/*" element={<DashBoardRoute />} />
        </Route>
        <Route element={<DefaultLayout/>}>
          <Route path="/errors/*" element={<DefaultRoute />}/>
          <Route path="/auth/*" element={<AuthRoute />}/>
        </Route>
        <Route path="*" element={(
          <Navigate to="/errors/404" replace />
        )}/>
      </Routes>
    </>
  )
}

function App() {
  const {i18n} = useTranslation();
  const currentLocale = i18n.language?.split('-')[0];
  const dateFnsLocale = getDateFnsLocale(currentLocale);

  return (
    <Provider store={store}>
      {/*//@ts-ignore*/}
      <HistoryRouter history={history}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={dateFnsLocale}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <AppRoutes/>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </HistoryRouter>
    </Provider>
  );
}

export default App;
