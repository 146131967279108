import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {AutocompleteProps} from '@mui/material/Autocomplete/Autocomplete';
import {AxiosResponse} from 'axios';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {useTranslation} from 'react-i18next';

type ResponseData = {
  [key: string]: Array<any>
} | Array<any>;

interface Props<T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
  > extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> {
  fetch: () => Promise<AxiosResponse<ResponseData>>;
  transformer?: (entities: Array<any>) => Array<T>;
  TextFieldProps: TextFieldProps,
  responseKey?: string;
}

const AutoCompleteField = <T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
  >(
  props: React.PropsWithChildren<Props<T, Multiple, DisableClearable, FreeSolo>>
): JSX.Element => {
  const {
    fetch,
    transformer,
    TextFieldProps,
    responseKey,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        setLoading(true)
        const { data } = await fetch();
        let items;
        if(responseKey) {
          // @ts-ignore
          items = data[responseKey]
        } else {
          items = data as [];
        }

        let currentItems = items;
        if(transformer){
          currentItems = transformer(items)
        }
        setOptions(currentItems);
      } finally {
        setLoading(false)
      }
    })();
  }, [fetch, loading, responseKey, transformer]);

  return (
    <Autocomplete
      {...rest}
      open={open}
      options={options}
      loading={loading}
      loadingText={`${t('loading')}...`}
      noOptionsText={t('no.data')}
      onOpen={() => {
        setOpen(true);
        setLoading(true);
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={16} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteField;
