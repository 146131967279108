import React from 'react';
import {useParams} from 'react-router-dom';
import ResourceEdit from '../../../../components/Resource/components/Edit';
import Form from '../components/Form';
import {postResponseToState, postStateToRequest} from '../../../../utils';
import {VoteRequestPayload} from '../../../../api/types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Edit = () => {
  let {id} = useParams();
  const user = useSelector(userSelector);

  return (
    <ResourceEdit
      id={Number(id)}
      name="posts"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
      transformer={(entity: any):any => {
        if(entity) {
          return postResponseToState(entity)
        }
        return entity;
      }}
    >
      {({entity, onEdit, submitting, loading, onDelete}) => {
        const handleEdit= (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = postStateToRequest(values);
          delete transformed.type;
          delete (transformed as VoteRequestPayload).voteVariants
          return onEdit(transformed, onSuccess, onError)
        }

        const handleDelete = () => {
          onDelete(true)
        }

        if(loading) {
          return  <Spinner/>
        }

        return entity && (
          <Form
            mode="edit"
            submitting={submitting}
            onSubmit={handleEdit}
            onDelete={handleDelete}
            initialValues={{
              ...entity,
              selectedGroupsType: 'selected',
            }}
          />
        )
      }}
    </ResourceEdit>
  );
};

export default React.memo(Edit);
