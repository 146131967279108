import React, {useEffect, useState} from 'react';
import {Nullable, ScheduleLesson} from '../types';
import API from '../api';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../redux/auth/selectors';
import {LESSON_TYPES, TIME_FORMAT} from '../constants';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import {CircleColor} from '../styled';
import format from 'date-fns/format';
import {getDuration, renderFullName, scheduleLessonResponseToState} from '../utils';
import {Translation, useTranslation} from 'react-i18next';
import slices from '../redux/notices/slice';
import Box from '@mui/material/Box';

interface Params {
  id: Nullable<number>;
  event: any;
  fetching?: boolean;
}

interface Data {
  loading: boolean,
  items: Nullable<Array<{
    half?: boolean;
    content: React.ReactNode;
  }>>
}

const useSchedulePopoverData = (params: Params): Data => {
  const {id, fetching, event} = params;
  const {t, i18n} = useTranslation();
  const user = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Nullable<ScheduleLesson>>(null);
  const dispatch = useDispatch();

  const currentLocale = i18n.language?.split('-')[0];

  const {
    noticeOpen,
  } =  slices.actions;

  useEffect(() => {
    if(id && fetching) {
      setLoading(true);
      API.schedules.get(`/kinder-garden/${user?.schoolId}`, id)
        .then((response) => {
          const {data: item, status} = response;
          if(item && status === 200) {
            setData(scheduleLessonResponseToState(item))
          }
        })
        .catch(() => {
          dispatch(noticeOpen({
            message: (
              <Translation>
                {(t) => t('messages.fetch.data.failed')}
              </Translation>
            ),
            options: {
              key: `schedule-data-error`,
              variant: 'error',
            },
          }))
        })
        .finally(() => {
          setLoading(false)
        })
    }
    return () => {
      setData(null);
    };
  }, [id, fetching, user?.schoolId, dispatch, noticeOpen]);

  const lessonType = LESSON_TYPES.find((el) => el.value === data?.Lesson?.type);

  const items = [
    {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.lesson.label')}{':'}
          </Typography>
          <Link
            to={`/dashboard/lessons/edit/${data?.Lesson?.id}`}
            target="_blank"
            component={RouterLink}
            underline="hover"
            rel="noopener noreferrer"
            replace
            color="text.primary"
            variant="subtitle2"
            fontWeight={700}
          >
            {data?.Lesson?.name}
          </Link>
        </>
      )
    },
    {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.lesson.type.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            <CircleColor
              component="span"
              sx={{
                mr: 1,
                background: lessonType?.color,
              }}
            />
            {lessonType?.label}
          </Typography>
        </>
      )
    },
    {
      half: true,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.time.start.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {event?.start ? format(event.start, TIME_FORMAT) : null}
          </Typography>
        </>
      )
    },
    {
      half: true,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.time.end.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {event?.end ? format(event.end, TIME_FORMAT) : null}
          </Typography>
        </>
      )
    },
    {
      half: true,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.date.start.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {event?.end ? format(event.end, TIME_FORMAT) : null}
          </Typography>
        </>
      )
    },
    ...(data?.Lesson?.duration ? [{
      half: true,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.duration.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {getDuration(data?.Lesson?.duration, currentLocale)}
          </Typography>
        </>
      )
    }] : []),
    ...(data?.quantity ? [{
      half: true,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.quantity.lessons.label')}{':'}
          </Typography>
          <Typography variant="body2" component="p">
            {data?.quantity}
          </Typography>
        </>
      )
    }] : []),
    {
      half: false,
      content: (
        <>
          <Typography variant="body3" component="p" color="gray">
            {t('field.teacher.label')}{':'}
          </Typography>
          <Link
            to={`/dashboard/teachers/edit/${data?.Teacher?.id}`}
            target="_blank"
            component={RouterLink}
            underline="hover"
            rel="noopener noreferrer"
            replace
            color="text.primary"
            variant="body2"
          >
            {data?.Teacher && renderFullName(data?.Teacher)}
          </Link>
        </>
      )
    },
    {
      half: false,
      content: (
        <>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="body3" component="span" color="text.primary">
              {t('field.groups.label')}{`:`}
            </Typography>
            <Typography variant="body3" component="strong"  color="text.primary" sx={{ml: 2, fontWeight: 600}}>
              {`(${data?.Groups?.length})`}
            </Typography>
          </Box>
          <Box>
            {data?.Groups?.map((group, index) => (
              <>
                {index !== 0 && ', '}
                <Link
                  key={group.id}
                  to={`/dashboard/groups/edit/${group.id}`}
                  target="_blank"
                  component={RouterLink}
                  underline="hover"
                  rel="noopener noreferrer"
                  replace
                  color="text.primary"
                  variant="body2"
                >
                  {group.name}
                </Link>
              </>
            ))}
          </Box>
        </>
      )
    },
    {
      half: false,
      content: (
        <>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="body3" component="span" color="text.primary">
              {t('field.children.label')}{`:`}
            </Typography>
            <Typography variant="body3" component="strong"  color="text.primary" sx={{ml: 2, fontWeight: 600}}>
              {`(${data?.Children?.length})`}
            </Typography>
          </Box>
          <Box>
            {data?.Children?.map((child, index) => (
              <>
                {index !== 0 && ', '}
                <Link
                  key={child.id}
                  to={`/dashboard/children/edit/${child.id}`}
                  target="_blank"
                  component={RouterLink}
                  underline="hover"
                  rel="noopener noreferrer"
                  replace
                  color="text.primary"
                  variant="body2"
                >
                  {renderFullName(child)}
                </Link>
              </>
            ))}
          </Box>
        </>
      )
    },
  ];

  return {
    loading,
    items,
  };
};

export default useSchedulePopoverData;
