import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import {taskStateToRequest} from '../../../../utils';
import {FormValues} from '../types';

const Create = () => {
  const user = useSelector(userSelector);

  return (
    <ResourceCreate
      name="tasks"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = taskStateToRequest(values);
          delete transformed.status;
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues: FormValues = {
          title: null,
          description: null,
          startAt: null,
          finishAt: null,
          Users: null,
          time: null,
          duration: null,
          status: 'pending',
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
            entity={null}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
