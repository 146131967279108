import React, {useCallback} from 'react';
import validationSchema from '../../validationSchema';
import Box from '@mui/material/Box';
import Content from '../../../../../layouts/Main/components/Content';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Persons from '../../../../../components/Persons';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {SxPropsObject, Teacher} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import {TeacherResponsePayload} from '../../../../../api/types';
import {apiErrorsToFormik, renderFullName, teacherResponseToState} from '../../../../../utils';
import API from '../../../../../api';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {useNavigate} from 'react-router-dom';
import {FormValues} from '../../types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'teacherId':'Teacher.id',
}

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(userSelector);

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`groups.content.title.${mode}`, {ns: 'pages'})

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          dirty,
          resetForm
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('groups.content.subtitle.group', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('groups.content.section.main', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}} flexDirection="column">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="name"
                        value={values?.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.name && errors?.name)}
                        helperText={(
                          <ErrorMessage
                            name="name"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.name.group.label')}
                        placeholder={t('field.name.group.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AutoCompleteField<Partial<Teacher>, undefined, undefined, undefined>
                        sx={styles.field}
                        fullWidth
                        size="medium"
                        transformer={(entities: Array<TeacherResponsePayload>): Array<Teacher> => {
                          return entities?.map((entity) => {
                            return teacherResponseToState(entity);
                          });
                        }}
                        TextFieldProps={{
                          name: "Teacher",
                          required: true,
                          label: t('field.teacher.label'),
                          placeholder: t('field.teacher.placeholder'),
                          margin: 'normal',
                          InputLabelProps: { shrink: true },
                          //@ts-ignore
                          error: Boolean(touched?.Teacher && errors?.Teacher),
                          helperText: (
                            <ErrorMessage
                              name="Teacher.id"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )
                        }}
                        fetch={() => API.teachers.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        getOptionLabel={(option) => {
                          return renderFullName(option);
                        }}
                        value={values?.Teacher?.id ? values.Teacher : null}
                        onChange={(event, value) => {
                          if(!value) {
                            setFieldValue('Teacher', {
                              id: null,
                              name: null,
                              surname: null,
                              patronymic:  null,
                              relationship: null,
                            })
                          }
                          setFieldValue('Teacher', value)
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              right={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('groups.content.subtitle.pupils', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{my: 2}}>
                    {t('groups.content.section.list', {ns: 'pages'})}
                  </Typography>
                  <Persons
                    emptyText="no.children"
                    items={values?.Children || []}
                    onClick={(id: number) => {
                      navigate(`/dashboard/children/edit/${id}`)
                    }}
                  />
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.group') : t('edit.group'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            />
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
