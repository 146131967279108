import React, {useState} from 'react';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import styles from '../../../common/styles';
import {useTranslation} from 'react-i18next';
import validationSchema from '../../validationSchema';
import {useDispatch, useSelector} from 'react-redux';
import {signIn} from '../../../../../redux/auth/slice';
import {authSelector} from '../../../../../redux/auth/selectors';
import {Link as RouterLink, Navigate, useLocation} from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as EyeIcon} from '../../../../../assets/icons/eye.svg';
import {ReactComponent as EyeCrossIcon} from '../../../../../assets/icons/eye-cross.svg';

type FormValues = {
  email: string;
  password: string;
}

const initialValues = {
  email: '',
  password: '',
}

const Form = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {user, error} = useSelector(authSelector);
  const [visiblePassword, setVisiblePassword] = useState(false);

  const onSubmit = async (
    values: FormValues,
    helpers:FormikHelpers<FormValues>
  ): Promise<any> => {
    const { setSubmitting } = helpers;
    const {
      email,
      password
    } = values;
    await dispatch(signIn({email, password}))
    setSubmitting(false);
  }

  const origin = location.state?.from?.pathname || '/dashboard';

  if (user) {
    return <Navigate to={origin} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {

        return (
          <Paper
            sx={styles.container}
            elevation={0}
            variant="outlined"
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={styles.form}
            >
              <Typography
                variant="h5"
                color="text.primary"
                textAlign="center"
                mb={3}
                sx={styles.title}
              >
                {t('signIn.heading', {ns: 'auth'})}
              </Typography>
              <TextField
                sx={styles.field}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={(
                  <ErrorMessage
                    name="email"
                    render={(message) => t(message, {ns: 'validation'})}
                  />
                )}
                margin="normal"
                required
                fullWidth
                label={t('field.email.label')}
                InputLabelProps={{ shrink: true }}
                name="email"
                size="medium"
                autoComplete='off'
              />
              <TextField
                sx={styles.field}
                type={visiblePassword ? 'text' : 'password'}
                name="password"
                value={values.password || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.password && errors.password)}
                helperText={(
                  <ErrorMessage
                    name="password"
                    render={(message) => t(message, {ns: 'validation', min: 8, max: 32})}
                  />
                )}
                margin="normal"
                required
                fullWidth
                label={t('field.password.label')}
                placeholder={t('field.password.placeholder')}
                InputLabelProps={{shrink: true}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setVisiblePassword((value) => !value);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <SvgIcon
                          fontSize="inherit"
                          viewBox="0 0 24 24"
                          component={visiblePassword ? EyeIcon : EyeCrossIcon}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                size="medium"
                autoComplete="off"
              />
              {error && (
                <Alert
                  severity="error"
                  variant="standard"
                  icon={false}
                  sx={{
                    mt: 1,
                    mb: 2,
                  }}
                >
                  {/*{t('auth.credentials', {*/}
                  {/*  ns: 'validation',*/}
                  {/*  credentials: 'email'*/}
                  {/*})}*/}
                  {error}
                </Alert>
              )}
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  mb: 1
                }}
              >
                {t('signIn')}
              </LoadingButton>
              <Box sx={styles.text}>
                <Link
                  component={RouterLink}
                  to="/"
                  replace
                  textAlign="center"
                  variant="body2"
                  color="text.primary"
                >
                  {t('home')}
                </Link>
              </Box>
            </Box>
          </Paper>
        )
      }}
    </Formik>
  );
};

export default Form;
