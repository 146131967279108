import {CSSProperties} from 'react';
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {createTheme, PaletteColorOptions} from "@mui/material/styles";
import {Theme} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

// When using TypeScript 4.x and above
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true,
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  body3: CSSProperties;
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    dark: PaletteColorOptions;
    light: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface CustomText {
    gray: string;
  }
  interface TypeText extends CustomText {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
  }
}

declare module '@mui/lab/LoadingButton' {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
  }
}

const defaultTheme = createTheme();

const createColor = (mainColor: string) => {
  return defaultTheme.palette.augmentColor({
    color: {
      main: mainColor
    }
  });
};

const appThemeBreakPoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1328,
    xl: 1600,
  }
};

const appThemeComponents = {
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      body: {
        fontSize: theme.typography.body2.fontSize,
      },
    }),
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: defaultTheme.spacing(0.5),
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
      },
      sizeLarge: {
        paddingTop: defaultTheme.spacing(1.5),
        paddingBottom: defaultTheme.spacing(1.5),
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
      // filled: {
      //   transform: "translate(2px, 10px) scale(0.75)",
      //   "&$marginDense": {
      //     transform: "translate(82px, 7px) scale(0.75)"
      //   }
      // },
      // outlined: {
      //   transform: "translate(52px, -6px) scale(0.75)",
      //   "&$animated": {
      //     transform: "translate(82px, 88px) scale(0.75)"
      //   }
      // }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
      input: {
        fontSize: '1rem',
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
    }
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: '0.35em',
      },
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      }
    }
  }
} as Theme['components'];

const appThemeFontFamily = [
  '"Open Sans"',
  '-apple-system',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  'sans-serif',
].join(',');

const appThemeTypography = {
  fontFamily: appThemeFontFamily,
  h1: {
    fontSize: '3.25rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '2.25rem',
    },
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '1.75rem',
    }
  },
  h2: {
    fontSize: '2.75rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1.75rem',
    },
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '1.25rem',
    }
  },
  h3: {
    fontSize: '2.25rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1.75rem',
    }
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1.75rem',
    },
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '1.25rem',
    },
  },
  h5: {
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1rem',
    },
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '0.875rem',
    },
  },
  h6: {
    lineHeight: 1.25,
    fontWeight: 600,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '1.125rem',
    }
  },
  subtitle1: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1rem',
    },
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '0.875rem',
    },
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '0.875rem',
    }
  },
  body1: {
    fontSize: '1.125rem',
    lineHeight: 1.25,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.lg)]: {
      fontSize: '1rem',
    }
  },
  body2: {
    fontSize: '1rem',
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '0.875rem',
    }
  },
  body3: {
    ...defaultTheme.typography.body2,
    fontFamily: appThemeFontFamily,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down(appThemeBreakPoints.values.sm)]: {
      fontSize: '0.75rem',
    }
  },
  button: {
    textTransform: 'initial',
  }
} as ExtendedTypographyOptions;

export const appThemeConfig:ThemeOptions = {
  direction: 'ltr',
  palette: {
    mode: 'light',
    primary: {
      main: '#6940D3',
    },
    secondary: {
      main: '#FFD800',
    },
    success: {
      main: '#2BA57E'
    },
    background: {
    },
    text: {
      primary: '#373D43',
      gray: '#85868A',
    },
    error: {
      main: '#DD1E31',
    },
    common: {
      black: '#37373F',
    },
    dark: createColor('#000'),
    light: createColor('#fff'),
  },
  breakpoints: appThemeBreakPoints,
  typography: appThemeTypography,
  components: appThemeComponents,
}

const theme = createTheme(appThemeConfig);

export default theme;
