import React from 'react';
import Box from '@mui/material/Box';
import Content from '../../../../layouts/Main/components/Content';
import {Link, Navigate, useParams} from 'react-router-dom';
import {pages} from '../content';
import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';

const View = () => {
  const {t, i18n} = useTranslation();
  let { id } = useParams();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const currentLocale = i18n.language?.split('-')[0];

  const content = pages.find((el) => el.id === id)

  if(!content) {
    return (
      <Navigate to="list"/>
    )
  }

  return (
    <Content title={t('questions.content.title', {ns: 'pages'})}>
      <Box sx={{p: 3, minHeight: 480}}>
        <Box mb={3} maxWidth={800}>
          {content.content(currentLocale)}
        </Box>
        <Button
          size="medium"
          variant="outlined"
          fullWidth={isDownMd}
          component={Link}
          to={'/dashboard/questions/list'}
        >
          {t('back')}
        </Button>
      </Box>
    </Content>
  );
};

export default View;
