import React from 'react';
import Typography from '@mui/material/Typography';
import {Nullable, SxPropsObject} from '../../../../../types';
import Box from '@mui/material/Box';
import {AnswerVariant} from '../../../../../api/types';
import LinearProgress from '@mui/material/LinearProgress';
import {useTranslation} from 'react-i18next';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';

interface Props {
  question: string;
  anonymous: boolean;
  answers: Nullable<Array<Partial<AnswerVariant>>>;
}

const VoteResult = (props: Props) => {
  const {question, anonymous, answers} = props;
  const {t} = useTranslation();

  const NoResults = useNoDataOverlay(
    t('no.answers'),
    null,
    true,
  );

  const totalCount = answers?.reduce((total, el) => {
    return total + Number(el?.votesCount)
  }, 0) || 0;

  return (
    <Box sx={styles.container}>
      <Typography variant="subtitle2" sx={{mt: 2, mb: 1}}>
        {question}
      </Typography>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="body2" color="text.gray" sx={{mb: 2}} >
          {anonymous ? t('vote.anonymous') : t('vote.public')}
        </Typography>
        <Typography variant="subtitle2" component="span">
          {t('measure.vote.plural', {count: totalCount})}
        </Typography>
      </Box>
      {answers && answers.length > 0 ? answers?.map((item, index) => {
        let value = 0
        if(totalCount !== 0) {
          value = Math.round(100 * (Number(item?.votesCount) / totalCount)) / 100;
        }

        return (
          <Box key={String(item.id) + index} sx={styles.item}>
            <Box sx={styles.itemHead}>
              <Typography variant="body2" component="span" color="text.gray">
                {item.answer}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {value}%
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={value}
              color="warning"
              sx={styles.progress}
            />
          </Box>
        )
      }) : (
        <NoResults/>
      )}
    </Box>
  );
};

const styles: SxPropsObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  item: {
    mb: 2
  },
  itemHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progress: (theme) => ({
    height: theme.spacing(1),
    mt: 1,
    borderRadius: 0.5,

    '&.MuiLinearProgress-bar': {
      backgroundColor: '#F95C00',
    }
  }),
}

export default VoteResult;
