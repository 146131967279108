import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import {SxPropsObject} from '../../types';
import Header from './components/Header';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Navigation from './components/Navigation';
import {getSxStyle} from '../../utils';
import grey from '@mui/material/colors/grey';
import {useSelector} from 'react-redux';
import {authSelector} from '../../redux/auth/selectors';
import ErrorBoundary from '../../components/ErrorBoundary';

type Props = {
  children?: React.ReactNode;
}

const Main:React.FC<Props> = () => {
  const [openNavigation, setNavigationOpen] = React.useState<boolean>(false);
  const navigationWidth = 256;

  const onClose = () => setNavigationOpen(false)

  const location = useLocation();
  const {user} = useSelector(authSelector);

  if (!user) {
    return <Navigate to="/auth/sign-in" replace state={{ from: location }}/>;
  }

  return (
    <Box sx={styles.container}>
      <Container maxWidth={false}>
        <Box sx={styles.inner}>
          <Header
            open={openNavigation}
            setOpen={setNavigationOpen}
          />
          <Navigation
            width={navigationWidth}
            open={openNavigation}
            onClose={onClose}
          />
          <Box
            component="main"
            sx={(theme) => {
              return {
                ...getSxStyle(styles.content, theme),
                width: {
                  lg: `calc(100% - ${navigationWidth}px)`,
                  xs: '100%',
                }
              }
            }}
          >
            <Toolbar sx={styles.toolbar}/>
            <Box sx={styles.contentInner}>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    p: 0,
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    maxWidth: '100vw',
    background: grey[50],
  },
  inner: () => ({
    display: 'flex',
    flex: 1,
    minHeight: '100%',
  }),
  content: () => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }),
  contentInner: {
    py: 4,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: (theme) => ({
    py: 0,
    px: {
      xs: 0,
    },
    minHeight: {
      sm: theme.spacing(10)
    },
  })
}

export default Main;
