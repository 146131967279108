import React from 'react';
import Box from '@mui/material/Box';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {Link, useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import {ownerResponseToState, renderFullName, statusColor} from '../../../../utils';
import Content from '../../../../layouts/Main/components/Content';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'owner',
      headerName: t('field.owner.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={styles.cellContent}>
            <Typography variant="subtitle2" gutterBottom>
              {renderFullName(row)}
            </Typography>
            <Typography variant="body3" color="text.gray">
              {row.phone}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'school',
      headerName: t('field.name.school.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={styles.cellContent}>
            <Typography variant="subtitle2" gutterBottom>
              {row.School.name}
            </Typography>
            <Typography variant="body3" color="text.gray">
              {row.School.address}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'contact',
      headerName: t('field.contact.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={styles.cellContent}>
            <Typography variant="subtitle2" gutterBottom>
              {renderFullName(row.School.Contact)}
            </Typography>
            <Typography variant="body3" color="text.gray">
              {row.School.Contact.phone}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'children',
      headerName: t('field.children.count.label'),
      width: 120,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2" gutterBottom>
            {row?.School?.children?.length  || 0}
          </Typography>
        );
      }
    },
    {
      field: 'payment',
      headerName: t('field.date.payment.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Box sx={styles.cellContent}>
            <Typography variant="subtitle2" gutterBottom>
              {row?.payment?.date?.from}
            </Typography>
            <Typography variant="body3" color="text.gray">
              {t('period.to')} {row?.payment?.date?.to}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.school.label'),
      flex: 0,
      minWidth: 220,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {active} = params?.row?.School || {}
        const chipColor = statusColor(active);

        return (
          <Chip
            label={t(`school.status.${active ? 'active' : 'deactivated'}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        )
      }
    },
  ];

  return (
    <Content title={t('owners.list.title', {ns: 'pages'})}>
      <ResourceList
        name="owners"
        title={t('owners.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any) => {
          return entities.map((el: any) => {
            return ownerResponseToState(el);
          })
        }}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={Link}
            to="/dashboard/owners/create"
          >
            {t('add.value')}
          </Button>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        onRowClick={(params) => {
          navigate(`/dashboard/owners/edit/${params.id}`)
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
