import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import resourceSaga from './resource/saga';

export function* saga(): Generator {
  yield all([
    authSaga(),
    resourceSaga()
  ])
}
