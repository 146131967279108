import Typography from '@mui/material/Typography';
import React from 'react';
import {Translation} from 'react-i18next';
import Box from '@mui/material/Box';

const data = {
  id: 'newNews',
  title: (
    <Translation>{(t) => t('questions.list.item.newNews.title', {ns: 'pages'})}</Translation>
  ),
  content: (locale: string): JSX.Element => (
    <>
      <Typography variant="h5" component="h4" mb={4}>
        <Translation>{(t) => t('questions.list.item.newNews.title', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newNews.content.text.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newNews.content.subtitle.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newNews.content.text.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newNews1.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newNews.content.subtitle.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p">
        <Translation>{(t) => t('questions.list.item.newNews.content.text.2', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newNews2.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Box component="img" src={`/assets/images/${locale}/questions/newNews3.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
    </>
  )
}
export default data;
