import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {expenseStateToRequest} from '../../../../utils';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {
  const user = useSelector(userSelector);

  return (
    <ResourceCreate
      name="expenses"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
          const transformed = expenseStateToRequest(values);
          return onCreate(transformed, onSuccess, onError, redirect)
        }

        const initialValues = {
          name: null,
          amount: null,
          date: new Date(),
          comment: null,
          media: null,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
