import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Spinner from '../../../../../components/Spinner';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import localeRu from '@fullcalendar/core/locales/ru';
import localeUa from '@fullcalendar/core/locales/uk';
import localeEn from '@fullcalendar/core/locales/en-gb';
import format from 'date-fns/format';
import {FullCalendarContainer} from '../../../../../styled';
import {SERVER_DATE_FORMAT} from '../../../../../constants';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {PopoverPosition} from '@mui/material/Popover/Popover';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import {CalendarViewType} from '../../../../../types';
import {DatesSetArg, EventClickArg} from '@fullcalendar/core';
import Controls from '../../../../../components/calendar/Controls';
import theme from '../../../../../config/theme';
import {animateScroll} from 'react-scroll';
import CalendarPopover from '../../../../../components/calendar/Popover';
import API from '../../../../../api';
import {userSelector} from '../../../../../redux/auth/selectors';
import {scheduleLessonsToCalendarEvents} from '../../../../../utils';
import useSchedulePopoverData from '../../../../../hooks/useSchedulePopoverData';
import {useParams} from 'react-router-dom';


const Calendar = () => {
  const user = useSelector(userSelector);
  const {id} = useParams();
  const ref = useRef<FullCalendar>(null);

  const {t, i18n} = useTranslation();
  let calendarLocale = i18n.language?.split('-')[0];
  if (calendarLocale === 'ua') {
    calendarLocale = 'uk';
  }

  const [viewType, setViewType] = useState<CalendarViewType>('dayGridMonth');
  const [autoHeight, setAutoHeight] = useState<boolean>(false);
  const [viewTitle, setViewTitle] = useState<string>('');

  const [anchorPosition, setAnchorPosition] = React.useState<PopoverPosition | undefined>(undefined);
  const [eventId, setEventId] = React.useState<number | null>(null);
  const [period, setPeriod] = useState<Array<string> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<Array<any>>([]);
  const entity = entities.find((el) => el.id === eventId);

  const {scheduleId} = entity?.extendedProps || {};

  const {loading: popoverLoading, items: popoverItems} = useSchedulePopoverData({
    id: scheduleId,
    fetching: Boolean(anchorPosition),
    event: entity,
  })

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (period) {
          const response = await API.teachers.schedule(`/kinder-garden/${user?.schoolId}`, Number(id), {
            from: period[0],
            to: period[1],
          });
          const transformed = scheduleLessonsToCalendarEvents(response.data.items);
          setEntities(transformed);
        }
      } catch (err) {
        console.info('Error: Can\'t load teacher schedule')
      } finally {
        setLoading(false)
      }
    })()
    return () => {
      setEntities([]);
    };
  }, [period, id, user?.schoolId]);

  useLayoutEffect(() => {
    if((viewType === 'timeGridDay')) {
      const indicator = document.querySelector('.fc-timegrid-now-indicator-line');
      const {top} = indicator?.getBoundingClientRect() || {};
      animateScroll.scrollTo(Number(top));
    }
  }, [viewType]);

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  const onSetPeriod = useCallback(
    (start: string, end: string) => {
      const currentPeriod = [
        format(new Date(start), SERVER_DATE_FORMAT),
        format(new Date(end), SERVER_DATE_FORMAT)
      ];

      setPeriod(prevPeriod => {
        if (prevPeriod === null || (prevPeriod.toString() !== currentPeriod.toString())) {
          return currentPeriod;
        }
        return prevPeriod;
      });
    }, []);

  const onChangeView = (value: CalendarViewType): void => {
    ref?.current?.getApi().changeView(value);
    setViewType(value);
  };

  const onSetViewTitle = (title: string): void => {
    setViewTitle(title);
  };

  const handleEventClick = (info: EventClickArg): void => {
    info.jsEvent.preventDefault();
    info.jsEvent.stopPropagation();
    setEventId(info?.event?.id ? Number(info?.event?.id) : null);
    setAnchorPosition({
      left: info.jsEvent.clientX,
      top: info.jsEvent.clientY,
    });
  };


  return (
    <FullCalendarContainer>
      <Controls
        calendar={ref?.current}
        viewTitle={viewTitle}
        viewType={viewType}
        onChangeView={onChangeView}
      />
      <Box sx={{position: 'relative', overflowX: 'auto'}}>
        {loading && (
          <Spinner sx={{
            position: 'absolute',
            height: '100%',
          }}/>
        )}
        <Box sx={{minWidth: {xs: theme.breakpoints.values.md, md: 'auto'}}} >
          <FullCalendar
            ref={ref}
            height={autoHeight ? 'auto' : undefined}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            windowResizeDelay={0}
            navLinks
            eventMaxStack={3}
            dayMaxEventRows={3}
            dayMaxEvents={3}
            headerToolbar={{
              left: '',
              center: '',
              right: ''
            }}
            initialView="dayGridMonth"
            editable={false}
            locales={[localeRu, localeUa, localeEn]}
            locale={calendarLocale}
            selectMirror={true}
            weekends={true}
            nowIndicator={true}
            eventOrderStrict
            events={entities}
            eventClick={handleEventClick}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              hour12: false
            }}
            navLinkDayClick={(date) => {
              ref?.current?.getApi()?.gotoDate(date);
              onChangeView('timeGridDay');
            }}
            datesSet={(info: DatesSetArg) => {
              //@ts-ignore
              const title = info.view?.getCurrentData().viewTitle;
              onSetPeriod(info.startStr, info.endStr);
              onSetViewTitle(title);
              setAutoHeight(info.view.type !== 'dayGridMonth');
            }}
            noEventsContent={() => (
              <NoData/>
            )}
          />
        </Box>
      </Box>
      <CalendarPopover
        loading={popoverLoading}
        anchorPosition={anchorPosition}
        setAnchorPosition={setAnchorPosition}
        items={popoverItems}
        footer={null}
      />
    </FullCalendarContainer>
  );
};
export default React.memo(Calendar);
