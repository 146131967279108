import React, {Dispatch, SetStateAction, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {ContentTabs, SxPropsObject} from '../../../../types';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {a11yProps, getSxStyle} from '../../../../utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        display: value !== index ? 'none' : 'flex'
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tabpanel-${index}`}
      {...other}
    >
      {value === index ? (
        <>
          {children}
        </>
      ) : null}
    </Box>
  );
};

type ChildrenProps = React.ReactNode | (() => React.ReactNode);

interface Props {
  title: React.ReactNode;
  head?: React.ReactNode;
  panel?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  actions?: React.ReactNode;
  footer?: React.ReactNode;
  children?: ChildrenProps;
  activeTab?: number;
  setActiveTab?: Dispatch<SetStateAction<number>>;
  tabs?: ContentTabs;
}

const Content = (props: Props) => {
  const {
    title,
    head,
    panel,
    left,
    right,
    actions,
    tabs,
    activeTab: initialTab = 0,
    setActiveTab: setInitialTab,
    children
  } = props;
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [activeTab, setActiveTab] = React.useState<number>(initialTab);

  useEffect(() => {
    setActiveTab(initialTab)
  }, [initialTab])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(Number(newValue));
    if(typeof setInitialTab === 'function') {
      setInitialTab(Number(newValue))
    }
  };


  function renderChildren() {
    if(typeof children === 'function') {
      return children();
    }

    return children || null;
  }

  function renderContent(content: any | {left: React.ReactNode, right: React.ReactNode}): React.ReactNode {
    const {left, right} = content;

    return (
      <Grid
        container
        sx={{
          flex: 1,
          flexWrap: 'nowrap',
          flexDirection: {
            md: 'row',
            xs: 'column',
          }
        }}
      >
        {left || right ? (
          <>
            {Boolean(left) && (
              <Grid item xs={12} md>
                <Box sx={styles.contentItem}>
                  {left}
                </Box>
              </Grid>
            )}
            {Boolean(left || right) && (
              <Divider orientation={isDownMd ? 'horizontal' : 'vertical'} flexItem/>
            )}
            {Boolean(right) && (
              <Grid item xs={12} md>
                <Box sx={styles.contentItem}>
                  {right}
                </Box>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12} md>
            <Box sx={styles.contentItem}>
              {content}
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={{mb: 3}}>
        {title}
      </Typography>
      {panel}
      <Paper
        elevation={2}
        sx={(theme) => {
          return {
            ...getSxStyle(styles.content, theme),
            ...(Boolean(actions) ? {
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              pb: {
                md: 10,
                xs: 0,
              },
            } : {})
          }
        }}
      >
        {Boolean(head) && (
          <Grid item xs={12} md={12}>
            <Box sx={{
              ...styles.contentItem,
              pb: {
                lg: 0,
                xs: 0,
              },
            }}>
              {head}
              <Divider sx={{mt: 2}}/>
            </Box>
          </Grid>
        )}
        {Boolean(left && right) && (
          renderContent({left, right})
        )}
        {Boolean(tabs) && (
          <>
            <Tabs
              sx={styles.tabs}
              value={activeTab}
              onChange={handleChangeTab}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Sign Up method tabs"
            >
              {tabs?.map(({label, color}, index) => {
                return (
                  <Tab
                    key={index}
                    sx={(theme) => {
                      const style = getSxStyle(styles.tab, theme)
                      return {
                        ...style,
                        ...(Boolean(color) ? {
                          color,
                          '&.Mui-selected': {
                            color,
                          },
                        } : {})
                      }
                    }}
                    label={label}
                    color="red"
                    {...a11yProps(index)}
                  />
                )
              })}
            </Tabs>
            {tabs?.map((tab, index) => {
              let tabContent = tab.content?.left || tab.content?.right ? {
                left:  tab.content?.left,
                right: tab.content?.right
              } : tab.content

              return (
                <TabPanel key={index} value={activeTab} index={index}>
                  {renderContent(tabContent)}
                </TabPanel>
              )
            })}
          </>
        )}
        {renderChildren()}
        {Boolean(actions) && (
          <Box sx={styles.actions}>
            {actions}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    borderRadius: 2,
    flex: 1,
  },
  contentItem: {
    p: {
      lg: 4,
      xs: 2,
    },
  },
  actions: (theme) => ({
    position: {
      md: 'absolute',
      xs: 'relative'
    },
    bottom: 0,
    left: 0,
    right: 0,
    height: {
      md: theme.spacing(10),
      xs: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '0px -8px 16px rgba(40, 52, 101, 0.06)',
    background: theme.palette.common.white,
    px: {
      md: 4,
      xs: 2,
    },
    py: {
      md: 0,
      xs: 2,
    },
  }),
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'divider',

    '& .MuiTab-root': {
      p: 3,
    },

    [theme.breakpoints.down('sm')]: {
      px: 3
    }
  }),
  tab: () => ({
    textTransform: 'capitalize',
  }),
}

export default Content;
