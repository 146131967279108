import Typography from '@mui/material/Typography';
import React from 'react';
import {Translation} from 'react-i18next';
import Box from '@mui/material/Box';

const data = {
  id: 'newLesson',
  title: (
    <Translation>{(t) => t('questions.list.item.newLesson.title', {ns: 'pages'})}</Translation>
  ),
  content: (locale: string): JSX.Element => (
    <>
      <Typography variant="h5" component="h4" mb={4}>
        <Translation>{(t) => t('questions.list.item.newLesson.title', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.text.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.subtitle.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.text.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson1.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.subtitle.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p">
        <Translation>{(t) => t('questions.list.item.newLesson.content.text.2', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson2.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson3.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.subtitle.2', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.text.3', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson4.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson5.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.subtitle.3', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newLesson.content.text.4', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newLesson6.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
    </>
  )
}
export default data;
