import {useSelector} from 'react-redux';
import {userSelector} from '../redux/auth/selectors';
const usePermission = () => {
  const user = useSelector(userSelector);
  const role = user?.role || null;

  return (value: string | string[] | undefined): boolean => {
    if(value === undefined) {
      return true
    }

    if(role) {
      if(Array.isArray(value)) {
        return value.includes(role)
      }
      return role === value
    }
    return false;
  };
};

export default usePermission;
