import React, {useCallback, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {Child, Group, Lesson, SxPropsObject, Teacher} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {FormValues} from '../../types';
import MenuItem from '@mui/material/MenuItem';
import Schedule from '../Schedule';
import Persons from '../../../../../components/Persons';
import {
  ChildResponsePayload,
  GroupResponsePayload,
  LessonResponsePayload,
  TeacherResponsePayload
} from '../../../../../api/types';
import {
  a11yProps,
  apiErrorsToFormik,
  childResponseToState, getDuration,
  groupsResponseToState, lessonResponseToState,
  renderFullName,
  teacherResponseToState
} from '../../../../../utils';
import API from '../../../../../api';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {useNavigate} from 'react-router-dom';
import {DATE_FORMAT, LESSON_TYPES} from '../../../../../constants';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import Groups from '../../../../../components/Groups';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../../../components/TabPanel';

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'teacherId':'Teacher.id',
  'lessonId':'Lesson.id',
  'kinderGardenId':'schoolId',
  'quantityPlaces':'places',
}

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t, i18n} = useTranslation();
  const currentLocale = i18n.language?.split('-')[0];
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const initialActiveTab = ['individual-permanent', 'individual-temporary'].includes(initialValues.type) ? 'children' : 'groups';
  const [activeTab, setActiveTab] = useState<string>(initialActiveTab);

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`schedules.content.title.${mode}`, {ns: 'pages'});

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
          dirty,
          resetForm,
          isSubmitting
        }) => {

        const tabs = [
          {
            value: 'groups',
            content: (
              <Box sx={{pt: 2}}>
                <AutoCompleteField<Partial<Group>, true, undefined, undefined>
                  sx={styles.field}
                  multiple
                  limitTags={2}
                  fullWidth
                  size="medium"
                  transformer={(entities: Array<GroupResponsePayload>): Array<Group> => {
                    return entities?.map((entity) => {
                      return groupsResponseToState(entity)
                    }) || [];
                  }}
                  TextFieldProps={{
                    name: "Groups",
                    required: true,
                    label: t('field.groups.label'),
                    placeholder: t('field.groups.placeholder'),
                    margin: 'normal',
                    InputLabelProps: { shrink: true },
                    error: Boolean(touched?.Groups && errors?.Groups),
                    helperText: (
                      <ErrorMessage
                        name="Groups"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )
                  }}
                  fetch={() => API.groups.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return option?.name || '';
                  }}
                  value={values?.Groups || []}
                  onChange={(event, value) => {
                    if(!value) {
                      setFieldValue('Groups', [])
                    } else {
                      setFieldValue('Groups', value)
                    }
                  }}
                  onBlur={handleBlur}
                />
                <Typography variant="subtitle1" sx={{mt: 2}}>
                  {t('schedules.content.section.list.groups', {ns: 'pages'})}
                </Typography>
                <Groups
                  items={values.Groups || []}
                  emptyText="no.groups"
                  onClick={(id: number) => {
                    navigate(`/dashboard/groups/edit/${id}`)
                  }}
                  onDelete={(id: number) => {
                    const updated = values?.Groups?.filter((item) => {
                      return item.id !== id;
                    }) || [];

                    setFieldValue('Groups', updated)
                  }}
                />
              </Box>
            ),
            disabled: ['individual-permanent', 'individual-temporary'].includes(values.type)
          },
          {
            value: 'children',
            content: (
              <Box sx={{pt: 2}}>
                <AutoCompleteField<Partial<Child>, true, undefined, undefined>
                  sx={styles.field}
                  multiple
                  limitTags={2}
                  fullWidth
                  size="medium"
                  transformer={(entities: Array<ChildResponsePayload>): Array<Child> => {
                    return entities?.map((entity) => {
                      return childResponseToState(entity);
                    }) || [];
                  }}
                  TextFieldProps={{
                    name: "Children",
                    required: false,
                    label: t('field.children.label'),
                    placeholder: t('field.children.placeholder'),
                    margin: 'normal',
                    InputLabelProps: { shrink: true },
                    error: Boolean(touched?.Children && errors?.Children),
                    helperText: (
                      <ErrorMessage
                        name="Children"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )
                  }}
                  fetch={() => API.children.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return renderFullName(option);
                  }}
                  value={values?.Children || []}
                  onChange={(event, value) => {
                    if(!value) {
                      setFieldValue('Children', [])
                    } else {
                      setFieldValue('Children', value)
                    }
                  }}
                  onBlur={handleBlur}
                />
                <Typography variant="subtitle1" sx={{mt: 2}}>
                  {t('schedules.content.section.list.children', {ns: 'pages'})}
                </Typography>
                <Persons
                  items={values.Children || []}
                  emptyText="no.children"
                  onClick={(id: number) => {
                    navigate(`/dashboard/children/edit/${id}`)
                  }}
                  onDelete={(id: number) => {
                    const updated = values?.Children?.filter((item) => {
                      return item.id !== id;
                    }) || [];

                    setFieldValue('Children', updated)
                  }}
                />
              </Box>
            ),
            disabled: ['main'].includes(values.type),
          }
        ];

        const handleChangeType: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
          const {value} = event.target;
          let Groups = values.Groups;
          let Children = values.Children;

          if(['individual-permanent', 'individual-temporary'].includes(value)) {
            Groups = [];
            setActiveTab('children');
          }

          if(['main'].includes(value)) {
            Children = [];
            setActiveTab('groups');
          }

          setValues({
            ...values,
            Groups,
            Children,
            type: value as Lesson['type'],
            Lesson: {
              id: null,
              name: null,
              duration: null,
              type: value as Lesson['type']
            },
          });
        }

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('schedules.content.subtitle.lessons', {ns: 'pages'})}
                  </Typography>

                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <AutoCompleteField<Partial<Teacher>, undefined, undefined, undefined>
                        sx={styles.field}
                        fullWidth
                        size="medium"
                        transformer={(entities: Array<TeacherResponsePayload>): Array<Teacher> => {
                          return entities?.map((entity) => {
                            return teacherResponseToState(entity);
                          });
                        }}
                        TextFieldProps={{
                          name: "Teacher",
                          required: true,
                          label: t('field.teacher.label'),
                          placeholder: t('field.teacher.placeholder'),
                          margin: 'normal',
                          InputLabelProps: { shrink: true },
                          error: Boolean(touched?.Teacher && errors?.Teacher),
                          helperText: (
                            <ErrorMessage
                              name="Teacher.id"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )
                        }}
                        fetch={() => API.teachers.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        getOptionLabel={(option) => {
                          return renderFullName(option);
                        }}
                        value={values?.Teacher?.id ? values.Teacher : null}
                        onChange={(event, value) => {
                          if(!value) {
                            setFieldValue('Teacher', {
                              id: null,
                              name: null,
                              surname: null,
                              patronymic:  null,
                              relationship: null,
                            })
                          }
                          setFieldValue('Teacher', value)
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        sx={styles.field}
                        name="type"
                        value={values.type || ''}
                        onChange={handleChangeType}
                        onBlur={handleBlur}
                        error={Boolean(touched.type && errors.type)}
                        helperText={(
                          <ErrorMessage
                            name="type"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.lesson.type.label')}
                        placeholder={t('field.lesson.type.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (value) => {
                            if (value as string === undefined) {
                              return (t('field.lesson.type.placeholder'));
                            }
                            const current = LESSON_TYPES.find((el) => el.value === value)
                            return current?.label || '';
                          }
                        }}
                      >
                        {LESSON_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <AutoCompleteField<Partial<Lesson>, undefined, undefined, undefined>
                    sx={styles.field}
                    disabled={!Boolean(values.type)}
                    fullWidth
                    size="medium"
                    transformer={(entities: Array<LessonResponsePayload>): Array<Lesson> => {
                      return entities?.map((entity) => {
                        return lessonResponseToState(entity);
                      });
                    }}
                    TextFieldProps={{
                      name: "Lesson",
                      required: true,
                      label: t('field.lesson.label'),
                      placeholder: t('field.lesson.placeholder'),
                      margin: 'normal',
                      InputLabelProps: { shrink: true },
                      error: Boolean(touched?.Lesson && errors?.Lesson),
                      helperText: Boolean(!errors?.Lesson && values.Lesson?.id) ? (
                        <Typography variant="body3" color="text.gray">
                          {t('field.duration.label')}{': '}
                          <Typography variant="body3" color="text.gray" component="span" fontWeight={600}>
                            {getDuration(values?.Lesson?.duration || 0, currentLocale)}
                          </Typography>
                        </Typography>
                        ) : (
                        <ErrorMessage
                          name="Lesson.id"
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )
                    }}
                    fetch={() => API.lessons.getAllSimple(`/kinder-garden/${user?.schoolId}`, {type: values.type})}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    getOptionLabel={(option) => {
                      return option?.name || '';
                    }}
                    value={values?.Lesson?.id ? values.Lesson : null}
                    onChange={(event, value) => {
                      if(!value) {
                        setFieldValue('Lesson', {
                          id: null,
                          name: null,
                        })
                      }
                      setFieldValue('Lesson', value)
                    }}
                    onBlur={handleBlur}
                  />
                  <Typography variant="subtitle1" sx={{mt: 3, mb: 2}}>
                    {t('schedules.content.subtitle.schedule', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        format={DATE_FORMAT}
                        slots={{
                          textField: TextField,
                        }}
                        slotProps={{
                          textField: {
                            sx: styles.field,
                            name: "startAt",
                            onBlur: handleBlur,
                            error: Boolean(touched?.startAt && errors?.startAt),
                            helperText: (
                              <ErrorMessage
                                name="startAt"
                                render={(message) => t(message, {ns: 'validation'})}
                              />
                            ),
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            InputLabelProps: { shrink: true },
                            size: "medium",
                            autoComplete: 'off'
                          }
                        }}
                        value={values.startAt || ''}
                        label={t('field.date.start.label')}
                        onChange={(value: any) => {
                          setFieldValue('startAt', value, true);
                        }}
                      />
                    </Grid>
                    {(values.type === 'group-temporary' || values.type === 'individual-temporary') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          sx={styles.field}
                          name="quantity"
                          required
                          value={values.quantity || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.quantity && errors.quantity)}
                          helperText={(
                            <ErrorMessage
                              name="quantity"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )}
                          margin="normal"
                          fullWidth
                          label={t('field.quantity.lessons.label')}
                          placeholder={t('field.quantity.lessons.placeholder')}
                          InputLabelProps={{ shrink: true }}
                          size="medium"
                          autoComplete='off'
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Schedule/>
                </>
              )}
              right={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('schedules.content.subtitle.groups', {ns: 'pages'})}
                    {' / '}
                    {t('schedules.content.subtitle.children', {ns: 'pages'})}
                  </Typography>
                  <Tabs
                    value={activeTab}
                    onChange={(event, value) => {
                      setActiveTab(value);
                    }}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Schedules tabs"
                  >
                    {tabs.map(({value, disabled}) => {
                      return (
                        <Tab
                          key={value}
                          disabled={disabled}
                          sx={{
                            px: 1,
                          }}
                          label={t(`schedules.content.tabs.${value}`, {ns: 'pages'})}
                          value={value}
                          {...a11yProps(value)}
                        />
                      )
                    })}
                  </Tabs>
                  {tabs?.map(({ content, value}) => {
                    return (
                      <TabPanel key={value} value={activeTab} index={value}>
                        {content}
                      </TabPanel>
                    )
                  })}
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.lesson') : t('edit.lesson'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
