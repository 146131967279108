import * as Yup from 'yup';

export const password = (mode: string) => Yup.string()
  .test('password', (password, {createError, path}) => {
    const valid = (/^(?=.*[0-9])(?=.*[a-zA-Z])(?!.* ).{8,32}$/u.test(password || ''));
    let message = 'required';
    if(mode === 'create') {
      if(!password) {
        return createError({
          path,
          message,
        })
      }
    }

    if(password && !valid) {
      message = 'password.invalid';
      if(password.length < 8) {
        message = 'password.min';
      }
      if(password.length > 32) {
        message = 'password.max';
      }
      return createError({
        path,
        message,
      })
    }
    return true;
  })
  .nullable(true);
