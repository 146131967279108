import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {SxPropsObject} from '../../types';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import {getSxStyle} from '../../utils';
import Typography from '@mui/material/Typography';

const LOCALES = ['ua', 'en', 'ru', 'es'];

type Props = {
  sx?: SxProps<Theme>
}

const Locales:React.FC<Props> = (props) => {
  const {t, i18n} = useTranslation();
  const {sx} = props;
  const currentLocale = i18n.language?.split('-')[0];
  const [locale, setLocale] = React.useState(currentLocale);

  const handleChangeLocale = async (event: SelectChangeEvent) => {
    const value = event.target.value;
    await i18n.changeLanguage(value);
    setLocale(value);
  };

  return (
    <FormControl
      size="small"
      sx={(theme) => {
        const style = getSxStyle(styles.container, theme)
        return {
          ...style,
          ...(getSxStyle(sx || {}, theme))
        }
      }}
    >
      <Select
        sx={styles.select}
        value={locale}
        defaultValue={locale}
        onChange={handleChangeLocale}
        renderValue={(value) => {
          return value;
        }}
        MenuProps={{ disableScrollLock: true }}
        inputProps={{
          sx: styles.selectInput,
          'aria-label': 'Locales',
        }}
      >
        {LOCALES.map((item, index) => {
          return (
            <MenuItem value={item} key={index} sx={styles.option}>
              <Typography variant="body2" component="span">
                {t(`language.${item}`)}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
};

const styles:SxPropsObject = {
  container: (theme) => ({
    mr: 4,
    ml: 'auto',
    [theme.breakpoints.down('sm')]: {
      mr: 0,
    }
  }),
  select: {
    textTransform: 'uppercase',

    '&, &.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      right: 0,
    }
  },
  selectInput: (theme) => ({
    p: theme.spacing(0.5),
    border: '0 none',

    '&.MuiOutlinedInput-input.MuiInputBase-input': {
      pr: theme.spacing(3),
    }
  }),
  option: {

  },
}

export default Locales;
