//@ts-ignore
export const noticeOpen = (state, { payload }) => {
  state.items.push(payload)
};

//@ts-ignore
export const noticeClose = (state, action) => {
  const { payload } = action;
  //@ts-ignore
  state.items = state.items.map((notification) => {
    const shouldDismiss = payload.dismissAll || notification.options.key === payload.key;
    return shouldDismiss ? { ...notification, dismissed: true } : { ...notification }
  });
};

//@ts-ignore
export const noticeRemove = (state, { payload }) => {
  //@ts-ignore
  state.items = state.items.filter((notification) => {
    return notification.options.key !== payload;
  });
}
