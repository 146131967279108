import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import uaTranslations from './languages/uk-UA';
import enTranslations from './languages/en-US';
import esTranslations from './languages/es-ES';
import ruTranslations from './languages/ru-RU';

const resources = {
  es: esTranslations,
  en: enTranslations,
  ru: ruTranslations,
  uk: uaTranslations,
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    defaultNS: "common",
    resources,
    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false,
    },
  });

