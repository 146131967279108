import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import {generatePassword, userStateToRequest} from '../../../../utils';
import Form from '../components/Form';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {

  const user = useSelector(userSelector);
  const password = generatePassword();

  return (
    <ResourceCreate
      name="parents"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
          const transformed = userStateToRequest(values);
          //@ts-ignore
          return onCreate(transformed, () => onSuccess(transformed), onError, redirect)
        }

        const initialValues = {
          name: null,
          surname: null,
          patronymic: null,
          email: null,
          phone: null,
          password: password,
          birthday:  null,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
