import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import { SxPropsObject } from '../../../types';

type Props = {
  statusCode?: number;
}

const Errors:React.FC<Props> = (props) => {
  const {statusCode} = props;

  const {t} = useTranslation();

  return (
    <Box sx={styles.container}>
      <Container>
        <Box sx={styles.content}>
          <Typography
            sx={styles.heading}
            variant="h2"
            component="h2"
            color="text.primary"
          >
            {statusCode}
          </Typography>
          <Typography variant="body1" component="p" color="text.primary" mb={3}>
            <Trans t={t} i18nKey={`pages:errors.${statusCode}.text`}/>
          </Typography>
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={Link}
            to="/"
          >
            {t('home')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    flex: 1,
    minHeight: '100vh',
  },
  content: {
    py: 14,
    textAlign: 'center',
  },
  heading: (theme) => ({
    fontWeight: 400,
    lineHeight: 1,
    mb: 1,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(12),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(10),
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(8),
    },
  }),
  text: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      'br': {
        display: 'none',
      }
    },
  }),
  button: (theme) => ({
    borderRadius: theme.spacing(1),
    textTransform: 'initial',
    minWidth: 298,
    mx: 'auto',

    [theme.breakpoints.down('sm')]: {
      minWidth: 224,
    },
  }),
}

export default Errors;
