import React, {useCallback} from 'react';
import {Child, Group, Lesson, SxPropsObject} from '../../../../types';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import {
  childResponseToState,
  groupsResponseToState,
  renderFullName,
  scheduleLessonsToCalendarEvents
} from '../../../../utils';
import Calendar from '../components/Calendar';
import format from 'date-fns/format';
import {LESSON_TYPES, SERVER_DATE_FORMAT} from '../../../../constants';
import {endOfMonth, startOfMonth} from 'date-fns';
import {ChildResponsePayload, GroupResponsePayload} from '../../../../api/types';
import API from '../../../../api';
import AutoCompleteField from '../../../../components/AutoCompleteFiled';
import SvgIcon from '@mui/material/SvgIcon';
import theme from '../../../../config/theme';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ReactComponent as ChevronDownIcon} from '../../../../assets/icons/chevronDown.svg';
import Sorting from '../../../../components/Sorting';
import Grid from '@mui/material/Grid';
import {CircleColor} from '../../../../styled';
import useSortParams from '../../../../hooks/useSortParams';

const Actions = () => {
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupParam = JSON.parse(searchParams.get('group') as string);
  const childParam = JSON.parse(searchParams.get('child') as string);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string, type: string) => {
    navigate({
      pathname: `/dashboard/schedules/${path}`,
    }, {
      state: {
        type
      }
    });
  }

  return (
    <>
      <AutoCompleteField<Partial<Group>, undefined, undefined, undefined>
        sx={styles.groupsSelect}
        size="small"
        transformer={(entities: Array<GroupResponsePayload>): Array<Group> => {
          return entities?.map((entity) => {
            return groupsResponseToState(entity);
          }) || [];
        }}
        TextFieldProps={{
          name: "group",
          placeholder: t('field.group.placeholder'),
          margin: 'none',
          InputLabelProps: { shrink: true },
          size: 'small',
        }}
        fetch={() => API.groups.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(option) => {
          return option?.name || '';
        }}
        value={groupParam}
        onChange={(event, value) => {
          if(!value) {
            searchParams.delete('group')
          } else {
            searchParams.set('group', JSON.stringify({
              id: value.id,
              name: value.name,
            }))
          }
          setSearchParams(searchParams)
        }}
      />
      <AutoCompleteField<Partial<Child>, undefined, undefined, undefined>
        sx={styles.groupsSelect}
        size="small"
        transformer={(entities: Array<ChildResponsePayload>): Array<Child> => {
          return entities?.map((entity) => {
            return childResponseToState(entity);
          }) || [];
        }}
        TextFieldProps={{
          name: "child",
          placeholder: t('field.child.placeholder'),
          margin: 'none',
          InputLabelProps: { shrink: true },
          size: 'small',
        }}
        fetch={() => API.children.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(option) => {
          return  option ? renderFullName(option) : '';
        }}
        value={childParam}
        onChange={(event, value) => {
          if(!value) {
            searchParams.delete('child');
          } else {
            searchParams.set('child', JSON.stringify({
              id: value.id,
              name: value.name,
              surname: value.surname,
              patronymic: value.patronymic,
            }));
          }
          setSearchParams(searchParams)
        }}
      />
      <Button
        aria-controls={open ? 'actions' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={styles.button}
        size="medium"
        variant="contained"
        onClick={handleClick}
        endIcon={(
          <SvgIcon
            fontSize="inherit"
            viewBox="0 0 24 24"
            component={ChevronDownIcon}
            sx={{
              transform: `rotateX(${open ? 180 : 0}deg)`,
              transition: theme.transitions.create('transform')
            }}
          />
        )}
      >
        {t('add.value')}
      </Button>
      <Menu
        id="actions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        {LESSON_TYPES.map((el) => {
          return (
            <MenuItem key={el.value} onClick={() => handleNavigate('create',el.value)}>
              {el.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const List = () => {

  const {t} = useTranslation();
  const user = useSelector(userSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const groupParam = JSON.parse(searchParams.get('group') as string);
  const childParam = JSON.parse(searchParams.get('child') as string);
  const periodParam = JSON.parse(searchParams.get('period') as string);
  const typesParam = searchParams.get('types') || LESSON_TYPES.map((el) => el.value).join(',');
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const handleSetCheckedTypes = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: Lesson['type']) => {
    const types = searchParams.get('types');
    let arr = types?.split(',') || [];
    if(arr.includes(name)) {
      arr = arr.filter((el: string) => el !== name);
    } else {
      arr.push(name);
    }
    if(arr?.length > 0) {
      searchParams.set('types', arr.join(','))
    } else {
      searchParams.delete('types')
    }
    setSearchParams(searchParams);
  },[searchParams, setSearchParams]);

  const defaultParams = {
    page: undefined,
    perPage: undefined,
    ...(periodParam ? {
      from: periodParam[0],
      to: periodParam[1]
    } : {
      from: format(startOfMonth(new Date()), SERVER_DATE_FORMAT),
      to: format(endOfMonth(new Date()), SERVER_DATE_FORMAT),
    }),
    ...(groupParam ? {groupId: groupParam.id }: {}),
    ...(childParam ? {childId: childParam.id }: {}),
    ...(typesParam ? {types: typesParam }: {}),
    // ...(sortParam ? {sort: sortParam} : {}),
    // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
  }

  return (
    <Content title={t('lessons.list.title', {ns: 'pages'})}>
      <ResourceList
        name="schedules"
        pathModifier={() => {
          return `/kinder-garden/${user?.schoolId}/lessons/schedule`
        }}
        title={(t('lessons.list.subtitle', {ns: 'pages'}))}
        columns={[]}
        HeadProps={{
          sx: {pb: 0}
        }}
        transformer={(entities: any) => {
          return scheduleLessonsToCalendarEvents(entities);
        }}
        actions={() => (<Actions/>)}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={defaultParams}
      >
        {({entities, loading}) => {
          return (
            <Calendar
              entities={entities}
              loading={loading}
              head={(
                <Grid
                  container
                  spacing={1}
                  component="ul"
                  sx={{
                    p: 0,
                    listStyle: 'none',
                  }}
                >
                  {LESSON_TYPES.map((el, index) => {
                    const checked = typesParam?.includes(el.value as Lesson['type']);
                    return (
                      <Grid item xs={12} sm="auto" key={index} component="li">
                        <Button
                          sx={(theme) => ({
                            borderRadius: 10,
                            color: checked ? theme.palette.text.primary : theme.palette.divider,
                            borderColor: checked ? theme.palette.primary.main : theme.palette.divider,
                          })}
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={(event) => {
                            handleSetCheckedTypes(event, el.value)
                          }}
                          startIcon={(
                            <CircleColor
                              component="span"
                              sx={(theme) => ({
                                background: checked ? el.color : theme.palette.divider,
                              })}
                            />
                          )}
                        >
                          {el.label}
                        </Button>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
            />
          )
        }}
      </ResourceList>
    </Content>
  );
};

const styles: SxPropsObject = {
  groupsSelect: {
    mt: {
      sm: 0,
      xs: 2,
    },
    ml: {
      sm: 2,
      xs: 0
    },
    width: {
      md: 200,
      sm: 160,
      xs: '100%'
    },
    order: {
      sm: 'initial',
      xs: 2
    }
  },
  button: () => ({
    maxWidth: 188,
    ml: {
      md: 4,
      xs: 2,
    },
  }),
}

export default List;
