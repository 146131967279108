import React, {useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {SupportRequest, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {apiErrorsToFormik} from '../../../../../utils';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Chip from '@mui/material/Chip';

interface FormValues extends Pick<
  SupportRequest, 'title' | 'description' | 'comment' | 'status'
> {
}

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => void;
  onDelete?: () => void;
}

const fields = {
  'kinderGardenId': 'schoolId',
};

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`supports.content.title.${mode}`, {ns: 'pages'})

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          dirty,
          resetForm,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('supports.content.subtitle.request', {ns: 'pages'})}
                  </Typography>
                  {mode === 'edit' && (
                    <>
                      <Typography variant="subtitle1" sx={{mb: 1}}>
                        {t('supports.content.section.status', {ns: 'pages'})}
                      </Typography>
                      <RadioGroup
                        sx={{mb: 2}}
                        row
                        aria-labelledby="request-status"
                        name="status"
                        value={values?.status || 'toDo'}
                        onChange={(event, value) => {
                          setFieldValue('status', value)
                        }}
                      >
                        <FormControlLabel
                          disabled={false}
                          value="toDo"
                          control={<Radio />}
                          label={(
                            <Chip
                              label={t(`request.status.todo`)}
                              color="warning"
                              variant="filled"
                              sx={(theme) => ({
                                borderRadius: 2,
                                width: theme.spacing(20),
                              })}
                            />
                          )}
                        />
                        <FormControlLabel
                          disabled={false}
                          value="complete"
                          control={<Radio />}
                          label={(
                            <Chip
                              label={t(`request.status.complete`)}
                              color="success"
                              variant="filled"
                              sx={(theme) => ({
                                borderRadius: 2,
                                width: theme.spacing(20),
                              })}
                            />
                          )}
                        />
                      </RadioGroup>
                    </>
                  )}
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        disabled={mode === 'edit'}
                        sx={styles.field}
                        name="title"
                        value={values.title || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.title && errors.title)}
                        helperText={(
                          <ErrorMessage
                            name="title"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.title.label')}
                        placeholder={t('field.title.placeholder')}
                        InputLabelProps={{shrink: true}}
                        size="medium"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        disabled={mode === 'edit'}
                        sx={styles.field}
                        name="description"
                        value={values.description || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.description && errors?.description)}
                        helperText={(
                          <ErrorMessage
                            name="description"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        fullWidth
                        label={t('field.description.label')}
                        placeholder={t('field.description.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        multiline
                        rows={4}
                      />
                    </Grid>
                    {mode === 'edit' && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          sx={styles.field}
                          name="comment"
                          value={values.comment || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched?.comment && errors?.comment)}
                          helperText={(
                            <ErrorMessage
                              name="comment"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )}
                          margin="normal"
                          fullWidth
                          label={t('field.comment.request.label')}
                          placeholder={t('field.comment.request.placeholder')}
                          InputLabelProps={{ shrink: true }}
                          size="medium"
                          autoComplete='off'
                          multiline
                          rows={4}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              right={(
                <>
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.request') : t('edit.request'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        );
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {},
};

export default Form;
