import React from 'react';
import {createEntity} from '../../../../redux/resource/slice';
import {useDispatch, useSelector} from 'react-redux';
import {entitySelector} from '../../../../redux/resource/selectors';
import {ResourcePathModifier, ResourceType} from '../../../../types';

interface ChildrenProps {
  onCreate: any;
  submitting: boolean;
  loading: boolean;
  errors: any;
}

interface Props {
  name: ResourceType;
  pathModifier?: ResourcePathModifier;
  children: (props: ChildrenProps) => React.ReactNode
}

const Create = (props: Props) => {
  const {name, pathModifier, children} = props;
  const dispatch = useDispatch();
  //@ts-ignore
  const {entityErrors, entitySubmitting, entityLoading} = useSelector(entitySelector(name))

  const onCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
    dispatch(createEntity({
      name,
      pathModifier: pathModifier || null,
      values,
      onSuccess,
      onError,
      redirect,
    }))
  }

  return (
    <>
      {children({
        onCreate,
        submitting : entitySubmitting,
        errors: entityErrors,
        loading: entityLoading,
      })}
    </>
  );
};

export default React.memo(Create);
