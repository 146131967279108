import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as CrossIcon} from '../../../../../assets/icons/cross.svg';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import TextField from '@mui/material/TextField';
import {ErrorMessage, getIn, useFormikContext} from 'formik';
import {TimePicker} from '@mui/x-date-pickers';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {useTheme} from '@mui/material';
import {FormValues} from '../../types';
import {getWeekDays} from '../../../../../utils';
import {ReactComponent as PlusIcon} from '../../../../../assets/icons/plus.svg'

const Schedule = () => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange
  } = useFormikContext<FormValues>();
  const {t, i18n} = useTranslation();
  const currentLocale = i18n.language?.split('-')[0];
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isUpXs = useMediaQuery(theme.breakpoints.up('xs'));

  const {Schedule} = values;

  const weekDays =  getWeekDays(currentLocale)

  const onAdd = () => {
    const items = [...Schedule];
    const time = new Date();
    time.setHours(8, 0, 0, 0)
    items.push({
      day: null,
      time,
    });
    setFieldValue('Schedule', items, true);
  }

  const onRemove = (index: number) => {
    const updated = [...Schedule.slice(0, index), ...Schedule.slice(index + 1)]
    setFieldValue('Schedule', updated, true);
  }

  const disabledAdd = Schedule?.length >= 7;

  return (
    <Box sx={{mt: 3}}>
      {isUpXs && (
        <Box
          sx={{
            pr: 6,
            pb: 1,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme => theme.palette.divider,
            display: {
              sm: 'block',
              xs: 'none'
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body3" textTransform="uppercase" color="text.gray" component="span">
                {t('field.day.label')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body3" textTransform="uppercase" color="text.gray" component="span">
                {t('field.time.start.label')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <List>
        {Schedule.map((item: any, index: number) => {
          const fieldDayName = `Schedule.${index}.day`;
          const fieldTimeName = `Schedule.${index}.time`;

          const fieldDayValue = getIn(values, fieldDayName, undefined);
          const fieldDayError = getIn(errors, fieldDayName, undefined);
          const fieldDayTouched = getIn(touched, fieldDayName, undefined);

          const fieldTimeValue = getIn(values, fieldTimeName, undefined);
          const fieldTimeError = getIn(errors, fieldTimeName, undefined);
          const fieldTimeTouched = getIn(touched, fieldTimeName, undefined);

          const divider = isDownSm && index !== Number(Schedule?.length) - 1;
          const deletable = index > 0;

          return (
            <ListItem
              key={index}
              dense
              disableGutters
              divider={divider}
              secondaryAction={(
                <IconButton
                  edge="end"
                  aria-label="delete"
                  disabled={!deletable}
                  onClick={() => onRemove(index)}
                >
                  <SvgIcon
                    fontSize="inherit"
                    viewBox="0 0 24 24"
                    component={CrossIcon}
                    color="inherit"
                  />
                </IconButton>
              )}
            >
              <Box width="100%">
                <Grid container spacing={{xs: 0, sm: 2}}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      sx={{
                        mt: {
                          sm: 0,
                          xs: 2
                        }
                      }}
                      select
                      name={fieldDayName}
                      value={fieldDayValue || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(fieldDayTouched && fieldDayError)}
                      helperText={(
                        <ErrorMessage
                          name={fieldDayName}
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      label={isDownSm ? t('field.day.label') : null}
                      placeholder={t('field.day.placeholder')}
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => {
                          const current = weekDays.find((el) => el.value === value)
                          if (value as string === '' || !current) {
                            return (t('field.day.placeholder'));
                          }

                          return current?.label;
                        }
                      }}
                    >
                      {weekDays.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      ampm={false}
                      timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          sx: {
                            mt: {
                              sm: 0,
                              xs: 2
                            }
                          },
                          name: fieldTimeName || '',
                          onBlur: handleBlur,
                          error: Boolean(fieldTimeTouched && fieldTimeError),
                          helperText: (
                            <ErrorMessage
                              name={fieldTimeName}
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          ),
                          margin: "normal",
                          required: true,
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                          size: "medium",
                          autoComplete: 'off'
                        }
                      }}
                      value={fieldTimeValue}
                      label={isDownSm ? t('field.time.start.label') : null}
                      onChange={(value: any) => {
                        setFieldValue(fieldTimeName, value, true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </ListItem>
          )
        })}
      </List>
      <Button
        sx={{
          p: 0.5
        }}
        disabled={disabledAdd}
        size="medium"
        variant="text"
        startIcon={(
          <SvgIcon
            viewBox="0 0 24 24"
            component={PlusIcon}
          />
        )}
        onClick={onAdd}
      >
        {t('add.schedule')}
      </Button>
    </Box>
  );
};

export default Schedule;
