import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {groupStateToRequest} from '../../../../utils';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {
  const user = useSelector(userSelector);

  return (
    <ResourceCreate
      name="groups"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = groupStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues = {
          name: null,
          Children: null,
          Teacher: {
            id: null,
            name: null,
            surname: null,
            patronymic: null,
          },
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
