import {Translation} from 'react-i18next';
import {Lesson, Task} from './types';
import {MuiTelInputCountry} from 'mui-tel-input/dist/shared/constants/countries';
import {ReactComponent as TelegramIcon} from './assets/icons/socials/telegram.svg';
import {ReactComponent as TwitterIcon} from './assets/icons/socials/twitter.svg';
import {ReactComponent as FacebookIcon} from './assets/icons/socials/facebook.svg';
import {ReactComponent as InstagramIcon} from './assets/icons/socials/instagram.svg';
import {blue, deepOrange, green, purple, teal, yellow} from '@mui/material/colors';
import heroScreenImageSrcUA from './assets/images/ua/heroScreen.png';
import applicationImageSrcUA from './assets/images/ua/applicationPhone.png';
import screenFirstImageSrcUA from './assets/images/ua/advantagesScreenFist.png';
import screenSecondImageSrcUA from './assets/images/ua/advantagesScreenSecond.png';
import screenThirdImageSrcUA from './assets/images/ua/advantagesScreenThird.png';
import screenForthImageSrcUA from './assets/images/ua/advantagesScreenForth.png';
import screenFifthImageSrcUA from './assets/images/ua/advantagesScreenFifth.png';
import screenSixthImageSrcUA from './assets/images/ua/advantagesScreenSixth.png';
import screenSeventhImageSrcUA from './assets/images/ua/advantagesScreenSeventh.png';

import heroScreenImageSrcEN from './assets/images/en/heroScreen.png';
import applicationImageSrcEN from './assets/images/en/applicationPhone.png';
import screenFirstImageSrcEN from './assets/images/en/advantagesScreenFist.png';
import screenSecondImageSrcEN from './assets/images/en/advantagesScreenSecond.png';
import screenThirdImageSrcEN from './assets/images/en/advantagesScreenThird.png';
import screenForthImageSrcEN from './assets/images/en/advantagesScreenForth.png';
import screenFifthImageSrcEN from './assets/images/en/advantagesScreenFifth.png';
import screenSixthImageSrcEN from './assets/images/en/advantagesScreenSixth.png';
import screenSeventhImageSrcEN from './assets/images/en/advantagesScreenSeventh.png';

import heroScreenImageSrcES from './assets/images/es/heroScreen.png';
import applicationImageSrcES from './assets/images/es/applicationPhone.png';
import screenFirstImageSrcES from './assets/images/es/advantagesScreenFist.png';
import screenSecondImageSrcES from './assets/images/es/advantagesScreenSecond.png';
import screenThirdImageSrcES from './assets/images/es/advantagesScreenThird.png';
import screenForthImageSrcES from './assets/images/es/advantagesScreenForth.png';
import screenFifthImageSrcES from './assets/images/es/advantagesScreenFifth.png';
import screenSixthImageSrcES from './assets/images/es/advantagesScreenSixth.png';
import screenSeventhImageSrcES from './assets/images/es/advantagesScreenSeventh.png';

import heroScreenImageSrcRU from './assets/images/ru/heroScreen.png';
import applicationImageSrcRU from './assets/images/ru/applicationPhone.png';
import screenFirstImageSrcRU from './assets/images/ru/advantagesScreenFist.png';
import screenSecondImageSrcRU from './assets/images/ru/advantagesScreenSecond.png';
import screenThirdImageSrcRU from './assets/images/ru/advantagesScreenThird.png';
import screenForthImageSrcRU from './assets/images/ru/advantagesScreenForth.png';
import screenFifthImageSrcRU from './assets/images/ru/advantagesScreenFifth.png';
import screenSixthImageSrcRU from './assets/images/ru/advantagesScreenSixth.png';
import screenSeventhImageSrcRU from './assets/images/ru/advantagesScreenSeventh.png';
import {ReactNode} from 'react';

const DOMAIN = 'https://api.studycare.io' // or 'http://localhost:80'
const MEDIA_PATH = `${DOMAIN}/media`;

const  PREFERRED_COUNTRIES = ['UA', 'US', 'ES', 'RU'] as Array<MuiTelInputCountry>;

const MIN_CHILD_AGE = 0; // months
const MAX_CHILD_AGE = 216; // months equal 18 years

const MIN_PARENT_AGE = 16; // years

const MIN_TEACHER_AGE = 18; // years
const MAX_TEACHER_AGE = 65; // years

export const PAYMENT_EXPIRED_AFTER = 5;

const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
const SERVER_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
const TIME_FORMAT = 'HH:mm';

const ROUTES_PERMISSIONS = {
  tasks: ['super-admin', 'admin', 'manager'],
  payments: ['super-admin', 'admin', 'manager'],
  owners: ['super-admin'],
  admins: ['super-admin', 'admin', 'manager'],
  complaints: ['super-admin', 'admin', 'manager'],
  support: ['super-admin', 'admin', 'manager'],
  schools: ['super-admin'],
  settings: ['super-admin', 'manager'],
}

export const ASSETS = {
  ua: {
    heroScreenImageSrc: heroScreenImageSrcUA,
    applicationImageSrc: applicationImageSrcUA,
    screenFirstImage: screenFirstImageSrcUA,
    screenSecondImageSrc: screenSecondImageSrcUA,
    screenThirdImageSrc: screenThirdImageSrcUA,
    screenForthImageSrc: screenForthImageSrcUA,
    screenFifthImageSrc: screenFifthImageSrcUA,
    screenSixthImageSrc: screenSixthImageSrcUA,
    screenSeventhImageSrc: screenSeventhImageSrcUA,
  },
  en: {
    heroScreenImageSrc: heroScreenImageSrcEN,
    applicationImageSrc: applicationImageSrcEN,
    screenFirstImage: screenFirstImageSrcEN,
    screenSecondImageSrc: screenSecondImageSrcEN,
    screenThirdImageSrc: screenThirdImageSrcEN,
    screenForthImageSrc: screenForthImageSrcEN,
    screenFifthImageSrc: screenFifthImageSrcEN,
    screenSixthImageSrc: screenSixthImageSrcEN,
    screenSeventhImageSrc: screenSeventhImageSrcEN
  },
  es: {
    heroScreenImageSrc: heroScreenImageSrcES,
    applicationImageSrc: applicationImageSrcES,
    screenFirstImage: screenFirstImageSrcES,
    screenSecondImageSrc: screenSecondImageSrcES,
    screenThirdImageSrc: screenThirdImageSrcES,
    screenForthImageSrc: screenForthImageSrcES,
    screenFifthImageSrc: screenFifthImageSrcES,
    screenSixthImageSrc: screenSixthImageSrcES,
    screenSeventhImageSrc: screenSeventhImageSrcES,
  },
  ru: {
    heroScreenImageSrc: heroScreenImageSrcRU,
    applicationImageSrc: applicationImageSrcRU,
    screenFirstImage: screenFirstImageSrcRU,
    screenSecondImageSrc: screenSecondImageSrcRU,
    screenThirdImageSrc: screenThirdImageSrcRU,
    screenForthImageSrc: screenForthImageSrcRU,
    screenFifthImageSrc: screenFifthImageSrcRU,
    screenSixthImageSrc: screenSixthImageSrcRU,
    screenSeventhImageSrc: screenSeventhImageSrcRU,
  },
} as const;

export const HOME_MENU_ITEMS = [
  {
    value: <Translation>{(t) => t(`navigation.base.features.staff`)}</Translation>,
    path: 'advantages',
  },
  {
    value: <Translation>{(t) => t(`navigation.base.features.parents`)}</Translation>,
    path: 'possibilities',
  },
  {
    value: <Translation>{(t) => t(`navigation.base.features.price`)}</Translation>,
    path: 'pricing',
  },
];

export const SECONDARY_MENU_ITEMS = [
  {
    value: <Translation>{(t) => t(`navigation.base.policy`)}</Translation>,
    path: '/',
  },
  {
    value: <Translation>{(t) => t(`navigation.base.terms`)}</Translation>,
    path: '/',
  },
];

export const SOCIAL_ITEMS = [
  {
    link: '',
    name: 'telegram',
    icon: {
      component: TelegramIcon,
      viewBox: '0 0 32 27',
    }
  },
  {
    link: '',
    name: 'twitter',
    icon: {
      component: TwitterIcon,
      viewBox: '0 0 33 27',
    }
  },
  {
    link: '',
    name: 'facebook',
    icon: {
      component: FacebookIcon,
      viewBox: '0 0 15 27',
    }
  },
  {
    link: '',
    name: 'instagram',
    icon: {
      component: InstagramIcon,
      viewBox: '0 0 27 27',
    }
  }
]

const GENDERS = [
  {
    value: 'male',
    label: (<Translation>{(t) => t('gender.male')}</Translation>),
  },
  {
    value: 'female',
    label: (<Translation>{(t) => t('gender.female')}</Translation>),
  }
];

const CURRENCY_ITEMS = [
  {
    value: 'uah',
    label: (
      <Translation>
        {(t) => `${t('currency.uah.text')} - ${t('currency.uah.symbol')} ${t('currency.uah.value').toUpperCase()}`}
      </Translation>
    ),
  },
  {
    value: 'usd',
    label: (
      <Translation>
        {(t) => `${t('currency.usd.text')} - ${t('currency.usd.symbol')} ${t('currency.usd.value').toUpperCase()}`}
      </Translation>
    ),
  },
  {
    value: 'eur',
    label: (
      <Translation>
        {(t) => `${t('currency.eur.text')} - ${t('currency.eur.symbol')} ${t('currency.eur.value').toUpperCase()}`}
      </Translation>
    ),
  },
  {
    value: 'kzt',
    label: (
      <Translation>
        {(t) => `${t('currency.kzt.text')} - ${t('currency.kzt.symbol')} ${t('currency.kzt.value').toUpperCase()}`}
      </Translation>
    ),
  },
  {
    value: 'aed',
    label: (
      <Translation>
        {(t) => `${t('currency.aed.text')} - ${t('currency.aed.symbol')} ${t('currency.aed.value').toUpperCase()}`}
      </Translation>
    ),
  }
];

const PAYMENT_SYSTEMS = [
  {
    value: 'monobank',
    label: (<Translation>{(t) => t('payment.system.mono')}</Translation>),
    disabled: false,
  },
  {
    value: 'stripe',
    label: (<Translation>{(t) => t('payment.system.stripe')}</Translation>),
    disabled: true,
  }
];

const PAYMENT_TYPES = [
  {
    value: 'one',
    label: (<Translation>{(t) => t('type.one')}</Translation>),
  },
  {
    value: 'two',
    label: (<Translation>{(t) => t('type.two')}</Translation>),
  }
];

const LESSON_TYPES: Array<{
  value: Lesson['type'],
  color: string;
  label: React.ReactNode,
}> = [
  {
    value: 'main',
    color:  blue[100],
    label: (<Translation>{(t) => t('lesson.type.main')}</Translation>),
  },
  {
    value: 'group-permanent',
    color:  yellow[100],
    label: (<Translation>{(t) => t('lesson.type.group.permanent')}</Translation>),
  },
  {
    value: 'group-temporary',
    color:  green[100],
    label: (<Translation>{(t) => t('lesson.type.group.temporary')}</Translation>),
  },
  {
    value: 'individual-permanent',
    color:  green[100],
    label: (<Translation>{(t) => t('lesson.type.individual.permanent')}</Translation>),
  },
  {
    value: 'individual-temporary',
    color:  green[100],
    label: (<Translation>{(t) => t('lesson.type.individual.temporary')}</Translation>),
  }
];

const TASK_STATUSES: Array<{
  value: Task['status'],
  label: ReactNode,
}> = [
  {
    value: 'pending',
    label: (<Translation>{(t) => t('task.status.pending')}</Translation>),
  },
  {
    value: 'in_progress',
    label: (<Translation>{(t) => t('task.status.in_progress')}</Translation>),
  },
  {
    value: 'complete',
    label: (<Translation>{(t) => t('task.status.complete')}</Translation>),
  },
];

const TASK_TYPES = [
  {
    value: 'children',
    color:  deepOrange[100],
    label: (<Translation>{(t) => t('task.type.birthday.child')}</Translation>),
  },
  {
    value: 'parents',
    color:  purple[100],
    label: (<Translation>{(t) => t('task.type.birthday.parent')}</Translation>),
  },
  {
    value: 'pending',
    color:  green[100],
    label: (<Translation>{(t) => t('task.type.pending')}</Translation>),
  },
  {
    value: 'in_progress',
    color:  green[100],
    label: (<Translation>{(t) => t('task.type.in_progress')}</Translation>),
  },
  {
    value: 'complete',
    color:  teal[100],
    label: (<Translation>{(t) => t('task.type.complete')}</Translation>),
  }
];

const LESSON_DURATION = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180];

const POSITIONS = [
  {
    value: 'teacher',
    label: (<Translation>{(t) => t('positions.teacher')}</Translation>),
    disabled: false,
  },
  {
    value: 'teacher of physical education',
    label: (<Translation>{(t) => t('positions.physical')}</Translation>),
    disabled: false,
  },
  {
    value: 'design',
    label: (<Translation>{(t) => t('positions.design')}</Translation>),
    disabled: true,
  },
  {
    value: 'informatics',
    label: (<Translation>{(t) => t('positions.informatics')}</Translation>),
    disabled: true,
  }
];

export const POSSIBILITIES_ITEMS = [
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.0.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.0.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen1.png',
  },
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.1.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.1.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen2.png',
  },
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.2.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.2.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen3.png',
  },
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.3.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.3.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen4.png',
  },
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.4.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.4.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen5.png',
  },
  {
    heading: <Translation>{(t) => t(`home.possibilities.items.5.heading`, {ns:'pages'})}</Translation>,
    text: <Translation>{(t) => t(`home.possibilities.items.5.text`, {ns:'pages'})}</Translation>,
    image: 'possibilities/screen6.png',
  }
];

const APPS = {
  android: {
    link: 'https://play.google.com/store/apps/details?id=io.studycare.oneapp'
  },
  apple: {
    link: 'https://apps.apple.com/us/app/study-care/id1662337676'
  }
}

const PER_PAGE_OPTIONS = [15, 25, 50, 100];

export {
  DOMAIN,
  PREFERRED_COUNTRIES,
  ROUTES_PERMISSIONS,
  MEDIA_PATH,
  MIN_CHILD_AGE,
  MAX_CHILD_AGE,
  MIN_PARENT_AGE,
  MIN_TEACHER_AGE,
  MAX_TEACHER_AGE,
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  GENDERS,
  PER_PAGE_OPTIONS,
  POSITIONS,
  LESSON_TYPES,
  LESSON_DURATION,
  PAYMENT_TYPES,
  TASK_TYPES,
  TASK_STATUSES,
  APPS,
  CURRENCY_ITEMS,
  PAYMENT_SYSTEMS,
}

