import React, {useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {Nullable, Person, SxPropsObject, Task} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {FormValues} from '../../types';
import Persons from '../../../../../components/Persons';
import {PersonResponsePayload} from '../../../../../api/types';
import {apiErrorsToFormik, personResponseToState, renderFullName} from '../../../../../utils';
import API from '../../../../../api';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {useNavigate} from 'react-router-dom';
import {DATE_TIME_FORMAT, TASK_STATUSES} from '../../../../../constants';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import usePermission from '../../../../../hooks/usePermission';
import MenuItem from '@mui/material/MenuItem';
import History from '../History';

interface Props {
  entity?: Nullable<Task>;
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'teacherId':'Teacher.id',
  'kinderGardenId':'schoolId',
}

const Form = (props: Props) => {
  const {
    entity,
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const permission = usePermission();

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`tasks.content.title.${mode}`, {ns: 'pages'});

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          isSubmitting
        }) => {

        let users = (<></>)
        if(permission(['super-admin'])) {
          users = (
            <>
              <Typography variant="h6" sx={{mt: 3, mb: 3}}>
                {t('tasks.content.subtitle.users', {ns: 'pages'})}
              </Typography>
              <Typography variant="subtitle1" sx={{mb: 2}}>
                {t('tasks.content.section.addition.users', {ns: 'pages'})}
              </Typography>
              <AutoCompleteField<Partial<Person>, true, undefined, undefined>
                sx={styles.field}
                multiple
                limitTags={2}
                fullWidth
                size="medium"
                transformer={(entities: Array<PersonResponsePayload>): Array<Person> => {
                  return entities?.map((entity) => {
                    return personResponseToState(entity);
                  }) || [];
                }}
                TextFieldProps={{
                  name: "Users",
                  required: false,
                  label: t('field.users.label'),
                  placeholder: t('field.users.placeholder'),
                  margin: 'normal',
                  InputLabelProps: { shrink: true },
                  error: Boolean(touched?.Users && errors?.Users),
                  helperText: (
                    <ErrorMessage
                      name="Users"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )
                }}
                fetch={() => API.admins.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                getOptionLabel={(option) => {
                  return renderFullName(option);
                }}
                value={values?.Users || []}
                onChange={(event, value) => {
                  if(!value) {
                    setFieldValue('Users', [])
                  }
                  setFieldValue('Users', value)
                }}
                onBlur={handleBlur}
              />
              <Typography variant="subtitle1" sx={{my: 2}}>
                {t('tasks.content.section.users', {ns: 'pages'})}
              </Typography>
              <Persons
                items={values.Users || []}
                emptyText="no.users"
                onClick={(id: number) => {
                  navigate(`/dashboard/admins/edit/${id}`)
                }}
                onDelete={(id: number) => {
                  const updated = values?.Users?.filter((item) => {
                    return item.id !== id;
                  }) || [];

                  setFieldValue('Users', updated)
                }}
              />
            </>
          )
        }

        const left = (
          <>
            <Typography variant="h6" sx={{mb: 3}}>
              {t('tasks.content.subtitle.details', {ns: 'pages'})}
            </Typography>
            <Typography variant="subtitle1" sx={{mb: 2}}>
              {t('tasks.content.section.main', {ns: 'pages'})}
            </Typography>
            <TextField
              sx={styles.field}
              name="title"
              value={values.title || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.title && errors.title)}
              helperText={(
                <ErrorMessage
                  name="title"
                  render={(message) => t(message, {ns: 'validation', min: 2, max: 1024})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.title.label')}
              placeholder={t('field.title.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              sx={styles.field}
              name="description"
              value={values.description || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.description && errors.description)}
              helperText={(
                <ErrorMessage
                  name="description"
                  render={(message) => t(message, {ns: 'validation', min: 12})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.description.label')}
              placeholder={t('field.description.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              multiline
              rows={4}
            />
            <Typography variant="subtitle1" sx={{mt: 3, mb: 2}}>
              {t('tasks.content.section.duration', {ns: 'pages'})}
            </Typography>
            <Grid container spacing={{xs: 0, sm: 2}}>
              <Grid item xs={12} sm={6}>
                <DateTimePicker
                  disablePast
                  format={DATE_TIME_FORMAT}
                  //@ts-ignore
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      sx={styles.field}
                      name="startAt"
                      onBlur={handleBlur}
                      error={Boolean(touched?.startAt && errors?.startAt)}
                      helperText={(
                        <ErrorMessage
                          name="startAt"
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                    />
                  )}
                  value={values.startAt || ''}
                  label={t('field.date.start.label')}
                  onChange={(value: any) => {
                    setFieldValue('startAt', value, true);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePicker
                  disablePast
                  format={DATE_TIME_FORMAT}
                  //@ts-ignore
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      sx={styles.field}
                      name="finishAt"
                      onBlur={handleBlur}
                      error={Boolean(touched?.finishAt && errors?.finishAt)}
                      helperText={(
                        <ErrorMessage
                          name="finishAt"
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                    />
                  )}
                  value={values.finishAt || ''}
                  label={t('field.date.end.label')}
                  onChange={(value: any) => {
                    setFieldValue('finishAt', value, true);
                  }}
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <TimePicker*/}
              {/*    ampm={false}*/}
              {/*    renderInput={(props) => (*/}
              {/*      <TextField*/}
              {/*        name="time"*/}
              {/*        onBlur={handleBlur}*/}
              {/*        error={Boolean(touched.time && errors.time)}*/}
              {/*        helperText={(*/}
              {/*          <ErrorMessage*/}
              {/*            name="time"*/}
              {/*            render={(message) => t(message, {ns: 'validation'})}*/}
              {/*          />*/}
              {/*        )}*/}
              {/*        margin="normal"*/}
              {/*        required*/}
              {/*        fullWidth*/}
              {/*        InputLabelProps={{ shrink: true }}*/}
              {/*        size="medium"*/}
              {/*        autoComplete='off'*/}
              {/*        {...props}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*    value={values.time}*/}
              {/*    label={t('field.time.start.label')}*/}
              {/*    onChange={(value: any) => {*/}
              {/*      setFieldValue('time', value, true);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <TextField*/}
              {/*    select*/}
              {/*    sx={styles.field}*/}
              {/*    name="duration"*/}
              {/*    value={values.duration || ''}*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={handleBlur}*/}
              {/*    error={Boolean(touched.duration && errors.duration)}*/}
              {/*    helperText={(*/}
              {/*      <ErrorMessage*/}
              {/*        name="duration"*/}
              {/*        render={(message) => t(message, {ns: 'validation'})}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*    margin="normal"*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    label={t('field.duration.label')}*/}
              {/*    placeholder={t('field.duration.placeholder')}*/}
              {/*    InputLabelProps={{ shrink: true }}*/}
              {/*    size="medium"*/}
              {/*    autoComplete='off'*/}
              {/*    SelectProps={{*/}
              {/*      displayEmpty: true,*/}
              {/*      renderValue: (value) => {*/}
              {/*        if (value as string === '') {*/}
              {/*          return (t('field.duration.placeholder'));*/}
              {/*        }*/}
              {/*        const current = LESSON_DURATION.find((el) => el === value)*/}
              {/*        return current ? getDuration(current, currentLocale) : '';*/}
              {/*      }*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {LESSON_DURATION.map((option) => {*/}
              {/*      return (*/}
              {/*        <MenuItem key={option} value={option}>*/}
              {/*          {getDuration(option, currentLocale)}*/}
              {/*        </MenuItem>*/}
              {/*      )*/}
              {/*    })}*/}
              {/*  </TextField>*/}
              {/*</Grid>*/}
            </Grid>
            {users}
          </>
        )

        let right = (<></>)
        if(permission(['super-admin'])) {
          right = (
            <>
              <Typography variant="h6" sx={{mb: 3}}>
                {t('tasks.content.subtitle.status', {ns: 'pages'})}
              </Typography>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  disabled={mode === 'create'}
                  sx={styles.field}
                  name="status"
                  value={values.status || undefined}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.status && errors?.status)}
                  helperText={(
                    <ErrorMessage
                      name="status"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.status.label')}
                  placeholder={t('field.status.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => {
                      if (value as string === undefined) {
                        return (t('field.status.placeholder'));
                      }
                      const current = TASK_STATUSES.find((el) => el.value === value)
                      return current?.label || '';
                    }
                  }}
                >
                  {TASK_STATUSES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Typography variant="subtitle1" sx={{my: 2}}>
                {t('tasks.content.section.history', {ns: 'pages'})}
              </Typography>

              {mode === 'edit' && entity && (
                <History entity={entity}/>
              )}
            </>
          )
        }

        // @ts-ignore
        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={left}
              right={right}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.task') : t('edit.task'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
