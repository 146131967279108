import React, {useEffect} from 'react';
import Content from '../../../layouts/Main/components/Content';
import Statistics from './components/Statistics';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import {Divider, Tab} from '@mui/material';
import usePermission from '../../../hooks/usePermission';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';

const Payments = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermission();
  const tabs= ['invoices', 'expenses'];
  const [searchParams, setSearchParams] = useSearchParams();
  const date = useSelector((state: any) => state.app.payments.date);
  const onChange = (event: React.SyntheticEvent, value: string) => {
    navigate({
      pathname: `/dashboard/payments/${value}/list`,
      search: location.search,
    })
  };

  useEffect(() => {
    if(date) {
      searchParams.set('period', format(date, 'yyyy-MM'));
    } else {
      searchParams.set('period', '');
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeTabIndex = tabs.findIndex((el) => location.pathname.indexOf(el) !== -1);

  const visibleStatistic = permission(['super-admin', 'manager']);

  return (
    <Content
      title={t('payments.title', {ns: 'pages'})}
      panel={visibleStatistic ? <Statistics /> : null}
    >
      <Tabs
        value={tabs[activeTabIndex]}
        onChange={onChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Payments tabs"
      >
        {tabs.map((el) => {
          return (
            <Tab
              key={el}
              value={el}
              label={t(`payments.tabs.${el}`, {ns: 'pages'})}
              sx={() => ({
                textTransform: 'uppercase',
                fontWeight: 600,
              })}
            />
          )
        })}
      </Tabs>
      <Divider/>
      <Outlet/>
    </Content>
  );
};

export default Payments;
