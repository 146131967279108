import {useSearchParams} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';

interface Values {
  handleChangeQueryDebounce: (value: string) => void;
}

const useSearchParamsHandler = (): Values => {

  let [searchParams, setSearchParams] = useSearchParams();

  const handleChangeQueryDebounce = useDebouncedCallback((value: string) => {
    searchParams.set('query', value)
    setSearchParams(searchParams);
  }, 400);

  return {
    handleChangeQueryDebounce,
  }
};

export default useSearchParamsHandler;
