import React from 'react';
import {FormValues} from '../types';
import ResourceCreate from '../../../../components/Resource/components/Create';
import {ownerStateToRequest} from '../../../../utils';
import Form from '../components/Form';
import Spinner from '../../../../components/Spinner';

const Create = () => {

  return (
    <ResourceCreate
      name="owners"
    >
      {({onCreate, submitting, loading}) => {
        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = ownerStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues: FormValues = {
          name: null,
          surname: null,
          patronymic: null,
          email: null,
          phone: null,
          password: null,
          Bank: {
            iban: null,
            receiver: null,
            edrpou: null,
            name: null,
            token: null,
          },
          Contact: {
            name: null,
            surname: null,
            patronymic: null,
            email: null,
            phone: null,
          },
          School: {
            name: null,
            address: null,
            active: true,
            currency: null,
            paymentSystem: null,
          },
          payment: {
            date: {
              current: null,
              expiry:  null,
            },
            value: null,
            comment: null,
            type: null,
          }
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
