import React, {useEffect} from 'react';
import {deleteEntity, fetchEntity, updateEntity} from '../../../../redux/resource/slice';
import {useDispatch, useSelector} from 'react-redux';
import {entitySelector} from '../../../../redux/resource/selectors';
import {ResourcePathModifier, ResourceType} from '../../../../types';

interface ChildrenProps {
  entity: any;
  onEdit: any;
  onDelete: (redirect?: boolean) => void;
  submitting: boolean;
  loading: boolean;
  errors: any;
}

interface Props {
  id: number;
  name: ResourceType;
  pathModifier?: ResourcePathModifier;
  transformer?: <T, R>(entities: Array<T>) => Array<R>;
  children(props: ChildrenProps): React.ReactNode
}

const Edit = (props: Props) => {
  const {id, name, pathModifier, transformer, children} = props;
  const dispatch = useDispatch();
  //@ts-ignore
  const {entity, entityErrors, entitySubmitting, entityLoading} = useSelector(entitySelector(name));

  useEffect(() => {
    dispatch(fetchEntity({
      id,
      name,
      pathModifier: pathModifier || null,
    }))
  }, [id, name, pathModifier, dispatch]);

  const onEdit = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
    dispatch(updateEntity({
      id: id || 0,
      name,
      pathModifier: pathModifier || null,
      values,
      onSuccess,
      onError,
      redirect
    }))
  }

  const onDelete = (redirect?: boolean) => {
    dispatch(deleteEntity({
      id: id || 0,
      name,
      pathModifier: pathModifier || null,
      redirect,
    }))
  }

  return (
    <>
      {children({
        entity: transformer ? transformer(entity) : entity,
        onEdit,
        onDelete,
        submitting : entitySubmitting,
        errors: entityErrors,
        loading: entityLoading,
      })}
    </>
  );
};

export default React.memo(Edit);
