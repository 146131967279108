import React from 'react';
import {FormValues} from '../types';
import ResourceCreate from '../../../../components/Resource/components/Create';
import {postStateToRequest} from '../../../../utils';
import Form from '../components/Form';
import {useLocation} from 'react-router-dom';
import {PostType} from '../../../../types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {
  const user = useSelector(userSelector);
  const location = useLocation();

  const type = location?.state?.type || 'news';


  return (
    <ResourceCreate
      name="posts"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = postStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues: FormValues = {
          name: null,
          Groups: null,
          text: null,
          type: type as PostType,
          media: null,
          Answers: [{
            id: null,
            answer: ''
          },{
            id: null,
            answer: ''
          }],
          multiple: false,
          anonymous: true,
          selectedGroupsType: 'all',
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
