import React from 'react';
import {Nullable, Payment, SxPropsObject} from '../../../../../types';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import {useTranslation} from 'react-i18next';
import {getSxStyle} from '../../../../../utils';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import format from 'date-fns/format';
import {parseISO} from 'date-fns';
import {DATE_FORMAT} from '../../../../../constants';

interface Props {
  sx?: SxProps<Theme>;
  data: Nullable<Array<Payment>>
}

const Logs = (props: Props) => {

  const {sx, data} = props;
  const {t} = useTranslation();

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      variant="outlined"
      sx={(theme) => {
        return {
          ...styles.container,
          ...(getSxStyle(sx || {}, theme))
        }
      }}
    >
      {data && data.length > 0 ? (
        <Table sx={styles.table} aria-label="Payment status logs">
          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={styles.row}
              >
                <TableCell sx={styles.row} align="left" scope="row">
                  <Typography variant="subtitle2" gutterBottom>
                    {format(parseISO(row.date.current || ''), DATE_FORMAT)}
                  </Typography>
                  <Typography variant="body2">
                    {t('period.to')}{' '}
                    {format(parseISO(row.date.expiry || ''), DATE_FORMAT)}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.row} align="center">
                  {row.value}
                </TableCell>
                {/*<TableCell sx={styles.row} align="center">*/}
                {/*  {t(`payment.type.${row.type}`)}*/}
                {/*</TableCell>*/}
                <TableCell sx={styles.row} align="right">
                  <Chip
                    label={t(`payment.status.${row.status}`)}
                    color="success"
                    variant="filled"
                    sx={{
                      borderRadius: 2
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <NoData/>
      )}
    </TableContainer>
  );
};

const styles:SxPropsObject = {
  container: {

  },
  table: {},
  row: {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
  cell: {}
}

export default Logs;
