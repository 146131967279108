import React, {useCallback, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {FormValues} from '../../types';
import {
  CURRENCY_ITEMS,
  DATE_FORMAT,
  PAYMENT_SYSTEMS,
  PAYMENT_TYPES,
  PREFERRED_COUNTRIES
} from '../../../../../constants';
import MenuItem from '@mui/material/MenuItem';
import {apiErrorsToFormik, generatePassword} from '../../../../../utils';
import Switch from '@mui/material/Switch';
import usePermission from '../../../../../hooks/usePermission';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as EyeIcon} from '../../../../../assets/icons/eye.svg';
import {ReactComponent as EyeCrossIcon} from '../../../../../assets/icons/eye-cross.svg';
import {ReactComponent as KeyIcon} from '../../../../../assets/icons/key.svg';
import {useSelector} from 'react-redux';
import {appSelector} from '../../../../../redux/app/selectors';
import {MuiTelInput} from 'mui-tel-input';
import {MuiTelInputCountry} from 'mui-tel-input/dist/shared/constants/countries';

const fields = {
  'firstName':'name',
  'middleName':'patronymic',
  'phoneNumber':'phone',
  'kinderGardenId':'schoolId',
  'kinderGardenName':'School.name',
  'kinderGardenAddress':'School.address',
  'isActive':'School.active',
  'contactSurname':'Contact.surname',
  'contactFirstName':'Contact.name',
  'contactMiddleName':'Contact.patronymic',
  'contactEmail':'Contact.email',
  'contactPhoneNumber':'Contact.phone',
  'iban':'Bank.iban',
  'edrpou':'Bank.edrpou',
  'receiver':'Bank.receiver',
  'bankName':'Bank.name',
  'bankToken':'Bank.token',
}

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const permission = usePermission();
  const {countryCode} = useSelector(appSelector);
  const [visiblePassword, setVisiblePassword] = useState(true);

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`owners.content.title.${mode}`, {ns: 'pages'})

  const schema = validationSchema(mode);

  const left = 0;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          isSubmitting,
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('owners.content.subtitle.owner', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('owners.content.section.main', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="surname"
                        value={values?.surname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.surname && errors?.surname)}
                        helperText={(
                          <ErrorMessage
                            name="surname"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.surname.label')}
                        placeholder={t('field.surname.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="name"
                        value={values?.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.name && errors?.name)}
                        helperText={(
                          <ErrorMessage
                            name="name"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.name.label')}
                        placeholder={t('field.name.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="patronymic"
                        value={values?.patronymic || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.patronymic && errors?.patronymic)}
                        helperText={(
                          <ErrorMessage
                            name="patronymic"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required={false}
                        fullWidth
                        label={t('field.patronymic.label')}
                        placeholder={t('field.patronymic.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="email"
                        value={values?.email || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={(
                          <ErrorMessage
                            name="email"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.email.label')}
                        placeholder={t('field.email.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiTelInput
                        name="phone"
                        defaultCountry={countryCode as MuiTelInputCountry}
                        preferredCountries={PREFERRED_COUNTRIES}
                        value={values.phone || ''}
                        onChange={(value, info) => {
                          setFieldValue('phone', info.numberValue)
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={(
                          <ErrorMessage
                            name="phone"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.phone.label')}
                        placeholder={t('field.phone.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        type={visiblePassword ? 'text' : 'password'}
                        name="password"
                        value={values.password || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={(
                          <ErrorMessage
                            name="password"
                            render={(message) => t(message, {ns: 'validation', min: 8, max: 32})}
                          />
                        )}
                        margin="normal"
                        required={mode === 'create'}
                        fullWidth
                        label={t('field.password.label')}
                        placeholder={t('field.password.placeholder')}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                edge="start"
                                size="small"
                                aria-label="set generated password"
                                onClick={() => {
                                  const password = generatePassword();
                                  setFieldValue('password', password);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <SvgIcon
                                  fontSize="inherit"
                                  viewBox="0 0 24 24"
                                  component={KeyIcon}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                size="small"
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setVisiblePassword((value) => !value);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <SvgIcon
                                  fontSize="inherit"
                                  viewBox="0 0 24 24"
                                  component={visiblePassword ? EyeIcon : EyeCrossIcon}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        size="medium"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" sx={{mt: 3, my: 2}}>
                    {t('owners.content.section.school', {ns: 'pages'})}
                  </Typography>
                  <TextField
                    sx={styles.field}
                    name="School.name"
                    value={values?.School?.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.School?.name && errors?.School?.name)}
                    helperText={(
                      <ErrorMessage
                        name="School.name"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.name.school.label')}
                    placeholder={t('field.name.school.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="School.address"
                    value={values?.School?.address || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.School?.address && errors?.School?.address)}
                    helperText={(
                      <ErrorMessage
                        name="School.address"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.address.label')}
                    placeholder={t('field.address.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  {permission(['super-admin']) && (
                    <>
                      <Typography variant="subtitle2" sx={{my: 1}}>
                        {t('owners.content.section.settings', {ns: 'pages'})}
                      </Typography>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body2">
                            {t('school.status.deactivated')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            name="School.active"
                            size="medium"
                            disabled={!permission(['super-admin'])}
                            checked={Boolean(values?.School?.active)}
                            onChange={(event, value) => {
                              setFieldValue( 'School.active', value);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {t('school.status.active')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <TextField
                    select
                    sx={styles.field}
                    name="School.currency"
                    value={values?.School?.currency || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.School?.currency && errors?.School?.currency)}
                    helperText={(
                      <ErrorMessage
                        name="School.currency"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.currency.label')}
                    placeholder={t('field.currency.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (value) => {
                        if (value as string === '') {
                          return (t('field.currency.placeholder'));
                        }
                        const current = CURRENCY_ITEMS.find((el) => el.value === value)
                        return current?.label || '';
                      }
                    }}
                  >
                    {CURRENCY_ITEMS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    sx={styles.field}
                    name="School.paymentSystem"
                    value={values?.School?.paymentSystem || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.School?.paymentSystem && errors?.School?.paymentSystem)}
                    helperText={(
                      <ErrorMessage
                        name="School.paymentSystem"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.payment.system.label')}
                    placeholder={t('field.payment.system.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (value) => {
                        if (value as string === '') {
                          return (t('field.payment.system.placeholder'));
                        }
                        const current = PAYMENT_SYSTEMS.find((el) => el.value === value)
                        return current?.label || '';
                      }
                    }}
                  >
                    {PAYMENT_SYSTEMS.map((option) => (
                      <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="subtitle1" sx={{mt: 3, my: 2}}>
                    {t('owners.content.section.contact', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="Contact.surname"
                        value={values?.Contact?.surname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.surname && errors?.Contact?.surname)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.surname"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.surname.label')}
                        placeholder={t('field.surname.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="Contact.name"
                        value={values?.Contact?.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.name && errors?.Contact?.name)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.name"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.name.label')}
                        placeholder={t('field.name.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="Contact.patronymic"
                        value={values?.Contact?.patronymic || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.patronymic && errors?.Contact?.patronymic)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.patronymic"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required={false}
                        fullWidth
                        label={t('field.patronymic.label')}
                        placeholder={t('field.patronymic.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="Contact.email"
                        value={values?.Contact?.email || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.email && errors?.Contact?.email)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.email"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.email.label')}
                        placeholder={t('field.email.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiTelInput
                        name="Contact.phone"
                        defaultCountry={countryCode as MuiTelInputCountry}
                        preferredCountries={PREFERRED_COUNTRIES}
                        value={values?.Contact?.phone || ''}
                        onChange={(value, info) => {
                          setFieldValue('Contact.phone', info.numberValue)
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.phone && errors?.Contact?.phone)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.phone"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.phone.label')}
                        placeholder={t('field.phone.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              right={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('owners.content.subtitle.payment', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('owners.content.section.requisites', {ns: 'pages'})}
                  </Typography>
                  <TextField
                    sx={styles.field}
                    name="Bank.name"
                    value={values?.Bank?.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.Bank?.name && errors?.Bank?.name)}
                    helperText={(
                      <ErrorMessage
                        name="Bank.name"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    fullWidth
                    label={t('field.name.bank.label')}
                    placeholder={t('field.name.bank.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="Bank.token"
                    value={values?.Bank?.token || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.Bank?.token && errors?.Bank?.token)}
                    helperText={(
                      <ErrorMessage
                        name="Bank.token"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    fullWidth
                    label={t('field.token.label')}
                    placeholder={t('field.token.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="Bank.receiver"
                    value={values?.Bank?.receiver || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.Bank?.receiver && errors?.Bank?.receiver)}
                    helperText={(
                      <ErrorMessage
                        name="Bank.receiver"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    fullWidth
                    label={t('field.recipient.label')}
                    placeholder={t('field.recipient.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="Bank.edrpou"
                    value={values?.Bank?.edrpou || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.Bank?.edrpou && errors?.Bank?.edrpou)}
                    helperText={(
                      <ErrorMessage
                        name="Bank.edrpou"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    fullWidth
                    label={t('field.edrpou.label')}
                    placeholder={t('field.edrpou.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="Bank.iban"
                    value={values?.Bank?.iban || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.Bank?.iban && errors?.Bank?.iban)}
                    helperText={Boolean(touched?.Bank?.iban && errors?.Bank?.iban) ? (
                      <ErrorMessage
                        name="Bank.iban"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                      ) : (t('field.iban.helper')
                    )}
                    margin="normal"
                    fullWidth
                    label={t('field.iban.label')}
                    placeholder={t('field.iban.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <Box sx={styles.head}>
                    <Typography variant="subtitle1" gutterBottom={false}>
                      {t('field.status.payment.label')}
                    </Typography>
                    <Chip
                      label={t(`payment.status.expired`)}
                      color="error"
                      variant="filled"
                      sx={{
                        borderRadius: 2
                      }}
                    />
                  </Box>
                  <Box sx={styles.paymentInfo}>
                    <Typography variant="subtitle2" component="span">
                      {t(`owners.content.payment.text.${left < 0 ? 'overdue' : 'next'}`, {ns: 'pages'})}
                    </Typography>
                    <Typography variant="subtitle2" component="span">
                      {t('measure.days.plural', {count: left})}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={80}
                    color="warning"
                    sx={styles.progress}
                  />
                  <Typography variant="body2" sx={{mt: 2, mb: 2}}>
                    {t('owners.content.payment.text.change', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        format={DATE_FORMAT}
                        //@ts-ignore
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            sx={styles.field}
                            name="payment.date.current"
                            onBlur={handleBlur}
                            error={Boolean(touched?.payment?.date?.current && errors?.payment?.date?.current)}
                            helperText={touched?.payment?.date?.current && errors?.payment?.date?.current}
                            margin="normal"
                            required
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            size="medium"
                            autoComplete='off'
                          />
                        )}
                        value={values?.payment?.date?.current || null}
                        label={t('field.date.payment.label')}
                        onChange={(value: any) => {
                          setFieldValue('payment.date.current', value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        format={DATE_FORMAT}
                        //@ts-ignore
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            sx={styles.field}
                            name="payment.date.expiry"
                            onBlur={handleBlur}
                            error={Boolean(touched?.payment?.date?.expiry && errors?.payment?.date?.expiry)}
                            helperText={touched?.payment?.date?.expiry && errors?.payment?.date?.expiry}
                            margin="normal"
                            required
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            size="medium"
                            autoComplete='off'
                          />
                        )}
                        value={values?.payment?.date?.expiry || null}
                        label={t('field.date.payment.expiry.label')}
                        onChange={(value: any) => {
                          setFieldValue('payment.date.expiry', value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="name"
                        value={values?.payment?.value || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.payment?.value && errors?.payment?.value)}
                        helperText={touched?.payment?.value && errors?.payment?.value}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.payment.label')}
                        placeholder={t('field.payment.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        sx={styles.field}
                        name="payment.type"
                        value={values?.payment?.type || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.payment?.type  && errors?.payment?.type)}
                        helperText={(
                          <ErrorMessage
                            name="payment.type"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.payment.type.label')}
                        placeholder={t('field.payment.type.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (value) => {
                            if (value as string === '') {
                              return (t('field.payment.type.placeholder'));
                            }
                            const current = PAYMENT_TYPES.find((el) => el.value === value)
                            return current?.label || '';
                          }
                        }}
                      >
                        {PAYMENT_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="payment.comment"
                        value={values?.payment?.comment || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.payment?.comment && errors?.payment?.comment)}
                        helperText={touched?.payment?.comment && errors?.payment?.comment}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.comment.payment.label')}
                        placeholder={t('field.comment.payment.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    sx={{
                      mt: 2,
                    }}
                  >
                    {t('add.payment')}
                  </Button>
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.owner') : t('edit.owner'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
  head: {
    mt: 6,
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  paymentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  progress: (theme) => ({
    height: theme.spacing(1),
    mt: 1,
    borderRadius: 0.5,

    '&.MuiLinearProgress-bar': {
      backgroundColor: '#F95C00',
    }
  }),
}

export default Form;
