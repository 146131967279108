import Box from '@mui/material/Box';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {SxPropsObject} from '../../types';
import {CircularProgressProps} from '@mui/material/CircularProgress/CircularProgress';
import {alpha} from '@mui/material';
import {getSxStyle} from '../../utils';

const Spinner = (props: Partial<CircularProgressProps>) => {
  const {sx, ...rest} = props;
  return (
    <Box sx={(theme) => {
      const style = getSxStyle(styles.container, theme)
      return {
        ...style,
        ...(getSxStyle(sx || {}, theme))
      }
    }}>
      <CircularProgress
        sx={styles.progress}
        {...rest}
      />
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
    position: 'relative',
    width: '100%',
    pointerEvents: 'none',
    zIndex: 999,
    background: theme => alpha(theme.palette.common.white, 0.66),
  },
}

export default Spinner;
