import React, {useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {Lesson, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {FormValues} from '../../types';
import MenuItem from '@mui/material/MenuItem';
import Files from '../../../../../components/Files';
import Persons from '../../../../../components/Persons';
import {apiErrorsToFormik, getDuration, getMediaUri} from '../../../../../utils';
import {useNavigate} from 'react-router-dom';
import {LESSON_DURATION, LESSON_TYPES} from '../../../../../constants';

interface Props {
  entity?: Lesson;
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'teacherId':'Teacher.id',
  'kinderGardenId':'schoolId',
  'quantityPlaces':'places',
}

const Form = (props: Props) => {
  const {
    entity,
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const currentLocale = i18n.language?.split('-')[0];

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`lessons.content.title.${mode}`, {ns: 'pages'});

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          isSubmitting
        }) => {

        // @ts-ignore
        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('lessons.content.subtitle.details', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('lessons.content.section.main', {ns: 'pages'})}
                  </Typography>
                  <TextField
                    sx={styles.field}
                    name="name"
                    value={values.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={(
                      <ErrorMessage
                        name="name"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.name.lesson.label')}
                    placeholder={t('field.name.lesson.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    sx={styles.field}
                    name="description"
                    value={values.description || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    helperText={(
                      <ErrorMessage
                        name="description"
                        render={(message) => t(message, {ns: 'validation', min: 12})}
                      />
                    )}
                    margin="normal"
                    required={false}
                    fullWidth
                    label={t('field.description.lesson.label')}
                    placeholder={t('field.description.lesson.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                    multiline
                    rows={4}
                  />
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        sx={styles.field}
                        name="type"
                        value={values.type || ''}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.type && errors.type)}
                        helperText={(
                          <ErrorMessage
                            name="type"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.lesson.type.label')}
                        placeholder={t('field.lesson.type.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (value) => {
                            if (value as string === undefined) {
                              return (t('field.lesson.type.placeholder'));
                            }
                            const current = LESSON_TYPES.find((el) => el.value === value)
                            return current?.label || '';
                          }
                        }}
                      >
                        {LESSON_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {(values.type === 'group-permanent' || values.type === 'individual-permanent') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          sx={styles.field}
                          name="cost"
                          value={values.cost || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={(
                            <ErrorMessage
                              name="cost"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )}
                          margin="normal"
                          required
                          fullWidth
                          label={t('field.cost.month.label')}
                          placeholder={t('field.cost.month.placeholder')}
                          InputLabelProps={{ shrink: true }}
                          size="medium"
                          autoComplete='off'
                        />
                      </Grid>
                    )}
                    {(values.type === 'group-temporary' || values.type === 'individual-temporary') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          sx={styles.field}
                          name="costSingle"
                          value={values.costSingle || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.costSingle && errors.costSingle)}
                          helperText={(
                            <ErrorMessage
                              name="costSingle"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )}
                          margin="normal"
                          required
                          fullWidth
                          label={t('field.cost.single.label')}
                          placeholder={t('field.cost.single.placeholder')}
                          InputLabelProps={{ shrink: true }}
                          size="medium"
                          autoComplete='off'
                        />
                      </Grid>
                    )}
                    {(values.type === 'group-permanent' || values.type === 'group-temporary') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          sx={styles.field}
                          name="places"
                          value={values.places || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.places && errors.places)}
                          helperText={(
                            <ErrorMessage
                              name="places"
                              render={(message) => t(message, {ns: 'validation'})}
                            />
                          )}
                          margin="normal"
                          required
                          fullWidth
                          label={t('field.quantity.places.label')}
                          placeholder={t('field.quantity.places.placeholder')}
                          InputLabelProps={{ shrink: true }}
                          size="medium"
                          autoComplete='off'
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        sx={styles.field}
                        name="duration"
                        value={values.duration || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.duration && errors.duration)}
                        helperText={(
                          <ErrorMessage
                            name="duration"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.duration.label')}
                        placeholder={t('field.duration.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (value) => {
                            if (value as string === '') {
                              return (t('field.duration.placeholder'));
                            }
                            const current = LESSON_DURATION.find((el) => el === value)
                            return current ? getDuration(current, currentLocale) : '';
                          }
                        }}
                      >
                        {LESSON_DURATION.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {getDuration(option, currentLocale)}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" sx={{mt: 3, mb: 2}}>
                    {t('lessons.content.section.cover', {ns: 'pages'})}
                  </Typography>
                  <Files
                    multiple={false}
                    data={values?.cover?.map((el) => {
                      return {
                        value: el,
                        uri: getMediaUri(el),
                      }
                    }) || null}
                    onUpload={(items) => {
                      setFieldValue('cover', items);
                    }}
                    onRemove={() => {
                      return setFieldValue('cover', []);
                    }}
                  />
                  <Typography variant="subtitle1" sx={{mt: 3, mb: 2}}>
                    {t('lessons.content.section.files', {ns: 'pages'})}
                  </Typography>
                  <Files
                    multiple={false}
                    data={values?.media?.map((el) => {
                      return {
                        value: el,
                        uri: getMediaUri(el),
                      }
                    }) || null}
                    onUpload={(items) => {
                      setFieldValue('media', [items]);
                    }}
                    onRemove={() => {
                      return setFieldValue('media', []);
                    }}
                  />
                </>
              )}
              right={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('lessons.content.subtitle.pupils', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{my: 2}}>
                    {t('lessons.content.section.list.pupil', {ns: 'pages'})}
                  </Typography>
                  <Persons
                    items={entity?.Children || []}
                    emptyText="no.children"
                    onClick={(id: number) => {
                      navigate(`/dashboard/children/edit/${id}`)
                    }}
                  />
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.lesson') : t('edit.lesson'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
