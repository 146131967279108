import common from './common.json';
import validation from './validation.json';
import auth from './auth.json';
import pages from './pages.json';

const namespaces = {
  common,
  validation,
  auth,
  pages
};

export default namespaces;
