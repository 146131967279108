import * as Yup from 'yup';
import {getAge} from '../../../utils';
import {MAX_TEACHER_AGE, MIN_TEACHER_AGE} from '../../../constants';
import {matchIsValidTel} from 'mui-tel-input';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'name.max')
    .required('required')
    .nullable(true),
  surname: Yup.string().required('required').nullable(true),
  patronymic: Yup.string().nullable(true),
  email: Yup.string()
    .email('email.invalid')
    .required('required')
    .nullable(true),
  phone: Yup.string()
    .test('phone', 'phone.invalid', function(value){
      return matchIsValidTel(value || '');
    })
    .required('required')
    .nullable(true),
  gender: Yup.string().required('required').nullable(true),
  birthday: Yup.date()
    .test('age', (birthday, {createError, path}) => {
      const {value} = getAge(birthday || null) || {}
      const years = Number(value?.years || 0);
      if (years < MIN_TEACHER_AGE) {
        return createError({
          path,
          message: 'age.min',
        })
      } else if(years > MAX_TEACHER_AGE) {
        return createError({
          path,
          message: 'age.max',
        })
      }
      return true;
    })
    .nullable(true),
  position: Yup.string().required('required').nullable(true),
  cost: Yup.number().nullable(true),
  costSingle: Yup.number().nullable(true),
  salary: Yup.number().nullable(true),
});

export default validationSchema;
