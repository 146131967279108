import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Trans, useTranslation} from 'react-i18next';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import {SxPropsObject} from '../../../../types';
import {Pointer} from '../../../../styled';
import {getAssets} from '../../../../utils';

interface ItemProps {
  index: number;
  heading: React.ReactNode;
  text: React.ReactNode;
}

const Item = (props: ItemProps) => {
  const {index, heading, text} = props;

  const reverse = index > 1;

  return (
    <Box sx={styles.specification}>
      <Pointer reverse={reverse}>
        <Box>{index}</Box>
      </Pointer>
      <Typography
        variant="subtitle1"
        component="p"
        color="common.white"
        fontWeight={700}
        mb={1}
      >
        {heading}
      </Typography>
      <Typography
        variant="body2"
        component="p"
        color="#AFB0B5"
      >
        {text}
      </Typography>
    </Box>
  );
};

const Application = () => {
  const {t, i18n} = useTranslation('pages');
  const currentLocale = i18n.language?.split('-')[0];

  const assets = getAssets(currentLocale);

  return (
    <Box sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Box>
          <Typography
            sx={styles.heading}
            variant="h1"
            component="h2"
            color="text.primary"
            fontWeight={700}
          >
            <Trans t={t} i18nKey="home.application.heading"/>
          </Typography>
          <Typography
            sx={styles.text}
            variant="body2"
            component="p"
            color="text.primary"
          >
            <Trans t={t} i18nKey="home.application.text"/>
          </Typography>
          <Box sx={styles.overview}>
            <Box
              sx={styles.overviewImage}
              src={assets.applicationImageSrc}
              component="img"
              alt="Application"
            />
            {[...new Array(3)].map((el, index) => {
              return (
                <Item
                  key={index}
                  index={index + 1}
                  heading={<Trans t={t} i18nKey={`home.application.items.${index}.heading`}/>}
                  text={<Trans t={t} i18nKey={`home.application.items.${index}.text`}/>}
                />
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: () => ({
    py: {
      sm: 15,
      xs: 10
    },
    position: 'relative',
    zIndex: 6,
    maxWidth: '100%',
    bgcolor: '#131827',
  }),
  heading: (theme) => ({
    textAlign: 'center',
    mb: 4,
    color: theme.palette.common.white,
  }),
  text: {
    textAlign: 'center',
    color: '#AFB0B5',
    mb: 8,
  },
  specification: (theme) => ({
    width: {
      lg: 300,
      md: 260,
      xs: 'auto'
    },
    position: {
      md: 'absolute',
      xs: 'relative'
    },
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      mb: 4,
      top: 0,
      left: 0,
      transform: 'none',
    },

    '&:nth-of-type(1)': {
      top: 112,
      left: '18%',
      [theme.breakpoints.down('lg')]: {
        top: 80,
        left: '6%',
      },
      [theme.breakpoints.down('md')]: {
        top: 0,
        left: 0,
        transform: 'none',
      },
    },

    '&:nth-of-type(2)': {
      top: 240,
      left: '84%',
      [theme.breakpoints.down('lg')]: {
        top: 160,
        left: '92%',
      },
      [theme.breakpoints.down('md')]: {
        top: 0,
        left: 0,
        transform: 'none',
      },
    },

    '&:nth-of-type(3)': {
      top: 522,
      left: '84%',

      [theme.breakpoints.down('lg')]: {
        top: 360,
        left: '92%',
      },
      [theme.breakpoints.down('md')]: {
        top: 0,
        left: 0,
        transform: 'none',
      },
    }
  }),
  overview: () => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    mb: 0,
    width: {
      lg: '100%',
      md: 680,
      sm: '100%',
    }
  }),
  overviewImage: () => ({
    marginBottom: {
      lg: 0,
      xs: 4,
    },
    maxWidth: {
      lg: 410,
      xs: 280,
    },
  }),
};

export default Application;
