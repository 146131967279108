import { matchIsValidTel } from 'mui-tel-input';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'name.max')
    .required('required')
    .nullable(true),
  email: Yup.string()
    .email('email.invalid')
    .required('required')
    .nullable(true),
  phone: Yup.string()
    .test('phone', 'phone.invalid', function(value){
      if(value) {
        return matchIsValidTel(value || '');
      }
      return true;
    }),
  message: Yup.string()
    .min(12, 'text.min')
    .required('required')
    .nullable(true),
});

export default validationSchema;
