import React from 'react';
import {SxPropsObject} from '../../types';
import Box from '@mui/material/Box';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import { Outlet } from 'react-router-dom';

type Props = {
}

const Base:React.FC<Props> = () => {
  const [openNavigation, setNavigationOpen] = React.useState<boolean>(false);

  return (
    <Box sx={styles.container}>
      <Header
        open={openNavigation}
        setOpen={setNavigationOpen}
      />
      <Navigation
        open={openNavigation}
        setOpen={setNavigationOpen}
      />
      <Box component="main">
        <Outlet />
      </Box>
      <Footer/>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    p: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    flexDirection: 'column',
  }
}

export default Base;
