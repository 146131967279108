import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'app',
  initialState: {
    countryCode: 'UA',
    currency: '₴',
    price: {
      discount: 0.5,
      value: 20,
    },
    payments: {
      date: new Date(),
    }
  },
  reducers: {
    setCountry:  (state, action) => {
      state.countryCode = action.payload
    },
    setPrice: (state, action) => {
      state.price = action.payload
    },
    setPaymentsDate: (state, action) => {
      state.payments = {
        ...state.payments,
        date: action.payload,
      }
    }
  },
})

export default slice;
