import * as Yup from 'yup';
import {isValid} from 'date-fns';


const validationSchema = Yup.object().shape({
  Child: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
    })
    .required('required')
    .nullable(true),
  date: Yup.date()
    .typeError('date.format')
    //@ts-ignore
    .test('date', 'date.year.full', function(value){
      if(value) {
        const date = new Date(value);
        return date.getFullYear().toString().length === 4 && isValid(date);
      }
      return true;
    })
    .required('required')
    .nullable(true),
  description: Yup.string().nullable(true),
  Services: Yup.array()
    .min(1, 'schedule.min')
    .of(Yup.object().shape({
      quantity: Yup.number()
        .min(1, 'quantity.min')
        .required('required')
        .nullable(true),
      type: Yup.string().required('required').nullable(true),
      price: Yup.number().required('required').nullable(true),
      cost: Yup.number().required('required').nullable(true),
    }))
    .nullable(true),
});

export default validationSchema;
