import React from 'react';
import {Service, SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {Translation, useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Link as RouterLink, useNavigate, useSearchParams} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import {
  downloadFileLink,
  getMediaUri,
  invoiceStatusStateToRequest,
  renderFullName,
  serviceResponseToState,
  statusColor
} from '../../../../utils';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import format from 'date-fns/format';
import {DATE_FORMAT} from '../../../../constants';
import API from '../../../../api';
import slices from '../../../../redux/notices/slice';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';
import theme from '../../../../config/theme';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Filters from '../../../../components/Filters';
import Sorting from '../../../../components/Sorting';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactComponent as ChevronDownIcon} from '../../../../assets/icons/chevronDown.svg';
import {ReactComponent as DownloadDocumentIcon} from '../../../../assets/icons/downloadDocument.svg';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import NumericValue from '../../../../components/NumericValue/NumericValue';
import useSortParams from '../../../../hooks/useSortParams';
import appSlice from '../../../../redux/app/slice';

const Actions = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string, status: string) => {
    navigate({
      pathname: `/dashboard/payments/invoices/${path}`,
    }, {state: {
        status
      }});
  }

  return <>
    <Button
      aria-controls={open ? 'actions' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      sx={styles.button}
      size="medium"
      variant="contained"
      onClick={handleClick}
      endIcon={(
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 24 24"
          component={ChevronDownIcon}
          sx={{
            transform: `rotateX(${open ? 180 : 0}deg)`,
            transition: theme.transitions.create('transform')
          }}
        />
      )}
    >
      {t('add.value')}
    </Button>
    <Menu
      id="actions"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'actions-button',
      }}
    >
      <MenuItem onClick={() => handleNavigate('create','complete')}>
        {t('add.payment')}
      </MenuItem>
      <MenuItem onClick={() => handleNavigate('create', 'wait')}>
        {t('create.invoice')}
      </MenuItem>
    </Menu>
  </>
}

const List = () => {
  const {t} = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const {setPaymentsDate} = appSlice.actions;

  const {
    noticeOpen,
  } =  slices.actions;

  const typeParam = searchParams.get('type') || 'subscriptions';
  const statusParam = searchParams.get('status') || '';
  const periodParam = searchParams.get('period') || format(Date.now(), 'yyyy-MM');
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'child',
      headerName: t('field.child.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box>
            <Link
              sx={{display: 'inline-flex'}}
              to={`/dashboard/children/edit/${row?.Invoice?.Child?.id}`}
              color="text.primary"
              variant="subtitle2"
              underline="hover"
              component={RouterLink}
              gutterBottom
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {renderFullName(row?.Invoice?.Child)}
            </Link>
            <Typography variant="body3" color="text.gray" component="div">
              {Boolean(row?.Invoice?.Child?.note) ? t('child.note.with') : t('child.note.without')}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'group',
      headerName: t('field.group.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Link
            to={`/dashboard/groups/edit/${row?.Invoice?.Child?.Group.id}`}
            sx={{display: 'inline-flex'}}
            color="text.primary"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            gutterBottom
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {row?.Invoice?.Child?.Group.name}
          </Link>
        );
      }
    },
    {
      field: 'date',
      headerName: t('field.date.payment.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const {date} = row?.Invoice || {};

        return (
          <Box>
            {date && (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  {format(date, DATE_FORMAT)}
                </Typography>
                <Typography variant="body3" color="text.gray" component="p">
                  {t('period.to')} {format(date, DATE_FORMAT)}
                </Typography>
              </>
            )}
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.label'),
      flex: 0,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const status = row?.Invoice?.status?.toLowerCase()
        const chipColor = statusColor(status);
        return (
          <Chip
            label={t(`payment.status.${status}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        )
      }
    },
    {
      field: 'price',
      headerName: t('field.price.label'),
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const value = Number(row?.price || 0) * Number(row?.quantity || 0);
        return (
          <Typography variant="body3" color="text.gray" component="p">
            <NumericValue
              formatType="price"
              value={value}
              displayType="text"
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
            />
          </Typography>
        );
      }
    },
    {
      field: 'description',
      headerName: t('field.description.label'),
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Typography
            variant="body3"
            color="text.gray"
            component="p"
            sx={{
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {row?.Invoice.description}
          </Typography>
        );
      }
    },
  ];

  const tabs = [
    {
      label: t('invoices.tabs.subscription', {ns: 'pages'}),
      value: 'subscriptions'
    },
    {
      label: t('invoices.tabs.extra', {ns: 'pages'}),
      value: 'extra'
    },
  ];

  const defaultParams = {
    ...(statusParam ? {status: invoiceStatusStateToRequest(statusParam)} : {}),
    ...(periodParam ? {date: periodParam} : {}),
    ...(sortParam ? {sort: sortParam} : {}),
    ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
  }

  return (
    <ResourceList
      name="invoices"
      pathModifier={(path) => {
        return `/kinder-garden/${user?.schoolId}${path}/${typeParam}`
      }}
      title={t('invoices.list.subtitle', {ns: 'pages'})}
      columns={columns}
      rowActions={{
        mode: 'extend',
        getterId: (entity: Service) => {
          return entity.Invoice?.id || ''
        },
        actions: [
          {
            name: 'generate',
            label: t('download.value'),
            color: 'text.gray',
            icon: {
              viewBox: '0 0 24 24',
              component: DownloadDocumentIcon,
            },
            action: async (id: number) => {
              try {
                const response = await API.invoices.generateDocument(`/kinder-garden/${user?.schoolId}`, id)
                if(response.status === 200) {
                  dispatch(noticeOpen({
                    message: (
                      <Translation>
                        {(t) => t('messages.generate.invoice.success')}
                      </Translation>
                    ),
                    options: {
                      key: `invoice-generate-pdf-success`,
                      variant: 'success',
                    },
                  }))

                  await downloadFileLink(getMediaUri(response.data), response.data);
                }
              } catch (err) {
                dispatch(noticeOpen({
                  message: (
                    <Translation>
                      {(t) => t('messages.generate.invoice.failed')}
                    </Translation>
                  ),
                  options: {
                    key: `invoice-generate-pdf-error`,
                    variant: 'error',
                  },
                }))
              }
            },
          },
        ],
        extended: [
          {
            name: 'edit',
            action: (id) => {
              navigate(`/dashboard/payments/invoices/edit/${id}`)
            },
          },
          {
            name: 'delete',
            action: async (id: number) => {
              try {
                await API.invoices.delete(`/kinder-garden/${user?.schoolId}`, id);
                dispatch(noticeOpen({
                  message: (
                    <Translation>
                      {(t) => t('messages.delete.entity.success')}
                    </Translation>
                  ),
                  options: {
                    key: `invoice-${id}-delete-success`,
                    variant: 'success',
                  },
                }))
              } catch (err) {
                dispatch(noticeOpen({
                  message: (
                    <Translation>
                      {(t) => t('messages.delete.entity.failed')}
                    </Translation>
                  ),
                  options: {
                    key: `invoice-${id}-delete-error`,
                    variant: 'error',
                  },
                }))
              }
            },
          },
        ]
      }}
      actions={() => (<Actions/>)}
      filters={(
        <Filters
          sx={{
            mr: 0,
            ml: {
              sm: 'auto',
              xs: 0,
            },
          }}
          items={[
            <TextField
              select
              name="status"
              value={statusParam}
              onChange={(event) => {
                const value = event.target.value;
                searchParams.set('status', value || '');
                setSearchParams(searchParams)
              }}
              margin="normal"
              required
              fullWidth
              label={t('field.status.label')}
              placeholder={t('field.status.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              SelectProps={{
                displayEmpty: true,
                endAdornment: (
                  <IconButton
                    size="small"
                    sx={(theme) => ({
                      right: theme.spacing(2),
                    })}
                    onClick={() => {
                      searchParams.set('status', '');
                      setSearchParams(searchParams)
                    }}
                  >
                    <ClearIcon fontSize="small"/>
                  </IconButton>
                ),
                renderValue: (value) => {
                  if (value as string === '') {
                    return (t('field.status.placeholder'));
                  }
                  return (t(`payment.status.${value}`))
                }
              }}
              InputProps={{
                sx: {
                  '& .MuiIconButton-root': {
                    visibility: 'hidden',
                  },
                  '&:hover': {
                    '& .MuiIconButton-root': {
                      visibility: statusParam ? 'visible' : 'hover',
                    },
                  }
                }
              }}
            >
              {['wait', 'complete'].map((name) => (
                <MenuItem key={name} value={name}>
                  {t(`payment.status.${name}`)}
                </MenuItem>
              ))}
            </TextField>,
            <DatePicker
              openTo="month"
              views={['year', 'month']}
              label={t('field.month.label')}
              value={new Date(periodParam)}
              onChange={(value) => {
                if(value) {
                  searchParams.set('period', format(value, 'yyyy-MM'));
                } else {
                  searchParams.set('period', '');
                }
                setSearchParams(searchParams);
                dispatch(setPaymentsDate(value));
              }}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  onKeyDown: (event) => { event.preventDefault()},
                  margin: "normal",
                  required: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true
                  },
                  InputProps: {
                    readOnly: true,
                    sx: {
                      textTransform: 'capitalize',
                      '& .MuiInputBase-input': {
                        textTransform: 'inherit'
                      }
                    }
                  },
                  size: "medium",
                  autoComplete: 'off'
                }
              }}
            />
          ]}
        />
      )}
      sorting={(
        <Sorting
          values={[
            {
              label: t('field.date.label'),
              value: 'createdAt',
            },
            {
              label: t('field.price.label'),
              value: 'price',
            },
            {
              label: t('field.date.payment.label'),
              value: 'Invoice.date',
            },
            {
              label: t('field.status.label'),
              value: 'Invoice.status',
            },
            {
              label: t('field.child.label'),
              value: 'Invoice.Child.surname',
            },
            {
              label: t('field.group.label'),
              value: 'Invoice.Child.Group.name',
            },
          ]}
          sort={sortParam}
          order={sortOrderParam}
          onChangeSort={onChangeSort}
          onChangeOrder={onChangeSortOrder}
          sx={{
            mr: 0,
            ml: 3,
          }}
        />
      )}
      transformer={(entities: any):any => {
        return entities.map((subscription: any) => {
          return serviceResponseToState(subscription);
        });
      }}
      defaultParams={defaultParams}
      onRowClick={(params) => {
        const {row} = params;
        navigate(`/dashboard/payments/invoices/edit/${row.Invoice.id}`)
      }}
      tabs={{
        value: typeParam,
        onChange: (value) => {
          searchParams.set('type', value);
          setSearchParams(searchParams);
        },
        items: tabs
      }}
      getRowId={(row) => row.Invoice.id}
    />
  );
};

const styles: SxPropsObject = {
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'divider',

    '& .MuiTab-root': {
      p: 3,
    },

    [theme.breakpoints.down('sm')]: {
      px: 3
    }
  }),
  tab: () => ({
    textTransform: 'capitalize',
  }),
  button: () => ({
    maxWidth: {
      sm: 188,
      xs: 'unset',
    },
    ml: {
      sm: 4,
      xs: 0,
    },
    mt: {
      sm: 0,
      xs: 1,
    },
  }),
}

export default List;
