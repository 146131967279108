import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import {childStateToRequest} from '../../../../utils';
import Form from '../components/Form';
import {Gender} from '../../../../types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import {useLocation } from 'react-router-dom';

const Create = () => {

  const user = useSelector(userSelector);
  let location = useLocation();

  const Trustee = {
    id: location.state?.Trustee?.id || null,
    name: location.state?.Trustee?.name || null,
    surname: location.state?.Trustee?.surname || null,
    patronymic: location.state?.Trustee?.patronymic || null,
    relationship: location.state?.Trustee?.relationship || null,
  }

  return (
    <ResourceCreate
      name="children"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = childStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues = {
          name: null,
          surname: null,
          patronymic: null,
          birthday: null,
          note: null,
          Group: {
            id: null,
            name: null,
          },
          gender: 'male' as Gender,
          Trustee,
          payment: {
            date: {
              current: null,
              expiry:  null,
            },
            status: 'wait',
            value: null,
            comment: null,
          },
          Tags: [],
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            entity={null}
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};


export default React.memo(Create);
