import {SxPropsObject} from '../../../types';

const styles:SxPropsObject = {
  container: (theme) => ({
    mx: 'auto',
    width: '100%',
    maxWidth: 470,
    borderRadius: theme.spacing(2),
    py: {
      md: 5,
      xs: 3,
    },
    px: {
      md: 4,
      xs: 2,
    },
  }),
  form: {},
  title: theme => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  }),
  text: {
    textAlign: 'center',
  },
}

export default styles;
