import React from 'react';
import {Translation, useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {ReactComponent as ChevronLeft} from '../../../assets/icons/chevronLeft.svg';
import {ReactComponent as ChevronRight} from '../../../assets/icons/chevronRight.svg';
import {CalendarViewType} from '../../../types';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

const viewTypes = [
  {
    value: 'dayGridMonth',
    label: (<Translation>{(t) => t('month.value')}</Translation>),
  },
  {
    value: 'timeGridWeek',
    label: (<Translation>{(t) => t('week.value')}</Translation>),
  },
  {
    value: 'timeGridDay',
    label: (<Translation>{(t) => t('day.value')}</Translation>),
  },
  {
    value: 'listWeek',
    label: (<Translation>{(t) => t('list.value')}</Translation>),
  }
] as Array<{
  value: CalendarViewType,
  label: React.ReactNode,
}>;


interface Props {
  calendar: any;
  viewType: CalendarViewType;
  viewTitle: string;
  onChangeView: (value: CalendarViewType) => void;
}

const Controls = (props: Props) => {

  const {calendar, viewTitle, viewType, onChangeView} = props;
  const {t} = useTranslation();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{mb: 2}}>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: {
            lg: 'space-between',
            sm: 'flex-start',
            xs: 'center'
          },
          flexDirection: {
            sm: 'row',
            xs: 'column'
          }
        }}
      >
        <Grid item xs={12} sm="auto">
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: {sm: 'flex-start', xs: 'center'}}}>
            <IconButton
              aria-label="Previous month"
              onClick={() => calendar?.getApi().prev()}
            >
              <SvgIcon
                fontSize="inherit"
                viewBox="0 0 24 24"
                component={ChevronLeft}
                color="inherit"
              />
            </IconButton>
            <Typography
              variant="subtitle2"
              sx={(theme) => {
                const width = viewType === 'timeGridWeek' ?
                  theme.spacing(34) :
                  theme.spacing(24);

                return ({
                  width,
                  mx: 1,
                  textTransform: 'capitalize',
                  textAlign: 'center',
                });
              }}
            >
              {viewTitle}
            </Typography>
            <IconButton
              aria-label="Next month"
              onClick={() => calendar?.getApi().next()}
            >
              <SvgIcon
                fontSize="inherit"
                viewBox="0 0 24 24"
                component={ChevronRight}
                color="inherit"
              />
            </IconButton>
          </Box>
        </Grid>
        <Grid item sx={{ml: {sm: 2, xs: 0}, mr: {sm: 'auto', xs: 0}}} xs={12} sm="auto">
          <Button
            aria-label="Previous month"
            variant="contained"
            onClick={() => calendar?.getApi().today()}
            fullWidth={isDownSm}
          >
            {t('today.value')}
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <ButtonGroup
            variant="outlined"
            orientation={isDownSm ? 'vertical' : 'horizontal'}
            aria-label="outlined button group"
            fullWidth={isDownSm}
          >
            {viewTypes.map((view) => {
              return (
                <Button
                  key={view.value}
                  variant={view.value === viewType ? 'contained' : 'outlined'}
                  onClick={() => onChangeView(view.value)}
                >
                  {view.label}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Controls;
