import React from 'react';
import Box from '@mui/material/Box';
import {DataGridCustom} from '../../../../../styled';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import {renderFullName, statusColor} from '../../../../../utils';
import {format, parseISO} from 'date-fns';
import {DATE_FORMAT, TIME_FORMAT} from '../../../../../constants';
import Chip from '@mui/material/Chip';
import {Task, TaskLog} from '../../../../../types';

interface Props {
  entity: Task
}


const History = (props: Props) => {
  const {entity} = props;

  const {t} = useTranslation();
  const NoRowsOverlay = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  const rows: Array<Pick<TaskLog, 'id' | 'status' | 'updatedAt' | 'Editor'> & {action: string}> = entity?.TaskLogs?.map((el) => ({
    ...el,
    action: 'change.status',
  })) || [];

  rows.unshift({
    id: -1,
    status: 'pending',
    action: 'create.task',
    updatedAt: entity.updatedAt,
    Editor: entity.Creator
  })

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: t('field.action.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box>
            <Typography variant="subtitle2">
              {t(`tasks.content.history.action.${row.action}`, {ns: 'pages'})}
            </Typography>
            <Typography variant="body3" color="text.gray" gutterBottom>
              {renderFullName(row?.Editor, false, true)}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'updatedAt',
      headerName: t('field.date.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Box>
            {row.updatedAt && (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  {format(parseISO(row.updatedAt), DATE_FORMAT)}
                </Typography>
                <Typography variant="body3" color="text.gray" component="p">
                  {format(parseISO(row.updatedAt), TIME_FORMAT)}
                </Typography>
              </>
            )}
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.label'),
      flex: 0,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const chipColor = statusColor(row?.status);
        return (
          <Chip
            label={t(`task.status.${row?.status}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        );
      }
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        borderRadius: 2,
        height: 'auto'
      })}
    >
      <DataGridCustom
        autoHeight={true}
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        headerHeight={44}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        hideFooter
        components={{
          NoRowsOverlay,
        }}
      />
    </Box>
  );
};

export default React.memo(History);
