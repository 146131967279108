import React from 'react';
import Box from '@mui/material/Box';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Link, useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Avatar from '@mui/material/Avatar';
import {getMedia, renderFullName, teacherResponseToState} from '../../../../utils';
import Content from '../../../../layouts/Main/components/Content';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import noImageSrc from '../../../../assets/images/noImage.svg';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.name.label'),
      flex: 1,
      minWidth: 360,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Avatar
              sx={{ bgcolor: '#4E4E4E', mr: 2}}
              alt={row.name}
              src={getMedia(row.media) || noImageSrc}
              onError={(event: any) => {
                //@ts-ignore
                event.target.src = noImageSrc;
              }}
            />
            <Typography variant="subtitle2">
              {renderFullName(row)}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'position',
      headerName: t('field.position.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body2">
            {row.position}
          </Typography>
        )
      }
    },
    {
      field: 'phone',
      headerName: t('field.phone.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'email',
      headerName: t('field.email.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <Content title={t('teachers.list.title', {ns: 'pages'})}>
      <ResourceList
        name="teachers"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('teachers.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any) => {
          return entities.map((el: any) => {
            return teacherResponseToState(el);
          })
        }}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={Link}
            to="/dashboard/teachers/create"
          >
            {t('add.value')}
          </Button>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        onRowClick={(params) => {
          navigate(`/dashboard/teachers/edit/${params.id}`)
        }}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
