import axios from 'axios';
import {DOMAIN} from '../constants';
import {history} from '../App';
import {enqueueSnackbar} from 'notistack';
import {Translation} from 'react-i18next';
import React from 'react';

const API_BASE_URL = `${DOMAIN}/saas`;

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use((config) => {
  //@ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const isTooManyRequest = error.response.status === 429;
    const isTokenExpired =  error.response.status === 401;


    if (isTooManyRequest || isTokenExpired) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    let redirect403 = true;
    const {message} = error.response.data || {};

    if(message.includes('has_group')) {
      redirect403 = false;
    }

    if (error.response.status === 422 && message.includes('requisites_required')) {
      enqueueSnackbar((
        <Translation>
          {(t) => t('messages.school.requisites.empty')}
        </Translation>
      ), {
        key: 'school-requisites-required',
        variant: 'error',
      });

      history.replace("/dashboard/settings");
    }

    if (error.response.status === 403 && redirect403) {
      history.replace("/errors/403");
    }

    if (error.response.status === 402) {
      history.replace("/errors/402");
    }

    if (error.response.status === 500) {
      history.replace("/errors/500");
    }

    return Promise.reject(error);
  }
);

export default instance;
