import React from 'react';
import {NumericFormat} from 'react-number-format';
import {NumericFormatProps} from 'react-number-format/types/types';
import {useSelector} from 'react-redux';
import {appSelector} from '../../redux/app/selectors';

interface Props extends NumericFormatProps {
  formatType?:
    | 'price'
    | 'percentage'
}

const NumericValue: React.FC<Props> = (props) => {
  const {
    formatType,
    ...rest
  } = props;

  const {currency} = useSelector(appSelector)

  const percent = '%';

  let suffix = '';

  if (formatType === 'percentage') {
    suffix = ` ${percent}`;
  } else if (formatType === 'price') {
    suffix = ` ${currency}`;
  }

  return (
    <NumericFormat
      {...rest}
      suffix={suffix}
    />
  );
};

NumericValue.defaultProps = {
  decimalScale: 2,
  fixedDecimalScale: false,
};

export default NumericValue;
