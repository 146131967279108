import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Trans, useTranslation} from 'react-i18next';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import {SxPropsObject} from '../../../../types';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import {DividerCustom} from '../../../../styled';
import Button from '@mui/material/Button';
//@ts-ignore
import {scroller} from 'react-scroll';
import {useSelector} from 'react-redux';
import {appSelector} from '../../../../redux/app/selectors';

const Pricing = () => {
  const {t} = useTranslation('pages');
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const {price} = useSelector(appSelector);

  return (
    <Box id="pricing" sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Box>
          <Typography
            sx={styles.heading}
            variant="h2"
            component="h3"
            color="text.primary"
            fontWeight={700}
          >
            <Trans t={t} i18nKey="home.pricing.heading"/>
          </Typography>
          <Typography
            sx={styles.text}
            variant="body2"
            component="p"
            color="text.primary"
          >
            <Trans t={t} i18nKey="home.pricing.text"/>
          </Typography>
          <Box sx={styles.features}>
            <Box sx={styles.featuresInner}>
              <Grid
                container
                spacing={isDownMd ? isDownSm ? 1 : 2 : 4}
                alignItems="center"
                flexDirection={{
                  md: 'row',
                  xs: 'column'
                }}
              >
                <Grid item xs={12} md={7}>
                  <Typography variant="body2"  color="common.white" sx={{mb: 3}}>
                    <Trans t={t} i18nKey="home.pricing.features.text.0">
                      Вартість користування системою залежить
                      <Typography color="#FE8A54" fontWeight={700} component="strong">від кількості дітей</Typography>
                      у вашому садочку!
                    </Trans>
                  </Typography>
                  <Typography variant="body2" color="common.white">
                    <Trans t={t} i18nKey="home.pricing.features.text.1"/>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                  <DividerCustom
                    view={isDownMd ? 'horizontal' : 'vertical'}
                    sx={{
                      ...(isDownMd ? {
                        height: 16,
                        width: 187,
                        my: 2,
                      } : {
                        width: 16,
                        height: 120,
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Typography variant="h2" component="div" color="common.white" mb={2}>
                      <Typography
                        component="span"
                        sx={{
                          textDecoration: 'line-through',
                          fontSize: '0.64em',
                          opacity: 0.72,
                          mr: 1,
                        }}
                      >
                        {`$${price.value}`}
                      </Typography>
                      {`$${price.value * price.discount}`}
                      <Typography
                        component="span"
                        textTransform="lowercase"
                        sx={{
                          fontSize: '0.52em',
                          opacity: 0.72,
                        }}
                      >
                        {' / '}{t('month.value', {ns: 'common'})}
                      </Typography>
                    </Typography>
                    <Button
                      size="large"
                      variant="contained"
                      color="light"
                      sx={{
                        borderRadius: 2,
                      }}
                      onClick={() => {
                        scroller.scrollTo('contact', {
                          duration: 800,
                          delay: 0,
                          smooth: "easeInOutQuart",
                        });
                      }}
                    >
                      {t('home.pricing.features.button.contact')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: () => ({
    py: {
      sm: 15,
      xs: 10
    },
    position: 'relative',
    zIndex: 6,
    maxWidth: '100%',
  }),
  heading: {
    textAlign: 'center',
    mb: 4,
  },
  text: {
    textAlign: 'center',
    mb: 8,
  },
  features: (theme) => ({
    borderRadius: theme.spacing(2),
    mx: 'auto',
    position: 'relative',
    maxWidth: {
      md: 856,
      xs: '100%'
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'block',
      opacity: 0.4,
      filter: 'blur(34px)',
      background: 'linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%)',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  }),
  featuresInner: (theme) => ({
    bgcolor: '#18181B',
    borderRadius: theme.spacing(2),
    p: theme.spacing(4),
    mx: 'auto',
    position: 'relative',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      p: theme.spacing(2),
    },

  }),
  list: () => ({
    listStyle: 'none',
    p: 0,
    mb: 0,
  }),
};

export default Pricing;
