import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Logo} from '../../../../styled';
import {Link, NavLink} from 'react-router-dom';
import {SxPropsObject} from '../../../../types';
import {alpha, useTheme} from '@mui/material';
import {getSxStyle} from '../../../../utils';
import SvgIcon from '@mui/material/SvgIcon';
import {MENU_ITEMS} from '../../constants';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {blueGrey} from '@mui/material/colors';
import {signOut} from '../../../../redux/auth/slice';
import {useDispatch} from 'react-redux';
import usePermission from '../../../../hooks/usePermission';
import {ReactComponent as LogoImage} from '../../../../assets/images/logo.svg';
import SchoolsAutoComplete from '../SchoolsAutoComplete';
import {ReactComponent as ExitIcon} from '../../../../assets/icons/exit.svg';
import {ReactComponent as GearIcon} from '../../../../assets/icons/gear.svg';
import {ROUTES_PERMISSIONS} from '../../../../constants';

interface Props {
  width: number;
  open: boolean;
  onClose: () => void;
}

const Navigation:React.FC<Props> = (props) => {
  const {
    width,
    open,
    onClose
  } = props;

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const permission = usePermission();

  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const container = window !== undefined ? () => window.document.body : undefined;

  const onSignOut = () => {
    dispatch(signOut())
  }

  const drawer = (
    <Box sx={styles.content}>
      <Toolbar sx={styles.toolbar}>
        <Logo
          component={Link}
          to="/"
          variant="h6"
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <LogoImage/>
        </Logo>
      </Toolbar>
      {permission(['super-admin']) && isDownMd && (
        <Box sx={styles.panel}>
          <SchoolsAutoComplete/>
        </Box>
      )}
      <Box sx={styles.inner}>
        {MENU_ITEMS.map((group, index) => {
          return (
            <React.Fragment key={index}>
              <List>
                {group.items.map((item, index) => {
                  const allow = permission(item?.permissions);
                  if(allow) {
                    return (
                      <ListItem key={index} disablePadding >
                        <ListItemButton
                          sx={styles.navItem}
                          component={NavLink}
                          to={item.path}
                          end={item?.end || false}
                        >
                          {item.icon && (
                            <ListItemIcon sx={styles.navIcon}>
                              <SvgIcon
                                color="inherit"
                                fontSize="medium"
                                viewBox={item.icon.viewBox}
                                component={item.icon.component}
                                sx={{
                                  fill: 'none',
                                }}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={item.name}
                            primaryTypographyProps={{
                              variant: 'body2',
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  }
                  return null;
                })}
              </List>
              {index < MENU_ITEMS.length - 1 && (
                <Divider light sx={{my: 0, borderColor: alpha(blueGrey[200], 0.2)}} />
              )}
            </React.Fragment>
          )
        })}
      </Box>
      <Box sx={styles.footer}>
        {permission(ROUTES_PERMISSIONS.settings) && (
          <ListItem key="settings" disablePadding>
            <ListItemButton
              sx={styles.navItem}
              component={NavLink}
              to={"/dashboard/settings"}
              end={false}
            >
              <ListItemIcon sx={styles.navIcon}>
                <SvgIcon
                  color="inherit"
                  fontSize="medium"
                  viewBox="0 0 24 24"
                  component={GearIcon}
                />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem key="logout" disablePadding>
          <ListItemButton
            sx={styles.navItem}
            onClick={onSignOut}
          >
            <ListItemIcon sx={styles.navIcon}>
              <SvgIcon
                color="inherit"
                fontSize="medium"
                viewBox="0 0 24 24"
                component={ExitIcon}
              />
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItemButton>
        </ListItem>
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={(theme) => {
        return {
          ...getSxStyle(styles.content, theme),
          position: {
            lg: 'relative',
            xs: 'absolute',
          },
          maxWidth: {
            sm: width,
            xs: 280,
          },
          width: {
            sm: width,
            xs: 280,
          },
        }
      }}
      aria-label="Main navigation"
    >
      <Drawer
        sx={styles.drawer}
        container={isDownLg ? container : undefined}
        variant={isDownLg ? 'temporary' : 'permanent'}
        open={open}
        onClose={onClose}
        ModalProps={isDownLg ? {
          keepMounted: true,
          BackdropProps: {
            invisible: true,
          },
        } : undefined}
        PaperProps={{
          elevation: 0,
          sx: (theme) => ({
            boxSizing: 'border-box',
            maxWidth: {
              sm: width,
              xs: 280,
            },
            width: {
              sm: width,
              xs: 280,
            },
            ...getSxStyle(styles.paper, theme)
          })
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

const styles:SxPropsObject = {
  heading: () => ({
    mb: 1,
    fontWeight: 300,
    color: 'rgba(55, 61, 67, 0.5)',
    px: {
      lg: 0,
      xs: 2
    },
  }),
  toolbar: (theme) => ({
    py: 0,
    px: {
      md: 3,
      xs: 2,
    },
    minHeight: {
      sm: theme.spacing(10)
    },
  }),
  content: () => ({
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  panel: () => ({
    p: 2,

    '& .MuiInputBase-root, & .MuiAutocomplete-popupIndicator': {
      color: blueGrey[200],
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: blueGrey[200],
    },
  }),
  inner: () => ({
    pb: {
      lg: 4,
      xs: 2,
    },
    pt: 0,
    px: 0,
  }),
  navItem: (theme) => ({
    color: blueGrey[200],
    transition: theme.transitions.create(['background', 'box-shadow', 'color']),
    px: {
      md: 3,
      xs: 2,
    },
    py: 1.5,
    my: 0.25,

    '&.active': {
      background: alpha(blueGrey[200], 0.25),
      boxShadow: 'inset 3px 0px 0px currentColor',
      color: theme.palette.common.white,
    }
  }),
  navIcon: (theme) => ({
    color: 'currentColor',
    minWidth: {
      sm: theme.spacing(5),
    }
  }),
  drawer: () => ({
  }),
  paper: (theme) => ({
    background: theme.palette.common.black,
  }),
  footer: () => ({
    mb: 6,
    mt: 'auto',
  }),
  socials: {
    px: 2
  }
}

export default Navigation;
