import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import slices from '../../../../redux/auth/slice';
import { authSelector } from '../../../../redux/auth/selectors';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {useTheme} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {AutocompleteValue} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import {School} from '../../../../types';

const SchoolsAutoComplete = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {setCurrentSchool} = slices.actions;
  const {user, schools} = useSelector(authSelector);

  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const currentSchool = schools?.find((el) => el?.id === user?.schoolId);

  const handleChange = (event: React.SyntheticEvent, value: AutocompleteValue<Partial<School>, false, false, false>) => {
    const mode = ['edit', 'create'].find((el) => location.pathname.includes(el));
    dispatch(setCurrentSchool({
      schoolId: value?.id || null
    }));
    localStorage.setItem('schoolId', String(value?.id));

    if(mode) {
      const baseUri  = location.pathname.slice(0, location.pathname.indexOf(mode));
      if(baseUri !== location.pathname){
        navigate(baseUri + 'list')
      }
    }
  }

  return (
    <Autocomplete
      fullWidth
      sx={{ width: isDownMd ? 'auto' : 240 }}
      options={schools || []}
      autoHighlight
      disableClearable
      size="small"
      value={currentSchool}
      defaultValue={currentSchool}
      onChange={handleChange}
      getOptionLabel={(option) => option.name || ''}
      noOptionsText={t('no.data')}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value.id;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
    />
  );
};

export default SchoolsAutoComplete;
