import {createAction, createSlice} from '@reduxjs/toolkit';
import {PER_PAGE_OPTIONS} from '../../constants';
import {Nullable, RequestParams, ResourcePathModifier, ResourceType} from '../../types';
import {resetState} from '../actions';

import {
  changeEntitiesPage,
  changeEntitiesPageSize,
  changeParams,
  entitiesFail,
  entitiesRequest,
  entitiesSuccess,
  entityFail,
  entityFetchRequest,
  entitySubmitRequest,
  entitySuccess
} from './reducers';

export interface ResourceState {
  entities: Array<any>,
  entitiesLoaded: boolean,
  entitiesLoading: boolean,
  entitiesErrors: Nullable<Array<any>>,
  entity: any,
  entitySubmitting: boolean,
  entityLoaded: boolean,
  entityLoading: boolean,
  entityErrors: Nullable<Array<any>>,
  pagination: {
    total: number,
    page: number,
    pageSize: number,
  },
  path: string,
  name: string,
  entityKey: string,
  route: string,
  params?: RequestParams,
  defaultSort: string,
  sortOrder: string,
}

const initialState: ResourceState = {
  entities: [],
  entitiesLoaded: false,
  entitiesLoading: false,
  entitiesErrors: null,
  entity: null,
  entitySubmitting: false,
  entityLoaded: false,
  entityLoading: false,
  entityErrors: null,
  pagination: {
    total: 0,
    page: 1,
    pageSize: PER_PAGE_OPTIONS[2],
  },
  path: '',
  name: '',
  entityKey: '',
  route: '',
  params: undefined,
  defaultSort: '',
  sortOrder: 'descend',
}

type Params = {
  name: ResourceType,
  initialState: ResourceState,
  reducers?: any,
  extraReducers?: any,
};

export const fetchEntity = createAction<{name: string, pathModifier: ResourcePathModifier, id: number}>('fetchEntity');
export const createEntity = createAction<{name: string, pathModifier: ResourcePathModifier, values: any, onSuccess?: any, onError?: any, redirect?: boolean}>('createEntity');
export const updateEntity = createAction<{name: string, pathModifier: ResourcePathModifier, id: number, values: any, onSuccess?: any, onError?: any, redirect?: boolean}>('updateEntity');
export const deleteEntity = createAction<{name: string, pathModifier: ResourcePathModifier, id: number, redirect?: boolean}>('deleteEntity');
export const fetchEntities = createAction<{name: string, pathModifier: ResourcePathModifier, params?: RequestParams}>('fetchEntities');

const createResourceSlice = ({
  name,
  initialState,
  reducers,
  extraReducers,
}: Params ) => {

  return createSlice({
    name,
    initialState,
    reducers: {
      entitiesRequest,
      entitiesSuccess,
      entitiesFail,
      entityFetchRequest,
      entitySubmitRequest,
      entitySuccess,
      entityFail,
      changeEntitiesPage,
      changeEntitiesPageSize,
      changeParams,
      cleanEntities: () => ({
        ...initialState,
      }),
      ...reducers,
    },
    extraReducers: {
      [createEntity.type]: (state) => {
        return state;
      },
      [updateEntity.type]: (state) => {
        // TODO: update entity on the array of entities.
        return state;
      },
      [fetchEntity.type]: (state) => {
        return state;
      },
      [deleteEntity.type]: (state, {payload}) => {
        if(Array.isArray(state.entities)){
          return {
            ...state,
            entities: state.entities.filter((el) => el.id !== payload.id)
          }
        }

        return state;
      },
      [fetchEntities.type]: (state) => {
        return state;
      },
      [resetState.type]: () => {
        return initialState;
      },
      ...extraReducers
    }
  });
}

const slices = {
  groups: createResourceSlice({
    name: 'groups',
    initialState,
  }),
  posts: createResourceSlice({
    name: 'posts',
    initialState,
  }),
  teachers: createResourceSlice({
    name: 'teachers',
    initialState,
  }),
  lessons: createResourceSlice({
    name: 'lessons',
    initialState,
  }),
  schedules: createResourceSlice({
    name: 'schedules',
    initialState,
  }),
  tasks: createResourceSlice({
    name: 'tasks',
    initialState,
  }),
  children: createResourceSlice({
    name: 'children',
    initialState,
  }),
  admins: createResourceSlice({
    name: 'admins',
    initialState,
  }),
  schools: createResourceSlice({
    name: 'schools',
    initialState,
  }),
  parents: createResourceSlice({
    name: 'parents',
    initialState,
  }),
  owners: createResourceSlice({
    name: 'owners',
    initialState,
  }),
  invoices: createResourceSlice({
    name: 'invoices',
    initialState,
  }),
  expenses: createResourceSlice({
    name: 'expenses',
    initialState,
  }),
  complaints: createResourceSlice({
    name: 'complaints',
    initialState,
  }),
  support: createResourceSlice({
    name: 'support',
    initialState,
  }),
}

export default slices;
