import React, {ComponentType} from 'react';
import Box from '@mui/material/Box';
import {motion, Variants} from 'framer-motion';

const variants: Variants = {
  offscreen: { opacity: 0, x: 0, transition: { duration: .2 } },
  onscreen: { opacity: 1, x: 0, transition: { duration: .2 } },
  exit: { opacity: 0, x: 0, transition: { duration: .2 } }
};

export default function withAnimatedRoute<ComponentProps>(Component:ComponentType<ComponentProps>){
  return (props: ComponentProps) => {
    // @ts-ignore
    return (
      <Box
        component={motion.div}
        initial="offscreen"
        animate="onscreen"
        exit="exit"
        variants={variants}
      >
        {/* @ts-ignore */}
        <Component {...props} />
      </Box>
    )
  }
}

