import * as Yup from 'yup';

const validationSchema = (mode: 'create' | 'edit') => {
  return Yup.object().shape({
    name: Yup.string().required('required').nullable(true),
    address: Yup.string().required('required').nullable(true),
    Contact: Yup.object()
      .shape({
        name: Yup.string()
          .min(2, 'name.min')
          .max(255, 'name.max.255.string')
          .required('required')
          .nullable(true),
        surname: Yup.string().required('required').nullable(true),
        patronymic: Yup.string().nullable(true),
        email: Yup.string()
          .email('email.invalid')
          .required('required')
          .nullable(true),
        phone: Yup.string()
          .test('phone', 'phone.invalid', (value) => {
            return (/^(?:\+38)?(0\d{2}\d{3}\d{2}\d{2})$/u.test(value || ''));
          })
          .nullable(true),
      }),
  });
}

export default validationSchema;
