import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {SortOrder, SxPropsObject} from '../../types';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import {getSxStyle} from '../../utils';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as StepsIcon} from '../../assets/icons/steps.svg';
import IconButton from '@mui/material/IconButton';

interface Props  {
  sort: string;
  order: SortOrder;
  values: Array<{
    label: React.ReactNode;
    value: string;
  }>,
  onChangeSort: (value: string) => void;
  onChangeOrder: (order: SortOrder) => void;
  sx?: SxProps<Theme>
}

const Sorting:React.FC<Props> = (props) => {
  const {sx, values, sort, order, onChangeSort, onChangeOrder} = props;
  const {t} = useTranslation();


  const handleChangeField = async (event: SelectChangeEvent) => {
    const value = event.target.value;
    onChangeSort(value);
  };

  const handleChangeOrder = ():void => {
    if(order === 'asc') {
      onChangeOrder('desc');
    } else {
      onChangeOrder('asc');
    }
  };

  const placeholder = t(`field.sorting.placeholder`);

  return (
    <Box
      sx={(theme) => {
        const style = getSxStyle(styles.container, theme)
        return {
          ...style,
          ...(getSxStyle(sx || {}, theme))
        }
      }}
    >
      <IconButton
        sx={styles.order}
        color="inherit"
        aria-label={t(`sorting.order.${order}`)}
        onClick={handleChangeOrder}
        edge="start"
      >
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 24 24"
          component={StepsIcon}
          sx={{
            position: 'relative',
            transform: `rotate(${order === 'asc' ? '0deg' : '180deg'})`
          }}
        />
      </IconButton>
      <FormControl
        size="small"
      >
        <Select
          sx={styles.select}
          value={sort}
          defaultValue={sort}
          onChange={handleChangeField}
          MenuProps={{ disableScrollLock: true }}
          displayEmpty
          inputProps={{
            sx: styles.selectInput,
            'aria-label': 'Sort values',
          }}
          renderValue={(value) => {
            if (value === undefined || value === '') {
              return placeholder;
            }
            const current = values?.find((el) => el.value === value)
            return current?.label || value;
          }}
        >
          {values.map((item) => {
            return (
              <MenuItem value={item.value} key={item.value} sx={styles.option}>
                {item.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    display: 'flex',
    alignItems: 'center',
    mr: 1,
    ml: 'auto',
  },
  control: {},
  select: {

    '&, &.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      right: 0,
    }
  },
  selectInput: (theme) => ({
    p: theme.spacing(0.5),
    border: '0 none',

    '&.MuiOutlinedInput-input.MuiInputBase-input': {
      pr: theme.spacing(3),
    }
  }),
  order: {
    mr: 0.5
  },
}

export default React.memo(Sorting);
