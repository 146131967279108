import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as EllipsisIcon} from '../../../../../../assets/icons/ellipsisHorisontal.svg';
import IconButton from '@mui/material/IconButton';
import React, {MouseEvent} from 'react';
import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import {SxPropsObject} from '../../../../../../types';
import {RowActions} from '../../../../types';

interface Props extends Pick<RowActions, 'actions'> {
}

const Actions = (props: Props) => {
  const {actions} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    //@ts-ignore
    setAnchorEl(e.currentTarget);
  }

  const onClose = () => {
    setAnchorEl(null);
  }

  return(
    <React.Fragment>
      <IconButton
        size="medium"
        color="inherit"
        aria-label="Actions"
        edge="end"
        onClick={onClick}
      >
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 24 24"
          component={EllipsisIcon}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{
          dense: true,
          disablePadding: true,
        }}
      >
        {actions?.map((item, index) => {
          return (
            <ListItem
              key={index}
              disablePadding
              dense
              divider={index !== actions?.length - 1}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  sx={{color: item?.color || 'text.primary'}}
                  disabled={item?.disabled}
                >
                  <SvgIcon
                    color="inherit"
                    fontSize="medium"
                    viewBox={item?.icon?.viewBox}
                    component={item?.icon?.component || 'big'}
                    sx={styles.icon}
                  />
                </IconButton>
              }
            >
              <ListItemButton disabled={item?.disabled} onClick={item.action as () => void} sx={styles.item}>
                <ListItemText
                  primary={item?.label}
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: item.color || 'text.primary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </Menu>
    </React.Fragment>
  )
}

const styles:SxPropsObject = {
  item: (theme) => ({
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['background', 'box-shadow', 'color']),
    p: 1.5,
  }),
  icon: {
    color: 'currentColor',
    fill: 'none',
  },
}

export default Actions;
