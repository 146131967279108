import React from 'react';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import {ReactComponent as EmptyIcon} from '../assets/icons/noData.svg';
import {alpha} from '@mui/material';

const useNoDataOverlay= (
  message: React.ReactNode,
  heading?: React.ReactNode,
  displayIcon?: boolean,
) => {
  return () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {heading && (
          <Typography
            variant="body1"
            gutterBottom
            fontWeight={500}
            color="text.primary"
          >
            {heading}
          </Typography>
        )}
        {displayIcon && (
          <SvgIcon
            viewBox="0 0 64 41"
            component={EmptyIcon}
            sx={(theme) => ({
              fontSize: {
                xs: theme.spacing(10)
              }
            })}
          />
        )}
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            color: theme => alpha(theme.palette.divider, 0.7),
          }}
        >
          {message}
        </Typography>
      </Stack>
    )
  };
};

export default useNoDataOverlay;
