import * as Yup from 'yup';
import {password} from '../../../schemas';
import {matchIsValidTel} from 'mui-tel-input';

const validationSchema = (mode: 'create' | 'edit') => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, 'name.min')
      .max(255, 'name.max')
      .required('required')
      .nullable(true),
    surname: Yup.string().required('required').nullable(true),
    patronymic: Yup.string().nullable(true),
    email: Yup.string()
      .email('email.invalid')
      .required('required')
      .nullable(true),
    phone: Yup.string()
      .test('phone', 'phone.invalid', function(value){
        return matchIsValidTel(value || '');
      })
      .required('required')
      .nullable(true),
    password: password(mode)
  });
}

export default validationSchema;
