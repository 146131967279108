import React, {useEffect} from 'react';
import {GridRenderCellParams} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {DataGridProps} from '@mui/x-data-grid/models/props/DataGridProps';
import useNoDataOverlay from '../../../../hooks/useNoDataOverlay';
import {Nullable, RequestParams, ResourcePathModifier, ResourceType, SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {Translation, useTranslation} from 'react-i18next';
import {RowActions} from '../../types';
import Actions from '../common/list/Actions';
import {a11yProps, getSxStyle} from '../../../../utils';
import {Outlet, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from '../../../../redux/store';
import slices, {deleteEntity, fetchEntities, updateEntity} from '../../../../redux/resource/slice';
import {paginationSelector, paramsSelector} from '../../../../redux/resource/selectors';
import {PER_PAGE_OPTIONS} from '../../../../constants';
import {ReactComponent as PencilIcon} from '../../../../assets/icons/percil.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/icons/trash.svg';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../TabPanel';
import {DataGridCustom} from '../../../../styled';
import useSearchParamsHandler from '../../../../hooks/useSearchParamsHandler';
import SearchBox from '../../../SearchBox';

interface ChildrenProps {
  entities: Array<any>;
  loading: boolean;
  onEdit: (id: number, values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => void;
  onDelete: (id: number) => void;
  content: React.ReactNode;
}

interface Props extends Omit<DataGridProps, 'rows'> {
  name: ResourceType;
  search?: boolean;
  transformer?: <T, R>(entities: Array<T>) => Array<R>;
  pathModifier?: ResourcePathModifier;
  title?: string;
  actions: () => React.ReactNode;
  filters?: React.ReactNode;
  sorting: React.ReactNode,
  defaultParams?: RequestParams;
  noRows?: {
    message:  Nullable<string>,
    heading:  Nullable<string>,
    icon:  boolean,
  },
  rowActions?: RowActions | boolean;
  children?: (props: ChildrenProps) => React.ReactNode;
  tabs?: {
    value: string | number;
    onChange: (value: any) => void;
    items: Array<{label: React.ReactNode, value: string | number}>,
  }
  HeadProps?: {
    sx?: SxProps<Theme>
  }
}

const defaultProps = {
  noRows: {
    icon: true,
    heading: null,
    message: <Translation>{(t) => t('no.data')}</Translation>
  }
}

const List = (props:Props) => {
  const {
    title,
    columns,
    filters,
    sorting,
    actions,
    rowActions,
    noRows,
    name,
    transformer,
    pathModifier,
    children,
    defaultParams,
    HeadProps,
    tabs,
    search,
    ...rest
  } = props;

  const {
    changeEntitiesPage,
    changeEntitiesPageSize,
  } =  slices[name].actions;

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const {page, pageSize, total} = useSelector(paginationSelector(name));
  const params = useSelector(paramsSelector(name));
  const query = searchParams.get('query') || '';

  useEffect(() => {
    dispatch(fetchEntities({
      name: name,
      pathModifier: pathModifier || null,
      params: defaultParams,
    }))
  }, [pathModifier, dispatch, name, page, pageSize, params, defaultParams]);

  const {
    entities,
    entitiesLoading
  } = useSelector((state: AppStore) => {
    //@ts-ignore
    return state.resources[name];
  });

  const onDelete = (id: number): void => {
    dispatch(deleteEntity({
      name: name,
      pathModifier: pathModifier || null,
      id,
    }));
  }

  const onEdit = (id: number, values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
    dispatch(updateEntity({
      id: id,
      name,
      pathModifier: pathModifier || null,
      values,
      onSuccess,
      onError,
      redirect
    }))
  }

  const {
    handleChangeQueryDebounce
  } = useSearchParamsHandler();

  const redirectOnEdit = (id: number): void => {
    navigate(`/dashboard/${name}/edit/${id}`)
  }

  const onPageChange = (page: number) => {
    //@ts-ignore
    dispatch(changeEntitiesPage(page + 1))
  }

  const onPageSizeChange = (pageSize: number) => {
    //@ts-ignore
    dispatch(changeEntitiesPageSize(pageSize))
  }

  const NoRowsOverlay = useNoDataOverlay(
    noRows?.message,
    noRows?.heading,
    noRows?.icon,
  );

  const extendedColumns = [
    ...columns
  ];

  const allActions = (id: number) => {
    let items = [] as RowActions['actions'];

    if(typeof rowActions !== 'boolean') {
      if(rowActions?.mode !== 'overwrite') {
        items?.push(
          {
            name: 'edit',
            label: t('edit.value'),
            color: 'text.gray',
            icon: {
              component: PencilIcon,
              viewBox: '0 0 24 24',
            },
            action: () => redirectOnEdit(id),
          },
          {
            name: 'delete',
            label: t('delete.value'),
            color: 'error.main',
            icon: {
              component: TrashIcon,
              viewBox: '0 0 24 24',
            },
            action: () => onDelete(id),
          },
        )
      }

      if(rowActions?.mode === 'extend') {
        items = items?.map((item) => {
          const current = rowActions?.extended?.find((el) => el.name === item.name);
          return {
            ...item,
            ...(current ? {
              ...current,
              action: () => current?.action?.(id)
            } : {})
          };
        });
      }

      if(Array.isArray(rowActions?.actions)) {
        items?.push(...(rowActions?.actions || []).map((item) => {
          return {
            ...item,
            action: () => item.action(id)
          }
        }))
      }
    }

    return items;
  }

  if(rowActions !== false) {
    extendedColumns.push({
      field: 'action',
      headerName: '',
      editable: false,
      sortable: false,
      type: 'actions',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        let id = row.id;
        if(typeof rowActions !== 'boolean' && typeof rowActions?.getterId === 'function') {
          id = rowActions?.getterId(row);
        }
        const actions = allActions(id)
        return (
          <Actions actions={actions}/>
        );
      }
    })
  }

  const rows = transformer ? transformer(entities) : entities;

  const content = (
    <Box sx={(theme) => {
      return {
        ...getSxStyle(styles.tableBox, theme),
        height: `calc(100vh - ${theme.spacing(37)})`,
        minHeight: 480,
      }
    }}>
      <DataGridCustom
        loading={entitiesLoading}
        sx={styles.table}
        rows={rows}
        rowCount={total}
        columns={extendedColumns}
        getRowHeight={() => 'auto'}
        headerHeight={44}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        pagination
        paginationMode="server"
        page={page - 1}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        components={{
          NoRowsOverlay,
        }}
        {...rest}
      />
    </Box>
  );


  const tabsContent = (
    <>
      <Tabs
        sx={styles.tabs}
        value={tabs?.value || ''}
        onChange={(event, value) => {
          tabs?.onChange(value)
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Invoice type tabs"
      >
        {tabs?.items?.map(({label, value}) => {
          return (
            <Tab
              key={value}
              sx={styles.tab}
              label={label}
              value={value}
              {...a11yProps(value)}
            />
          )
        })}
      </Tabs>
      {tabs?.items?.map(({ value}) => {
        return (
          <TabPanel key={value} value={tabs?.value || ''} index={value}>
            {content}
          </TabPanel>
        )
      })}
    </>
  )

  return (
    <>
      <Box
        sx={(theme) => ({
          ...getSxStyle(styles.head, theme),
          ...(getSxStyle(HeadProps?.sx || {}, theme))
        })}
      >
        {title && (
          <Typography sx={styles.heading} variant="h6">
            {title}
          </Typography>
        )}
        {search && (
          <SearchBox
            size="small"
            fullWidth
            placeholder={`${t('field.search.label')}...`}
            searchValue={query}
            onChangeSearchValue={(value) => {
              handleChangeQueryDebounce(value);
            }}
            sx={(theme) => ({
              maxWidth: 280,
              ml: 'auto',
              order: {

              }
            })}
          />
        )}
        {filters}
        {sorting}
        {actions && actions()}
      </Box>
      {children ? (
        <>
          {children({
            entities: rows,
            loading: entitiesLoading,
            onEdit,
            onDelete,
            content,
          })}
        </>
      ) : (
        <>{tabs ? tabsContent : content}</>
      )}
      <Outlet/>
    </>
  );
};

const styles: SxPropsObject = {
  container: {
    flex: 1,
  },
  content: {
    borderRadius: 2,
    minHeight: '100%',
  },
  head: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    p: 3,
    pb: 2,

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  }),
  heading: (theme) => ({
    mr: 'auto',
    ml: 0,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      mb: 1,
    },
  }),
  tableBox: theme => ({
    background: theme.palette.common.white,
    borderRadius: 2,
  }),
  cellContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'divider',

    '& .MuiTab-root': {
      p: 3,
    },

    [theme.breakpoints.down('sm')]: {
      px: 3
    }
  }),
  tab: () => ({
    textTransform: 'capitalize',
  }),
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

List.defaultProps = defaultProps;
export default React.memo(List);
