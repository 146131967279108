import Box from '@mui/material/Box';
import List from '@mui/material/List';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Content from '../../../../layouts/Main/components/Content';
import {pages} from '../content';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import {useTranslation} from 'react-i18next';

const ListComponent = () => {
  const {t} = useTranslation();

  return (
    <Content title={t('questions.list.title', {ns: 'pages'})}>
      <Box sx={{p: 3, minHeight: 480}}>
        <List component="nav" aria-label="secondary mailbox folder">
          {pages.map((page) => {
            return (
              <ListItem
                key={page.id}
              >
                <Link
                  component={RouterLink}
                  to={`/dashboard/questions/view/${page.id}`}
                  replace
                  underline="always"
                  color="text.primary"
                  variant="subtitle2"
                >
                  {page.title}
                </Link>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Content>
  );
};

export default ListComponent;
