import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'name.max')
    .required('required')
    .nullable(true),
  Teacher: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
    })
    .required('required').nullable(true),
});

export default validationSchema;
