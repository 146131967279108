import React, {useCallback} from 'react';
import {createSearchParams, Link as RouterLink, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers, setNestedObjectValues} from 'formik';
import {Child, Group, Nullable, Payment as PaymentType, SxPropsObject, TrusteePerson, User} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import validationSchema from '../../validationSchema';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DATE_FORMAT, GENDERS, MAX_CHILD_AGE, MIN_CHILD_AGE} from '../../../../../constants';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';
import Logs from '../Logs';
import Button from '@mui/material/Button';
import Payment from '../Payment';
import API from '../../../../../api';
import {
  apiErrorsToFormik,
  getPaymentStatus,
  groupsResponseToState,
  renderFullName,
  statusColor,
  userResponseToState
} from '../../../../../utils';
import {GroupResponsePayload, TagResponsePayload, UserResponsePayload} from '../../../../../api/types';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import {NumericFormat} from 'react-number-format';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as PhoneIcon} from '../../../../../assets/icons/phone.svg';
import {ReactComponent as EmailIcon} from '../../../../../assets/icons/email.svg';

interface FormValues extends Pick<
  Child, 'name' | 'surname' | 'patronymic' | 'birthday' | 'note' | 'gender' | 'Group' | 'Tags'
> {
  Trustee: Partial<TrusteePerson>,
  payment: {
    date: {
      current: Nullable<string>,
      expiry: Nullable<string>,
    },
    value: Nullable<number>;
    comment: Nullable<string>;
  }
}

interface Props {
  entity: Nullable<Child>;
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'firstName':'name',
  'middleName':'patronymic',
  'phoneNumber':'phone',
  'kinderGardenId':'schoolId',
  'parentId': 'Trustee.id',
  'parentType':'Trustee.relationship',
  'groupId':'Group.id',
  'curseCost':'payment.value',
}

const Form = (props: Props) => {
  const {
    entity,
    mode,
    submitting,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers: FormikHelpers<FormValues>,
    ): Promise<any> => {
      const {setSubmitting, setTouched, validateForm, setFormikState} = helpers;

      const errors = await validateForm();
      const invalidPayment = Object.keys(errors).length === 1 && Object.keys(errors).includes('payment');
      const invalid = Object.keys(errors).length > 0;

      setTouched(setNestedObjectValues(errors, true));

      if(invalidPayment && activeTab === 0) {
        setActiveTab(1);
      } else if (activeTab === 1){
        setActiveTab(0)
      }

      if (!invalid) {
        setSubmitting(true)
        onSubmit(values, () => {
          setSubmitting(false);
        }, (errors) => {
          const apiErrors = apiErrorsToFormik(errors, fields);
          const apiTouched = setNestedObjectValues(apiErrors, true);

          setFormikState((state) => {
            return {
              ...state,
              isSubmitting: false,
              errors: apiErrors || {},
              touched: apiTouched || {}
            }
          })
        });
      }
    }, [activeTab, onSubmit],
  );

  const handleCreateInvoice = () => {
    navigate({
      pathname: `/dashboard/payments/invoices/create`,
      search: createSearchParams({
        status: 'in_progress'
      }).toString()
    });
  }

  const handleAddPayment = () => {
    navigate({
      pathname: `/dashboard/payments/invoices/create`,
      search: createSearchParams({
        status: 'complete'
      }).toString()
    });
  }

  const title = t(`children.content.title.${mode}`, {ns: 'pages'})

  const {Lessons} = entity || {};

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => console.log('submit')}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          dirty,
          resetForm,
          setSubmitting,
          setErrors,
          setTouched,
          validateForm,
          setFormikState
        }) => {

        const generalLeft = (
          <>
            <Typography variant="h6" sx={{mb: 3}}>
              {t('children.content.main.section.child', {ns: 'pages'})}
            </Typography>
            <Grid container spacing={{xs: 0, sm: 2}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="surname"
                  value={values.surname || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.surname && errors?.surname)}
                  helperText={(
                    <ErrorMessage
                      name="surname"
                      render={(message) => t(message, {ns: 'validation', min: 2, max: 255})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.surname.label')}
                  placeholder={t('field.surname.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="name"
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.name && errors?.name)}
                  helperText={(
                    <ErrorMessage
                      name="name"
                      render={(message) => t(message, {ns: 'validation', min: 2, max: 255})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.name.label')}
                  placeholder={t('field.name.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="patronymic"
                  value={values.patronymic || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.patronymic && errors?.patronymic)}
                  helperText={(
                    <ErrorMessage
                      name="patronymic"
                      render={(message) => t(message, {ns: 'validation', min: 2, max: 255})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.patronymic.label')}
                  placeholder={t('field.patronymic.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  format={DATE_FORMAT}
                  disableFuture
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      sx: styles.field,
                      name: "birthday",
                      onBlur: handleBlur,
                      error: Boolean(touched?.birthday && errors?.birthday),
                      helperText: (
                        <ErrorMessage
                          name="birthday"
                          render={(message) => t(message, {
                            ns: 'validation',
                            min: t('measure.month.plural', {
                              count: MIN_CHILD_AGE
                            }),
                            max: t('measure.year.plural', {
                              count: MAX_CHILD_AGE / 12
                            })
                          })}
                        />
                      ),
                      margin: "normal",
                      required: true,
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      size: "medium",
                      autoComplete: 'off'
                    }
                  }}
                  value={values.birthday}
                  label={t('field.birthday.label')}
                  onChange={(value: any) => {
                    setFieldValue('birthday', value, true);
                    setFieldTouched('birthday', true, false)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  sx={styles.field}
                  name="gender"
                  value={values.gender || undefined}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.gender && errors?.gender)}
                  helperText={(
                    <ErrorMessage
                      name="gender"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.gender.label')}
                  placeholder={t('field.gender.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => {
                      if (value as string === undefined) {
                        return (t('field.gender.placeholder'));
                      }
                      const current = GENDERS.find((el) => el.value === value)
                      return current?.label || '';
                    }
                  }}
                >
                  {GENDERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutoCompleteField<Partial<Group>, undefined, undefined, undefined>
                  sx={styles.field}
                  fullWidth
                  size="medium"
                  transformer={(entities: Array<GroupResponsePayload>): Array<Group> => {
                    return entities?.map((entity) => {
                      return groupsResponseToState(entity);
                    }) || [];
                  }}
                  TextFieldProps={{
                    name: "Group",
                    required: true,
                    label: t('field.group.label'),
                    placeholder: t('field.group.placeholder'),
                    margin: 'normal',
                    InputLabelProps: { shrink: true },
                    error: Boolean(touched?.Group && errors?.Group),
                    helperText: (
                      <ErrorMessage
                        name="Group.id"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )
                  }}
                  fetch={() => API.groups.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return option?.name || '';
                  }}
                  value={values?.Group?.id ? values.Group : null}
                  onChange={(event, value) => {
                    if(!value) {
                      setFieldValue('Group', {
                        id: null,
                        name: null,
                      })
                    }
                    setFieldValue('Group', value)
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <TextField
              sx={styles.field}
              name="note"
              value={values.note || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.note && errors?.note)}
              helperText={(
                <ErrorMessage
                  name="child.note"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              fullWidth
              label={t('field.note.child.label')}
              placeholder={t('field.note.child.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              multiline
              rows={4}
            />
            <AutoCompleteField<string, true, undefined, true>
              sx={styles.field}
              fullWidth
              multiple
              freeSolo
              size="medium"
              TextFieldProps={{
                name: "Tags",
                required: true,
                label: t('field.tags.label'),
                placeholder: t('field.tags.placeholder'),
                margin: 'normal',
                InputLabelProps: { shrink: true },
                error: Boolean(touched?.Tags && errors?.Tags),
                helperText: (
                  <ErrorMessage
                    name="Tags"
                    render={(message) => t(message, {ns: 'validation'})}
                  />
                )
              }}
              transformer={(entities: Array<TagResponsePayload>): Array<string> => {
                return entities?.map((entity) => {
                  return entity.title;
                }) || [];
              }}
              fetch={() => API.tags.getAll(`/kinder-garden/${user?.schoolId}`)}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              value={values.Tags}
              onChange={(event, value) => {
                setFieldValue('Tags', value)
              }}
              onBlur={handleBlur}
            />
          </>
        );

        const generalRight = (
          <>
            <Typography variant="h6" sx={{mb: 3}}>
              {t('children.content.main.section.trustee', {ns: 'pages'})}
            </Typography>
            <Grid container spacing={{xs: 0, sm: 2}}>
              <Grid item xs={12} sm={6}>
                <AutoCompleteField<Partial<TrusteePerson>, undefined, undefined, undefined>
                  sx={styles.field}
                  fullWidth
                  size="medium"
                  transformer={(entities: Array<UserResponsePayload>): Array<User> => {
                    return entities?.map((entity) => {
                      return userResponseToState(entity);
                    }) || [];
                  }}
                  TextFieldProps={{
                    name: "Trustee",
                    required: true,
                    label: t('field.trustee.label'),
                    placeholder: t('field.trustee.placeholder'),
                    margin: 'normal',
                    InputLabelProps: { shrink: true },
                    error: Boolean(touched?.Trustee && errors?.Trustee),
                    helperText: (
                      <ErrorMessage
                        name="Trustee.id"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )
                  }}
                  fetch={() => API.parents.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return renderFullName(option);
                  }}
                  value={values?.Trustee?.id ? values.Trustee : null}
                  onChange={(event, value) => {
                    if(!value) {
                      setFieldValue('Trustee', {
                        id: null,
                        name: null,
                        surname: null,
                        patronymic:  null,
                        relationship: null,
                      })
                    }
                    setFieldValue('Trustee', value)
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="Trustee.relationship"
                  value={values?.Trustee?.relationship || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.Trustee?.relationship && errors?.Trustee?.relationship)}
                  helperText={(
                    <ErrorMessage
                      name="Trustee.relationship"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.relationship.child.label')}
                  placeholder={t('field.relationship.child.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              {mode === 'edit' && values?.Trustee && (
                <Grid item xs={12} sm={6}>
                  <Link
                    variant="subtitle2"
                    to={`/dashboard/parents/edit/${values?.Trustee.id}`}
                    component={RouterLink}
                    underline="hover"
                    color="text.primary"
                  >
                    {renderFullName(values?.Trustee)}
                  </Link>
                  <List dense>
                    <ListItem dense disableGutters>
                      <SvgIcon
                        fontSize="medium"
                        viewBox="0 0 24 24"
                        component={PhoneIcon}
                        sx={{
                          mr: 2
                        }}
                      />
                      <ListItemText
                        primary={values?.Trustee?.phone}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.primary',
                        }}
                      />
                    </ListItem>
                    <ListItem dense disableGutters>
                      <SvgIcon
                        fontSize="medium"
                        viewBox="0 0 24 24"
                        component={EmailIcon}
                        sx={{
                          mr: 2
                        }}
                      />
                      <ListItemText
                        primary={values?.Trustee?.email}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.primary',
                        }}
                      />
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </>
        )

        const paymentLeft = (
          <>
            <Typography variant="h6" sx={{mb: 3}}>
              {t('children.content.payment.section.education', {ns: 'pages'})}
            </Typography>
            <Grid container spacing={{xs: 0, sm: 2}}>
              <Grid item xs={6}>
                <NumericFormat
                  customInput={TextField}
                  allowNegative={false}
                  name="payment.value"
                  value={values?.payment?.value || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.payment?.value && errors?.payment?.value)}
                  helperText={(
                    <ErrorMessage
                      name="payment.value"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.payment.month.label')}
                  placeholder={t('field.payment.month.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
            </Grid>
            <Button
              disabled={mode === 'create'}
              size="medium"
              variant="outlined"
              color="primary"
              sx={{
                mt: 2,
              }}
              onClick={handleCreateInvoice}
            >
              {t('create.invoice')}
            </Button>
          </>
        );

        const {current, expiry} = entity?.payment?.date || {};

        const {
          value,
          left,
          status,
        } = getPaymentStatus(current || null, expiry || null);

        const chipColor = statusColor(status);

        let paymentsLogs = null as Nullable<Array<PaymentType>>;
        if(current && expiry) {
          paymentsLogs = entity?.payment ? [entity?.payment] : null;
        }

        const paymentRight = (
          <>
            <Typography variant="h6" sx={{mb: 3}}>
              {t('children.content.payment.section.addition', {ns: 'pages'})}
            </Typography>
            <Box sx={styles.contentItemHead}>
              <Typography variant="subtitle1">
                {t('field.status.payment.label')}
              </Typography>
              <Chip
                label={t(`payment.status.${entity?.payment?.status}`)}
                color={chipColor}
                variant="filled"
                sx={{
                  borderRadius: 2
                }}
              />
            </Box>
            <Box sx={styles.paymentInfo}>
              <Typography variant="subtitle2" component="span">
                {t(`children.content.payment.text.${left < 0 ? 'overdue' : 'next'}`, {ns: 'pages'})}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {t('measure.days.plural', {count: left})}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={value}
              color={chipColor === 'default' ? undefined : chipColor}
              sx={styles.progress}
            />
            <Logs
              sx={{mt: 2, mb: 3}}
              data={paymentsLogs}
            />
            <Typography variant="body2" sx={{mt: 2, mb: 2}}>
              {t('children.content.payment.text.change', {ns: 'pages'})}
            </Typography>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{
                mt: 2,
              }}
              onClick={handleAddPayment}
            >
              {t('add.payment')}
            </Button>
          </>
        );

        const lessonsLeft = (
          <>
            <Typography variant="subtitle1" sx={{mt: 3, mb: 2}}>
              {t('children.content.lessons.section.lessons', {ns: 'pages'})}
            </Typography>
            {(Lessons && Lessons.length > 0 ) ? Lessons?.map((lesson, index) => {
              const payment: PaymentType = {
                ...lesson.payment,
                type: 'card',
                status: 'expired',
                comment: null,
                value: 0,
              }

              return (
                <Payment
                  key={String(lesson?.id) + index}
                  name={lesson.name || ''}
                  sx={{mb: 1}}
                  payment={payment}
                />
              )
            }) : (
              <NoData/>
            )}
          </>
        );

        const tabs = [
          {
            label: t('children.tabs.main', {ns: 'pages'}),
            content: {
              left: generalLeft,
              right: generalRight,
            }
          },
          {
            label: t('children.tabs.payment', {ns: 'pages'}),
            content: {
              left: paymentLeft,
              right: paymentRight
            }
          },
        ]

        if(mode === 'edit') {
          tabs.push({
            label: t('children.tabs.lessons', {ns: 'pages'}),
            content: {
              left: lessonsLeft,
              right: <></>
            }
          })
        }

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                    custom: {
                      color: 'primary',
                      label: mode === 'create' ? t('add.child') : t('edit.child'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting,
                      action: async () => {
                        // @ts-ignore
                        await handleCreate(values, {setSubmitting, setErrors, setTouched, validateForm, setFormikState});
                      }
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentItemHead: {
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  field: {
  },
  paymentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  progress: (theme) => ({
    height: theme.spacing(1),
    mt: 1,
    borderRadius: 0.5,

    '&.MuiLinearProgress-bar': {
      backgroundColor: '#F95C00',
    }
  }),
}

export default Form;
