import React, {Dispatch, SetStateAction} from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useNoDataOverlay from '../../../hooks/useNoDataOverlay';
import {useTranslation} from 'react-i18next';
import {PopoverPosition} from '@mui/material/Popover/Popover';
import Spinner from '../../Spinner';
import {Nullable} from '../../../types';

interface Props {
  loading?: boolean;
  anchorPosition: PopoverPosition | undefined;
  setAnchorPosition: Dispatch<SetStateAction<PopoverPosition | undefined>>;
  items: Nullable<Array<{
    half?: boolean;
    content: React.ReactNode;
  }>>;
  footer: React.ReactNode;
}

const PopoverComponent = (props: Props) => {
  const {
    anchorPosition,
    setAnchorPosition,
    items,
    footer,
    loading,
  } = props;

  const {t} = useTranslation();

  const handleClosePopover = () => {
    setAnchorPosition(undefined);
  };

  const popoverOpen = Boolean(anchorPosition);

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  return (
    <Popover
      open={popoverOpen}
      onClose={handleClosePopover}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 4,
        sx: {
          borderRadius: 4,
        }
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        zIndex: 99999999,
      }}
    >
      <Box
        component="div"
        sx={{
          p: 2,
          minWidth: 270,
          maxWidth: 320,
        }}
      >
        {loading ? (
           <Spinner/>
          ) : (
          <>
            {Boolean(items && items.length > 0) ? (
              <>
                <Grid container spacing={{sm: 2, xs: 1}}>
                  {items?.map((el, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={el.half ? 6 : 12}>
                        {el.content}
                      </Grid>
                    )
                  })}
                </Grid>
                {footer}
              </>
            ) : (
              <NoData/>
            )}
          </>
        )}
      </Box>
    </Popover>
  );
};

export default PopoverComponent;
