import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {Divider} from '@mui/material';
import {SxPropsObject} from '../../../../types';
import {Nav, NavItem, NavLink} from '../../../../styled';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {HOME_MENU_ITEMS, SECONDARY_MENU_ITEMS} from '../../../../constants';
import Socials from '../../../../components/Socials';
import { scroller } from 'react-scroll';

const Footer = () => {
  const {t} = useTranslation();

  const handleScroll = (name: string): void => {

    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Box
      sx={styles.container}
      component="footer"
    >
      <Container>
        <Grid container spacing={2} sx={{alignItems: 'center', flexDirection: {md: 'row', xs: 'column'}}}>
          <Grid item xs={12} md={0} lg={3} sx={styles.left}/>
          <Grid item xs={12} md={8} lg={6} sx={styles.center}>
            <Nav sx={styles.nav}>
              {HOME_MENU_ITEMS.map((item, index) => {
                return (
                  <NavItem sx={styles.navItem} key={index}>
                    <NavLink
                      variant="body2"
                      color="text.primary"
                      fontWeight={600}
                      type="button"
                      href={`#${item.path}`}
                      onClick={(event) => {
                        event.preventDefault();
                        handleScroll(item.path);
                      }}
                    >
                      {item.value}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
          </Grid>
          <Grid item xs={12} md={4} lg={3} sx={styles.right}>
            <Socials justify="flex-end"/>
          </Grid>
        </Grid>

        <Divider sx={{mt: 3, mb: 4}}/>

        <Grid container spacing={2} sx={{justifyContent: 'space-between'}}>
          <Grid item xs={12} md="auto">
            <Typography
              sx={styles.copyright}
              component="div"
              variant="body3"
              color="text.primary"
              align="center"
            >
              {t(`copyright`, {year: new Date().getFullYear()})}
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Nav>
              {SECONDARY_MENU_ITEMS.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      variant="body2"
                      color="text.primary"
                      type="button"
                      component={Link}
                      to={item.path}
                    >
                      {item.value}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: () => ({
    paddingY: 4,
    bgcolor: '#FAFAFA',
    color: 'text.primary',
    mt: 'auto',
  }),
  left: () => ({
    display: {
      lg: 'flex',
      sm: 'none'
    }
  }),
  center: () => ({
    display: 'flex',
    justifyContent: {
      lg: 'center',
      sm: 'flex-start'
    },
  }),
  right: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    }
  }),
  nav: {
    m: 0,
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    alignItems: {
      md: 'flex-start',
      xs: 'center',
    },
  },
  navItem: (theme) => ({
    '&:not(:first-of-type)':{
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
      }
    }
  }),
  copyright: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    }
  })
}

export default Footer;
