import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Trans} from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as SadEyesIcon} from '../../assets/icons/sadEyes.svg';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material';


type State = {
  hasError: boolean;
  error: any;
  info: any;
  openDialog: boolean;
}
type Props = {
  children: any;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null,
      openDialog: false,
    };
  }

  static getDerivedStateFromError() {
    return ({
      hasError: true,
    });
  }

  componentDidCatch(error:any, info:any) {
    this.setState({ ...this.state, hasError: true, error, info });
  }

  openDialog(){
    this.setState({...this.state, openDialog: true})
  }
  closeDialog(){
    this.setState({...this.state, openDialog: false})
  }

  render() {

    const {children} = this.props;
    const {hasError} = this.state;

    if (hasError) {
      return (
        <>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2" sx={{mb: 2, textAlign: 'center'}}>
              <Trans i18nKey="messages.ops"/>{'...'}
            </Typography>
            <SvgIcon
              viewBox="0 0 430.598 430.598"
              component={SadEyesIcon}
              color="disabled"
              sx={(theme) => ({
                fontSize: theme.spacing(20)
              })}
            />
            <Typography variant="body2" sx={{mb: 4, textAlign: 'center'}}>
              <Trans i18nKey="messages.something"/>
            </Typography>
            <Alert severity="error" sx={{maxWidth: 800, mb: 4}}>
              <Stack direction="row" spacing={2} >
                <Typography variant="body3"> {this?.state?.error?.toString()}</Typography>
                <Tooltip title={<Trans i18nKey="show.details"/>}>
                  <IconButton sx={{p: 0.3}} size="small"  onClick={() => this.openDialog()}>
                    <HelpOutlineIcon fontSize="small" color="info"/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Alert>
            <Button startIcon={<RestartAltIcon/>} color="primary" variant="contained" onClick={() =>  window.location.reload()}>
              <Trans i18nKey="reload"/>
            </Button>
          </Box>
          <Dialog fullWidth maxWidth="lg" open={this.state.openDialog} onClose={() => this.closeDialog()}>
            <DialogTitle variant="subtitle2">
              <Trans i18nKey="error.details"/>
            </DialogTitle>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => this.closeDialog()}
              sx={(theme) => ({
                position: 'absolute',
                right: theme.spacing(1.5),
                top: theme.spacing(1.5),
              })}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <DialogContent dividers>
              <DialogContentText variant="body3" sx={{fontSize: '0.625rem'}}>
                <pre>{this?.state?.info?.componentStack}</pre>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      );
    }

    return (children);
  }
}

export default ErrorBoundary;
