import {useSearchParams} from 'react-router-dom';
import {SortOrder} from '../types';

type Values = {
  sort: string;
  order: SortOrder;
  onChangeSort: (value: string) => void;
  onChangeSortOrder: (value: SortOrder) => void;
}

type Props = {
  defaultSort?: string;
  defaultOrder?: SortOrder;
}

const useSortParams = (props?: Props): Values => {
  const {defaultSort, defaultOrder} = props || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') || defaultSort ||'createdAt';
  const order: SortOrder = searchParams.get('sortOrder') as SortOrder || defaultOrder || 'asc';

  const onChangeSort = (value: string) => {
    searchParams.set('sort', value)
    setSearchParams(searchParams);
  }

  const onChangeSortOrder = (value: SortOrder) => {
    searchParams.set('sortOrder', value)
    setSearchParams(searchParams);
  }

  return {
    sort,
    order,
    onChangeSort,
    onChangeSortOrder,
  };
};

export default useSortParams;
