import React, {PropsWithChildren, useCallback} from 'react';
import validationSchema from '../../validationSchema';
import Box from '@mui/material/Box';
import Content from '../../../../../layouts/Main/components/Content';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {Child, Invoice, Nullable, SxPropsObject} from '../../../../../types';
import {Translation, useTranslation} from 'react-i18next';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import {ChildResponsePayload} from '../../../../../api/types';
import {
  apiErrorsToFormik,
  childResponseToState,
  downloadFileLink,
  getMediaUri,
  renderFullName
} from '../../../../../utils';
import API from '../../../../../api';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {FormValues} from '../../types';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Services from '../Services';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import slices from '../../../../../redux/notices/slice';
import {DATE_FORMAT} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';

interface Props {
  entity: Nullable<Invoice>
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => void;
  onDelete?: () => void;
}

const fields = {
  "childId": "Child"
}

const Form = (props: PropsWithChildren<Props>) => {
  const {
    entity,
    mode,
    submitting,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    noticeOpen,
  } =  slices.actions;

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>,
    ): Promise<any> => {
    const { setSubmitting, setErrors } = helpers;
    const onSuccess = async (entity: any):Promise<void> => {
      if(values.status === 'wait'){
        try {
          const response = await API.invoices.generateDocument(`/kinder-garden/${user?.schoolId}`, entity.id)
          if(response.status === 200) {
            dispatch(noticeOpen({
              message: (
                <Translation>
                  {(t) => t('messages.generate.invoice.success')}
                </Translation>
              ),
              options: {
                key: `invoice-generate-pdf-success`,
                variant: 'success',
              },
            }))

            await downloadFileLink(getMediaUri(response.data), response.data);
          }
        } catch (err) {
          dispatch(noticeOpen({
            message: (
              <Translation>
                {(t) => t('messages.generate.invoice.failed')}
              </Translation>
            ),
            options: {
              key: `invoice-generate-pdf-error`,
              variant: 'error',
            },
          }))
        }
      }
      setSubmitting(false);
      navigate(`/dashboard/payments/invoices`)
    };

      onSubmit(values, onSuccess, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      }, false);
    }, [dispatch, navigate, noticeOpen, onSubmit, user?.schoolId],
  );

  const disabled = mode === 'edit';
  const disableStrict = disabled && entity?.status === 'complete';

  const title = t(`invoices.content.title.${mode}`, {ns: 'pages'})

  //TODO fix tslint error
  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange,
          isSubmitting,
          dirty,
          resetForm
        }) => {

        let submitLabel = mode === 'create' ? t('add.payment') : t('edit.payment');
        if(values.status === 'wait') {
          submitLabel = mode === 'create' ? t('generate.download') : t('generate.edit');
        }

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: submitLabel,
                      disabled: disableStrict && !dirty,
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
              <Box sx={styles.content}>
                <Typography variant="h6" sx={{mb: 3}}>
                  {t('invoices.content.subtitle.details', {ns: 'pages'})}
                </Typography>
                <Typography variant="subtitle1" sx={{mb: 1}}>
                  {t('invoices.content.section.status', {ns: 'pages'})}
                  {' '}
                  {entity?.id && ` #${entity?.id}`}
                </Typography>
                <RadioGroup
                  sx={{mb: 2}}
                  row
                  aria-labelledby="invoice-status"
                  name="status"
                  value={values?.status || 'wait'}
                  onChange={(event, value) => {
                    setFieldValue('status', value, true)
                  }}
                >
                  <FormControlLabel
                    disabled={disableStrict}
                    value="wait"
                    control={<Radio />}
                    label={(
                      <Chip
                        label={t(`payment.status.wait`)}
                        color="warning"
                        variant="filled"
                        sx={(theme) => ({
                          borderRadius: 2,
                          width: theme.spacing(20),
                        })}
                      />
                    )}
                  />
                  <FormControlLabel
                    disabled={disableStrict}
                    value="complete"
                    control={<Radio />}
                    label={(
                      <Chip
                        label={t(`payment.status.complete`)}
                        color="success"
                        variant="filled"
                        sx={(theme) => ({
                          borderRadius: 2,
                          width: theme.spacing(20),
                        })}
                      />
                    )}
                  />
                </RadioGroup>
                <Typography variant="subtitle1" sx={{mb: 2}}>
                  {t('invoices.content.section.buyer', {ns: 'pages'})}
                </Typography>
                <Grid container spacing={{xs: 0, sm: 2}} flexDirection="column">
                  <Grid item xs={12} sm={6}>
                    <Grid container  spacing={{xs: 0, sm: 2}}>
                      <Grid item xs={12} sm={6}>
                        <AutoCompleteField<Partial<Child>, false, undefined, undefined>
                          sx={styles.field}
                          fullWidth
                          disabled={disabled}
                          size="medium"
                          transformer={(entities: Array<ChildResponsePayload>): Array<Child> => {
                            return entities?.map((entity) => {
                              return childResponseToState(entity);
                            }) || [];
                          }}
                          TextFieldProps={{
                            name: "Child",
                            required: true,
                            label: t('field.child.label'),
                            placeholder: t('field.child.placeholder'),
                            margin: 'normal',
                            InputLabelProps: { shrink: true },
                            error: Boolean(touched?.Child && errors?.Child),
                            helperText: (
                              <ErrorMessage
                                name="Child"
                                render={(message) => t(message, {ns: 'validation'})}
                              />
                            )
                          }}
                          fetch={() => API.children.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          getOptionLabel={(option) => {
                            return renderFullName(option);
                          }}
                          value={values?.Child || null}
                          onChange={(event, value) => {
                            const Services = [...(initialValues.Services || [])];
                            setFieldValue('Services', Services, true);
                            if(!value) {
                              setFieldValue('Child', null, true);
                            } else {
                              setFieldValue('Child', value, true);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          disabled={disabled}
                          format={DATE_FORMAT}
                          slots={{
                            textField: TextField,
                          }}
                          slotProps={{
                            textField: {
                              sx: styles.field,
                              name: "date",
                              onBlur: handleBlur,
                              error: Boolean(touched?.date && errors?.date),
                              helperText: (
                                <ErrorMessage
                                  name="date"
                                  render={(message) => t(message, {ns: 'validation'})}
                                />
                              ),
                              margin: "normal",
                              required: true,
                              fullWidth: true,
                              InputLabelProps: { shrink: true },
                              size: "medium",
                              autoComplete: 'off'
                            }
                          }}
                          value={values?.date}
                          label={t('field.date.invoice.label')}
                          onChange={(value: any) => {
                            setFieldValue('date', value, true);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      sx={styles.field}
                      disabled={disabled}
                      name="description"
                      value={values?.description || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched?.description && errors?.description)}
                      helperText={(
                        <ErrorMessage
                          name="description"
                          render={(message) => t(message, {ns: 'validation', min: 12})}
                        />
                      )}
                      margin="normal"
                      required={false}
                      fullWidth
                      label={t('field.description.label')}
                      placeholder={t('field.description.placeholder')}
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6" sx={{mt: 2, mb: 3}}>
                  {t('invoices.content.subtitle.services', {ns: 'pages'})}
                </Typography>
                <Services
                  disabled={disabled || !(typeof values?.Child?.id === 'number')}
                  child={values.Child}
                />
              </Box>
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  content: {
    p: {
      lg: 4,
      xs: 2,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
