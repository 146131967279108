import React from 'react';
import {Lesson, SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import {getDuration, getMedia, lessonResponseToState} from '../../../../utils';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import {LESSON_TYPES} from '../../../../constants';
import SvgIcon from '@mui/material/SvgIcon';
import theme from '../../../../config/theme';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Sorting from '../../../../components/Sorting';
import {ReactComponent as ChevronDownIcon} from '../../../../assets/icons/chevronDown.svg';
import noImageSrc from '../../../../assets/images/noImage.svg';
import useSortParams from '../../../../hooks/useSortParams';

const Actions = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string, type: Lesson['type']) => {
    navigate({
      pathname: `/dashboard/lessons/${path}`,
    }, {state: {
        type
      }});
  }

  return <>
    <Button
      aria-controls={open ? 'actions' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      sx={styles.button}
      size="medium"
      variant="contained"
      onClick={handleClick}
      endIcon={(
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 24 24"
          component={ChevronDownIcon}
          sx={{
            transform: `rotateX(${open ? 180 : 0}deg)`,
            transition: theme.transitions.create('transform')
          }}
        />
      )}
    >
      {t('add.value')}
    </Button>
    <Menu
      id="actions"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'actions-button',
      }}
    >
      {LESSON_TYPES.map((el) => {
        return (
          <MenuItem
            key={el.value}
            onClick={() => handleNavigate('create', el.value)}
          >
            {el.label}
          </MenuItem>
        )
      })}
    </Menu>
  </>
}

const List = () => {
  const {t, i18n} = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const currentLocale = i18n.language?.split('-')[0];

  const typeParam = searchParams.get('type') || 'main';
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.name.lesson.label'),
      flex: 1,
      minWidth: 420,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={styles.cellInner}>
            <Box sx={styles.cellImage}>
              <Box
                component="img"
                src={getMedia(row.media) || noImageSrc}
                alt={row.title}
                onError={(event: any) => {
                  //@ts-ignore
                  event.target.src = noImageSrc;
                }}
              />
            </Box>
            <Box sx={styles.cellContent}>
              <Typography variant="subtitle2" gutterBottom >
                {row.name}
              </Typography>
              <Typography
                variant="body3"
                color="text.gray"
                component="p"
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.description}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'duration',
      headerName: t('field.duration.label'),
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body3" color="text.gray">
            {getDuration(row.duration, currentLocale)}
          </Typography>
        );
      }
    },
    {
      field: 'quantity',
      headerName: t('field.quantity.label'),
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body3" color="text.gray">
            {row.quantity}
          </Typography>
        );
      }
    },
    {
      field: 'cost',
      headerName: t('field.cost.label'),
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body3" color="text.gray">
            {row.cost}
          </Typography>
        );
      }
    },
  ];

  const tabs = [
    {
      label: t('lessons.tabs.main', {ns: 'pages'}),
      value: 'main'
    },
    {
      label: t('lessons.tabs.group', {ns: 'pages'}),
      value: 'group'
    },
    {
      label: t('lessons.tabs.individual', {ns: 'pages'}),
      value: 'individual'
    },
  ];

  return (
    <Content
      title={t('lessons.list.title', {ns: 'pages'})}
    >
      <ResourceList
        name="lessons"
        pathModifier={(path) => {
          return `/kinder-garden/${user?.schoolId}${path}`
        }}
        title={t('lessons.list.subtitle', {ns: 'pages'})}
        columns={columns}
        actions={() => (<Actions/>)}
        transformer={(entities: any):any => {
          return entities?.map((lesson: any) => {
            return lessonResponseToState(lesson);
          });
        }}
        onRowClick={(params) => {
          const {row} = params;
          navigate(`/dashboard/lessons/edit/${row.id}`)
        }}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          ...(typeParam ? {type: typeParam} : {}),
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        tabs={{
          value: typeParam,
          onChange: (value) => {
            setSearchParams({type: value})
          },
          items: tabs
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  cellInner: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  cellContent: (theme) => ({
    maxWidth: `calc(100% - ${theme.spacing(9)})`
  }),
  cellImage: theme => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    width: theme.spacing(7),
    height: theme.spacing(7),
    flexShrink: 0,
    mr: 2,
    fontSize: '0.75rem',

    '& > img, & > svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    }
  }),
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
