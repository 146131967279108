import React, {SyntheticEvent, useCallback, useState} from 'react';
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import Box from '@mui/material/Box';
import {Nullable, SxPropsObject} from '../../types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as CrossIcon} from '../../assets/icons/cross.svg';
import {ReactComponent as FileIcon} from '../../assets/icons/file.svg';
import IconButton from '@mui/material/IconButton';
import {alpha} from '@mui/material';
import {blueGrey} from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {userSelector} from '../../redux/auth/selectors';
import {downloadFileLink} from '../../utils';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import noImageSrc from '../../assets/images/noImage.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import heic2any from 'heic2any';
import API from '../../api';

interface Props extends Partial<DropzoneOptions> {
  onUpload: (files: Array<string>) => void;
  onRemove: (file: string) => void;
  data: Nullable<Array<{
    value: string,
    uri: string,
  }>>
}

const Files = (props: Props) => {
  const {data, onUpload, onRemove, ...rest} = props;
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const user = useSelector(userSelector);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    setLoading(true);
    try {
      const files = await Promise.all(acceptedFiles?.map(async (file: any) => {
        if(file?.type?.includes('image/heic')) {
          const converted = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          }) as Blob;
          let name = file?.name?.replace('.heic', '.jpeg').replace('.HEIC', '.jpeg')
          return new File([converted], name, { lastModified: new Date().getTime(), type: converted.type });
        }
        return file;
      }));

      const response = await API.files.upload(`/kinder-garden/${user?.schoolId}`, files);
      onUpload(response?.data || null);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [onUpload, user?.schoolId]);

  const {getRootProps, getInputProps, open} = useDropzone({
    ...rest,
    disabled: loading,
    onDrop
  });

  const onDownload = async (file: Nullable<{
    value: string,
    uri: string,
  }>) => {
    if(file) {
      await downloadFileLink(file.uri, file.value);
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.dropzone} {...getRootProps()}>
        <Box component="input" {...getInputProps()}/>
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 24 24"
          component={FileIcon}
          sx={{
            opacity: 0.6,
            mr: 2
          }}
        />
        <Typography
          variant="body3"
          color="inherit"
        >
          {t('select.files.text')}
        </Typography>
      </Box>
      <LoadingButton
        sx={{mt: 2}}
        loading={loading}
        size="medium"
        variant="contained"
        onClick={open}
      >
        {t('select.file')}
      </LoadingButton>
      {Boolean(data && data.length > 0) && (
        <Box sx={styles.thumbs}>
          <Grid container spacing={1}>
            {data?.map((file, index) => {
              return (
                <Grid key={index} item xs={2.4}>
                  <Thumbnail
                    file={file}
                    onRemove={() => onRemove(file.value || '')}
                    onDownload={() => onDownload(file || null)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
    </Box>
  )
};

const styles:SxPropsObject = {
  container: {

  },
  dropzone: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#D5D6DF',
    backgroundColor: alpha(blueGrey[200], 0.2),
    color: '#9298A8',
  },
  thumbs: {
    mt: 2,
  }
}

interface ThumbnailProps {
  file: {
    value: string,
    uri: string,
  };
  onRemove: () => void,
  onDownload: () => void,
}

const Thumbnail = (props: ThumbnailProps) => {
  const {file, onRemove, onDownload} = props;

  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);
  }

  function onError(event: SyntheticEvent<HTMLImageElement, Event>) {
    setLoaded(true);
    //@ts-ignore
    event.target.src = noImageSrc;
  }

  return (
    <Box sx={stylesThumbnail.container}>
      <Box sx={stylesThumbnail.element}>
        <Box
          sx={{
            ...stylesThumbnail.img,
            display: loaded ? 'block' : 'none',
          }}
          component="img"
          src={file.uri || noImageSrc}
          alt={file.value || 'Image'}
          onLoad={onLoad}
          onError={onError}
        />
        {!loaded && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              position: 'absolute',
            }}
          />
        )}
        {Boolean(loaded && file.uri) && (
          <Box
            className="overlay"
            onClick={onDownload}
            width="100%"
            height="100%"
            sx={(theme) => ({
              cursor: 'pointer',
              position: 'absolute',
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
              pointerEvents: 'none',
              transition: theme.transitions.create('opacity'),
              background: alpha(theme.palette.common.black, 0.66),
              color: theme.palette.common.white
            })}
          >
            <SimCardDownloadIcon color="inherit" fontSize="large"/>
          </Box>
        )}
      </Box>
      <Typography
        sx={stylesThumbnail.panel}
        component="div"
        variant="body3"
      >
        <Box component="span">
          {file.value}
        </Box>
        <IconButton
          size="small"
          color="inherit"
          aria-label="Delete"
          edge="end"
          onClick={onRemove}
        >
          <SvgIcon
            fontSize="inherit"
            viewBox="0 0 24 24"
            component={CrossIcon}
          />
        </IconButton>
      </Typography>
    </Box>
  )
}

const stylesThumbnail:SxPropsObject = {
  container: {
  },
  element: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    height: 0,
    borderRadius: 1,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: theme => theme.palette.divider,

    '&:hover': {
      '.overlay': {
        pointerEvents: 'initial',
        opacity: 1,
      }
    }
  },
  img: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: 1,
  },
  panel: {
    mt: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
    }
  },
}

export default Files;
