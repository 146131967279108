import React from 'react';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import {groupsResponseToState, renderFullName} from '../../../../utils';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.groups.label'),
      flex: 1,
      minWidth: 320,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2">
            {row.name}
          </Typography>
        );
      }
    },
    {
      field: 'teacher',
      headerName: t('field.teacher.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Link
            sx={{display: 'inline-flex'}}
            to={`/dashboard/teachers/edit/${row?.Teacher?.id}`}
            color="text.primary"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            gutterBottom
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {renderFullName(row?.Teacher)}
          </Link>
        );
      }
    },
    {
      field: 'childrenCount',
      headerName: t('field.children.count.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="body2">
            {Number(row.childrenCount)}
          </Typography>
        );
      }
    },
  ];

  return (
    <Content title={t('groups.list.title', {ns: 'pages'})}>
      <ResourceList
        name="groups"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('groups.list.subtitle', {ns: 'pages'})}
        columns={columns}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={RouterLink}
            to="/dashboard/groups/create"
          >
            {t('add.value')}
          </Button>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        transformer={(entities: any):any => {
          return entities.map((group: any) => {
            return groupsResponseToState(group);
          });
        }}
        onRowClick={(params) => {
          navigate(`/dashboard/groups/edit/${params.id}`)
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
