import React from 'react';
import {Translation} from 'react-i18next';
import {ReactComponent as FilesIcon} from '../../assets/icons/files.svg';
import {ReactComponent as BookIcon} from '../../assets/icons/book.svg';
import {ReactComponent as FaceIcon} from '../../assets/icons/face.svg';
import {ReactComponent as ActorsIcon} from '../../assets/icons/actors.svg';
import {ReactComponent as CoinIcon} from '../../assets/icons/coin.svg';
import {ReactComponent as PersonIcon} from '../../assets/icons/person.svg';
import {ReactComponent as PersonPinIcon} from '../../assets/icons/personPin.svg';
import {ReactComponent as HomeIcon} from '../../assets/icons/home.svg';
import {ReactComponent as ParentsIcon} from '../../assets/icons/parents.svg';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning.svg';
import {ReactComponent as SupportIcon} from '../../assets/icons/support.svg';
import {ReactComponent as DocsListIcon} from '../../assets/icons/docsList.svg';
import {ReactComponent as QuestionIcon} from '../../assets/icons/question.svg';
import {ReactComponent as UniHatIcon} from '../../assets/icons/uniHat.svg';
import {ReactComponent as CalendarIcon} from '../../assets/icons/calendar.svg';
import {ROUTES_PERMISSIONS} from '../../constants';

export type MenuItem = {
  name: React.ReactNode,
  icon: {
    component: React.ElementType<any>,
    viewBox: string
  },
  path: string,
  end?: boolean,
  permissions?: string | string[]
}

export const MENU_ITEMS = [
  {
    name: 'group.0',
    items: [
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.tasks')}</Translation>,
        permissions: ROUTES_PERMISSIONS.tasks,
        icon: {
          component: DocsListIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/tasks'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.lessons')}</Translation>,
        icon: {
          component: BookIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/lessons'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.schedule')}</Translation>,
        icon: {
          component: CalendarIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/schedules'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.posts')}</Translation>,
        icon: {
          component: FilesIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/posts',
        end: false
      },
    ],
  },
  {
    name: 'group.1',
    items: [
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.groups')}</Translation>,
        icon: {
          component: ActorsIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/groups'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.children')}</Translation>,
        icon: {
          component: FaceIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/children'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.parents')}</Translation>,
        icon: {
          component: ParentsIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/parents'
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.teachers')}</Translation>,
        icon: {
          component: PersonIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/teachers'
      },
    ],
  },
  {
    name: 'group.2',
    items: [
      {
        name: <Translation>{(t) => t('navigation.main.group.0.items.payments')}</Translation>,
        icon: {
          component: CoinIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/payments',
        permissions: ROUTES_PERMISSIONS.payments,
      },
    ],
  },
  {
    name: 'group.3',
    items: [
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.owners')}</Translation>,
        icon: {
          component: HomeIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/owners',
        permissions:  ROUTES_PERMISSIONS.owners,
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.admins')}</Translation>,
        permissions: ROUTES_PERMISSIONS.admins,
        icon: {
          component: PersonPinIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/admins',
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.complaints')}</Translation>,
        permissions: ROUTES_PERMISSIONS.complaints,
        icon: {
          component: WarningIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/complaints',
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.questions')}</Translation>,
        icon: {
          component: QuestionIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/questions',
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.support')}</Translation>,
        permissions: ROUTES_PERMISSIONS.support,
        icon: {
          component: SupportIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/support',
      },
      {
        name: <Translation>{(t) => t('navigation.main.group.1.items.schools')}</Translation>,
        permissions: ROUTES_PERMISSIONS.schools,
        icon: {
          component: UniHatIcon,
          viewBox: '0 0 24 24',
        },
        path: '/dashboard/schools',
      },
    ],
  },
] as Array<{
  name: string,
  items: Array<MenuItem>
}>
