import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'text.min')
    .max(255, 'text.max')
    .required('required')
    .nullable(true),
  description: Yup.string().required('required').nullable(true),
  comment: Yup.string().nullable(true),
});

export default validationSchema;
