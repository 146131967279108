import React from 'react';
import Container from '@mui/material/Container';
import Form from './components/Form';

const SignIn = () => {
  return (
    <Container>
      <Form/>
    </Container>
  );
};

export default SignIn;
