import {AppStore} from '../store';
import {ResourceType} from '../../types';
import {ResourceState} from './slice';

const resourceSelector = (
  name: ResourceType,
  state: AppStore
): ResourceState => {
  return state.resources[name];
};

export const authSelector = (state: AppStore) => {
  return state.auth;
}

export const paginationSelector = (name: ResourceType) => (state: AppStore) => {
  const resource = resourceSelector(name, state);
  return resource.pagination;
};

export const paramsSelector = (name: ResourceType) => (state: AppStore) => {
  const resource = resourceSelector(name, state);
  return resource.params;
};

export const entitySelector = (name: ResourceType) => (state: AppStore) => {
  const resource = resourceSelector(name, state);

  return {
    entity: resource.entity,
    entitySubmitting: resource.entitySubmitting,
    entityErrors: resource.entityErrors,
    entityLoading: resource.entityLoading,
  }
};
