import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {SxPropsObject} from '../../../../types';
import {Trans, useTranslation} from 'react-i18next';
import {getAssets, getSxStyle} from '../../../../utils';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {Link as RouterLink} from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import {scroller} from 'react-scroll';
import {APPS} from '../../../../constants';
import {ReactComponent as AppleIcon} from '../../../../assets/icons/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/icons/android.svg';
import {ReactComponent as ComputerIcon} from '../../../../assets/icons/computer.svg';

const Hero = () => {
  const {t, i18n} = useTranslation('pages');
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const currentLocale = i18n.language?.split('-')[0];

  const assets = getAssets(currentLocale);

  return (
    <Box sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 20}}>
        <Box>
          <Grid container spacing={2} flexDirection={isDownSm ? 'column' : 'row-reverse'}>
            <Grid
              item xs={12}
              sm={6}
              md={6}
            >
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Box
                  component="img"
                  src={assets.heroScreenImageSrc}
                  alt="App preview"
                  sx={styles.desktopImage}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <Box
                sx={(theme) => {
                  const style = getSxStyle(styles.content, theme);
                  return {
                    ...style,
                    // pr: 8,
                    [theme.breakpoints.down('lg')]: {
                      pr: 0,
                      maxWidth: '100%',
                    }
                  }
                }}
              >
                <Typography  variant="h2" component="h1" fontWeight={700} mb={3}>
                  <Trans t={t} i18nKey="pages:home.hero.heading">
                    Спробуйте <Typography
                    variant="h2"
                    fontWeight="inherit"
                    component="span"
                    sx={{
                      background: '-webkit-linear-gradient(0deg, rgba(138,209,221,1) 0%, rgba(115,89,197,1) 70%, rgba(150,160,215,1) 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    новий рівень
                  </Typography> керування садочком разом із StudyCare
                  </Trans>
                </Typography>
                <Typography  variant="body2" component="p" mb={3}>
                  <Trans t={t} i18nKey="pages:home.hero.text" />
                </Typography>
                <Grid container spacing={1}>
                  <Grid container item xs={12} sm={12} md={6} spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <IconButton
                        component={RouterLink}
                        to={'/dashboard'}
                        replace
                        aria-label={t('apps.apple')}
                        sx={{
                          color: '#BBBBC0'
                        }}
                      >
                        <SvgIcon
                          fontSize={isDownSm ? 'small' : 'medium'}
                          viewBox="0 0 24 24"
                          component={ComputerIcon}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography variant="body2" color="text.primary">
                        {t('home.hero.apps.dashboard')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={6} spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <Grid container spacing={0.25} justifyContent="center">
                        <Grid item>
                          <IconButton
                            component="a"
                            href={APPS.apple.link}
                            aria-label={t('apps.apple')}
                            target="_blank"
                            rel="noreferrer noopener"
                            sx={{
                              color: '#BBBBC0'
                            }}
                          >
                            <SvgIcon
                              fontSize={isDownSm ? 'small' : 'medium'}
                              viewBox="0 0 18 20"
                              component={AppleIcon}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            component="a"
                            href={APPS.android.link}
                            aria-label={t('apps.android')}
                            target="_blank"
                            rel="noreferrer noopener"
                            sx={{
                              color: '#BBBBC0'
                            }}
                          >
                            <SvgIcon
                              fontSize={isDownSm ? 'small' : 'medium'}
                              viewBox="0 0 18 20"
                              component={AndroidIcon}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography variant="body2" color="text.primary">
                        {t('home.hero.apps.mobile')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  size="large"
                  variant="contained"
                  color="dark"
                  onClick={() => {
                    scroller.scrollTo('contact', {
                      duration: 800,
                      delay: 0,
                      smooth: "easeInOutQuart",
                    });
                  }}
                  sx={{
                    mt: 4,
                    borderRadius: 2
                  }}
                >
                  {t('pages:home.hero.button.findOut')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: () => ({
    py: 10,
    minHeight: {
      sm: '100vh',
      xs: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    backgroundImage: 'unset',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    backgroundColor: '#FAFAFA',
  }),
  content: {
    position: 'relative',
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  desktopImage: (theme) => ({
    position: 'relative',
    width: '49.375vw',
    maxWidth: 790,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    }
  }),
}

export default Hero;

