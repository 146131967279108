import newChild from './newChild';
import newTask from './newTask';
import newLesson from './newLesson';
import newPaidPayment from './newPaidPayment';
import newExpectedPayment from './newExpectedPayment';
import newNews from './newNews';
import createApp from './createApp';

export const pages = [
  newChild,
  newTask,
  newLesson,
  newPaidPayment,
  newExpectedPayment,
  newNews,
  createApp,
]
