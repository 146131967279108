import Typography from '@mui/material/Typography';
import React from 'react';
import {Translation} from 'react-i18next';
import Box from '@mui/material/Box';

const data = {
  id: 'newPaidPayment',
  title: (
    <Translation>{(t) => t('questions.list.item.newPaidPayment.title', {ns: 'pages'})}</Translation>
  ),
  content: (locale: string): JSX.Element => (
    <>
      <Typography variant="h5" component="h4" mb={4}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.title', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.subtitle.0', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment1.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.subtitle.1', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="ul">
        <Box component="li">
          <Typography variant="subtitle2" mb={2}>
            <Translation>{(t) => t(`questions.list.item.newPaidPayment.content.list.0.item.0`, {ns: 'pages'})}</Translation>
          </Typography>
          <Typography variant="body2" component="p">
            <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.2', {ns: 'pages'})}</Translation>
          </Typography>
          <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment2.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
          <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment3.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
        </Box>
        <Box component="li">
          <Typography variant="subtitle2" mb={2}>
            <Translation>{(t) => t(`questions.list.item.newPaidPayment.content.list.0.item.1`, {ns: 'pages'})}</Translation>
          </Typography>
          <Typography variant="body2" component="p">
            <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.3', {ns: 'pages'})}</Translation>
          </Typography>
          <Typography variant="body2" component="p">
            <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.4', {ns: 'pages'})}</Translation>
          </Typography>
          <Typography variant="body2" component="p">
            <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.5', {ns: 'pages'})}</Translation>
          </Typography>
          <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment4.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
          <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment5.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
        </Box>
      </Box>
      <Typography variant="h6" component="h6" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.subtitle.2', {ns: 'pages'})}</Translation>
      </Typography>
      <Typography variant="body2" component="p" mb={2}>
        <Translation>{(t) => t('questions.list.item.newPaidPayment.content.text.6', {ns: 'pages'})}</Translation>
      </Typography>
      <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment6.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
      <Box component="img" src={`/assets/images/${locale}/questions/newPaidPayment7.png`} alt="" mb={4} sx={{display: 'block', maxWidth: '100%'}}/>
    </>
  )
}
export default data;
