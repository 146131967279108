import React from 'react';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Link as RouterLink, useNavigate, useSearchParams} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Link from '@mui/material/Link';
import {useDispatch, useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import format from 'date-fns/format';
import {DATE_FORMAT} from '../../../../constants';
import Filters from '../../../../components/Filters';
import Sorting from '../../../../components/Sorting';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {ReactComponent as DownloadDocumentIcon} from '../../../../assets/icons/downloadDocument.svg';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import NumericValue from '../../../../components/NumericValue/NumericValue';
import {downloadFileLink, expenseResponseToState, getMediaUri, renderFullName} from '../../../../utils';
import useSortParams from '../../../../hooks/useSortParams';
import appSlice from '../../../../redux/app/slice';

const List = () => {
  const {t} = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const {setPaymentsDate} = appSlice.actions;

  const periodParam = searchParams.get('period') || format(Date.now(), 'yyyy-MM');
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams({
    defaultSort: 'date',
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.name.expense.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Link
            sx={{display: 'inline-flex'}}
            to={`/dashboard/payments/expenses/edit/${row?.id}`}
            color="text.primary"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {row?.name}
          </Link>
        );
      }
    },
    {
      field: 'date',
      headerName: t('field.date.payment.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const {date} = row || {};

        if(!date) {
          return null;
        }

        return (
          <Typography variant="subtitle2">
            {format(date, DATE_FORMAT)}
          </Typography>
        );
      }
    },
    {
      field: 'amount',
      headerName: t('field.cost.label'),
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Typography variant="body3" color="text.gray" component="p">
            <NumericValue
              formatType="price"
              value={row?.amount}
              displayType="text"
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
            />
          </Typography>
        );
      }
    },
    {
      field: 'user',
      headerName: t('field.person.label'),
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Typography
            variant="body3"
            color="text.gray"
            component="p"
            sx={{
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {renderFullName(row.User, false, true)}
          </Typography>
        );
      }
    },
    {
      field: 'comment',
      headerName: t('field.comment.label'),
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        return (
          <Typography
            variant="body3"
            color="text.gray"
            component="p"
            sx={{
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {row?.comment}
          </Typography>
        );
      }
    },
    {
      field: 'media',
      headerName: '',
      minWidth: 70,
      maxWidth: 70,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;

        const hasMedia = row.media && row?.media?.length > 0;
        const handleDownload = async (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          try {
            const promises = row?.media?.map(async (el: string) => {
              return await downloadFileLink(getMediaUri(el), el);
            });
            await Promise.all(promises);
          } catch (err) {
            console.warn('Can\'t download files', err);
          }
        }

        return hasMedia ? (
          <IconButton
            disabled={!hasMedia}
            onClick={handleDownload}
            aria-label={t('apps.apple')}
            sx={{
              color: '#BBBBC0'
            }}
          >
            <SvgIcon
              fontSize={'medium'}
              viewBox="0 0 24 24"
              component={DownloadDocumentIcon}
            />
          </IconButton>
        ) : null;
      }
    },
  ];

  const defaultParams = {
    ...(periodParam ? {date: periodParam} : {}),
    ...(sortParam ? {sort: sortParam} : {}),
    ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
  }

  return (
    <ResourceList
      name="expenses"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
      title={t('expenses.list.subtitle', {ns: 'pages'})}
      columns={columns}
      actions={() => (
        <Button
          sx={styles.button}
          size="medium"
          variant="contained"
          component={RouterLink}
          to="/dashboard/payments/expenses/create"
        >
          {t('add.value')}
        </Button>
      )}
      filters={(
        <Filters
          sx={{
            mr: 0,
            ml: 'auto',
          }}
          items={[
            <DatePicker
              openTo="month"
              views={['year', 'month']}
              label={t('field.month.label')}
              value={new Date(periodParam)}
              onChange={(value) => {
                if(value) {
                  searchParams.set('period', format(value, 'yyyy-MM'));
                } else {
                  searchParams.set('period', '');
                }
                setSearchParams(searchParams);
                dispatch(setPaymentsDate(value));
              }}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  onKeyDown: (event) => { event.preventDefault()},
                  margin: "normal",
                  required: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true
                  },
                  InputProps: {
                    readOnly: true,
                    sx: {
                      textTransform: 'capitalize',
                      '& .MuiInputBase-input': {
                        textTransform: 'inherit'
                      }
                    }
                  },
                  size: "medium",
                  autoComplete: 'off'
                }
              }}
            />
          ]}
        />
      )}
      sorting={(
        <Sorting
          values={[
              {
                label: t('field.date.payment.label'),
                value: 'date',
              },
              {
                label: t('field.date.created.label'),
                value: 'createdAt',
              },
            ]}
          sort={sortParam}
          order={sortOrderParam}
          onChangeSort={onChangeSort}
          onChangeOrder={onChangeSortOrder}
          sx={{
            mr: 0,
            ml: 3,
          }}
        />
      )}
      defaultParams={defaultParams}
      transformer={(entities: any) => {
        return entities.map((el: any) => {
          return expenseResponseToState(el);
        });
      }}
      onRowClick={(params) => {
        const {row} = params;
        navigate(`/dashboard/payments/expenses/edit/${row.id}`)
      }}
      rowActions={{
        mode: 'extend',
        extended: [
          {
            name: 'edit',
            action: (id) => {
              navigate(`/dashboard/payments/expenses/edit/${id}`)
            },
          },
        ]
      }}
    />
  );
};

const styles: SxPropsObject = {
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'divider',

    '& .MuiTab-root': {
      p: 3,
    },

    [theme.breakpoints.down('sm')]: {
      px: 3
    }
  }),
  tab: () => ({
    textTransform: 'capitalize',
  }),
  button: () => ({
    maxWidth: {
      sm: 188,
      xs: 'unset',
    },
    ml: {
      sm: 4,
      xs: 0,
    },
    mt: {
      sm: 0,
      xs: 1,
    },
  }),
}

export default List;
