//@ts-ignore
export const entityFetchRequest = (state) => ({
  ...state,
  entityLoading: true,
  entityLoaded: false,
});

//@ts-ignore
export const entitySubmitRequest = (state) => ({
  ...state,
  entitySubmitting: true,
  entityLoaded: false,
});

//@ts-ignore
export const entityFail = (state, action) => ({
  ...state,
  entityLoading: false,
  entitySubmitting: false,
  entityLoaded: false,
  entityErrors: action.payload,
});

//@ts-ignore
export const entitySuccess = (state, action) => ({
  ...state,
  entityLoading: false,
  entitySubmitting: false,
  entityLoaded: true,
  entityErrors: null,
  entity: action.payload,
});

//@ts-ignore
export const entitiesRequest = (state) => ({
  ...state,
  entitiesLoading: true,
  entitiesLoaded: false,
});

//@ts-ignore

export const entitiesSuccess = (state, action) => {
  return ({
    ...state,
    entitiesLoading: false,
    entitiesLoaded: true,
    entities: action.payload.items,
    pagination: {
      ...state.pagination,
      total: action.payload.total,
      page: action.payload.page,
    }
  })
};

//@ts-ignore
export const entitiesFail = (state, action) => ({
  ...state,
  entitiesLoading: false,
  entitiesLoaded: false,
  entitiesError: action.payload,
});

//@ts-ignore
export const changeEntitiesPage = (state, action) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: action.payload,
  }
});

//@ts-ignore
export const changeParams = (state, action) => ({
  ...state,
  params: {
    ...action.payload
  }
});

//@ts-ignore
export const changeEntitiesPageSize = (state, action) => ({
  ...state,
  pagination: {
    ...state.pagination,
    pageSize: action.payload,
  }
});
