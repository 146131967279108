import React from 'react';
import {SxPropsObject} from '../../../../types';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import {Link as RouterLink} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import {tasksToCalendarEvents} from '../../../../utils';
import Calendar from '../components/Calendar';
import format from 'date-fns/format';
import {SERVER_DATE_FORMAT} from '../../../../constants';
import {endOfMonth, startOfMonth} from 'date-fns';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {

  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  return (
    <Content title={t('tasks.list.title', {ns: 'pages'})}>
      <ResourceList
        name="tasks"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('tasks.list.subtitle', {ns: 'pages'})}
        columns={[]}
        transformer={(entities: any) => {
          return tasksToCalendarEvents(entities);
        }}
        HeadProps={{
          sx: {pb: 0}
        }}
        actions={() => (
          <>
            <Button
              sx={styles.button}
              size="medium"
              variant="contained"
              component={RouterLink}
              to="/dashboard/tasks/create"
            >
              {t('add.value')}
            </Button>
          </>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {}),
          from: format(startOfMonth(new Date()), SERVER_DATE_FORMAT),
          to: format(endOfMonth(new Date()), SERVER_DATE_FORMAT),
          page: undefined,
          perPage: undefined,
        }}
      >
        {({entities, loading}) => {
          return (
            <Calendar
              entities={entities}
              loading={loading}
            />
          )
        }}
      </ResourceList>
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
