import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import {lessonStateToRequest} from '../../../../utils';
import {FormValues} from '../types';
import {useLocation} from 'react-router-dom';
import {Lesson} from '../../../../types';

const Create = () => {
  const user = useSelector(userSelector);
  let location = useLocation();

  const type = (location?.state?.type || 'main') as Lesson['type'];

  return (
    <ResourceCreate
      name="lessons"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = lessonStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues: FormValues = {
          name: null,
          description: null,
          media: null,
          type,
          cover: null,
          duration: 30,
          cost: null,
          places:  null,
          costSingle: null,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
