import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import appSlice from './app/slice';
import authSlice from './auth/slice';
import noticesSlice from './notices/slice';
import resourceSlices from './resource/slice';
import {saga} from './saga';

const sagaMiddleware = createSagaMiddleware();

const resources = combineReducers({
  groups: resourceSlices.groups.reducer,
  posts: resourceSlices.posts.reducer,
  teachers: resourceSlices.teachers.reducer,
  children: resourceSlices.children.reducer,
  parents: resourceSlices.parents.reducer,
  lessons: resourceSlices.lessons.reducer,
  schedules: resourceSlices.schedules.reducer,
  tasks: resourceSlices.tasks.reducer,
  admins: resourceSlices.admins.reducer,
  schools: resourceSlices.schools.reducer,
  owners: resourceSlices.owners.reducer,
  invoices: resourceSlices.invoices.reducer,
  expenses: resourceSlices.expenses.reducer,
  complaints: resourceSlices.complaints.reducer,
  support: resourceSlices.support.reducer,
});

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: authSlice.reducer,
    notices: noticesSlice.reducer,
    resources: resources,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
  devTools: true,
});

sagaMiddleware.run(saga);

export type AppStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;

