import React from 'react';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardMedia, CardProps} from '@mui/material';
import {SxPropsObject} from '../../../../../../types';
import {useTranslation} from 'react-i18next';

interface Props extends CardProps {
  entity: {
    image: string;
    heading: React.ReactNode;
    text: React.ReactNode;
  }
}

const CardComponent:React.FC<Props> = (props): JSX.Element => {
  const {entity, ...rest} = props;
  const {i18n} = useTranslation();
  const currentLocale = i18n.language?.split('-')[0];

  return (
    <Card
      sx={styles.container}
      {...rest}
    >
      <CardContent sx={styles.content}>
        <Typography
          variant="h6"
          component="h6"
          color="text.primary"
          sx={styles.heading}
        >
          {entity.heading}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={styles.text}
        >
          {entity.text}
        </Typography>
      </CardContent>
      <CardMedia
        sx={styles.media}
        component="img"
        image={`/assets/images/${currentLocale}/${entity.image}`}
        alt=""
      />
    </Card>
  )
}

const styles:SxPropsObject = {
  container: (theme) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    boxShadow: 'none',
    border: 'none',
    position: 'relative',
    background: 'transparent',

    [theme.breakpoints.down('md')]: {

    },
  }),
  media: (theme) => ({
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
  }),
  content: (theme) => ({
    position: 'relative',
    zIndex: 2,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    p: theme.spacing(0),

    '&:last-child': {
      pb: theme.spacing(0),
    }
  }),
  heading: {
    mb: 2,
  },
  text: {
    mb: 5,
  }
}

export default  CardComponent;
