import React, {useCallback, useEffect, useLayoutEffect, useReducer, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Spinner from '../../../../../components/Spinner';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin, {DateClickArg} from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import localeRu from '@fullcalendar/core/locales/ru';
import localeUa from '@fullcalendar/core/locales/uk';
import localeEn from '@fullcalendar/core/locales/en-gb';
import {Link as RouterLink, useNavigate, useSearchParams} from 'react-router-dom';
import format from 'date-fns/format';
import {FullCalendarContainer} from '../../../../../styled';
import {DATE_TIME_FORMAT, SERVER_DATE_FORMAT} from '../../../../../constants';
import {Translation, useTranslation} from 'react-i18next';
import {PopoverPosition} from '@mui/material/Popover/Popover';
import useNoDataOverlay from '../../../../../hooks/useNoDataOverlay';
import {CalendarViewType, DayValue, Lesson, Nullable, SxPropsObject} from '../../../../../types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material';
import {formatISO, parseISO} from 'date-fns';
import {getDayObject, getDuration, lessonResponseToState} from '../../../../../utils';
import theme from '../../../../../config/theme';
import {DatesSetArg, EventClickArg} from '@fullcalendar/core';
import CalendarControls from '../../../../../components/calendar/Controls';
import CalendarPopover from '../../../../../components/calendar/Popover';
import {animateScroll} from 'react-scroll';
import {useDispatch, useSelector} from 'react-redux';
import slices from '../../../../../redux/notices/slice';
import API from '../../../../../api';
import {authSelector} from '../../../../../redux/auth/selectors';
import useSchedulePopoverData from '../../../../../hooks/useSchedulePopoverData';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {LessonResponsePayload} from '../../../../../api/types';

interface Props {
  entities: Array<any>;
  loading: boolean;
  head: React.ReactNode;
}
interface initialValues {
  Lesson: Nullable<Partial<Lesson>>;
  date: string;
  day: DayValue;
}

const initialEvent: initialValues = {
  date: '',
  day: {
    label: '',
    value: 'mon'
  },
  Lesson: null,
};

const eventReducer = (state: any, action: { type: any; payload?: any }) => {
  switch (action.type) {
    case 'ON_CHANGE_FIELD':
      return {
        ...state,
        [action.payload.name]: action?.payload?.value,
      };
    case 'ON_CHANGE_DATE':
      return {
        ...state,
        date: action?.payload?.date,
        day: action?.payload?.day,
      };
    case 'ON_RESET':
      return {
        ...initialEvent
      };
    default:
      return state;
  }
};

const Calendar = (props: Props) => {
  const {entities, loading, head} = props;
  const ref = useRef<FullCalendar>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector(authSelector);

  const {
    noticeOpen,
  } =  slices.actions;

  const {t, i18n} = useTranslation();
  let currentLocale = i18n.language?.split('-')[0];
  let calendarLocale = currentLocale;
  if (calendarLocale === 'ua') {
    calendarLocale = 'uk';
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const [viewType, setViewType] = useState<CalendarViewType>('dayGridMonth');
  const [autoHeight, setAutoHeight] = useState<boolean>(false);
  const [viewTitle, setViewTitle] = useState<string>('');

  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined);
  const [eventId, setEventId] = React.useState<string | null>(null);

  const [openCreateDialog, setCreateOpenDialog] = React.useState(false);
  const [event, dispatchEvent] = useReducer(eventReducer, initialEvent);

  const [period, setPeriod] = useState(searchParams.get('period'));

  useEffect(() => {
    searchParams.set('period', period || '');
    setSearchParams(searchParams);
  }, [period, searchParams, setSearchParams]);

  const entity = entities.find((el) => el.id === eventId);

  const {scheduleId} = entity?.extendedProps || {};

  const {loading: popoverLoading, items: popoverItems} = useSchedulePopoverData({
    id: scheduleId,
    fetching: Boolean(anchorPosition),
    event: entity,
  })

  const handleChangeEventField = (name: any, value: any) => {
    dispatchEvent({
      type: 'ON_CHANGE_FIELD',
      payload: {
        name,
        value,
      },
    });
  };

  useLayoutEffect(() => {
    if((viewType === 'timeGridDay')) {
      const indicator = document.querySelector('.fc-timegrid-now-indicator-line');
      const {top} = indicator?.getBoundingClientRect() || {};
      animateScroll.scrollTo(Number(top));
    }
  }, [viewType]);

  const NoData = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  const onChangeView = (value: CalendarViewType): void => {
    ref?.current?.getApi().changeView(value);
    setViewType(value);
  };

  const onSetViewTitle = (title: string): void => {
    setViewTitle(title);
  };

  const handleEventClick = (info: EventClickArg): void => {
    info.jsEvent.preventDefault();
    info.jsEvent.stopPropagation();
    setEventId(info?.event?.id || null);
    setAnchorPosition({
      left: info.jsEvent.clientX,
      top: info.jsEvent.clientY,
    });
  };

  const handleDateClick = useCallback((info: DateClickArg) => {

    let date = info.date;
    date.getHours();

    if (date.getHours() === 0) {
      date.setHours(8, 0, 0, 0);
    }

    const day = getDayObject(date, currentLocale);

    dispatchEvent({
      type: 'ON_CHANGE_DATE',
      payload: {
        date: formatISO(date),
        day,
      },
    });
    setCreateOpenDialog(true);
  }, [currentLocale]);

  const handleCloseDialog = () => {
    setCreateOpenDialog(false);
    dispatchEvent({
      type: 'ON_RESET',
    });
  };

  const handleRedirectToCreate = useCallback(() => {
    navigate('/dashboard/schedules/create', {
      replace: true,
      state: {
        date: event.date,
        day: event.day,
        Lesson: event.Lesson,
      },
    })
  }, [event.date, event.day, event.Lesson, navigate]);

  const handleDeleteSchedule = useCallback(async (id: number, schoolId: number ) => {
    try {
      await API.schedules.delete(`/kinder-garden/${schoolId}`, id);
      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.delete.entity.success')}
          </Translation>
        ),
        options: {
          key: `invoice-${id}-delete-success`,
          variant: 'success',
        },
      }))
    } catch (err) {
      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.delete.entity.failed')}
          </Translation>
        ),
        options: {
          key: `invoice-${id}-delete-error`,
          variant: 'error',
        },
      }))
    }

  }, [dispatch, noticeOpen]);

  const onSetPeriod = useCallback((start: string, end: string) => {
    const newPeriod = JSON.stringify([
      format(new Date(start), SERVER_DATE_FORMAT),
      format(new Date(end), SERVER_DATE_FORMAT)
    ]);

    if(!period || (period !== newPeriod)){
      setPeriod(newPeriod);
    }

  },[period]);

  return (
    <FullCalendarContainer>
      <Typography variant="subtitle2" component="p" mb={1}>
        {t('lessons.calendar.colors.title', {ns: 'pages'})}{':'}
      </Typography>
      {head && (
        <>
          {head}
          <Divider sx={{mx: -3, my: 3}}/>
        </>
      )}
      <CalendarControls
        calendar={ref?.current}
        viewTitle={viewTitle}
        viewType={viewType}
        onChangeView={onChangeView}
      />
      <Box sx={{position: 'relative', overflowX: 'auto'}}>
        {loading && (
          <Spinner sx={{
            position: 'absolute',
            height: '100%',
          }}/>
        )}
        <Box sx={{minWidth: {xs: theme.breakpoints.values.md, md: 'auto'}}}>
          <FullCalendar
            ref={ref}
            height={autoHeight ? 'auto' : undefined}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            windowResizeDelay={0}
            navLinks
            eventMaxStack={6}
            dayMaxEventRows={6}
            dayMaxEvents={4}
            headerToolbar={{
              left: '',
              center: '',
              right: ''
            }}
            initialView='dayGridMonth'
            editable={false}
            locales={[localeRu, localeUa, localeEn]}
            locale={calendarLocale}
            selectMirror={true}
            weekends={true}
            nowIndicator={true}
            eventOrderStrict
            events={entities}
            eventClick={handleEventClick}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              hour12: false
            }}
            navLinkDayClick={(date) => {
              ref?.current?.getApi()?.gotoDate(date);
              onChangeView('timeGridDay');
            }}
            datesSet={(info: DatesSetArg) => {
              //@ts-ignore
              const title = info.view?.getCurrentData().viewTitle;
              onSetPeriod(info.startStr, info.endStr);
              onSetViewTitle(title)
              setAutoHeight(info.view.type !== 'dayGridMonth');
            }}
            dateClick={handleDateClick}
            noEventsContent={() => (
              <NoData/>
            )}
          />
        </Box>
      </Box>
      <CalendarPopover
        loading={popoverLoading}
        anchorPosition={anchorPosition}
        setAnchorPosition={setAnchorPosition}
        items={popoverItems}
        footer={(
          <>
            <Button
              fullWidth
              component={RouterLink}
              to={`/dashboard/schedules/edit/${scheduleId}`}
              variant="outlined"
              sx={{
                mt: 4
              }}
            >
              {t('edit.value')}
            </Button>

            <Button
              fullWidth
              color="error"
              variant="outlined"
              onClick={async () => {
                if (scheduleId != null && user?.schoolId) {
                  await handleDeleteSchedule(scheduleId, user?.schoolId);
                }
              }}
              sx={{
                mt: 1
              }}
            >
              {t('delete.value')}
            </Button>
          </>
        )}
      />
      <Dialog fullWidth maxWidth="sm" open={openCreateDialog} onClose={handleCloseDialog}>
        <DialogTitle variant="subtitle2">
          {t('lessons.calendar.dialog.create.title', {ns: 'pages'})}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText variant="body2" mb={2}>
            {t('lessons.calendar.dialog.create.text', {
              ns: 'pages',
              date: event.date ? format(parseISO(event.date), DATE_TIME_FORMAT) : '',
              day: event.day?.label.toLowerCase() || '',
            })}
          </DialogContentText>
          <AutoCompleteField<Partial<Lesson>, undefined, undefined, undefined>
            sx={styles.field}
            fullWidth
            size="medium"
            transformer={(entities: Array<LessonResponsePayload>): Array<Lesson> => {
              return entities?.map((entity) => {
                return lessonResponseToState(entity);
              });
            }}
            TextFieldProps={{
              name: "Lesson",
              required: true,
              label: t('field.lesson.label'),
              placeholder: t('field.lesson.placeholder'),
              margin: 'normal',
              InputLabelProps: { shrink: true },
              helperText: Boolean(event?.Lesson?.id) && (
                <Typography variant="body3" color="text.gray">
                  {t('field.duration.label')}{': '}
                  <Typography variant="body3" color="text.gray" component="span" fontWeight={600}>
                    {getDuration(event?.Lesson?.duration || 0, currentLocale)}
                  </Typography>
                </Typography>
              )
            }}
            fetch={() => API.lessons.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(option) => {
              return option?.name || '' ;
            }}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  <Box>
                    <Typography variant="body2" color="text.primary">
                      {option.name}
                    </Typography>
                    <Typography variant="body3" color="text.gray" component="div" fontWeight={600}>
                      {option.description}
                    </Typography>
                  </Box>
                </Box>
              )
            }}
            value={event.Lesson?.id ? event.Lesson : null}
            onChange={(event, value) => {
              if(!value) {
                handleChangeEventField('Lesson',{
                  id: null,
                  name: null,
                })
              } else {
                handleChangeEventField('Lesson', value)
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{py: 2, px: 3}}>
          <Button variant="outlined" onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleRedirectToCreate}>
            {t('add.lesson')}
          </Button>
        </DialogActions>
      </Dialog>
    </FullCalendarContainer>
  )
};

const styles: SxPropsObject = {
  controls: {
    mb: 2,
  },
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default Calendar;
