import React, {Dispatch, SetStateAction} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {useTheme} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import {SxPropsObject} from '../../../../types';
import {Logo} from '../../../../styled';
import SvgIcon from '@mui/material/SvgIcon';
import Locales from '../../../../components/Locales';
import {Link} from 'react-router-dom';
import {grey} from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import {authSelector} from '../../../../redux/auth/selectors';
import {ReactComponent as CrossIcon} from '../../../../assets/icons/cross.svg';
import {ReactComponent as MenuIcon} from '../../../../assets/icons/menu.svg';
import {useTranslation} from 'react-i18next';
import usePermission from '../../../../hooks/usePermission';
import {ReactComponent as LogoImage} from '../../../../assets/images/logo.svg';
import SchoolsAutoComplete from '../SchoolsAutoComplete';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<Props> = (props) => {
  const {open , setOpen} = props;

  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const {t} = useTranslation();
  const {user} = useSelector(authSelector);
  const permission = usePermission();


  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        component="header"
        sx={styles.container}
      >
        <Container maxWidth={false}>
          <Toolbar sx={styles.toolbar}>
            <Grid container spacing={{xs: 0, sm: 2}} justifyContent="space-between">
              <Grid item xs="auto" sx={styles.left}>
                <Logo
                  component={Link}
                  to="/"
                  variant="h6"
                >
                  <LogoImage/>
                </Logo>
              </Grid>
              <Grid item xs="auto"  sx={styles.right}>
                {permission(['super-admin']) && isUpMd && (
                  <SchoolsAutoComplete/>
                )}
                <Locales sx={styles.locales}/>
                <Box sx={styles.person}>
                  {isUpSm && (
                    <Box sx={styles.personInfo}>
                      <Typography variant="subtitle2">{user?.surname && user?.name}</Typography>
                      <Typography variant="body3">{user?.role ? t(`roles.${user.role}`) : ''}</Typography>
                    </Box>
                  )}
                  <Avatar
                    sx={styles.avatar}
                    alt="Ірина Валеріївна"
                    src={undefined}
                  />
                </Box>
                {isDownLg && (
                  <IconButton
                    sx={styles.menuButton}
                    color="inherit"
                    aria-label="Menu button"
                    edge="end"
                    onClick={() => setOpen(state => !state)}
                  >
                    {open ? (
                      <SvgIcon fontSize="inherit" viewBox="0 0 25 25" component={CrossIcon} />
                    ) : (
                      <SvgIcon fontSize="inherit" viewBox="0 0 24 17" component={MenuIcon} />
                    )}
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

const styles:SxPropsObject = {
  container: (theme) => ({
    overflow: 'hidden',
    maxWidth: '100vw',
    background: grey[50],
    [theme.breakpoints.down('lg')]: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.common.white,
    }
  }),
  person: {
    display: 'flex',
    alignItems: 'center',
  },
  personInfo: {
    mr: 2,
    textAlign: 'right',
  },
  avatar: (theme) => ({
    bgcolor: '#4E4E4E',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      width: theme.spacing(4),
      height: theme.spacing(4),
      ml: 2,
    }
  }),
  toolbar: (theme) => ({
    py: 0,
    px: {
      xs: 0,
    },
    minHeight: {
      sm: theme.spacing(10),
    },
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      position: 'static',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: '-100vw',
      height: '1px',
      background: theme.palette.divider,

      [theme.breakpoints.down('lg')]: {
        right: 0,
        left: 0,
      }
    }
  }),
  left: () => ({
    display: {
      lg: 'none',
      md: 'flex'
    },
  }),
  right: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ml: 'auto'
  }),
  menuButton: (theme) => ({
    ml: 3,
    [theme.breakpoints.down('sm')]: {
      ml: 2,
    },
    [theme.breakpoints.down(319.95)]: {
      fontSize: '1.125rem',
    }
  }),
  locales: (theme) =>({
    mx: {
      sm: theme.spacing(3),
    },
  }),
}

export default Header;
