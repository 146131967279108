import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'name.max')
    .required('required')
    .nullable(true),
  description: Yup.string()
    // .min(12, 'text.min')
    // .required('required')
    .nullable(true),
  type: Yup.string()
    .required('required')
    .nullable(true),
  places: Yup.number()
    .when(['type'], {
      is: (type: string) => {
        return type === 'group-permanent' || type === 'group-temporary';
      },
      then: Yup.number()
        .typeError('number.format')
        .required('required')
        .nullable(true),
      otherwise: Yup.number()
        .typeError('number.format')
        .nullable(true),
    }),
  cost: Yup.mixed()
    .when(['type'], {
      is: (type: string) => {
        return type === 'group-permanent' || type === 'individual-permanent'
      },
      then: Yup.number()
        .typeError('number.format')
        .required('required')
        .nullable(true),
      otherwise: Yup.number()
        .typeError('number.format')
        .nullable(true),
    }),
  costSingle: Yup.mixed()
    .when(['type'], {
      is: (type: string) => {
        return type === 'group-temporary' || type === 'individual-temporary'
      },
      then: Yup.number()
        .typeError('number.format')
        .required('required')
        .nullable(true),
      otherwise: Yup.number()
        .typeError('number.format')
        .nullable(true),
    }),
  duration: Yup.string().required('required').nullable(true)
});

export default validationSchema;
