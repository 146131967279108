import React from 'react';
import {SxPropsObject} from '../../types';
import Box from '@mui/material/Box';
import {Navigate, Route, Routes} from 'react-router-dom';
import SignInPage from '../../pages/auth/SignIn';
import {useSelector} from 'react-redux';
import {authSelector} from '../../redux/resource/selectors';
import Spinner from '../../components/Spinner';

const Auth = () => {
  const {loading} = useSelector(authSelector);

  return (
    <Box sx={styles.container}>
      {loading && (
        <Spinner
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Box sx={styles.background}/>
      <Box sx={styles.content}>
        <Routes>
          <Route path="sign-in" element={<SignInPage />}/>
          <Route path="*" element={(
            <Navigate to="sign-in" replace />
          )}/>
        </Routes>
      </Box>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    display: 'flex',
    flex: 1,
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  background: {
    position: 'absolute',
    left: -10,
    top: -10,
    width: 'calc(100% + 20px)',
    height: 'calc(100% + 20px)',
    backgroundSize: 'cover',
    filter: 'blur(8px)',
  },
  content: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    py: 14,
  },
}

export default Auth;
