import React from 'react';
import Box from '@mui/material/Box';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import {Link as RouterLink, useNavigate, useSearchParams} from 'react-router-dom';

import ResourceList from '../../../../components/Resource/components/List';
import {childResponseToState, renderFullName, statusColor} from '../../../../utils';
import Content from '../../../../layouts/Main/components/Content';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import {format, parseISO} from 'date-fns';
import {DATE_FORMAT} from '../../../../constants';
import Sorting from '../../../../components/Sorting';
import Filters from '../../../../components/Filters';
import {TagResponsePayload} from '../../../../api/types';
import API from '../../../../api';
import AutoCompleteField from '../../../../components/AutoCompleteFiled';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const tagsParam = searchParams.getAll('tags') || [];
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();


  const columns: GridColDef[] = [
    {
      field: 'child',
      headerName: t('field.child.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {renderFullName(row)}
            </Typography>
            <Typography
              variant="body3"
              color="text.gray"
              title={Boolean(row.note) ? row.note : ''}
              sx={({
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              })}
            >
              {Boolean(row.note) ? row.note : t('child.note.without')}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'trustee',
      headerName: t('field.trustee.label'),
      flex: 2,
      minWidth: 180,
      maxWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box>
            <Link
              to={`/dashboard/parents/edit/${row.Trustee?.id}`}
              sx={{display: 'inline-flex'}}
              color="text.primary"
              variant="subtitle2"
              underline="hover"
              component={RouterLink}
              gutterBottom
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {renderFullName(row.Trustee)}
            </Link>
            <Typography variant="body3" color="text.gray" component="div">
              {row.Trustee.relationship}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'group',
      headerName: t('field.group.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Link
            to={`/dashboard/groups/edit/${row?.Group?.id}`}
            sx={{display: 'inline-flex'}}
            color="text.primary"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            gutterBottom
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {row.Group.name}
          </Link>
        );
      }
    },
    {
      field: 'payment',
      headerName: t('field.date.payment.label'),
      flex: 1,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const current = row?.payment?.date?.current;
        const expiry = row?.payment?.date?.expiry;
        return (
          <Box>
            {current && (
              <Typography variant="subtitle2" gutterBottom>
                {format(parseISO(current), DATE_FORMAT)}
              </Typography>
            )}
            {expiry && (
              <Typography variant="body3" color="text.gray" component="p">
                {t('period.to')} {format(parseISO(expiry), DATE_FORMAT)}
              </Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.label'),
      flex: 0,
      minWidth: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        const chipColor = statusColor(row?.payment?.status);
        return (
          <Chip
            label={t(`payment.status.${row?.payment?.status}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        );
      }
    },
  ];

  return (
    <Content title={t('children.list.title', {ns: 'pages'})}>
      <ResourceList
        name="children"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('children.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any) => {
          return entities.map((el: any) => {
            return childResponseToState(el);
          });
        }}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={RouterLink}
            to="/dashboard/children/create"
          >
            {t('add.value')}
          </Button>
        )}
        filters={(
          <Filters
            sx={{ mr: 0, ml: 2 }}
            items={[
              <AutoCompleteField<string, true, undefined, true>
                sx={styles.field}
                fullWidth
                multiple
                freeSolo
                size="medium"
                TextFieldProps={{
                  name: 'tags',
                  required: true,
                  label: t('field.tags.label'),
                  placeholder: t('field.tags.placeholder'),
                  margin: 'normal',
                  InputLabelProps: {shrink: true},
                }}
                transformer={(entities: Array<TagResponsePayload>): Array<string> => {
                  return entities?.map((entity) => {
                    return entity.title;
                  }) || [];
                }}
                fetch={() => API.tags.getAll(`/kinder-garden/${user?.schoolId}`)}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                value={tagsParam}
                onChange={(event, value) => {
                  setSearchParams({tags: value});
                }}
              />,
            ]}
          />
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.created.label'),
                value: 'createdAt',
              },
              {
                label: t('field.surname.label'),
                value: 'surname',
              },
              {
                label: t('field.name.label'),
                value: 'firstName',
              },
              {
                label: t('field.birthday.label'),
                value: 'birthday',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          ...(tagsParam ? {tags: tagsParam} : {}),
          ...(sortParam ? {sort: sortParam} : {}),
          ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        onRowClick={(params) => {
          navigate(`/dashboard/children/edit/${params.id}`);
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
};

export default List;
