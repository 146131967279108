import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('required')
    .nullable(true),
  date: Yup.string().required('required').nullable(true),
  comment: Yup.string().nullable(true),
  amount: Yup.number().required('required').nullable(true),
});

export default validationSchema;
