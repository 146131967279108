import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as CrossIcon} from '../../../../../assets/icons/cross.svg';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import TextField from '@mui/material/TextField';
import {ErrorMessage, getIn, useFormikContext} from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {Divider, useTheme} from '@mui/material';
import {FormValues} from '../../types';
import {NumericFormat} from 'react-number-format';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {Child, Lesson, Nullable} from '../../../../../types';
import {LessonResponsePayload} from '../../../../../api/types';
import {lessonResponseToState} from '../../../../../utils';
import API from '../../../../../api';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';


interface Props {
  child: Nullable<Partial<Child>>;
  disabled?: boolean;
}
const Services = (props: Props) => {

  const {disabled, child} = props;

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormikContext<FormValues>();
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const Services = values?.Services || [];

  const lesson = {
    id: -1,
    name: '',
    description: null,
    type: 'main',
    places: null,
    quantity: null,
    cost: null,
    costSingle: null,
    cover: null,
    media: null,
    Group: {
      id: null,
      name: null,
    },
    startAt: null,
    Schedule: [],
    schoolId: null,
    Children: null,
    createdAt: null,
    updatedAt: null,
    duration: null,
    Teacher: null
  } as Lesson;

  const onAdd = () => {
    const items = Services || [];
    items.push({
      name: '',
      quantity: null,
      price: null,
      cost: null,
      type: 'common',
    });

    setFieldValue('Services', items, true);
  }

  const onRemove = (index: number) => {
    const items = Services || [];
    const updated = [...items.splice(0, index), ...items.splice(index + 1)]
    setFieldValue('Services', updated, true);
  }

  return (
    <Box sx={{mt: 3}}>
      <Divider/>
      <List sx={{my: 2}}>
        {Services?.map((item, index) => {
          const fieldNameName = `Services.${index}`;
          const fieldQuantityName = `Services.${index}.quantity`;
          const fieldPriceName = `Services.${index}.price`;
          const fieldCostName = `Services.${index}.cost`;

          const fieldNameValue = getIn(values, fieldNameName, undefined);
          const fieldNameError = getIn(errors, `${fieldNameName}.id`, undefined);
          const fieldNameTouched = getIn(touched, `${fieldNameName}.id`, undefined);

          const fieldQuantityValue = getIn(values, fieldQuantityName, undefined);
          const fieldQuantityError = getIn(errors, fieldQuantityName, undefined);
          const fieldQuantityTouched = getIn(touched, fieldQuantityName, undefined);

          const fieldCostValue = getIn(values, fieldCostName, undefined);
          const fieldCostError = getIn(errors, fieldCostName, undefined);
          const fieldCostTouched = getIn(touched, fieldCostName, undefined);

          const fieldPriceValue = getIn(values, fieldPriceName, undefined);
          const fieldPriceError = getIn(errors, fieldPriceName, undefined);
          const fieldPriceTouched = getIn(touched, fieldPriceName, undefined);

          const divider = isDownSm && index !== Number(Services?.length) - 1;
          const deletable = index > 0 && !disabled;

          return (
            <ListItem
              key={index}
              dense
              disableGutters
              divider={divider}
              secondaryAction={(
                <IconButton
                  edge="end"
                  aria-label="delete"
                  disabled={!deletable}
                  onClick={() => onRemove(index)}
                >
                  <SvgIcon
                    fontSize="inherit"
                    viewBox="0 0 24 24"
                    component={CrossIcon}
                    color="inherit"
                  />
                </IconButton>
              )}
            >
              <Box width="100%">
                <Grid container spacing={{xs: 0, sm: 2}}>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteField<Partial<Lesson>, false, undefined, undefined>
                      fullWidth
                      disabled={disabled}
                      size="medium"
                      getOptionDisabled={(option) => {
                        const isMainSelected = values?.Services?.findIndex((el) => el.type === 'common') !== -1;
                        return option.id === -1 && isMainSelected;
                      }}
                      transformer={(entities: Array<LessonResponsePayload>): Array<Lesson> => {
                        let items = entities?.map((entity) => {
                          return lessonResponseToState(entity);
                        }) || [];

                        if(items && items?.length > 0) {
                          items.unshift(lesson)
                        }

                        return items;
                      }}
                      TextFieldProps={{
                        name: fieldNameName,
                        required: true,
                        label: t('field.name.service.label'),
                        placeholder: t('field.name.service.placeholder'),
                        margin: 'normal',
                        InputLabelProps: { shrink: true },
                        error: Boolean(fieldNameTouched && fieldNameError),
                        helperText: (
                          <ErrorMessage
                            name={`${fieldNameName}.id`}
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )
                      }}
                      fetch={() => API.lessons.getAllExtra(`/kinder-garden/${user?.schoolId}`, {
                        childId: child?.id,
                      })}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      getOptionLabel={(option) => {
                        return option?.name || t('subscriptions.type.subscription');
                      }}
                      value={fieldNameValue || null}
                      onChange={(event, value) => {
                        if(!value) {
                          setFieldValue(fieldNameName, {
                            id: null,
                            name: null,
                            cost: null,
                            price: null,
                            quantity: null,
                            type: null,
                          }, true)
                        } else {
                          setFieldValue(fieldNameName, {
                            id: value?.id,
                            cost: value?.cost,
                            price: value?.cost,
                            type: value?.type === 'main' ? 'common' : 'extra',
                            quantity: 1,
                            name: value?.name,
                          }, true)
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <NumericFormat
                      disabled={disabled}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={0}
                      allowLeadingZeros={false}
                      name={fieldQuantityName}
                      value={fieldQuantityValue || ''}
                      onChange={(event) => {
                        const {value} = event.target;
                        const cost = Number(value) * Number(fieldPriceValue)
                        setFieldValue(fieldQuantityName, value, true)
                        setFieldValue(fieldCostName, cost, true);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(fieldQuantityTouched && fieldQuantityError)}
                      helperText={(
                        <ErrorMessage
                          name={fieldQuantityName}
                          render={(message) => t(message, {ns: 'validation', min: 1})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      label={t('field.quantity.label')}
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <NumericFormat
                      disabled={disabled}
                      customInput={TextField}
                      allowNegative={false}
                      name={fieldPriceName}
                      value={fieldPriceValue || ''}
                      onChange={(event) => {
                        const {value} = event.target;
                        const cost = Number(value) * Number(fieldQuantityValue)
                        setFieldValue(fieldPriceName, value, true)
                        setFieldValue(fieldCostName, cost, true);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(fieldPriceTouched && fieldPriceError)}
                      helperText={(
                        <ErrorMessage
                          name={fieldPriceName}
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      label={t('field.price.label')}
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <NumericFormat
                      disabled
                      customInput={TextField}
                      allowNegative={false}
                      name={fieldCostName}
                      value={fieldCostValue || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(fieldCostTouched && fieldCostError)}
                      helperText={(
                        <ErrorMessage
                          name={fieldCostName}
                          render={(message) => t(message, {ns: 'validation'})}
                        />
                      )}
                      margin="normal"
                      required
                      fullWidth
                      label={t('field.cost.label')}
                      InputLabelProps={{ shrink: true }}
                      size="medium"
                      autoComplete='off'
                    />
                  </Grid>
                </Grid>
              </Box>
            </ListItem>
          )
        })}
      </List>
      <Button
        size="medium"
        variant="outlined"
        onClick={onAdd}
        disabled={disabled}
      >
        {t('add.service')}
      </Button>
    </Box>
  );
};

export default Services;
