import {css, styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {amber, blueGrey} from '@mui/material/colors';
import Link, {LinkTypeMap} from '@mui/material/Link';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {alpha} from '@mui/material';
import linesVerticalSrc from './assets/images/linesVertical.svg';
import linesHorizontalSrc from './assets/images/linesHorizontal.svg';
import Typography from '@mui/material/Typography';
import {DataGrid} from '@mui/x-data-grid';

interface LogoProps {
  size?: 'large' | 'default'
}

export const Logo = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'size',
})<LogoProps>(({
  theme, size
}) => ({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  width: size === 'large' ? 186 : 152,
  color: '#000',

  [theme.breakpoints.down('lg')]: {
    width: size === 'large' ? 136 : 124,
  },

  [theme.breakpoints.down(319.95)]: {
    width: size === 'large' ? 112 : 96,
  },

  '& img, & svg': {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
  }
})) as OverridableComponent<LinkTypeMap<LogoProps>>;

interface DividerCustomProps {
  view: 'horizontal' | 'vertical'
}
export const DividerCustom = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'view',
})<DividerCustomProps>(({view}) => ({
  position: 'relative',
  display: 'block',
  backgroundImage: `url(${view === 'vertical' ? linesVerticalSrc : linesHorizontalSrc})`,
  backgroundRepeat: `${view === 'vertical' ? 'repeat-y' : 'repeat-x'}`,
  backgroundSize: 'contain',
}));

interface PointerProps {
  reverse?: boolean
}

export const Pointer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'reverse',
})<PointerProps>(({theme, reverse}) => ({
  position: 'absolute',
  top: 12,
  width: '64%',
  height: '1px',
  background: theme.palette.primary.main,
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  ...(reverse ? {
    right: '100%',
  } : {
    left: '100%'
  }),

  [theme.breakpoints.down('lg')]: {
    width: '36%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'inline-flex',
    position: 'relative',
    width: 'auto',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 'auto',
    right: 'auto',
    top: 0,
    marginBottom: theme.spacing(2),
  },

  '&::before': {
    content: '""',
    width: 0,
    height: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderStyle: 'solid',
    ...(reverse ? {
      right: 0,
      borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
      borderWidth: '4px 8px 4px 0',
    } : {
      left: 0,
      borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
      borderWidth: '4px 0 4px 8px',
    }),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '& > div': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1,
    borderRadius: 1,
    background: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25em',
    height: '1.5em',
    minWidth: '1.5em',
    boxShadow: `0 0 0 0.5em ${alpha(theme.palette.primary.main, 0.4)}`,
    ...(reverse ? {
      left: 0,
    } : {
      right: 0
    }),

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.66rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      top: 0,
      transform: 'unset',
      position: 'relative',
    },
  }

}));

export const Nav = styled(Box)`
  display: flex;
  justify-content: center;
  margin: auto auto;
`;

export const NavItem = styled(Box)`
  &:not(:first-of-type) {
    margin-left: ${({theme}) => theme.spacing(5)};

    ${({theme}) => theme.breakpoints.down('md')} {
      margin-left: ${({theme}) => theme.spacing(2)};
    }
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  position: relative;
  cursor: pointer;

  ${({theme}) => theme.breakpoints.down('md')} {
    font-size: 0.875rem;
  }
  
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    display: flex;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: currentColor;
    border-radius: 1px;
    opacity: 0;
    transition: opacity .2s ease-in, width .2s ease-in;
  }
  
  &:hover {
    &::after {
      opacity: 1;
      width: 100%;
    }
  }
` as OverridableComponent<LinkTypeMap>

export const Tag = styled(Box)(({theme}) => ({
  position: 'relative',
  padding: `0.06em 0.36em`,
  fontWeight: 500,
  height: 'auto',
  borderRadius: theme.spacing(0.75),
  color: theme.palette.common.white,
  background: blueGrey[300],
  zIndex: 1,
})) as typeof Box;

export const CircleColor = styled(Box)(() => ({
  borderRadius: '50%',
  display: 'inline-block',
  width: 10,
  height: 10,
  verticalAlign: 'middle',
})) as typeof Box;

export const TypographyDecorated = styled(Typography)(() => ({
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    display: 'block',
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, #44BCFF -0.55%, #44B0FF 22.86%, #FF44EC 48.36%, #FF44EC 73.33%, #FF675E 99.34%)',
    filter: 'blur(24px)',
    zIndex: -1,
  }
})) as typeof Typography;

export const FullCalendarContainer = styled(Box)(({theme}) => {
  return css`
    position: relative;
    font-size: ${theme.typography.body2.fontSize};
    padding: ${theme.spacing(3)};

    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      font-size: 0.75rem;
    }
    
    .fc-theme-standard {
      .fc-popover {
        background: #FFFFFF;
        box-shadow: 0 1px 2px 2px rgba(197, 204, 215, 0.5);
        border-radius: 4px;
        border: 0 none;
        top: 40%;
        left: calc(50% - 160px);

        &-header {
          background-color: #eceff5;
          color: #666;
          padding: ${theme.spacing(0.5)};
        }

        &-body {
          padding: ${theme.spacing(0.5)};
          max-height: 200px;
          width: 320px;
          overflow: auto;
        }

        .fc-daygrid-event-harness {
          &:not(:last-child){
            margin: 0 0 4px;
          }
        }
      }

      .fc-daygrid-day-top {
        flex-direction: row;
      }

      .fc-scrollgrid {
        //border: 0 none;
      }

      .fc-daygrid-day-events {
        margin-top: 4px;
      }

      .fc-timegrid-slot-minor {
        border-top-style: solid;
        border-top-color: transparent;
      }

      .fc-timegrid-divider {
        background: transparent;
        border: 0 none;
      }

      td, th {
        font-weight: normal;
        text-align: left;
      }

      .fc-listWeek-view {
        border: 0 none;
      }

      .fc-list-day-cushion {
        background: transparent;
        font-weight: 400;

        a {
          &:first-letter {
            text-transform: capitalize;
          }
        }
      }
    }

    .fc-daygrid-day-number {
      color: ${theme.palette.text.primary};
      font-style: normal;
      font-weight: 400;
      padding: ${theme.spacing(0.5)};
    }

    .fc-col-header-cell {
      background: transparent;
    }

    .fc-col-header-cell-cushion {
      color: ${theme.palette.text.primary};
      text-transform: capitalize;
      padding: ${theme.spacing(0.5)};
      font-size: 0.875em;
      font-weight: ${theme.typography.subtitle1.fontWeight};
    }

    .fc-toolbar-title {
      font-size: 1.25em;
      text-overflow: ellipsis;
    }

    .fc-button-primary {
      font-weight: normal;
      border-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.main};
      background-color: #fff;

      &:hover {
        background: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
      }

      &:focus {
        box-shadow: none;
      }

      &:not(:disabled) {
        &.fc-button-active {
          background-color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          color: ${theme.palette.common.white};
          &:focus {
            box-shadow: none;
          }
        }

        &:active {
          background-color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          color: #fff;

          &:focus {
            box-shadow: none;
          }
        }
      }

      &:disabled {
        border-color: ${theme.palette.divider};
        color: ${theme.palette.divider};
        background-color: ${theme.palette.common.white};
        box-shadow: none;
        opacity: 1;
      }
    }

    .fc-button {
      .fc-icon {
        font-size: 1.25em;
      }
    }

    .fc-daygrid-day, .fc-timegrid-col {
      background: ${theme.palette.common.white};

      &.fc-day-today {
        background: rgba(44, 172, 235, 0.05);
        .fc-daygrid-day-number {
          color: ${theme.palette.primary.main};
        }
      }

    }

    .fc-daygrid-day {

      &.fc-day-today {
        box-shadow: inset 0 2px 0 ${theme.palette.primary.main};
      }

      &.fc-day-other {
        background: ${alpha(amber[100], 0.2)};
      }
    }

    .fc-list-day {
      .fc-list-day-cushion {
        box-shadow: inset 0 2px 0 ${theme.palette.divider};
      }

      &.fc-day-today {
        .fc-list-day-cushion {
          box-shadow: inset 0 2px 0 ${theme.palette.primary.main};
        }
      }
    }

    .fc-timegrid-axis-cushion {
      max-width: 100px;

      @media only screen and (max-width: 1830px) {
        max-width: ${theme.spacing(8)};
      }
    }

    .fc-daygrid-block-event {
      .fc-event-time {
        flex-shrink: 0;
        font-weight: 600;
        
        &:empty {
          margin: 0;
        }
      }
    }

    .fc-daygrid-event {
      border-radius: ${theme.spacing(0.5)};
      border: 0 none;

      .fc-event-box {
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        padding-left: 4px;
      }
    }
    
    .fc-event-main-frame {
      display: flex;
      flex: 1;
      align-items: center;
      position: relative;
      max-width: 100%;
    }
    
    .fc-event-title-container {
      display: flex;
      flex: 1;
      max-width: calc(100% - 72px);
    }

    .fc-h-event, .fc-v-event {
      .fc-event-main {
        color: currentColor;
      }

      &:not(.fc-event-start) {
        .fc-event-main-frame, .fc-event-box {
          box-shadow: none !important;
        }
      }

      .fc-event-main-frame, .fc-event-box {
        display: flex;
        border-radius: ${theme.spacing(0.5)};
      }
    }

    .fc-timegrid-event-harness,
    .fc-daygrid-event-harness {
      background: #fff;
    }

    .fc-timegrid-event, .fc-timegrid-more-link {
      border-radius: ${theme.spacing(0.5)};
      border: 0 none;
      
      .fc-event-main-frame {
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 8px;
      }
      
      .fc-event-time {
        order: 2;
      }

      .fc-event-title-container {
        flex: none;
      }
      
      .fc-event-title {
        flex-grow: initial;
      }

      .fc-event-box {
        height: 100%;
        flex-direction: column-reverse;

        .fc-event-time {
          margin-bottom: 0;
          padding: 0 ${theme.spacing(1)};
          margin-top: 2px;
        }
      }
    }

    .fc-daygrid-dot-event {
      &.fc-event-mirror, &:hover {
        background-color: #eaf5fd;
      }
    }

    .fc-event-selected, .fc-event:focus {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &:after {
        background-color: ${theme.palette.divider};
      }
    }

    .fc-event {
      color: #666;

      &::before, &::after {
        pointer-events: none !important;
      }
    }

    .fc-event-title {
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .fc-list-event {
      .fc-event-title {
        white-space: initial;
      }
    }

    .fc-list-empty {
      background: inherit;
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
    }

    .fc-event-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      & > .icon {
        font-size: 1.36em;
        width: 1em;
        height: 1em;
        margin: 0 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        & > svg {
          height: 1em;
          max-width: 1em;
          max-height: 1em;
        }
      }
    }

    .fc-list-event {
      .fc-event-title {
        white-space: initial;
      }
    }

    .fc-list-empty {
      background: inherit;
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
    }
    
    .fc-event-label {
      position: absolute;
      right: 0;
      top: -2px;
      bottom: -2px;
      border-radius: 4px;
      width: 10px;
      margin-left: auto;
      height: calc(100% + 4px);
    }
  `;
}) as typeof Box;

export const DataGridCustom = styled(DataGrid)(({ theme }) => ({
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  border: '0 none',

  '& .MuiDataGrid-iconSeparator': {
    opacity: 0,
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.text.gray,
  },

  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    px: 3,

    '&, &:focus, &:focus-within': {
      boxShadow: 'none',
      outline: 'none',
    }
  },

  '& .MuiDataGrid-columnHeader': {
    textTransform: 'uppercase',
  },

  '& .MuiDataGrid-cell': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

export const ListBox = styled(Box)(({ theme }) => ({
  my: theme.spacing(3),
  listStyle: 'none',
  p: 0,

  '&:first-of-type': {
    mt: 0,
  },

  '&:last-of-type': {
    mt: 0,
  },

  '& li': {
    mb: theme.spacing(3),
    '&:last-of-type': {
      mb: 0,
    },
  },

  [theme.breakpoints.down('lg')]: {
    my: theme.spacing(1),

    '&:first-of-type': {
      mt: 0,
    },

    '&:last-of-type': {
      mt: 0,
    },
  }
}));
