import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'text.min')
    .max(1024, 'text.max')
    .required('required')
    .nullable(true),
  description: Yup.string()
    .min(12, 'text.min')
    .required('required')
    .nullable(true),
  startAt: Yup.string()
    .required('required')
    .nullable(true),
  finishAt: Yup.string()
    .required('required')
    .test('phone', 'date.end.more.start', function(finishAt){
      const { startAt } = this.parent;
      const start = new Date(startAt);
      const end = new Date(finishAt || Date.now());
      return start <= end;
    })
    .nullable(true),
  Users: Yup.array()
    .of(Yup.object().shape({
      id: Yup.number().required('required').nullable(true),
    }))
    // .required('required')
    .nullable(true),
});

export default validationSchema;
