import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Group, SxPropsObject} from '../../types';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import {useTranslation} from 'react-i18next';
import useNoDataOverlay from '../../hooks/useNoDataOverlay';
import {ReactComponent as CrossIcon} from '../../assets/icons/cross.svg';

interface Props {
  items: Array<Partial<Group>>,
  onClick: (id: number) => void;
  onDelete?: (id: number) => void;
  emptyText?: string;
}

const Groups = (props: Props) => {
  const {
    items: groups,
    onClick,
    onDelete,
    emptyText = 'no.data',
  } = props;
  const {t} = useTranslation();

  const NoData = useNoDataOverlay(
    t(emptyText),
    null,
    true,
  );

  return (
    <Box sx={styles.container}>
      {groups && groups.length > 0 ? (
        <List>
          {groups.map((group, index) => {
            return (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  if(group.id) {
                    onClick(group.id)
                  }
                }}
                secondaryAction={typeof onDelete === 'function' && (
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="Delete"
                    edge="end"
                    onClick={(event) => {
                      event.stopPropagation();
                      if(group.id) {
                        onDelete(group.id)
                      }
                    }}
                  >
                    <SvgIcon
                      fontSize="inherit"
                      viewBox="0 0 24 24"
                      component={CrossIcon}
                    />
                  </IconButton>
                )}
              >
                <ListItemButton sx={styles.button} dense>
                  <ListItemText
                    primary={group.name}
                    secondary={group.Children?.length || ''}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      ) : (
        <NoData/>
      )}
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {},
  button: {
    borderRadius: 1,
  },
}

export default Groups;
