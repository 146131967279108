import React from 'react';
import Box from '@mui/material/Box';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import ResourceList from '../../../../components/Resource/components/List';
import SvgIcon from '@mui/material/SvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from '../../../../config/theme';
import {useNavigate} from 'react-router-dom';
import {getMedia, postResponseToState} from '../../../../utils';
import format from 'date-fns/format';
import Content from '../../../../layouts/Main/components/Content';
import {parseISO} from 'date-fns';
import {DATE_FORMAT} from '../../../../constants';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import noImageSrc from '../../../../assets/images/noImage.svg';
import {ReactComponent as ChevronDownIcon} from '../../../../assets/icons/chevronDown.svg';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(userSelector);

  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string, type: string) => {
    navigate({
      pathname: `/dashboard/posts/${path}`,
    }, {
      state: {
        type,
      }
    });
  }

  const columns: GridColDef[] = [
    {
      field: 'entity',
      headerName: t('news.value'),
      flex: 2,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      minWidth: 360,
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Box sx={styles.cellInner}>
            <Box sx={styles.cellImage}>
              <Box
                component="img"
                src={getMedia(row.media) || noImageSrc}
                alt={row.title}
                onError={(event: any) => {
                  //@ts-ignore
                  event.target.src = noImageSrc;
                }}
              />
            </Box>
            <Box sx={styles.cellContent}>
              <Typography variant="subtitle2" gutterBottom >
                {row.name}
              </Typography>
              <Typography
                variant="body3"
                color="text.gray"
                component="p"
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.text}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'type',
      headerName: t('field.type.label'),
      minWidth: 160,
      width: 160,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return t(`${params.row.type}.value`);
      }
    },
    {
      field: 'date',
      headerName: t('field.date.publish.label'),
      type: 'number',
      minWidth: 200,
      width: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({row}: GridRenderCellParams) => {
        return (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {format(parseISO(row.createdAt), DATE_FORMAT)}
            </Typography>
          </Box>
        );
      }
    },
  ];

  return (
    <Content title={t('posts.list.title', {ns: 'pages'})}>
      <ResourceList
        name="posts"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('posts.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any) => {
          return entities.map((el:any) => {
            return postResponseToState(el);
          })
        }}
        actions={() => (
          <>
            <Button
              aria-controls={open ? 'actions' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={styles.button}
              size="medium"
              variant="contained"
              onClick={handleClick}
              endIcon={(
                <SvgIcon
                  fontSize="inherit"
                  viewBox="0 0 24 24"
                  component={ChevronDownIcon}
                  sx={{
                    transform: `rotateX(${open ? 180 : 0}deg)`,
                    transition: theme.transitions.create('transform')
                  }}
                />
              )}
            >
              {t('add.value')}
            </Button>
            <Menu
              id="actions"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'actions-button',
              }}
            >
              <MenuItem onClick={() => handleNavigate('create','vote')}>
                {t('vote.value')}
              </MenuItem>
              <MenuItem onClick={() => handleNavigate('create', 'news')}>
                {t('news.value')}
              </MenuItem>
            </Menu>
          </>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        onRowClick={(params) => {
          handleNavigate(`edit/${params.id}`, params.row.type)
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  cellInner: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  cellContent: (theme) => ({
    maxWidth: `calc(100% - ${theme.spacing(9)})`
  }),
  cellImage: theme => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    width: theme.spacing(7),
    height: theme.spacing(7),
    flexShrink: 0,
    mr: 2,
    fontSize: '0.75rem',

    '& > img, & > svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    }
  }),
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
