import * as Yup from 'yup';
import {getAge} from '../../../utils';
import {MAX_CHILD_AGE, MIN_CHILD_AGE} from '../../../constants';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'mane.max')
    .required('required')
    .nullable(true),
  surname: Yup.string().required('required').nullable(true),
  patronymic: Yup.string().nullable(true),
  birthday: Yup.date()
    .test('age', (birthday, {createError, path}) => {
      const {value} = getAge(birthday || null) || {}
      const months = (Number(value?.years || 0) * 12 + Number(value?.months || 0));
      if (months < MIN_CHILD_AGE) {
        return createError({
          path,
          message: 'age.min',
        })
      } else if(months > MAX_CHILD_AGE) {
        return createError({
          path,
          message: 'age.max',
        })
      }
      return true;
    })
    .required('required')
    .nullable(true),
  gender: Yup.string().required('required').nullable(true),
  payment: Yup.object()
    .shape({
      value: Yup.number().required('required').nullable(true),
    }),
  Group: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
    }),
  Trustee: Yup.object()
    .shape({
      id: Yup.number().required('required').nullable(true),
      relationship: Yup.string().required('required').nullable(true),
    }),
});

export default validationSchema;
