import React, {PropsWithChildren, useCallback} from 'react';
import validationSchema from '../../validationSchema';
import Box from '@mui/material/Box';
import Content from '../../../../../layouts/Main/components/Content';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import {apiErrorsToFormik, getMediaUri} from '../../../../../utils';
import {FormValues} from '../../types';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DATE_FORMAT} from '../../../../../constants';
import Files from '../../../../../components/Files';
import {NumericFormat} from 'react-number-format';
import {useNavigate} from 'react-router-dom';

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => void;
  onDelete?: () => void;
}

const fields = {
  "childId": "Child"
}
const Form = (props: PropsWithChildren<Props>) => {
  const {
    mode,
    submitting,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
        navigate(`/dashboard/payments/expenses`);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      }, false);
    }, [navigate, onSubmit],
  );

  const title = t(`expenses.content.title.${mode}`, {ns: 'pages'})

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange,
          isSubmitting,
          dirty,
          resetForm
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('expenses.content.subtitle.details', {ns: 'pages'})}
                  </Typography>
                  <TextField
                    sx={styles.field}
                    name="name"
                    value={values?.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.name && errors?.name)}
                    helperText={(
                      <ErrorMessage
                        name="name"
                        render={(message) => t(message, {ns: 'validation', min: 12})}
                      />
                    )}
                    margin="normal"
                    required={false}
                    fullWidth
                    label={t('field.title.label')}
                    placeholder={t('field.title.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        format={DATE_FORMAT}
                        slots={{
                          textField: TextField,
                        }}
                        slotProps={{
                          textField: {
                            sx: styles.field,
                            name: "date",
                            onBlur: handleBlur,
                            error: Boolean(touched?.date && errors?.date),
                            helperText: (
                              <ErrorMessage
                                name="date"
                                render={(message) => t(message, {ns: 'validation'})}
                              />
                            ),
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            InputLabelProps: { shrink: true },
                            size: "medium",
                            autoComplete: 'off'
                          }
                        }}
                        value={values?.date}
                        label={t('field.date.expense.label')}
                        onChange={(value: any) => {
                          setFieldValue('date', value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumericFormat
                        customInput={TextField}
                        allowNegative={false}
                        name="amount"
                        value={values?.amount || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.amount && errors?.amount)}
                        helperText={(
                          <ErrorMessage
                            name="amount"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.expense.label')}
                        placeholder={t('field.expense.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    sx={styles.field}
                    name="comment"
                    value={values?.comment || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.comment && errors?.comment)}
                    helperText={(
                      <ErrorMessage
                        name="description"
                        render={(message) => t(message, {ns: 'validation', min: 12})}
                      />
                    )}
                    margin="normal"
                    required={false}
                    fullWidth
                    label={t('field.comment.label')}
                    placeholder={t('field.comment.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                    multiline
                    rows={4}
                  />
                </>
              )}
              right={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('expenses.content.subtitle.files', {ns: 'pages'})}
                  </Typography>
                  <Files
                    data={values?.media?.map((el) => {
                      return {
                        value: el,
                        uri: getMediaUri(el),
                      }
                    }) || null}
                    onUpload={(items) => {
                      setFieldValue('media', items);
                    }}
                    onRemove={() => {
                      return setFieldValue('media', []);
                    }}
                  />
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.expense') : t('edit.expense'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            />
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  content: {
    p: {
      lg: 4,
      xs: 2,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
