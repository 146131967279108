import React, {useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {School, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {PatternFormat} from 'react-number-format';
import {apiErrorsToFormik} from '../../../../../utils';
import Switch from '@mui/material/Switch';
import usePermission from '../../../../../hooks/usePermission';

interface FormValues extends Pick<
  School, 'name' | 'active' | 'address' | 'Contact'
  > {
}

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'kinderGardenId':'schoolId',
  'kinderGardenName':'School.name',
  'kinderGardenAddress':'School.address',
  'isActive':'School.active',
  'contactSurname':'Contact.surname',
  'contactFirstName':'Contact.name',
  'contactMiddleName':'Contact.patronymic',
  'contactEmail':'Contact.email',
  'contactPhoneNumber':'Contact.phone',
}

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const permission = usePermission();

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`schools.content.title.${mode}`, {ns: 'pages'})

  const schema = validationSchema(mode);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
          resetForm,
          isSubmitting,
          setFieldValue,
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('schools.content.subtitle.school', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('schools.content.section.main', {ns: 'pages'})}
                  </Typography>
                  <TextField
                    disabled
                    sx={styles.field}
                    name="name"
                    value={values?.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.name && errors?.name)}
                    helperText={(
                      <ErrorMessage
                        name="name"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.name.school.label')}
                    placeholder={t('field.name.school.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  <TextField
                    disabled
                    sx={styles.field}
                    name="address"
                    value={values?.address || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.address && errors?.address)}
                    helperText={(
                      <ErrorMessage
                        name="address"
                        render={(message) => t(message, {ns: 'validation'})}
                      />
                    )}
                    margin="normal"
                    required
                    fullWidth
                    label={t('field.address.label')}
                    placeholder={t('field.address.placeholder')}
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    autoComplete='off'
                  />
                  {permission(['super-admin']) && (
                    <>
                      <Typography variant="subtitle2" sx={{my: 1}}>
                        {t('schools.content.section.settings', {ns: 'pages'})}
                      </Typography>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body2">
                            {t('school.status.deactivated')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            name="active"
                            size="medium"
                            disabled={!permission(['super-admin'])}
                            checked={Boolean(values?.active)}
                            onChange={(event, value) => {
                              setFieldValue( 'active', value);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {t('school.status.active')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
              right={(
                <>
                  <Typography variant="subtitle1" sx={{mt: 3, my: 2}}>
                    {t('schools.content.subtitle.contact', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        sx={styles.field}
                        name="Contact.surname"
                        value={values?.Contact?.surname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.surname && errors?.Contact?.surname)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.surname"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.surname.label')}
                        placeholder={t('field.surname.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        sx={styles.field}
                        name="Contact.name"
                        value={values?.Contact?.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.name && errors?.Contact?.name)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.name"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.name.label')}
                        placeholder={t('field.name.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        sx={styles.field}
                        name="Contact.patronymic"
                        value={values?.Contact?.patronymic || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.patronymic && errors?.Contact?.patronymic)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.patronymic"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required={false}
                        fullWidth
                        label={t('field.patronymic.label')}
                        placeholder={t('field.patronymic.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        sx={styles.field}
                        name="Contact.email"
                        value={values?.Contact?.email || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.email && errors?.Contact?.email)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.email"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.email.label')}
                        placeholder={t('field.email.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PatternFormat
                        disabled
                        sx={styles.field}
                        customInput={TextField}
                        name="Contact.phone"
                        value={values?.Contact?.phone || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.Contact?.phone && errors?.Contact?.phone)}
                        helperText={(
                          <ErrorMessage
                            name="Contact.phone"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.phone.label')}
                        placeholder={t('field.phone.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                        format="+38##########"
                        allowEmptyFormatting
                        mask="_"
                        type="tel"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.school') : t('edit.school'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: true,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
