import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name.min')
    .max(255, 'name.max')
    .required('required')
    .nullable(true),
  type: Yup.string().required(),
  text: Yup.mixed()
    .when(['type'], {
      is: (type: string) => {
        return type === 'news';
      },
      then: Yup.string()
        // .min(12, 'text.min')
        // .required('required')
        .nullable(true),
      otherwise: Yup.string().nullable(true),
    }),

  Groups: Yup.array()
    .min(1, 'required')
    .of(Yup.object().shape({
      id: Yup.number().required('required').nullable(true),
    }))
    .required('required')
    .nullable(true),
  Answers: Yup.mixed()
    .when(['type'], {
      is: (type: string) => {
        return type === 'vote';
      },
      then: Yup.array()
        .min(2, 'answers.min')
        .of(Yup.object().shape({
          answer: Yup.string()
            .min(2, 'text.min')
            .required('required')
            .nullable(true),
        }))
        .required('required')
        .nullable(true),
      otherwise: Yup.mixed().nullable(true),
    }),
});

export default validationSchema;
