import React from 'react';
import {Complaint, SxPropsObject} from '../../../../types';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import Content from '../../../../layouts/Main/components/Content';
import {a11yProps, complaintResponseToState, complaintStatusStateToRequest} from '../../../../utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../../components/TabPanel';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import Grid from '@mui/material/Grid';
import Card from '../components/Card';
import useNoDataOverlay from '../../../../hooks/useNoDataOverlay';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(userSelector);

  const type = searchParams.get('type') || 'wait';
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const tabs = [
    {
      label: t('complaints.tabs.new', {ns: 'pages'}),
      value: 'wait'
    },
    {
      label: t('complaints.tabs.complete', {ns: 'pages'}),
      value: 'complete'
    },
    {
      label: t('complaints.tabs.all', {ns: 'pages'}),
      value: 'all'
    },
  ]

  const NoComplaints = useNoDataOverlay(
    t('no.data'),
    null,
    true,
  );

  return (
    <Content
      title={t('complaints.list.title', {ns: 'pages'})}
    >
      <ResourceList
        name="complaints"
        pathModifier={(path) => {
          const params = type !== 'all' ? `?status=${complaintStatusStateToRequest(type)}` : '';
          return `/kinder-garden/${user?.schoolId}${path}${params}`
        }}
        title={t('complaints.list.subtitle', {ns: 'pages'})}
        columns={[]}
        actions={() => null}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          page: undefined,
          perPage: undefined,
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        transformer={(entities: any):any => {
          return entities?.map((complaint: any) => {
            return complaintResponseToState(complaint);
          });
        }}
      >
        {({entities, loading, onEdit}) => {

          const content = Boolean(entities && entities.length > 0) ? (
            <Grid container spacing={{xs: 2, lg: 2}}>
              {entities?.map((entity, index) => {
                const onChangeStatus = (status: Complaint['status']) => {
                  if(status) {
                    const transformed = complaintStatusStateToRequest(status)
                    onEdit(entity.id, {
                      status: transformed,
                    })
                  }
                }

                return (
                  <Grid key={index + entity.id} item xs={12} sm={6} md={4}>
                    <Card entity={entity} onChangeStatus={onChangeStatus}/>
                  </Grid>
                )
              })}
            </Grid> ) : (
            <Box sx={{margin: 'auto'}}>
              <NoComplaints/>
            </Box>
          );

          return (
            <>
              <Tabs
                sx={styles.tabs}
                value={type}
                onChange={(event, value) => {
                  setSearchParams({type: value})
                }}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Complaints tabs"
              >
                {tabs?.map(({label, value}) => {
                  return (
                    <Tab
                      key={value}
                      sx={styles.tab}
                      label={label}
                      value={value}
                      {...a11yProps(value)}
                    />
                  )
                })}
              </Tabs>
              {tabs?.map(({ value}) => {
                return (
                  <TabPanel key={value} value={type || ''} index={value}>
                    <Box sx={{p: 3, minHeight: 480, display: 'flex', alignItems: 'flex-start'}}>
                      {loading ? (
                        <Spinner/>
                      ) : content}
                    </Box>
                  </TabPanel>
                )
              })}
            </>
          )
        }}
      </ResourceList>
    </Content>
  );
};

const styles: SxPropsObject = {
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'divider',

    '& .MuiTab-root': {
      p: 3,
    },

    [theme.breakpoints.down('sm')]: {
      px: 3
    }
  }),
  tab: () => ({
    textTransform: 'capitalize',
  }),
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
