import {createSlice} from '@reduxjs/toolkit';
import {noticeClose, noticeOpen, noticeRemove} from './reducers';

export interface NotificationsState {
  items: Array<any>;
}

const initialState: NotificationsState = {
  items: [],
}

export const slice = createSlice({
  name: 'notices',
  initialState: initialState,
  reducers: {
    noticeOpen: {
      reducer: noticeOpen,
      //@ts-ignore
      prepare: (notification) => {
        return { payload: notification };
      },
    },
    noticeClose: {
      reducer: noticeClose,
      //@ts-ignore
      prepare: (key) => ({ payload: { key, dismissAll: !key } })
    },
    noticeRemove: noticeRemove
  }
});

export default slice;
