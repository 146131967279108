import {SagaIterator} from 'redux-saga';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../api';
import {AxiosResponse} from 'axios';
import {AuthResponsePayload, SchoolResponsePayload} from '../../api/types';
import {getFirstSchoolId, personResponseToState, schoolResponseToState} from '../../utils';
import slice, {authCheck, signIn, signOut} from './slice';
import {history} from '../../App';
import { resetState } from '../actions';

function* signInSaga(action: any): SagaIterator<void> {
  const { email, password } = action.payload;
  yield put(slice.actions.signInRequest());
  try {
    const response: AxiosResponse<AuthResponsePayload> = yield call(API.auth.login, email, password);
    const {user, access} = response.data;
    let schools = null;
    let redirectPath = '/dashboard';
    localStorage.setItem('token', access.token)
    localStorage.setItem('expires', access.expires);

    if(user.role === 'super-admin') {
      const {data, status} = yield call(API.schools.getAll)
      if(status === 200) {
        schools = data?.map((item:SchoolResponsePayload) => {
          return schoolResponseToState(item)
        }) || null;
      }
    }

    yield put(slice.actions.signInSuccess({
      user: personResponseToState(user),
      token: access.token,
      expires: access.expires,
      schools,
    }));

    const schoolId = getFirstSchoolId(schools) || user.kinderGardenId;
    localStorage.setItem('schoolId', String(schoolId));
    yield put(slice.actions.setCurrentSchool({
      schoolId,
    }))

    history.replace(redirectPath);
  } catch (error) {
    //@ts-ignore
    yield put(slice.actions.signInFail(error?.response?.data?.message));
  }
}

function* authCheckSaga(): SagaIterator<void> {
  yield put(slice.actions.signInRequest());
  try {
    const response: AxiosResponse<AuthResponsePayload> = yield call(API.auth.refresh);
    const {user, access} = response.data;
    let schools = null;
    localStorage.setItem('token', access.token)
    localStorage.setItem('expires', access.expires);

    if(user.role === 'super-admin') {
      const {data, status} = yield call(API.schools.getAll)
      if(status === 200) {
        schools = data?.map((item:SchoolResponsePayload) => {
          return schoolResponseToState(item)
        }) || null;
      }
    }

    yield put(slice.actions.signInSuccess({
      user: personResponseToState(user),
      token: access.token,
      expires: access.expires,
      schools,
    }));

    const schoolIdStorage = localStorage.getItem('schoolId');
    const schoolId = getFirstSchoolId(schools) || user.kinderGardenId;

    let currentSchoolId = schoolId;

    if(user.role === 'super-admin') {
      currentSchoolId = Number(schoolIdStorage) || schoolId;
    }

    yield put(slice.actions.setCurrentSchool({
      schoolId: currentSchoolId,
    }))

  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    //@ts-ignore
    yield put(slice.actions.signInFail(error?.response?.data?.message));
  }
}

function* signOutSaga(): SagaIterator<void> {
  localStorage.removeItem('token');
  localStorage.removeItem('expires');
  localStorage.removeItem('schoolId');
  yield put(slice.actions.signOutSuccess());
  yield put(resetState());
  history.replace('/auth/sign-in')
}

export default function* authSaga() {
  yield all([
    takeEvery(signIn.type, signInSaga),
    takeEvery(signOut.type, signOutSaga),
    takeEvery(authCheck.type, authCheckSaga)
  ])
}
