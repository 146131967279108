import {SxProps, SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import {
  ChildRequestPayload,
  ChildResponsePayload,
  ComplaintResponsePayload, ExpenseRequestPayload, ExpenseResponsePayload,
  GroupRequestPayload,
  GroupResponsePayload,
  InvoiceRequestPayload,
  InvoiceResponsePayload,
  InvoiceResponseStatus,
  LessonRequestPayload,
  LessonResponsePayload,
  NewsRequestPayload,
  OwnerRequestPayload,
  OwnerResponsePayload,
  PersonResponsePayload,
  PostResponsePayload, ScheduleLessonRequestPayload, ScheduleResponsePayload,
  SchoolRequestPayload,
  SchoolResponsePayload,
  ServiceRequestPayload,
  ServiceResponsePayload,
  TaskRequestPayload,
  TaskResponsePayload,
  TeacherRequestPayload,
  TeacherResponsePayload,
  UserRequestPayload,
  UserResponsePayload,
  VoteRequestPayload
} from './api/types';

import {
  Child,
  Complaint,
  DayFormat,
  DayValue, Expense,
  Gender,
  Group,
  Invoice,
  InvoiceStatus,
  Lesson,
  Media,
  Nullable,
  Owner,
  Payment as PaymentType,
  Payment,
  Person,
  Post,
  ScheduleItem, ScheduleLesson,
  School,
  Service,
  Task,
  Teacher,
  User
} from './types';
import format from 'date-fns/format';
import {ASSETS, LESSON_TYPES, MEDIA_PATH, SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT, TASK_TYPES, TIME_FORMAT} from './constants';
import differenceInMonths from 'date-fns/differenceInMonths';
import {Translation} from 'react-i18next';
import React from 'react';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import ukLocale from 'date-fns/locale/uk';
import enUsLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ruLocale from 'date-fns/locale/ru';
import {differenceInDays, formatDuration, isValid, Locale} from 'date-fns';
import {blueGrey, teal} from '@mui/material/colors';
import {utcToZonedTime} from 'date-fns-tz';
import {alpha} from '@mui/material';
import i18next from 'i18next';

export const getTimeZoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

type AssetKeys = keyof typeof ASSETS.ua;

export const getAssets = (locale: string): {[key in AssetKeys]: any} => {
  if(locale in ASSETS ){
    return ASSETS[(locale as keyof typeof ASSETS)]
  } else {
    return ASSETS['ua']
  }
}

export const getSxStyle = (styleProp:SxProps<Theme>, theme: Theme):SystemStyleObject<Theme> => {
  let style: SystemStyleObject<Theme>;
  if(typeof styleProp === 'function') {
    style = styleProp(theme)
  } else {
    //@ts-ignore
    style = styleProp;
  }

  return style;
}

type StatusColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export const statusColor = (status: string | boolean | undefined): StatusColor => {

  if(typeof status === 'boolean') {
    return status ? 'success' : 'error'
  }

  switch (status) {
    case 'expired':
      return 'error';
    case 'wait':
    case 'in_progress':
    case 'todo':
      return 'warning';
    case 'complete':
      return 'success';
    default:
      return 'default';
  }
}

export const fromHHmmToDateUTC = (time: string | null): Date | null => {
  if(!time) {
    return null;
  }
  const hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);
  const date = new Date();
  return  new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
    date.getSeconds(),
    date.getMilliseconds(),
  ))
}
export const initialScheduleItem = (date?: Date, day?: DayFormat): ScheduleItem => {
  let value = new Date();
  value.setHours(8, 0, 0, 0)

  if(date) {
    value = date;
  }

  return {
    day: day || null,
    time: value,
  }
}

export const personResponseToState = (person: PersonResponsePayload): Person => {
  const {
    id,
    role,
    firstName,
    middleName,
    surname,
    createdAt,
    updatedAt,
    phoneNumber,
    kinderGardenId,
    email
  } = person;

  return {
    id,
    role,
    surname,
    name: firstName,
    patronymic: middleName || '',
    phone: phoneNumber,
    schoolId: kinderGardenId,
    email,
    createdAt,
    updatedAt,
    media: null
  }
};

export const groupsResponseToState = (person: GroupResponsePayload): Group => {
  const {
    id,
    name,
    createdAt,
    updatedAt,
    kinderGardenId,
    Teacher,
    Children,
    childrenCount
  } = person;

  return {
    id,
    name,
    schoolId: kinderGardenId,
    createdAt,
    updatedAt,
    Children: Children?.map((el) => childResponseToState(el)) || null,
    Teacher: Teacher ? teacherResponseToState(Teacher) : null,
    childrenCount,
  }
};

export const groupStateToRequest = (group: Group): GroupRequestPayload => {
  const {
    name,
    ageRange,
    Teacher
  } = group;

  return {
    name,
    teacherId: Teacher?.id || null,
    ...(ageRange ? {ageCategory: ageRange} : {}),
  }
}

export const schoolStateToRequest = (school: School): SchoolRequestPayload => {

  return {
    isActive: school.active,
    currency: school.currency?.toUpperCase() || null,
    paymentSystem: school.paymentSystem || null,
    iban: school?.Bank?.iban || '',
    edrpou: school?.Bank?.edrpou || '',
    receiver: school?.Bank?.receiver || '',
    bankName: school?.Bank?.name || '',
    bankToken: school?.Bank?.token || '',
  }
}

export const schoolResponseToState = (school: SchoolResponsePayload): School => {
  return {
    id: school.id,
    name: school.name,
    address: school.address,
    active: school.isActive,
    createdAt: school.createdAt,
    updatedAt: school.updatedAt,
    paymentSystem: school.paymentSystem,
    currency: school?.currency?.toLowerCase() || '',
    Contact: {
      name: school.contactFirstName,
      surname: school.contactSurname,
      patronymic: school.contactMiddleName,
      phone: school.contactPhoneNumber,
      email: school.contactEmail,
    },
    Bank: {
      iban: school.iban,
      receiver: school.receiver,
      edrpou: school.edrpou,
      name: school.bankName,
      token: school.bankToken,
    },
  }
}

export const ownerResponseToState = (person: OwnerResponsePayload): Owner => {
  const {
    id,
    role,
    firstName,
    middleName,
    surname,
    createdAt,
    updatedAt,
    phoneNumber,
    kinderGardenId,
    email,
    KinderGarden,
    password,
  } = person;

  return {
    id,
    role,
    surname,
    name: firstName,
    patronymic: middleName || '',
    phone: phoneNumber,
    schoolId: kinderGardenId,
    email,
    createdAt,
    updatedAt,
    Bank: {
      iban: KinderGarden.iban,
      receiver: KinderGarden.receiver,
      edrpou: KinderGarden.edrpou,
      name: KinderGarden.bankName,
      token: KinderGarden.bankToken,
    },
    Contact: {
      name: KinderGarden.contactFirstName,
      surname: KinderGarden.contactSurname,
      patronymic: KinderGarden.contactMiddleName,
      email: KinderGarden.contactEmail,
      phone: KinderGarden.contactPhoneNumber,
    },
    password,
    School: schoolResponseToState(KinderGarden)
  }
};

export const ownerStateToRequest = (
  person: Owner
): OwnerRequestPayload => {
  const {
    surname,
    name,
    patronymic,
    email,
    phone,
    password,
    School,
    Contact,
    Bank,
  } = person;

  return {
    surname: surname,
    firstName: name,
    middleName: patronymic || null,
    email,
    phoneNumber: phone,
    iban: Bank?.iban || null,
    edrpou: Bank?.edrpou || null,
    receiver: Bank?.receiver || null,
    bankName: Bank?.name || null,
    bankToken: Bank?.token || null,
    kinderGardenName: School?.name || '',
    kinderGardenAddress: School?.address || '',
    contactFirstName: Contact?.name || '',
    contactMiddleName: Contact?.patronymic || null,
    contactSurname: Contact?.surname || '',
    contactPhoneNumber: Contact?.phone || '',
    contactEmail: Contact?.email || '',
    isActive: Boolean(School?.active),
    currency: School?.currency?.toUpperCase() || '',
    paymentSystem: School?.paymentSystem || null,
    ...(password ? {password} : {})
  }
};

export const getPaymentStatus = (
  current: Nullable<string>,
  expiry: Nullable<string>
): {
  value: number,
  left: number,
  total: number,
  status: Payment['status'],
} => {
  let status: PaymentType['status'] = 'expired';
  const complete = utcToZonedTime(new Date(current || Date.now()), 'UTC');
  const expired = utcToZonedTime(new Date(expiry || Date.now()), 'UTC');

  const total = differenceInDays(expired, complete);
  const left = differenceInDays(expired, new Date());

  let value = ((total - left) / total) * 100;

  if(left < 0) {
    value = 0;
  }


  if(left > 0 && left <= 5) {
    status = 'wait'
  } else if(left > 5) {
    status = 'complete'
  }

  if(current && expiry === null) {
    status = 'complete'
  }

  return {
    value,
    left,
    total,
    status: status as Payment['status']
  };
}

export const childResponseToState = (person: ChildResponsePayload): Child => {
  const {
    id,
    firstName,
    middleName,
    surname,
    createdAt,
    updatedAt,
    groupId,
    birthday,
    kinderGardenId,
    specialNotice,
    parentType,
    Group,
    Parent,
    sex,
    payedAt,
    payedUpTo,
    Lessons,
    curseCost,
    Tags,
  } = person;

  const status = getPaymentStatus(payedAt, payedUpTo)?.status;

  return {
    id,
    surname,
    name: firstName,
    patronymic: middleName || '',
    group: groupId,
    createdAt,
    updatedAt,
    birthday: birthday ? new Date(birthday) : null,
    schoolId: kinderGardenId,
    gender: sex,
    note: specialNotice,
    Group: {
      id: Group?.id || null,
      name: Group?.name || null,
    },
    Tags: Tags?.map((el) => el.title) || [],
    Trustee: {
      id: Parent?.id || null,
      surname: Parent?.surname || null,
      name: Parent?.firstName,
      patronymic: Parent?.middleName || null,
      email: Parent?.email || null,
      phone: Parent?.phoneNumber || null,
      relationship: parentType || null,
    },
    payment: {
      value: curseCost,
      date:  {
        current: payedAt,
        expiry: payedUpTo,
      },
      status,
      type: 'card',
      comment: '',
    },
    Lessons: Lessons?.map((lesson) => {
      const {id, type, name, LessonChild} = lesson;

      return {
        id,
        type,
        name,
        payment: {
          date: {
            current: LessonChild.payedAt,
            expiry: LessonChild.payedUpTo
          }
        },
      }
    }) || null
  }
};

export const childStateToRequest = (
  person: Pick<Child, 'name' | 'surname' | 'patronymic' | 'birthday' | 'note' | 'payment' | 'gender' | 'Trustee' | 'Group' | 'Tags'>
): ChildRequestPayload => {
  const {
    surname,
    name,
    patronymic,
    birthday,
    note,
    Group,
    Trustee,
    gender,
    payment,
    Tags
  } = person;

  return {
    surname: surname,
    firstName: name,
    middleName: patronymic || null,
    birthday: birthday ? format(new Date(birthday), SERVER_DATE_FORMAT) : null,
    parentId: Trustee.id,
    parentType: Trustee.relationship,
    groupId: Group.id,
    sex: gender,
    tags: Tags || [],
    curseCost: payment?.value || null,
    ...(note ? {specialNotice: note} : {}),
  }
}

export const postResponseToState = (post: PostResponsePayload): Post => {
  const {
    id,
    name,
    text,
    type,
    media,
    createdAt,
    updatedAt,
    kinderGardenId,
    // isAnonymous,
    isMultipleVariants,
    VoteVariants,
    Groups
  } = post;

  return {
    id,
    name,
    text,
    type,
    media,
    schoolId: kinderGardenId,
    createdAt,
    updatedAt,
    multiple: isMultipleVariants,
    anonymous: true, // isAnonymous,
    Groups: Groups?.map((el) => groupsResponseToState(el)) || null,
    Answers: VoteVariants,
  }
}

export const postStateToRequest = (post: Post): VoteRequestPayload | NewsRequestPayload  => {
  const {
    name,
    type,
    text,
    media,
    Groups,
    anonymous,
    Answers,
    multiple,
  } = post;

  const groupIds = Groups?.map((el) => el.id || 0) || null;

  if(type === 'vote') {
    return {
      name,
      type,
      isAnonymous: anonymous,
      isMultipleVariants: multiple,
      voteVariants: Answers?.map((el) => el.answer || '') || null,
      ...(media ? {media} : {}),
      ...(text ? {text} : {}),
      groupIds,
    }
  }

  return {
    name,
    type,
    ...(text ? {text} : {}),
    ...(media ? {media} : {}),
    groupIds,
  }
}

export const teacherResponseToState = (person: TeacherResponsePayload): Teacher => {
  const {
    id,
    firstName,
    middleName,
    surname,
    createdAt,
    updatedAt,
    phoneNumber,
    kinderGardenId,
    email,
    sex,
    position,
    birthday,
    media,
    rateGroupLesson,
    rateIndividualLesson,
    salary
  } = person;

  return {
    id,
    surname: surname || null,
    name: firstName || null,
    patronymic: middleName || null,
    phone: phoneNumber || null,
    schoolId: kinderGardenId || null,
    email: email || null,
    createdAt: createdAt || null,
    updatedAt: updatedAt || null,
    birthday: birthday ? new Date(birthday) : null,
    position: position || null,
    gender: sex || 'male' as Gender,
    media: media || null,
    rateGroupLesson,
    rateIndividualLesson,
    salary
  }
};

export const teacherStateToRequest = (person: Teacher): TeacherRequestPayload => {
  const {
    surname,
    name,
    patronymic,
    email,
    phone,
    gender,
    position,
    birthday,
    media,
    salary,
    rateGroupLesson,
    rateIndividualLesson,
  } = person;

  const groups = null;

  //TODO change to real values
  //@ts-ignore
  return {
    surname: surname,
    firstName: name,
    middleName: patronymic || null,
    email: email,
    phoneNumber: phone,
    position,
    salary,
    rateGroupLesson,
    rateIndividualLesson,
    birthday: birthday && isValid(new Date(birthday)) ? format(new Date(birthday), SERVER_DATE_FORMAT) : null,
    sex: gender,
    ...(groups ? {groupsId: null} : {}),
    ...(media ? {media} : {}),
  }
}

export const userResponseToState = (person: UserResponsePayload): User => {
  const {
    id,
    firstName,
    middleName,
    surname,
    createdAt,
    updatedAt,
    phoneNumber,
    kinderGardenId,
    email,
    role,
    birthday,
  } = person;

  return {
    id,
    surname: surname || null,
    name: firstName || null,
    patronymic: middleName || null,
    phone: phoneNumber || null,
    schoolId: kinderGardenId || null,
    email: email || null,
    createdAt: createdAt || null,
    updatedAt: updatedAt || null,
    role: role || null,
    password: null,
    birthday: birthday ? new Date(birthday) : null,
  }
};

export const userStateToRequest = (person: User): UserRequestPayload => {
  const {
    surname,
    name,
    patronymic,
    email,
    phone,
    password,
    birthday
  } = person;

  return {
    surname: surname,
    firstName: name,
    middleName: patronymic || null,
    email: email || null,
    phoneNumber: phone || null,
    birthday: birthday ? format(new Date(birthday), SERVER_DATE_FORMAT) : null,
    ...(password ? {password} : {})
  }
}

export const lessonResponseToState = (lesson: LessonResponsePayload): Lesson => {
  const {
    id,
    name,
    description,
    kinderGardenId,
    type,
    quantityPlaces,
    costPerMonth,
    costPerOnce,
    avatar,
    media,
    Children,
    duration,
  } = lesson;

  return {
    id,
    name,
    description,
    duration,
    type,
    places: quantityPlaces,
    cost: costPerMonth,
    costSingle: costPerOnce,
    cover: avatar ? [avatar] : null,
    media,
    schoolId: kinderGardenId,
    Children: Children?.map((el) => childResponseToState(el)) || null,
    createdAt: null,
    updatedAt: null,
  }
};

export const lessonStateToRequest = (lesson: Lesson): LessonRequestPayload => {
  const {
    id,
    name,
    description,
    type,
    places,
    cost,
    costSingle,
    cover,
    media,
    duration
  } = lesson;

  let avatar: null | string = null;
  if(cover && cover?.length > 0) {
    avatar = cover[0]
  }

  return {
    id,
    name,
    description,
    type,
    quantityPlaces: places,
    costPerMonth: cost,
    duration,
    avatar,
    ...(costSingle ? {costPerOnce: costSingle} : {}),
    ...(media ? {media} : {}),
  }
};

export const scheduleLessonResponseToState = (lesson: ScheduleResponsePayload): ScheduleLesson => {
  const {
    id,
    kinderGardenId,
    Children,
    schedule,
    Groups,
    startDate,
    Teacher,
    Lesson,
    quantity,
  } = lesson;

  const scheduleItem = initialScheduleItem()

  let Schedule: Array<ScheduleItem> = [scheduleItem];
  if(schedule && schedule.length > 0) {
    Schedule = schedule?.map((el) => {

      const timeZone = getTimeZoneName();
      const date = fromHHmmToDateUTC(el?.startTime)
      const time = utcToZonedTime(date || Date.now(), timeZone);

      return {
        day: el.dayOfWeek as DayFormat,
        duration: el.duration,
        time
      }
    });
  }


  return {
    id,
    Teacher: Teacher ? teacherResponseToState(Teacher) : null,
    Lesson: Lesson ? lessonResponseToState(Lesson) : null,
    Schedule,
    quantity,
    schoolId: kinderGardenId,
    Children: Children?.map((el) => childResponseToState(el)) || null,
    Groups: Groups?.map((el) => groupsResponseToState(el)) || null,
    startAt: new Date(startDate || Date.now()),
    createdAt: null,
    updatedAt: null,
  }
};

export const scheduleLessonStateToRequest = (lesson: ScheduleLesson): ScheduleLessonRequestPayload => {
  const {
    id,
    Children,
    Groups,
    Schedule,
    quantity,
    Lesson,
    Teacher,
    startAt,
  } = lesson;


  let childrenIds = null;
  let groupIds = null;
  if(Children && Children.length > 0) {
    childrenIds = Children?.map((el) => el.id || 0) || null;
  }
  if(Groups && Groups.length > 0) {
    groupIds = Groups?.map((el) => el.id || 0) || null;
  }


  return {
    id,
    quantity,
    childrenIds,
    groupIds,
    lessonId: Lesson?.id || null,
    teacherId: Teacher?.id || null,
    startDate: startAt ? format(new Date(startAt), SERVER_DATE_FORMAT) : null,
    schedule: Schedule?.map((el) => {
      let startTime: string | null = null;
      if(el.time) {
        startTime = format(utcToZonedTime(el.time, 'UTC'), TIME_FORMAT)
      }
      return {
        dayOfWeek: el.day as DayFormat,
        //TODO remove from client side
        duration: Lesson?.duration || null,
        startTime
      }
    }) || null
  }

};

export const invoiceStatusResponseToState = (status: InvoiceResponseStatus): InvoiceStatus => {
  switch (status) {
    case 'in_progress':
      return 'wait';
    case 'complete':
      return 'complete';
    default:
      return 'expired';
  }
}

export const invoiceResponseToState = (invoice: InvoiceResponsePayload): Invoice => {
  const {
    id,
    date,
    description,
    kinderGardenId,
    status,
    InvoiceServices,
    createdAt,
    updatedAt,
    Child
  } = invoice;

  return {
    id,
    date: date ? new Date(date) : null,
    description,
    status: invoiceStatusResponseToState(status),
    schoolId: kinderGardenId,
    Child: Child ? childResponseToState(Child): null,
    Services: InvoiceServices?.map((el) => {
      return serviceResponseToState(el);
    }) || null,
    createdAt,
    updatedAt,
  }
}

export const invoiceStateToRequest = (invoice: Invoice): InvoiceRequestPayload => {
  const {
    id,
    description,
    status,
    date,
    Child,
    Services,
  } = invoice;

  return {
    id,
    childId: Child?.id || null,
    date: date ? format(new Date(date), SERVER_DATE_FORMAT) : null,
    services: Services?.map((el) => serviceStateToRequest(el)) || [],
    status: invoiceStatusStateToRequest(status),
    ...(description ? {description} : {}),
  }
}

export const expenseResponseToState = (expense: ExpenseResponsePayload): Expense => {
  const {
    id,
    date,
    amount,
    name,
    comment,
    createdAt,
    updatedAt,
    media,
    User,
  } = expense;

  return {
    id,
    name,
    amount,
    date: date ? new Date(date) : null,
    comment,
    createdAt,
    updatedAt,
    media,
    User: personResponseToState(User)
  }
}

export const expenseStateToRequest = (expense: Expense): ExpenseRequestPayload => {
  const {
    id,
    amount,
    name,
    date,
    comment,
    media,
  } = expense;

  return {
    id,
    amount,
    name,
    comment,
    date: date ? format(new Date(date), SERVER_DATE_FORMAT) : null,
    media:  media || null
  }
}

export const complaintStatusResponseToState = (status: ComplaintResponsePayload['status']): Complaint['status'] => {
  switch (status) {
    case 'in_progress':
      return 'wait';
    case 'complete':
      return 'complete';
    default:
      return 'wait';
  }
}

export const complaintStatusStateToRequest = (status: Complaint['status'] | string): ComplaintResponsePayload['status'] => {
  switch (status) {
    case 'wait':
      return 'in_progress';
    case 'complete':
      return 'complete';
    default:
      return 'in_progress';
  }
}

export const complaintResponseToState = (service: ComplaintResponsePayload): Complaint => {
  const {
    id,
    kinderGardenId,
    status,
    authorId,
    description,
    isAnonymous,
    createdAt,
    updatedAt,
    User,
  } = service;

  return {
    id,
    schoolId: kinderGardenId,
    anonymous: Boolean(isAnonymous),
    authorId,
    status: complaintStatusResponseToState(status),
    description,
    createdAt,
    updatedAt,
    User: {
      id: User?.id || 0,
      name: User?.firstName || null,
      surname: User?.surname || null,
      patronymic: User?.middleName || null,
    }
  }
};

export const taskResponseToState = (task: TaskResponsePayload): Task => {
  const {
    kinderGardenId,
    Users,
    Creator,
    TaskLogs,
    ...rest
  } = task;

  return {
    ...rest,
    schoolId: kinderGardenId,
    Creator: {
      id: Creator?.id || null,
      name: Creator?.firstName || '',
      surname: Creator?.surname || '',
      patronymic: Creator?.middleName || '',
    },
    Users: Users?.map((el) => ({
      id: el.id,
      name: el.firstName,
      surname: el.surname,
      patronymic: el?.middleName || '',
    })) || null,
    TaskLogs: TaskLogs?.map((el) => ({
      ...el,
      Editor: {
        id: el?.Editor?.id || null,
        name: el?.Editor?.firstName || '',
        surname: el?.Editor?.surname || '',
        patronymic: el?.Editor?.middleName || '',
      }
    }))
  }
};

export const taskStateToRequest = (task: Task): TaskRequestPayload => {
  const {
    title,
    description,
    startAt,
    finishAt,
    Users,
    status,
  } = task;


  let adminIds = null;
  if(Users && Users.length > 0) {
    adminIds = Users?.map((el) => el.id || 0) || null;
  }

  return {
    title,
    description,
    startAt: startAt ? format(new Date(startAt), SERVER_DATE_TIME_FORMAT) : null,
    finishAt: finishAt ? format(new Date(finishAt), SERVER_DATE_TIME_FORMAT) : null,
    adminIds,
    status,
  }
};

export const serviceResponseToState = (service: ServiceResponsePayload): Service => {
  const {
    lessonId,
    title,
    price,
    cost,
    type,
    quantity,
    createdAt,
    updatedAt,
    Invoice,
  } = service;


  return {
    id: lessonId || -1,
    price,
    name: title,
    cost,
    type,
    quantity,
    createdAt,
    updatedAt,
    ...(Invoice ? {
      Invoice: invoiceResponseToState(Invoice)
    } : {}),
  }
};

export const serviceStateToRequest = (service: Service): ServiceRequestPayload => {
  const {
    id,
    price,
    cost,
    quantity,
    type
  } = service;

  return {
    price,
    cost,
    quantity,
    type: type === 'common' ? type : 'extra',
    lessonId: id || null,
  }
}

export const invoiceStatusStateToRequest = (status: InvoiceStatus | string): InvoiceResponseStatus => {
  switch (status) {
    case 'wait':
      return 'in_progress';
    case 'complete':
      return 'complete';
    default:
      return 'in_progress';
  }
}

export const scheduleLessonsToCalendarEvents = (entities: {
  [key: string] : Array<any>
}): Array<any> => {
  return Object.entries(entities).map((el) => {
    const [date, elements]: [string, Array<Partial<LessonResponsePayload>>] = el;
    return elements?.map((item) => {
      const currentDate = new Date(date?.replaceAll('-', '/') || Date.now()); // Safari date fix

      const start = new Date(Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        //@ts-ignore
        parseInt(item?.startTime?.split(':')[0] || '', 10),
        //@ts-ignore
        parseInt(item?.startTime?.split(':')[1] || '', 10),
        currentDate.getSeconds(),
        currentDate.getMilliseconds(),
      ))


      const end = new Date(Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        //@ts-ignore
        parseInt(item?.endTime?.split(':')[0] || '', 10),
        //@ts-ignore
        parseInt(item?.endTime?.split(':')[1] || '', 10),
        currentDate.getSeconds(),
        currentDate.getMilliseconds(),
      ));

      let color = blueGrey[100] as string;
      const lessonType = LESSON_TYPES.find((el) => el.value === item?.type);

      if(lessonType) {
        color = lessonType.color;
      }

      const id = `date-${date}-schedule-${item.scheduleId}-id-${item.id}`;
      const event: any  = {
        id,
        title: item.name,
        start: start,
        end: end,
        backgroundColor: color,
        borderColor: color,
        extendedProps: {
          type: item.type,
          scheduleId: item.scheduleId,
        }
      }

      //@ts-ignore
      if(!item?.endTime || item?.endTime === '00:00:00') {
        event.allDay = true;
      }

      return event;
    }) || [];
  }).flat();
}

export const tasksToCalendarEvents = (entities: {
  [key: string] : Array<any>
}): Array<any> => {

  return Object.entries(entities).map((el) => {
    const [value, data]: [string, any] = el;

    const events = [] as Array<any>;

    if(value === 'tasks' && Array.isArray(data)) {
      const tasks = data?.map((el: TaskResponsePayload) => {
        const task = taskResponseToState(el);

        const start = utcToZonedTime(new Date(task.startAt || Date.now()), 'UTC');
        const end = utcToZonedTime(new Date(task.finishAt ||  Date.now()), 'UTC');

        let color = teal[100] as string;
        const taskType = TASK_TYPES.find((el) => el.value === task.status);

        if(taskType) {
          color = taskType.color
        }

        const alphaColor =  alpha(color, 0.26);

        const event: any  = {
          id: task.id,
          title: task.title,
          start: start,
          end: end,
          backgroundColor: alphaColor,
          borderColor: color,
          url: `/dashboard/tasks/edit/${task.id}`,
          extendedProps: {
            type: 'task',
            description: task.description,
            status: task.status,
            Users: task.Users,
            color: color,
          }
        }

        return event;
      });

      events.push(...tasks)
    }


    if(!Array.isArray(data)) {
      const birthdays = [
        ...(data?.childrenBirthdays ?? [])?.map((el: any) => ({...el, category: 'children'})),
        ...(data?.parentsBirthdays ?? [])?.map((el: any) => ({...el, category: 'parents'}))
      ].map((item: Partial< PersonResponsePayload & { category: string}>) => {
        const start = new Date(value?.replaceAll('-', '/') || Date.now()); // Safari date fix

        let color = blueGrey[100] as string;
        const taskType = TASK_TYPES.find((el) => el.value === item.category);

        if(taskType) {
          color = taskType.color
        }

        const alphaColor =  alpha(color, 0.26);

        const event: any  = {
          id: item.id,
          title: renderFullName({
            surname: item?.surname || null,
            name: item?.firstName || null,
            patronymic: item?.middleName || null,
          }),
          start: start,
          allDay: true,
          backgroundColor: alphaColor,
          borderColor: color,
          url: `/dashboard/${item.category}/edit/${item.id}`,
          extendedProps: {
            type: 'birthday',
            category: item.category,
            birthday: item?.birthday,
            color: color,
          }
        }

        return event;
      })

      events.push(...birthdays)
    }

    return events;
  }).flat();
}

export const getMediaUri = (file: string):string => {
  if(file) {
    return `${MEDIA_PATH}/${file}`;
  }
  return '';
}

export const getMedia = (media: Media | string | null | undefined) => {
  if(Array.isArray(media)) {
    return getMediaUri(media[0] || '')
  }

  return getMediaUri(media || '')
}

export const a11yProps = (index: number | string) => {
  return {
    id: `auth-tab-${index}`,
    'aria-controls': `auth-tabpanel-${index}`,
  };
}

export const renderFullName = (
  person: Nullable<Partial<Person>>,
  full: boolean = false,
  alternative: boolean = false
) => {
  let value = '';
  const isSupperAdmin = person?.role === 'super-admin' || (person?.id && (!person?.name && !person?.surname));

  if(person) {
    value = `${person.surname} ${person.name}`;

    if(full) {
      return value + ` ${person?.patronymic || ''}`
    }

    if(isSupperAdmin && alternative) {
      value = i18next.t('roles.super-admin', {ns: 'common'});
    }
  }

  return value;
}

export const renderInitials = (person: Nullable<Partial<Person>>) => {
  const name = renderFullName(person);
  return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
}

interface PersonAge {
  value: {
    years: number,
    months: number,
  },
  formatted:  React.ReactNode;
}

export const getAge = (birthday: Date | null | undefined): PersonAge | null => {
  if(birthday) {
    const age = differenceInMonths(new Date(), birthday);
    const years =  Math.floor(age / 12);
    const months =  age % 12;
    return {
      value: {
        years,
        months,
      },
      formatted: (
        <Translation>
          {(t) => {
            return `${t('measure.year.plural', {count: years})}, ${t('measure.month.plural', {count: months})}`
          }}
        </Translation>
      )
    }
  }
  return null;
};

export const getDateFnsLocale = (locale?: string): Locale => {
  switch (locale) {
    case 'ua':
      return ukLocale;
    case 'ru':
      return ruLocale;
    case 'en':
      return enUsLocale;
    case 'es':
      return esLocale;
    default:
      return enUsLocale;
  }
}

export const getDuration = (time: number, locale?: string): React.ReactNode => {
  const dateFnsLocale = getDateFnsLocale(locale)
  const days = Math.floor(time / 24 / 60);
  const hours =  Math.floor((time / 60) % 24);
  const minutes = time % 60;

  return formatDuration({
    days,
    hours,
    minutes,
  }, {
    zero: false,
    ...(dateFnsLocale ? {locale: dateFnsLocale} : {})
  });
};



export const getDayObject = (date: Date, locale?: string):DayValue => {
  const dateFnsLocale = getDateFnsLocale(locale);

  const label = format(date, 'EEEE', {
    ...(dateFnsLocale ? {locale: dateFnsLocale} : {})
  });

  return {
    value: format(date, 'eee').toLowerCase() as DayFormat,
    label: label.charAt(0).toUpperCase() + label.slice(1),
  }
}

export const getWeekDays = (locale?: string): Array<DayValue> => {
  return eachDayOfInterval({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date())
  }).map((d) => {
    return getDayObject(d, locale);
  })
}


export const downloadFileLink = (href: string, name: string): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = href;
      link.download = name.toLowerCase();
      link.target = '_blank'
      link.click();

      resolve();

    }, 1000)
  })
}

export const apiErrorToValue = (value: string): string => {
  const name = value.split('.')[0];
  const data: {
    [key: string] : string
  } = {
    'firstName':'name',
    'middleName':'patronymic',
    'phoneNumber':'phone',
    'kinderGardenId':'schoolId',
    'sex' : 'gender',
    'bankName': 'bank',
    'bankToken': 'token',
    'contactEmail': 'email',
    'contactFirstName': 'name',
    'contactMiddleName': 'patronymic',
    'contactSurname': 'surname',
    'contactPhoneNumber': 'phone',
  }

  if(name in data) {
    return value.replace(name, data[name]);
  }

  return value || ''
}

function deepen(obj: any) {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      //@ts-ignore
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    //@ts-ignore
    target[parts[0]] = obj[objectPath]
  }

  return result;
}


export const apiErrorsToFormik = (errors: any, fields: {
  [key: string] : string
}): {
  [key: string] : string
} | undefined => {
  const begin = 'body.';
  if(Array.isArray(errors)){
    const currentErrors = errors.filter((err) => err?.attribute)
    const obj = currentErrors.reduce(function (obj, item) {
      let key = item.attribute.replace(begin, '');
      if(key.split('.').length === 1) {
        if(key in fields) {
          key = fields[key]
        }
        obj[key] = apiErrorToValue(item.error);
      } else {
        console.log('multiple cases');
      }
      return obj;
    }, {});
    return deepen(obj);
  }

  return undefined;
}

export const generatePassword = (
  passwordLength = 12,
  passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
): string => {
  // @ts-ignore
  return [...window?.crypto.getRandomValues(new Uint32Array(passwordLength))]
    .map(x => passwordChars[x % passwordChars.length])
    .join('');
}


export const getFirstSchoolId = (array: Nullable<Array<School>>): number | null => {
  if(Array.isArray(array) && array.length > 0) {
    return array[0].id;
  }

  return null
}
