import {createAction, createSlice} from '@reduxjs/toolkit';
import {AuthUser, Nullable, School} from '../../types';
import {
  setCurrentSchool,
  signInFail,
  signInRequest,
  signInSuccess,
  signOutFail,
  signOutRequest,
  signOutSuccess
} from './reducers';
import {resetState} from '../actions';

export interface AuthState {
  loading: boolean;
  error: string | null;
  token: string | null;
  expires: string | null;
  user: AuthUser;
  schools: Nullable<Array<Partial<School>>>
}

const initialState: AuthState = {
  loading: false,
  error: null,
  token: null,
  expires: null,
  user: null,
  schools: null,
}

export const signIn = createAction<any | undefined>("auth/signIn");
export const signOut = createAction("auth/signOut");
export const authCheck = createAction("auth/check");

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signInRequest,
    signInSuccess,
    signInFail,
    signOutRequest,
    signOutSuccess,
    signOutFail,
    setCurrentSchool,
  },
  extraReducers: {
    [resetState.type]: () => {
      return initialState;
    },
  },
});

export default slice;
