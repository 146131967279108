import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';

const Create = () => {

  const user = useSelector(userSelector);

  return (
    <ResourceCreate
      name="support"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
          const current = {
            title: values.title,
            description: values.description
          };
          //@ts-ignore
          return onCreate(current, () => onSuccess(values), onError, redirect)
        }

        const initialValues = {
          title: null,
          description: null,
          comment: null,
          status: null,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
