import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Errors from '../../pages/default/Errors';

const Default = () => {
  return (
    <Routes>
      <Route
        path="/500"
        element={<Errors statusCode={500}/>}
      />
      <Route
        path="/402"
        element={<Errors statusCode={402}/>}
      />
      <Route
        path="/403"
        element={<Errors statusCode={403}/>}
      />
      {['*', '/404'].map((path) => (
        <Route key={path} path={path} element={<Errors statusCode={404} />} />
      ))}
    </Routes>
  );
};

export default Default;
