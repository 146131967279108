import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {SxPropsObject} from '../../../../types';
import {useTranslation} from 'react-i18next';
import {ReactComponent as LogoImage} from '../../../../assets/images/logoLarge.svg';
import {Link} from 'react-router-dom';
import {Logo} from '../../../../styled';
import Form from './components/Form';

const Contact = () => {
  const {t} = useTranslation('pages');

  return (
    <Box id="contact" sx={styles.container}>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo
            size="large"
            component={Link}
            to="/"
            sx={{
              mb: 7,
            }}
          >
            <LogoImage/>
          </Logo>
          <Typography
            sx={styles.heading}
            variant="h2"
            color="text.primary"
            fontWeight={700}
          >
            {t('home.contact.heading')}
          </Typography>
          <Box sx={{ maxWidth: 480}}>
            <Form/>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: () => ({
    py: {
      sm: 15,
      xs: 10
    },
    position: 'relative',
    zIndex: 6,
    maxWidth: '100%',
    bgcolor: '#FAFAFA',
  }),
  heading: {
    textAlign: 'center',
    mb: 6,
  },
}

export default Contact;
