import React, {useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DATE_FORMAT, GENDERS, MAX_TEACHER_AGE, MIN_TEACHER_AGE, PREFERRED_COUNTRIES} from '../../../../../constants';
import MenuItem from '@mui/material/MenuItem';
import Files from '../../../../../components/Files';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {ContentTabs, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {apiErrorsToFormik, getMediaUri} from '../../../../../utils';
import {FormValues} from '../../types';
import Calendar from '../Calendar';
import {useSelector} from 'react-redux';
import {appSelector} from '../../../../../redux/app/selectors';
import {MuiTelInputCountry} from 'mui-tel-input/dist/shared/constants/countries';
import {MuiTelInput} from 'mui-tel-input';
import {NumericFormat} from 'react-number-format';

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'firstName':'name',
  'middleName':'patronymic',
  'phoneNumber':'phone',
  'kinderGardenId':'schoolId',
};

const Form = (props: Props) => {

  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const {countryCode} = useSelector(appSelector);
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`teachers.content.title.${mode}`, {ns: 'pages'})

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          isSubmitting
        }) => {

        const generalLeft = (
          <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
              {t('teachers.content.section.main', {ns: 'pages'})}
            </Typography>
            <Grid container spacing={{xs: 0, sm: 2}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="surname"
                  value={values.surname || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.surname && errors.surname)}
                  helperText={(
                    <ErrorMessage
                      name="surname"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.surname.label')}
                  placeholder={t('field.surname.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="name"
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={(
                    <ErrorMessage
                      name="name"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.name.label')}
                  placeholder={t('field.name.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="patronymic"
                  value={values.patronymic || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.patronymic && errors.patronymic)}
                  helperText={(
                    <ErrorMessage
                      name="patronymic"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.patronymic.label')}
                  placeholder={t('field.patronymic.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="email"
                  value={values.email || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={(
                    <ErrorMessage
                      name="email"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.email.label')}
                  placeholder={t('field.email.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTelInput
                  name="phone"
                  defaultCountry={countryCode as MuiTelInputCountry}
                  preferredCountries={PREFERRED_COUNTRIES}
                  value={values.phone || ''}
                  onChange={(value, info) => {
                    setFieldValue('phone', info.numberValue)
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={(
                    <ErrorMessage
                      name="phone"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.phone.label')}
                  placeholder={t('field.phone.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  format={DATE_FORMAT}
                  disableFuture
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      sx: styles.field,
                      name: "birthday",
                      onBlur: handleBlur,
                      error: Boolean(touched?.birthday && errors?.birthday),
                      helperText: (
                        <ErrorMessage
                          name="birthday"
                          render={(message) => t(message, {
                            ns: 'validation',
                            min: t('measure.year.plural', {
                              count: MIN_TEACHER_AGE
                            }),
                            max: t('measure.year.plural', {
                              count: MAX_TEACHER_AGE
                            })
                          })}
                        />
                      ),
                      margin: "normal",
                      required: false,
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      size: "medium",
                      autoComplete: 'off'
                    }
                  }}
                  value={values.birthday}
                  label={t('field.birthday.label')}
                  onChange={(value: any) => {
                    setFieldValue('birthday', value, true);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  sx={styles.field}
                  name="gender"
                  value={values.gender || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.gender && errors?.gender)}
                  helperText={(
                    <ErrorMessage
                      name="gender"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.gender.label')}
                  placeholder={t('field.gender.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => {
                      if (value as string === '') {
                        return (t('field.gender.placeholder'));
                      }
                      const current = GENDERS.find((el) => el.value === value)
                      return current?.label || '';
                    }
                  }}
                >
                  {GENDERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="position"
                  value={values.position || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched?.position && errors?.position)}
                  helperText={(
                    <ErrorMessage
                      name="position"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.position.label')}
                  placeholder={t('field.position.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumericFormat
                  sx={styles.field}
                  customInput={TextField}
                  allowNegative={false}
                  name="rateGroupLesson"
                  value={values.rateGroupLesson || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.rateGroupLesson && errors.rateGroupLesson)}
                  helperText={(
                    <ErrorMessage
                      name="rateGroupLesson"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.cost.group.label')}
                  placeholder={t('field.cost.group.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumericFormat
                  sx={styles.field}
                  customInput={TextField}
                  allowNegative={false}
                  name="rateIndividualLesson"
                  value={values.rateIndividualLesson || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.rateIndividualLesson && errors.rateIndividualLesson)}
                  helperText={(
                    <ErrorMessage
                      name="rateIndividualLesson"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.cost.individual.label')}
                  placeholder={t('field.cost.individual.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumericFormat
                  sx={styles.field}
                  customInput={TextField}
                  allowNegative={false}
                  name="salary"
                  value={values.salary || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.salary && errors.salary)}
                  helperText={(
                    <ErrorMessage
                      name="salary"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.salary.label')}
                  placeholder={t('field.salary.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
              </Grid>
            </Grid>
          </>
        );

        const generalRight = (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2}}>
              {t('teachers.content.section.photo', {ns: 'pages'})}
            </Typography>
            <Files
              multiple={false}
              data={values?.media?.map((el) => {
                return {
                  value: el,
                  uri: getMediaUri(el),
                }
              }) || null}
              onUpload={(items) => {
                setFieldValue('media', items);
              }}
              onRemove={() => {
                return setFieldValue('media', []);
              }}
            />
          </>
        )

        const tabs: ContentTabs = [
          {
            label: t('teachers.tabs.main', {ns: 'pages'}),
            content: {
              left: generalLeft,
              right: generalRight,
            }
          }
        ];

        if(mode === 'edit') {
          tabs.push({
            label: t('teachers.tabs.schedule', {ns: 'pages'}),
            content: (
              <Calendar/>
            ),
          })
        }

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.teacher') : t('edit.teacher'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
