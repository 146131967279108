import React from 'react';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import {renderFullName, schoolResponseToState, statusColor} from '../../../../utils';
import Content from '../../../../layouts/Main/components/Content';
import Chip from '@mui/material/Chip';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.name.school.label'),
      flex: 1,
      minWidth: 320,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2">
            {row?.name}
          </Typography>
        );
      }
    },
    {
      field: 'contact',
      headerName: t('field.contact.label'),
      flex: 1,
      minWidth: 320,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2">
            {renderFullName(row?.Contact)}
          </Typography>
        );
      }
    },
    {
      field: 'status',
      headerName: t('field.status.school.label'),
      flex: 0,
      minWidth: 220,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {active} = params?.row || {}
        const chipColor = statusColor(active);

        return (
          <Chip
            label={t(`school.status.${active ? 'active' : 'deactivated'}`)}
            color={chipColor}
            variant="filled"
            sx={{
              borderRadius: 2
            }}
          />
        )
      }
    },
  ];

  return (
    <Content title={t('schools.list.title', {ns: 'pages'})}>
      <ResourceList
        name="schools"
        pathModifier={() => {
          return `/kinder-gardens`
        }}
        title={t('schools.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any):any => {
          return entities.map((person: any) => {
            return schoolResponseToState(person);
          });
        }}
        actions={() => null}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          ...(sortParam ? {sort: sortParam} : {}),
          ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        rowActions={{
          mode: 'extend',
          extended: [
            {
              name: 'delete',
              disabled: true,
            },
          ]
        }}
        onRowClick={(params) => {
          navigate(`/dashboard/schools/edit/${params.id}`)
        }}
      />
    </Content>
  );
};

export default List;
