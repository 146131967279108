import React from 'react';
import HeroSection from './sections/Hero';
import AdvantagesSection from './sections/Advantages';
import PossibilitiesSection from './sections/Possibilities';
import ApplicationSection from './sections/Application';
import PricingSection from './sections/Pricing';
import ContactSection from './sections/Contact';
import {useSelector} from 'react-redux';
import {authSelector} from '../../redux/auth/selectors';
import {Navigate} from 'react-router-dom';

const Home = () => {
  const {user} = useSelector(authSelector);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <HeroSection/>
      <AdvantagesSection/>
      <PossibilitiesSection/>
      <ApplicationSection/>
      <PricingSection/>
      <ContactSection/>
    </>
  );
};

export default Home;
