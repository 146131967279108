import React from 'react';
import { withAnimatedRoute } from '../../hoc';
import HomePage from '../../pages/Home';

const Home = () => {
  return (
    <HomePage/>
  );
};

export default withAnimatedRoute(Home);
