import React from 'react';
import {SxPropsObject} from '../../../../types';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Link, useNavigate} from 'react-router-dom';
import ResourceList from '../../../../components/Resource/components/List';
import {personResponseToState, renderFullName} from '../../../../utils';
import Content from '../../../../layouts/Main/components/Content';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Sorting from '../../../../components/Sorting';
import useSortParams from '../../../../hooks/useSortParams';

const List = () => {
  const {t} = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const {
    sort: sortParam,
    order: sortOrderParam,
    onChangeSort,
    onChangeSortOrder
  } = useSortParams();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('field.name.label'),
      flex: 1,
      minWidth: 320,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        const {row} = params;
        return (
          <Typography variant="subtitle2">
            {renderFullName(row)}
          </Typography>
        );
      }
    },
    {
      field: 'phone',
      headerName: t('field.phone.label'),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'email',
      headerName: t('field.email.label'),
      flex: 1,
      minWidth: 240,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <Content title={t('admins.list.title', {ns: 'pages'})}>
      <ResourceList
        name="admins"
        pathModifier={`/kinder-garden/${user?.schoolId}`}
        title={t('admins.list.subtitle', {ns: 'pages'})}
        columns={columns}
        transformer={(entities: any):any => {
          return entities.map((person: any) => {
            return personResponseToState(person);
          });
        }}
        actions={() => (
          <Button
            sx={styles.button}
            size="medium"
            variant="contained"
            component={Link}
            to="/dashboard/admins/create"
          >
            {t('add.value')}
          </Button>
        )}
        sorting={(
          <Sorting
            values={[
              {
                label: t('field.date.label'),
                value: 'createdAt',
              },
            ]}
            sort={sortParam}
            order={sortOrderParam}
            onChangeSort={onChangeSort}
            onChangeOrder={onChangeSortOrder}
            sx={{
              mr: 0,
              ml: 3,
            }}
          />
        )}
        defaultParams={{
          // ...(sortParam ? {sort: sortParam} : {}),
          // ...(sortOrderParam ? {sortOrder: sortOrderParam} : {})
        }}
        onRowClick={(params) => {
          navigate(`/dashboard/admins/edit/${params.id}`)
        }}
      />
    </Content>
  );
};

const styles: SxPropsObject = {
  button: () => ({
    maxWidth: 188,
    ml: 4,
  }),
}

export default List;
