import React from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import PostsList from '../../pages/dashboard/Posts/List';
import PostsCreate from '../../pages/dashboard/Posts/Create';
import PostsEdit from '../../pages/dashboard/Posts/Edit';
import LessonsList from '../../pages/dashboard/Lessons/List';
import LessonsCreate from '../../pages/dashboard/Lessons/Create';
import LessonsEdit from '../../pages/dashboard/Lessons/Edit';
import ScheduleList from '../../pages/dashboard/Schedule/List';
import ScheduleCreate from '../../pages/dashboard/Schedule/Create';
import ScheduleEdit from '../../pages/dashboard/Schedule/Edit';
import GroupsList from '../../pages/dashboard/Groups/List';
import GroupsCreate from '../../pages/dashboard/Groups/Create';
import GroupsEdit from '../../pages/dashboard/Groups/Edit';
import AdminsList from '../../pages/dashboard/Admins/List';
import AdminsCreate from '../../pages/dashboard/Admins/Create';
import AdminsEdit from '../../pages/dashboard/Admins/Edit';
import ParentsList from '../../pages/dashboard/Parents/List';
import ParentsCreate from '../../pages/dashboard/Parents/Create';
import ParentsEdit from '../../pages/dashboard/Parents/Edit';
import OwnersList from '../../pages/dashboard/Owners/List';
import OwnersCreate from '../../pages/dashboard/Owners/Create';
import OwnersEdit from '../../pages/dashboard/Owners/Edit';
import ChildrenList from '../../pages/dashboard/Children/List';
import ChildrenCreate from '../../pages/dashboard/Children/Create';
import ChildrenEdit from '../../pages/dashboard/Children/Edit';
import TeachersList from '../../pages/dashboard/Teachers/List';
import TeachersCreate from '../../pages/dashboard/Teachers/Create';
import TeachersEdit from '../../pages/dashboard/Teachers/Edit';
import Payments from '../../pages/dashboard/Payments';
import InvoicesList from '../../pages/dashboard/Invoices/List';
import InvoicesCreate from '../../pages/dashboard/Invoices/Create';
import InvoicesEdit from '../../pages/dashboard/Invoices/Edit';
import ExpensesList from '../../pages/dashboard/Expenses/List';
import ExpensesCreate from '../../pages/dashboard/Expenses/Create';
import ExpensesEdit from '../../pages/dashboard/Expenses/Edit';
import ComplaintsList from '../../pages/dashboard/Complaints/List';
import SupportList from '../../pages/dashboard/Support/List';
import SupportCreate from '../../pages/dashboard/Support/Create';
import SupportEdit from '../../pages/dashboard/Support/Edit';
import TasksList from '../../pages/dashboard/Tasks/List';
import TasksCreate from '../../pages/dashboard/Tasks/Create';
import TasksEdit from '../../pages/dashboard/Tasks/Edit';
import SchoolsList from '../../pages/dashboard/Schools/List';
import SchoolsEdit from '../../pages/dashboard/Schools/Edit';
import QuestionsList from '../../pages/dashboard/Questions/List';
import QuestionsView from '../../pages/dashboard/Questions/View';
import Settings from '../../pages/dashboard/Settings';
import usePermission from '../../hooks/usePermission';
import {ROUTES_PERMISSIONS} from '../../constants';

interface ProtectedRouteProps {
  permissions?: string[];
}
const ProtectedRoutes = (props: ProtectedRouteProps) => {
  const {permissions} = props;
  const permission = usePermission();
  const allow = permission(permissions);

  if(!allow) {
    return <Navigate to="/403"/>
  }

  return <Outlet/>
};

const Dashboard = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/dashboard/posts/list"/>}/>
      <Route path="posts" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<PostsList/>}/>
        <Route path="create" element={<PostsCreate/>}/>
        <Route path="edit/:id" element={<PostsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="children" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<ChildrenList/>}/>
        <Route path="create" element={<ChildrenCreate/>}/>
        <Route path="edit/:id" element={<ChildrenEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="groups" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<GroupsList/>}/>
        <Route path="create" element={<GroupsCreate/>}/>
        <Route path="edit/:id" element={<GroupsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="payments" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.payments}/>}>
        <Route path="invoices">
          <Route element={<Payments/>}>
            <Route path="list" element={<InvoicesList/>}/>
          </Route>
          <Route path="create" element={<InvoicesCreate/>}/>
          <Route path="edit/:id" element={<InvoicesEdit/>}/>
          <Route path="" element={(
            <Navigate to="list"/>
          )}/>
        </Route>
        <Route path="expenses">
          <Route element={<Payments/>}>
            <Route path="list" element={<ExpensesList/>}/>
          </Route>
          <Route path="create" element={<ExpensesCreate/>}/>
          <Route path="edit/:id" element={<ExpensesEdit/>}/>
          <Route path="" element={(
            <Navigate to="list"/>
          )}/>
        </Route>
        <Route path="" element={(
          <Navigate to="invoices"/>
        )}/>
      </Route>
      <Route path="lessons" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<LessonsList/>}/>
        <Route path="create" element={<LessonsCreate/>}/>
        <Route path="edit/:id" element={<LessonsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="schedules" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<ScheduleList/>}/>
        <Route path="create" element={<ScheduleCreate/>}/>
        <Route path="edit/:id" element={<ScheduleEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="owners" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.owners}/>}>
        <Route path="list" element={<OwnersList/>}/>
        <Route path="create" element={<OwnersCreate/>}/>
        <Route path="edit/:id" element={<OwnersEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="teachers" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<TeachersList/>}/>
        <Route path="create" element={<TeachersCreate/>}/>
        <Route path="edit/:id" element={<TeachersEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="parents" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<ParentsList/>}/>
        <Route path="create" element={<ParentsCreate/>}/>
        <Route path="edit/:id" element={<ParentsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="support" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.support}/>}>
        <Route path="list" element={<SupportList/>}/>
        <Route path="create" element={<SupportCreate/>}/>
        <Route path="edit/:id" element={<SupportEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="admins" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.admins}/>}>
        <Route path="list" element={<AdminsList/>}/>
        <Route path="create" element={<AdminsCreate/>}/>
        <Route path="edit/:id" element={<AdminsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="tasks" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.tasks}/>}>
        <Route path="list" element={<TasksList/>}/>
        <Route path="create" element={<TasksCreate/>}/>
        <Route path="edit/:id" element={<TasksEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="complaints" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.complaints}/>}>
        <Route path="list" element={<ComplaintsList/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="questions" element={<ProtectedRoutes permissions={undefined}/>}>
        <Route path="list" element={<QuestionsList/>}/>
        <Route path="view/:id" element={<QuestionsView/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="schools" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.schools}/>}>
        <Route path="list" element={<SchoolsList/>}/>
        <Route path="edit/:id" element={<SchoolsEdit/>}/>
        <Route path="" element={(
          <Navigate to="list"/>
        )}/>
      </Route>
      <Route path="settings" element={<ProtectedRoutes permissions={ROUTES_PERMISSIONS.settings}/>}>
        <Route index element={<Settings/>}/>
      </Route>
      <Route path="*" element={(
        <Navigate to="/errors/404" replace />
      )}/>
    </Routes>
  );
};

export default Dashboard;
