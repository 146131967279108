import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import {Complaint, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {renderFullName, statusColor} from '../../../../../utils';
import {format, parseISO} from 'date-fns';
import {DATE_FORMAT} from '../../../../../constants';
import Link from '@mui/material/Link';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material';

interface Props {
  entity: Complaint;
  onChangeStatus: (status: Complaint['status']) => void;
}

const CardComponent = (props: Props) => {
  const {entity, onChangeStatus} = props;
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const chipColor = statusColor(entity?.status ||'');

  const completed = entity.status === 'complete';
  const status = entity.status === 'wait' ? 'new' : entity.status;

  let sender = t('complaint.type.anonymous');

  if(!entity.anonymous) {
    sender = renderFullName(entity.User)
  }

  return (
    <>
      <Card sx={styles.card} variant="outlined" onClick={handleOpen}>
        <CardContent sx={styles.cardContent}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs="auto">
              <Typography
                variant="subtitle1"
                component="p"
                gutterBottom
              >
                {sender}
              </Typography>
              {entity.createdAt && (
                <Typography
                  variant="body2"
                  gutterBottom
                  color="text.gray"
                >
                  {format(parseISO(entity.createdAt), DATE_FORMAT)}
                </Typography>
              )}
            </Grid>
            <Grid item xs="auto">
              <Chip
                label={t(`complaint.status.${status}`)}
                color={chipColor}
                variant="filled"
                sx={{
                  borderRadius: 2
                }}
              />
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
              mt: 1
            }}
          >
            {entity.description}
          </Typography>
        </CardContent>
        <CardActions sx={styles.cardActions}>
          <Link
            component="button"
            underline="hover"
            color="primary"
            variant="subtitle2"
          >
            {t('view.details')}
          </Link>
        </CardActions>
      </Card>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle variant="subtitle2">
          {t('complaints.dialog.view.title', {ns: 'pages'})}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText variant="subtitle2" mb={2} color="text.primary">
            {t('complaints.dialog.view.content', {ns: 'pages'})}
          </DialogContentText>
          <DialogContentText variant="body2" mb={2} color="text.primary">
            {entity.description}
          </DialogContentText>
          <Divider sx={{my: 2}} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DialogContentText variant="subtitle2" mb={2} color="text.primary">
                {t('complaints.dialog.view.sender', {ns: 'pages'})}
              </DialogContentText>
              <DialogContentText variant="body2" mb={2} color="text.primary">
                {sender}
              </DialogContentText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DialogContentText variant="subtitle2" mb={2} color="text.primary">
                {t('complaints.dialog.view.date', {ns: 'pages'})}
              </DialogContentText>
              <DialogContentText variant="body2" mb={2} color="text.primary">
                {entity.description}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{py: 2, px: 3}}>
          <Button variant="outlined" onClick={handleClose} sx={{mr: 'auto'}}>
            {t('cancel')}
          </Button>
          <Button
            variant={!completed ? 'contained' : 'outlined'}
            color={!completed ? 'primary' : 'error'}
            onClick={() => {
              onChangeStatus(completed ? 'wait' : 'complete');
            }}
          >
            {t(`complaints.dialog.view.button.${!completed ? 'complete' : 'open'}`, {ns: 'pages'})}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles: SxPropsObject = {
  card: (theme) => ({
    borderRadius: theme.spacing(2),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  cardContent: () => ({
    p: {
      lg: 3,
      xs: 2,
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    '&:last-child': {
      pb: {
        lg: 3,
        xs: 2
      }
    }
  }),
  cardActions: () => ({
    pt: 0,
    pb: {
      lg: 3,
      xs: 2,
    },
    px: {
      lg: 3,
      xs: 2,
    },
  }),
  button: () => ({
  })
}

export default CardComponent;
