import React, {useState} from 'react';
import {InputAdornment, OutlinedInput} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {OutlinedInputProps} from '@mui/material/OutlinedInput/OutlinedInput';

interface Props extends OutlinedInputProps {
  anchorEl?: HTMLElement;
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
}

const SearchBox = (props: Props) => {
  const {
    searchValue,
    onChangeSearchValue,
    anchorEl,
    ...rest} = props;
  const [searchQuery, setSearchQuery] = useState<string>(searchValue);

  return  (
    <OutlinedInput
      fullWidth
      value={searchQuery}
      onChange={(event) => {
        const value = event.target.value;
        setSearchQuery(value)
        onChangeSearchValue(value);
      }}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      )}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            sx={{
              visibility: searchQuery ? 'visible': 'hidden'
            }}
            size="small"
            onClick={() => {
              setSearchQuery('')
              onChangeSearchValue('');
            }}
          >
            <ClearIcon fontSize="small"/>
          </IconButton>
        </InputAdornment>
      )}
      {...rest}
    />
  );
};

export default SearchBox;
