import React, {Dispatch, SetStateAction} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {useTheme} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {Logo, Nav, NavItem, NavLink} from '../../../../styled';
import SvgIcon from '@mui/material/SvgIcon';
import {SxPropsObject} from '../../../../types';
import Locales from '../../../../components/Locales';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {HOME_MENU_ITEMS} from '../../../../constants';
import { scroller } from 'react-scroll';
import {ReactComponent as LogoImage} from '../../../../assets/images/logo.svg';
import {ReactComponent as MenuIcon} from '../../../../assets/icons/menu.svg';
import {ReactComponent as CrossIcon} from '../../../../assets/icons/cross.svg';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Header:React.FC<Props> = (props) => {
  const {open, setOpen} = props;
  const {t} = useTranslation();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const handleScroll = (name: string): void => {

    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      <AppBar
        position="absolute"
        color={isDownLg ? 'primary' : 'transparent'}
        elevation={0}
        component="header"
        sx={styles.container}
      >
        <Container>
          <Toolbar sx={styles.toolbar}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto" sx={styles.left}>
                <Logo
                  component={Link}
                  to="/"
                >
                  <LogoImage/>
                </Logo>
              </Grid>
              {isUpLg && (
                <Grid item xs="auto" sx={styles.center}>
                  <Nav>
                    {HOME_MENU_ITEMS.map((item, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            variant="body2"
                            color="text.primary"
                            fontWeight={600}
                            type="button"
                            href={`#${item.path}`}
                            onClick={(event) => {
                              event.preventDefault();
                              handleScroll(item.path);
                            }}
                          >
                            {item.value}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </Grid>
              )}
              <Grid item xs="auto" sx={styles.right}>
                <Locales/>
                {isUpLg && (

                  <>
                    <NavLink
                      variant="body2"
                      color="text.primary"
                      type="button"
                      component={Link}
                      to="/auth/sign-in"
                    >
                      {t('signInUser')}
                    </NavLink>
                    <Button
                      size="medium"
                      variant="contained"
                      color="dark"
                      onClick={() => console.log('click')}
                      sx={{ml: 3, borderRadius: 2}}
                    >
                      {t('contact')}
                    </Button>
                  </>
                )}
                {isDownLg && (
                  <IconButton
                    sx={styles.menuButton}
                    color="inherit"
                    aria-label="Menu button"
                    edge="end"
                    onClick={() => setOpen(state => !state)}
                  >
                    {open ? (
                      <SvgIcon fontSize="inherit" viewBox="0 0 25 25" component={CrossIcon} />
                    ) : (
                      <SvgIcon fontSize="inherit" viewBox="0 0 24 17" component={MenuIcon} />
                    )}
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

const styles:SxPropsObject = {
  container: {
    background: 'rgba(255, 255, 255, 0.05)',
    backdropFilter:' blur(10px)',
    zIndex: theme => theme.zIndex.drawer + 1,
  },
  toolbar: (theme) => ({
    py: 0,
    px: {
      xs: 0,
    },
    minHeight: {
      sm: theme.spacing(10),
    },
  }),
  left: () => ({
    display: 'flex',
  }),
  center: () => ({
    display: 'flex',
    justifyContent: 'center',
    mr: 'auto',
    ml: 8,
  }),
  right: () => ({
    display: 'flex',
    alignItems: 'center',
    ml: 'auto'
  }),
  apps: (theme) => ({
    borderRadius: theme.spacing(1),
    ml: theme.spacing(4),

    [theme.breakpoints.down('lg')]: {
      ml: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      mx: 'auto',
      mt: 'auto',
      mb: 3,
    },
  }),
  appsButton: (theme) => ({
    borderRadius: theme.spacing(1),
  }),
  menuButton: (theme) => ({
    color: 'text.primary',
    ml: 3,
    [theme.breakpoints.down('sm')]: {
      ml: 2,
    },
    [theme.breakpoints.down(319.95)]: {
      fontSize: '1.125rem',
    },
  }),
}

export default Header;
