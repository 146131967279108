import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import {initialScheduleItem, scheduleLessonStateToRequest} from '../../../../utils';
import {FormValues} from '../types';
import {useLocation} from 'react-router-dom';
import {Lesson} from '../../../../types';

const Create = () => {
  const user = useSelector(userSelector);
  let location = useLocation();

  const Lesson = (location?.state?.Lesson || null) as Lesson;
  const type = (location?.state?.type || Lesson?.type || 'main') as Lesson['type'];

  const date = new Date(location.state?.date || Date.now());
  if(!location.state?.date){
    date.setHours(8, 0, 0, 0)
  }
  const day = location.state?.day || null;
  const scheduleItem = initialScheduleItem(date, day?.value);

  return (
    <ResourceCreate
      name="schedules"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any) => {
          const transformed = scheduleLessonStateToRequest(values);
          return onCreate(transformed, onSuccess, onError)
        }

        const initialValues: FormValues = {
          Teacher: {
            id: null,
            name: null,
            surname: null,
            patronymic: null,
          },
          Lesson: {
            id: Lesson?.id || null,
            name: Lesson?.name || null,
            duration: Lesson?.duration || null,
            type: Lesson?.type || 'main'
          },
          type,
          quantity:  null,
          Schedule: [scheduleItem],
          Children: null,
          Groups: null,
          startAt: date,
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
