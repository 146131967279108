import React, {useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {Nullable, PaymentSystem, School, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {apiErrorsToFormik} from '../../../../../utils';
import {CURRENCY_ITEMS, PAYMENT_SYSTEMS} from '../../../../../constants';
import MenuItem from '@mui/material/MenuItem';

export interface  FormValues extends Pick<School, 'Bank'>{
  currency: Nullable<string>;
  paymentSystem: Nullable<PaymentSystem>;
}

interface Props {
  submitting: boolean;
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
}

const fields = {
  'firstName':'name',
  'middleName':'patronymic',
  'phoneNumber':'phone',
  'kinderGardenId':'schoolId',
  'parentId': 'Trustee.id',
  'parentType':'Trustee.relationship',
  'groupId':'Group.id',
  'curseCost':'payment.value',
}

const Form = (props: Props) => {
  const {
    submitting,
    initialValues,
    onSubmit,
  } = props;

  const {t} = useTranslation();
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleEdit = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`settings.content.title`, {ns: 'pages'})

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleEdit}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty,
          resetForm,
        }) => {

        const requisitesLeft = (
          <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
              {t('settings.content.section.requisites', {ns: 'pages'})}
            </Typography>
            <TextField
              select
              sx={styles.field}
              name="currency"
              value={values.currency || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.currency && errors?.currency)}
              helperText={(
                <ErrorMessage
                  name="currency"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.currency.label')}
              placeholder={t('field.currency.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => {
                  if (value as string === '') {
                    return (t('field.currency.placeholder'));
                  }
                  const current = CURRENCY_ITEMS.find((el) => el.value === value)
                  return current?.label || '';
                }
              }}
            >
              {CURRENCY_ITEMS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              sx={styles.field}
              name="paymentSystem"
              value={values.paymentSystem || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.paymentSystem && errors?.paymentSystem)}
              helperText={(
                <ErrorMessage
                  name="paymentSystem"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.payment.system.label')}
              placeholder={t('field.payment.system.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => {
                  if (value as string === '') {
                    return (t('field.payment.system.placeholder'));
                  }
                  const current = PAYMENT_SYSTEMS.find((el) => el.value === value)
                  return current?.label || '';
                }
              }}
            >
              {PAYMENT_SYSTEMS.map((option) => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={styles.field}
              name="Bank.name"
              value={values?.Bank?.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.Bank?.name && errors?.Bank?.name)}
              helperText={(
                <ErrorMessage
                  name="Bank.name"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.name.bank.label')}
              placeholder={t('field.name.bank.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              sx={styles.field}
              name="Bank.token"
              value={values?.Bank?.token || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.Bank?.token && errors?.Bank?.token)}
              helperText={(
                <ErrorMessage
                  name="Bank.token"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.token.label')}
              placeholder={t('field.token.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              sx={styles.field}
              name="Bank.receiver"
              value={values?.Bank?.receiver || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.Bank?.receiver && errors?.Bank?.receiver)}
              helperText={(
                <ErrorMessage
                  name="Bank.receiver"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.recipient.label')}
              placeholder={t('field.recipient.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              sx={styles.field}
              name="Bank.edrpou"
              value={values?.Bank?.edrpou || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.Bank?.edrpou && errors?.Bank?.edrpou)}
              helperText={(
                <ErrorMessage
                  name="Bank.edrpou"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.edrpou.label')}
              placeholder={t('field.edrpou.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              sx={styles.field}
              name="Bank.iban"
              value={values?.Bank?.iban || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.Bank?.iban && errors?.Bank?.iban)}
              helperText={Boolean(touched?.Bank?.iban && errors?.Bank?.iban) ? (
                <ErrorMessage
                  name="Bank.iban"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              ) : (t('field.iban.helper')
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.iban.label')}
              placeholder={t('field.iban.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
          </>
        );

        const tabs = [
          {
            label: t('settings.content.tabs.requisites', {ns: 'pages'}),
            content: {
              left: requisitesLeft,
              right: (<></>),
            }
          },
        ]

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actions={(
                <Actions
                  mode={"edit"}
                  buttons={{
                    submit: {
                      label:  t('save'),
                      disabled: !dirty,
                      loading: isSubmitting || submitting
                    },
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    }
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentItemHead: {
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  field: {
  },
}

export default Form;
