import React from 'react';
import ResourceCreate from '../../../../components/Resource/components/Create';
import Form from '../components/Form';
import {invoiceStateToRequest} from '../../../../utils';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';
import Spinner from '../../../../components/Spinner';
import {useTranslation} from 'react-i18next';
import {InvoiceStatus, Service} from '../../../../types';
import {useSearchParams} from 'react-router-dom';

const Create = () => {
  const user = useSelector(userSelector);
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') || 'wait';
  const serviceName = t('subscriptions.type.subscription');

  return (
    <ResourceCreate
      name="invoices"
      pathModifier={`/kinder-garden/${user?.schoolId}`}
    >
      {({onCreate, submitting, loading}) => {

        const handleCreate = (values: any, onSuccess?: (values: any) => any, onError?: (values: any) => any, redirect?: boolean) => {
          const transformed = invoiceStateToRequest(values);
          return onCreate(transformed, onSuccess, onError, redirect)
        }

        const initialValues = {
          Child: null,
          date: null,
          status: status as InvoiceStatus,
          description: null,
          Services: [{
            id: -1,
            quantity: 1,
            name: serviceName,
            description: null,
            type: 'common' as Service['type'],
            price: null,
            cost: null,
          }]
        }

        if(loading) {
          return  <Spinner/>
        }

        return (
          <Form
            mode="create"
            submitting={submitting}
            onSubmit={handleCreate}
            initialValues={initialValues}
            entity={null}
          />
        )
      }}
    </ResourceCreate>
  );
};

export default React.memo(Create);
