import {SagaIterator} from 'redux-saga';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import API from '../../api';
import {AxiosResponse} from 'axios';
import {
  CreateResourceActionType,
  DeleteResourceActionType,
  FetchResourceActionType,
  FetchResourcesActionType,
  UpdateResourceActionType
} from '../../types';
import slices, {createEntity, deleteEntity, fetchEntities, fetchEntity, updateEntity} from './slice';
import noticesSlice from '../notices/slice';

import {paginationSelector, paramsSelector} from './selectors';
import {Trans, Translation} from 'react-i18next';
import {history} from '../../App';

function getApiName(name: string): string {
  return (name === 'owners' && 'managers') ||
    name;
}

const safelyParseJSON = (data: any):  any => {
  let parsed: any;

  try {
    parsed = JSON.parse(data);
  } catch (e) {
    parsed = null;
  }

  return parsed;
}

function* fetchEntitySaga( action: FetchResourceActionType ): SagaIterator<void> {
  const { name, pathModifier, id } = action?.payload;
  const {
    entityFetchRequest,
    entitySuccess,
    entityFail
  } =  slices[name].actions;
  const {noticeOpen} = noticesSlice.actions;

  const apiName = getApiName(name)

  // @ts-ignore
  yield put(entityFetchRequest());
  try {
    const response: AxiosResponse<any> = yield call(API.resources.get, pathModifier, apiName, id);

    //@ts-ignore
    yield put(entitySuccess(response.data));

  } catch (error) {
    //@ts-ignore
    const {code, message} = error?.response?.data;
    //@ts-ignore
    yield put(entityFail(error?.message || ''));
    yield put(noticeOpen({
      message: (
        <Translation>
          {/*@ts-ignore*/}
          {(t) => <Trans t={t} i18nKey="messages.fetch.data.failed" values={{status: code, error: message}}/>}
        </Translation>
      ),
      options: {
        key: `${name}-fetch-error`,
        variant: 'error',
      },
    }));
  }
}

function* createEntitySaga( action: CreateResourceActionType ): SagaIterator<void> {
  const { name, pathModifier, values, onSuccess, onError, redirect = true } = action?.payload;
  const {
    entitySubmitRequest,
    entitySuccess,
    entityFail
  } =  slices[name].actions;
  const {noticeOpen} = noticesSlice.actions;

  const apiName = getApiName(name)

  //@ts-ignore
  yield put(entitySubmitRequest());
  try {
    const response: AxiosResponse<any> = yield call(API.resources.create, pathModifier, apiName, values);

    //@ts-ignore
    yield put(entitySuccess(response.data));

    if(response.status === 201 ||response.status === 200) {
      yield put(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.create.entity.success')}
          </Translation>
        ),
        options: {
          key: `${name}-${response.data.id}-create-success`,
          variant: 'success',
        },
      }));

      if(redirect) {
        history.replace(`/dashboard/${name}/list`)
      }
    }

    if(onSuccess) {
      yield onSuccess(response.data)
    }

  } catch (error) {
    //@ts-ignore
    const {code, message} = error?.response?.data;

    let messageStr = safelyParseJSON(message);
    if(!messageStr && typeof message === 'string') {
      messageStr = message;
    }
    //@ts-ignore
    yield put(entityFail(messageStr));

    if(onError) {
      yield onError(messageStr)
    }

    yield put(noticeOpen({
      message: (
        <Translation>
          {/*@ts-ignore*/}
          {(t) => <Trans t={t}  i18nKey="messages.create.entity.failed" values={{status: code, error: message}}/>}
        </Translation>
      ),
      options: {
        key: `${name}-create-error`,
        variant: 'error',
      },
    }));
  }
}

function* updateEntitySaga( action: UpdateResourceActionType ): SagaIterator<void> {
  const { id, name, pathModifier, values, onSuccess, onError, redirect = true } = action?.payload;
  const {
    entitySubmitRequest,
    entitySuccess,
    entityFail
  } =  slices[name].actions;
  const {noticeOpen} = noticesSlice.actions;

  const apiName = getApiName(name)

  //@ts-ignore
  yield put(entitySubmitRequest());
  try {
    const response: AxiosResponse<any> = yield call(API.resources.update, pathModifier, apiName, values, id);

    //@ts-ignore
    yield put(entitySuccess(response.data));

    if(response.status === 200) {
      yield put(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.update.entity.success')}
          </Translation>
        ),
        options: {
          key: `${name}-${id}-update-success`,
          variant: 'success',
        },
      }));

      if(redirect) {
        history.replace(`/dashboard/${name}/list`)
      }
    }

    if(onSuccess) {
      yield onSuccess(response.data)
    }

  } catch (error) {
    //@ts-ignore
    const {code, message} = error?.response?.data;

    let messageStr = safelyParseJSON(message);
    if(!messageStr && typeof message === 'string') {
      messageStr = message;
    }
    //@ts-ignore
    yield put(entityFail(messageStr));

    if(onError) {
      yield onError(messageStr)
    }
    yield put(noticeOpen({
      message: (
        <Translation>
          {/*@ts-ignore*/}
          {(t) => <Trans t={t} i18nKey="messages.update.entity.failed" values={{status: code, error: message}}/>}
        </Translation>
      ),
      options: {
        key: `${name}-${id}-update-error`,
        variant: 'error',
      },
    }));
  }
}

function* deleteEntitySaga( action: DeleteResourceActionType ): SagaIterator<void> {
  const { name, pathModifier, id, redirect = false } = action?.payload;
  const {
    entityFetchRequest,
    entitySuccess,
    entityFail
  } =  slices[name].actions;

  const {noticeOpen} = noticesSlice.actions;

  const apiName = getApiName(name);

  //@ts-ignore
  yield put(entityFetchRequest());
  try {
    yield call(API.resources.delete, pathModifier, apiName, id);
    //@ts-ignore
    yield put(entitySuccess(null));

    yield put(noticeOpen({
      message: (
        <Translation>
          {(t) => t('messages.delete.entity.success')}
        </Translation>
      ),
      options: {
        key: `${name}-${id}-delete-success`,
        variant: 'success',
      },
    }));

    if(redirect) {
      history.replace(`/dashboard/${name}/list`)
    }

  } catch (error) {
    let messageKey = 'messages.delete.entity.failed';
    //@ts-ignore
    yield put(entityFail(error?.message || ''));
    //@ts-ignore
    const {code, message} = error.response.data || {};
    if(message.includes('has_group')) {
      messageKey = 'messages.delete.teacher.failed.group';
    }
    yield put(noticeOpen({
      message: (
        <Translation>
          {/*@ts-ignore*/}
          {(t) => <Trans t={t} i18nKey={messageKey} values={{status: code, error: message}}/>}
        </Translation>
      ),
      options: {
        key: `${name}-${id}-delete-error`,
        variant: 'error',
      },
    }));
  }
}

function* fetchEntitiesSaga( action: FetchResourcesActionType ): SagaIterator<void> {
  const { name, pathModifier, params: defaultParams } = action?.payload;
  const {entitiesRequest, entitiesSuccess, entitiesFail} =  slices[name].actions;

  const apiName = getApiName(name);
  const {page, pageSize} = yield select(paginationSelector(name));
  const params = yield select(paramsSelector(name));

  let searchParams;
  if(defaultParams || params) {
    searchParams = {...defaultParams, ...params}
  }

  // @ts-ignore
  yield put(entitiesRequest());
  try {
    const response: AxiosResponse<any> = yield call(API.resources.getAll, pathModifier, apiName, {
      page,
      perPage: pageSize,
      ...(searchParams ? searchParams : {})
    });

    //@ts-ignore
    yield put(entitiesSuccess({
      name,
      items: response.data.items,
      total: response.data.totalItems,
      page: response.data.currentPage,
    }));

  } catch (error) {
    //@ts-ignore
    yield put(entitiesFail(error?.message || ''));
  }
}

export default function* resourceSaga() {
  yield all([
    takeEvery(fetchEntity.type, fetchEntitySaga),
    takeEvery(createEntity.type, createEntitySaga),
    takeEvery(updateEntity.type, updateEntitySaga),
    takeEvery(deleteEntity.type, deleteEntitySaga),
    takeEvery(fetchEntities.type, fetchEntitiesSaga),
  ])
}
