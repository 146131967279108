import React from 'react';
import {SxPropsObject} from '../../types';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Theme} from '@mui/material/styles/createTheme';
import {getSxStyle} from '../../utils';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as FiltersIcon} from '../../assets/icons/filters.svg';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface Props  {
  items: Array<React.ReactElement>,
  sx?: SxProps<Theme>
}

const Filters:React.FC<Props> = (props) => {
  const {sx, items} = props;
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={(theme) => {
        const style = getSxStyle(styles.container, theme)
        return {
          ...style,
          ...(getSxStyle(sx || {}, theme))
        }
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}} onClick={handleClick}>
        <IconButton
          sx={styles.order}
          color="inherit"
          aria-label={t(`filters.button`)}
          edge="start"
        >
          <SvgIcon
            fontSize="inherit"
            viewBox="0 0 24 24"
            component={FiltersIcon}

          />
        </IconButton>
        <Typography variant="body2" sx={{cursor: 'pointer', ml: 0.5}}>
          {t('filters')}
        </Typography>
      </Box>
      <Popover
        id="actions"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: { width: 300 },
        }}
      >
        <List dense>
          {items.map((el, index) => {
            return (
              <ListItem key={index}>
                {el}
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: {
    display: 'flex',
    alignItems: 'center',
    mr: 1,
    ml: 'auto',
  },
  control: {},
  select: {

    '&, &.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      right: 0,
    }
  },
  selectInput: (theme) => ({
    p: theme.spacing(0.5),
    border: '0 none',

    '&.MuiOutlinedInput-input.MuiInputBase-input': {
      pr: theme.spacing(3),
    }
  }),
  order: {
    mr: 0.5
  },
}

export default Filters;
