import React, {Dispatch, SetStateAction} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {Link} from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {SxPropsObject} from '../../../../types';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import {ReactComponent as AppleIcon} from '../../../../assets/icons/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/icons/android.svg';
import {HOME_MENU_ITEMS} from '../../../../constants';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import { scroller } from 'react-scroll';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Navigation:React.FC<Props> = (props) => {
  const {open, setOpen} = props;
  const theme = useTheme();
  const {t} = useTranslation();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const handleScroll = (name: string): void => {
    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Drawer
      hidden={isUpLg}
      open={open}
      anchor="right"
      onClose={() => setOpen(false)}
      PaperProps={{
        elevation: 0,
        sx: styles.paper,
      }}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
        sx: {
          background: 'transparent',
        },
      }}
    >
      <Toolbar sx={styles.toolbar}/>
      <List>
        {HOME_MENU_ITEMS.map((item, index: number) => (
          <ListItem
            key={index}
            sx={styles.item}
            disablePadding
          >
            <ListItemButton
              sx={styles.itemButton}
              onClick={() => {
                handleScroll(item.path);
                setOpen(false);
              }}
            >
              <ListItemText
                primary={item.value}
                sx={styles.itemText}
                primaryTypographyProps={{
                  variant: 'body2'
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={styles.actions}>
        <Button
          fullWidth
          size="medium"
          color="dark"
          variant="contained"
          component={Link}
          to="/auth/sign-in"
        >
          {t('signInUser')}
        </Button>
      </Box>
      {isDownSm && (
        <Box sx={{mt: 'auto', py: 4}}>
          <Grid container spacing={1} flexDirection="column" alignItems="center">
            <Grid item xs="auto">
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <IconButton
                    component="a"
                    href="https://apps.apple.com/ua/"
                    aria-label={t('apps.apple')}
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{
                      color: '#BBBBC0'
                    }}
                  >
                    <SvgIcon
                      fontSize={'medium'}
                      viewBox="0 0 18 20"
                      component={AppleIcon}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    component="a"
                    href="https://play.google.com/store/apps/"
                    aria-label={t('apps.android')}
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{
                      color: '#BBBBC0'
                    }}
                  >
                    <SvgIcon
                      fontSize={'medium'}
                      viewBox="0 0 18 20"
                      component={AndroidIcon}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2" color="text.primary">
                {t('home.hero.apps.mobile', {ns: 'pages'})}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};

const styles:SxPropsObject = {
  container: {
    display: { xs: 'none', lg: 'none' },
  },
  toolbar: (theme) => ({
    py: 0,
    px: {
      lg: 0,
    },
    minHeight: {
      sm: theme.spacing(10)
    },
  }),
  apps: (theme) => ({
    borderRadius: theme.spacing(1),
    ml: theme.spacing(4),

    [theme.breakpoints.down('lg')]: {
      ml: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      mx: 'auto',
      mt: 'auto',
      mb: 3,
    },
  }),
  appsButton: (theme) => ({
    borderRadius: theme.spacing(1),
  }),
  paper: (theme) => ({
    width: '100vw',
    maxWidth: 328,
    bgcolor: 'common.white',
    backgroundImage: 'unset',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 386,
    }
  }),
  item: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  itemButton: {
    p: theme => theme.spacing(2),
  },
  itemText: {
  },
  actions: {
    mt: 10,
    mb: 3,
    px: 3,
  },
  button: {
  }
}

export default Navigation;
