import React, {PropsWithChildren, useCallback} from 'react';
import Box from '@mui/material/Box';
import Content from '../../../../../layouts/Main/components/Content';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers, getIn} from 'formik';
import {Group, SxPropsObject} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Files from '../../../../../components/Files';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as CrossIcon} from '../../../../../assets/icons/cross.svg';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import {FormValues} from '../../types';
import validationSchema from '../../validationSchema';
import AutoCompleteField from '../../../../../components/AutoCompleteFiled';
import {GroupResponsePayload} from '../../../../../api/types';
import {apiErrorsToFormik, getMediaUri, groupsResponseToState} from '../../../../../utils';
import API from '../../../../../api';
import VoteResult from '../VoteResult/VoteResult';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../../redux/auth/selectors';
import useGroupsList from '../../hooks/useGroupsList';

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'groupIds':'Groups',
  'voteVariants': 'Answers'
}

const Form = (props: PropsWithChildren<Props>) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete,
  } = props;

  const {t} = useTranslation();
  const user = useSelector(userSelector);

  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const {groups} = useGroupsList();

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`posts.content.title.${mode}`, {ns: 'pages'});

  if(mode === 'create') {
    initialValues.Groups = groups;
    initialValues.selectedGroupsType = 'all';
  }

  //TODO fix tslint error
  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
          isSubmitting
        }) => {

        const Answers = values?.Answers;
        const postType = values?.type || 'news';

        const disabled = mode === 'edit' && values?.type === 'vote';

        const onAddAnswer = () => {
          const items = [...Answers || []];
          items.push({
            id: null,
            answer: '',
          })
          setFieldValue('Answers', items, true);
        }

        const onRemoveAnswer = (index: number) => {
          const items = [...Answers || []];
          const updated = [...items.splice(0, index), ...items.splice(index + 1)]
          setFieldValue('Answers', updated, true);
        }

        const groupsContent = (
          <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
              {t('posts.content.section.placement', {ns: 'pages'})}
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="selectedGroupsType"
              value={values?.selectedGroupsType}
              onChange={(event, value) => {
                setFieldValue('selectedGroupsType', value);
                if(value === 'selected') {
                  setFieldValue('Groups', null)
                } else {
                  setFieldValue('Groups', groups)
                }
              }}
            >
              <FormControlLabel
                disabled={disabled}
                value="all"
                control={<Radio />}
                label={('Для усіх груп')}
              />
              <FormControlLabel
                disabled={disabled}
                value="selected"
                control={<Radio />}
                label={('Вибрані групи')}
              />
            </RadioGroup>
            <AutoCompleteField<Partial<Group>, true, undefined, undefined>
              sx={styles.field}
              disabled={disabled || values.selectedGroupsType === 'all'}
              multiple
              fullWidth
              size="medium"
              transformer={(entities: Array<GroupResponsePayload>): Array<Group> => {
                return entities?.map((entity) => {
                  return groupsResponseToState(entity);
                }) || [];
              }}
              TextFieldProps={{
                name: "Groups",
                required: true,
                label: t('field.group.label'),
                placeholder: t('field.group.placeholder'),
                margin: 'normal',
                InputLabelProps: { shrink: true },
                error: Boolean(touched?.Groups && errors?.Groups),
                helperText: (
                  <ErrorMessage
                    name="Groups"
                    render={(message) => t(message, {ns: 'validation'})}
                  />
                )
              }}
              fetch={() => API.groups.getAllSimple(`/kinder-garden/${user?.schoolId}`)}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              getOptionLabel={(option) => {
                return option?.name || '';
              }}
              value={values?.Groups || []}
              onChange={(event, value) => {
                if(!value) {
                  setFieldValue('Groups', null)
                }
                setFieldValue('Groups', value)
              }}
              onBlur={handleBlur}
            />
          </>
        )

        const mediaContent = (
          <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
              {t('posts.content.section.files', {ns: 'pages'})}
            </Typography>
            <Files
              data={values?.media?.map((el) => {
                return {
                  value: el,
                  uri: getMediaUri(el),
                }
              }) || null}
              disabled={disabled}
              onUpload={(items) => {
                setFieldValue('media', [...(values?.media || []), ...items]);
              }}
              onRemove={(name) => {
                return setFieldValue('media', (values?.media || []).filter((item) => item !== name));
              }}
            />
          </>
        )

        const content = {
          news: {
            left: (
              <>
                <Typography variant="subtitle1" sx={{mt: 2, mb: 2}}>
                  {t('posts.content.section.content.news', {ns: 'pages'})}
                </Typography>
                <TextField
                  sx={styles.field}
                  name="name"
                  disabled={disabled}
                  value={values?.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors?.name)}
                  helperText={(
                    <ErrorMessage
                      name="name"
                      render={(message) => t(message, {ns: 'validation', min: 2, max: 255})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.name.news.label')}
                  placeholder={t('field.name.news.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
                <TextField
                  sx={styles.field}
                  name="text"
                  disabled={disabled}
                  value={values?.text || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.text && errors.text)}
                  helperText={(
                    <ErrorMessage
                      name="text"
                      render={(message) => t(message, {ns: 'validation', min: 12})}
                    />
                  )}
                  margin="normal"
                  required={false}
                  fullWidth
                  label={t('field.text.label')}
                  placeholder={t('field.text.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                  multiline
                  rows={4}
                />
                <Divider sx={{my: 2}}/>
                {groupsContent}
              </>
            ),
            right: (
              <>{mediaContent}</>
            ),
          },
          vote: {
            left: (
              <>
                <Typography variant="subtitle1" sx={{mt: 2, mb: 2}}>
                  {t('posts.content.section.content.vote', {ns: 'pages'})}
                </Typography>
                <TextField
                  sx={styles.field}
                  name="name"
                  value={values?.name || ''}
                  disabled={disabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors?.name)}
                  helperText={(
                    <ErrorMessage
                      name="name"
                      render={(message) => t(message, {ns: 'validation'})}
                    />
                  )}
                  margin="normal"
                  required
                  fullWidth
                  label={t('field.question.label')}
                  placeholder={t('field.question.placeholder')}
                  InputLabelProps={{ shrink: true }}
                  size="medium"
                  autoComplete='off'
                />
                <Divider sx={{my: 2}}/>
                <Typography variant="subtitle1" sx={{mb: 2}}>
                  {t('posts.content.section.answers', {ns: 'pages'})}
                </Typography>

                <List sx={{mb: 2}}>
                  {Answers?.map((answer, index) => {
                    const fieldName = `Answers[${index}].answer`;
                    const fieldValue = getIn(values, fieldName, undefined);
                    const fieldError = getIn(errors, fieldName, undefined);
                    const fieldTouched = getIn(touched, fieldName, undefined);

                    const divider = isDownSm && index !== Number(Answers?.length) - 1;
                    const deletable = index > 1 && mode !== 'edit' && !disabled;

                    return (
                      <ListItem
                        key={index}
                        dense
                        disableGutters
                        divider={divider}
                        secondaryAction={(
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            disabled={!deletable}
                            onClick={() => onRemoveAnswer(index)}
                          >
                            <SvgIcon
                              fontSize="inherit"
                              viewBox="0 0 24 24"
                              component={CrossIcon}
                              color="inherit"
                            />
                          </IconButton>
                        )}
                      >
                        <Box width="100%">
                          <TextField
                            sx={styles.field}
                            name={fieldName}
                            disabled={disabled}
                            value={fieldValue || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(fieldTouched && fieldError)}
                            helperText={(
                              <ErrorMessage
                                name={fieldName}
                                render={(message) => t(message, {ns: 'validation', min: 2})}
                              />
                            )}
                            margin="normal"
                            required={!deletable}
                            fullWidth
                            label={t('field.answer.label')}
                            placeholder={t('field.answer.placeholder')}
                            InputLabelProps={{ shrink: true }}
                            size="medium"
                            autoComplete='off'
                          />
                        </Box>
                      </ListItem>
                    )
                  })}
                </List>

                <Button
                  size="medium"
                  variant="outlined"
                  fullWidth={isDownMd}
                  onClick={onAddAnswer}
                  disabled={mode === 'edit'}
                >
                  {t('add.answer')}
                </Button>
                <FormGroup sx={{mt: 2}}>
                  <FormControlLabel
                    name="multiple"
                    disabled={disabled}
                    checked={Boolean(values?.multiple)}
                    onChange={handleChange}
                    control={(
                      <Checkbox
                        name="multiple"
                        inputProps={{ 'aria-label': 'multiple' }}
                      />
                    )}
                    label={t('posts.content.checkbox.multiple', {ns: 'pages'})}
                  />
                  <FormControlLabel
                    name="anonymous"
                    disabled
                    checked={Boolean(values?.anonymous)}
                    onChange={handleChange}
                    control={(
                      <Checkbox
                        name="anonymous"
                        inputProps={{ 'aria-label': 'anonymous' }}
                      />
                    )}
                    label={t('posts.content.checkbox.anonymous', {ns: 'pages'})}
                  />
                </FormGroup>
                <Divider sx={{my: 2}}/>
                {groupsContent}
              </>
            ),
            right: (
              <>
                {mediaContent}
                {mode === 'edit' && (
                  <>
                    <Typography variant="subtitle1" sx={{mt: 2, mb: 2}}>
                      {t('posts.content.section.overview', {ns: 'pages'})}
                    </Typography>
                    <VoteResult
                      anonymous={Boolean(values?.anonymous)}
                      question={values?.name || ''}
                      answers={values?.Answers || null}
                    />
                  </>
                )}
              </>
            ),
          }
        }

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              head={(
                <>
                  <Typography variant="subtitle1" sx={{mb: 1}}>
                    {t('posts.content.section.type', {ns: 'pages'})}
                  </Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">
                        {t('vote.value')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        name="type"
                        size="medium"
                        disabled={mode === 'edit'}
                        checked={postType === 'news'}
                        onChange={(event, value) => {
                          const postType = value ? 'news' : 'vote';
                          setFieldValue( 'type', postType);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {t('news.value')}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              left={content[postType].left}
              right={content[postType].right}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t(`add.${postType}`) : t(`edit.${postType}`),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            />
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
