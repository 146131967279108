import {Group, Nullable, Post} from '../../../../types';
import {useEffect, useState} from 'react';
import API from '../../../../api';
import {GroupResponsePayload} from '../../../../api/types';
import {groupsResponseToState} from '../../../../utils';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../../redux/auth/selectors';


type Value = {
  groups: Nullable<Array<Group>> | undefined;
}
const useGroupsList = ():Value  => {
  const user = useSelector(userSelector);
  const [groups, setGroups] = useState<Post['Groups']>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await API.groups.getAllSimple(`/kinder-garden/${user?.schoolId}`)
        if(data) {
          const transformed =  data?.map((group: GroupResponsePayload) => {
            return groupsResponseToState(group);
          }) || [];
          setGroups(transformed)
        }
      } catch (error) {
        setGroups(null)
      }
    })();
  }, [user?.schoolId]);

  return {
    groups
  };
};

export default useGroupsList;
