import React, {useCallback, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {SxPropsObject, User} from '../../../../../types';
import {useTranslation} from 'react-i18next';
import Content from '../../../../../layouts/Main/components/Content';
import Actions from '../../../../../components/Resource/components/common/form/Actions';
import validationSchema from '../../validationSchema';
import {apiErrorsToFormik, generatePassword} from '../../../../../utils';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as EyeIcon} from '../../../../../assets/icons/eye.svg';
import {ReactComponent as EyeCrossIcon} from '../../../../../assets/icons/eye-cross.svg';
import {ReactComponent as KeyIcon} from '../../../../../assets/icons/key.svg';
import {MuiTelInputCountry} from 'mui-tel-input/dist/shared/constants/countries';
import {MuiTelInput} from 'mui-tel-input';
import {useSelector} from 'react-redux';
import {appSelector} from '../../../../../redux/app/selectors';
import {PREFERRED_COUNTRIES} from '../../../../../constants';

interface FormValues extends Pick<
  User, 'name' | 'surname' | 'patronymic' | 'email' | 'phone' | 'password'
  > {
}

interface Props {
  submitting: boolean;
  mode: 'create' | 'edit';
  initialValues: FormValues;
  onSubmit: (values: FormValues, onSuccess?: (values: any) => any, onError?: (values: any) => any) => void;
  onDelete?: () => void;
}

const fields = {
  'firstName':'name',
  'middleName':'patronymic',
  'phoneNumber':'phone',
  'kinderGardenId':'schoolId',
}

const Form = (props: Props) => {
  const {
    submitting,
    mode,
    initialValues,
    onSubmit,
    onDelete
  } = props;

  const {t} = useTranslation();
  const {countryCode} = useSelector(appSelector);
  const [visiblePassword, setVisiblePassword] = useState(true);

  const handleCreate = useCallback(async (
      values: FormValues,
      helpers:FormikHelpers<FormValues>
    ): Promise<any> => {
      const { setSubmitting, setErrors } = helpers;
      onSubmit(values, () => {
        setSubmitting(false);
      }, (errors) => {
        const apiErrors = apiErrorsToFormik(errors, fields);
        setErrors(apiErrors || {});
      });
    }, [onSubmit],
  );

  const title = t(`admins.content.title.${mode}`, {ns: 'pages'})

  const schema = validationSchema(mode);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleCreate}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
          resetForm,
          isSubmitting,
          setFieldValue,
        }) => {

        return (
          <Box
            sx={styles.form}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <Content
              title={title}
              left={(
                <>
                  <Typography variant="h6" sx={{mb: 3}}>
                    {t('admins.content.subtitle.admin', {ns: 'pages'})}
                  </Typography>
                  <Typography variant="subtitle1" sx={{mb: 2}}>
                    {t('admins.content.section.main', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="surname"
                        value={values.surname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.surname && errors.surname)}
                        helperText={(
                          <ErrorMessage
                            name="surname"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.surname.label')}
                        placeholder={t('field.surname.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="name"
                        value={values.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.name && errors.name)}
                        helperText={(
                          <ErrorMessage
                            name="name"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.name.label')}
                        placeholder={t('field.name.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="patronymic"
                        value={values.patronymic || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.patronymic && errors.patronymic)}
                        helperText={(
                          <ErrorMessage
                            name="patronymic"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required={false}
                        fullWidth
                        label={t('field.patronymic.label')}
                        placeholder={t('field.patronymic.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        name="email"
                        value={values.email || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={(
                          <ErrorMessage
                            name="email"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.email.label')}
                        placeholder={t('field.email.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiTelInput
                        name="phone"
                        defaultCountry={countryCode as MuiTelInputCountry}
                        preferredCountries={PREFERRED_COUNTRIES}
                        value={values.phone || ''}
                        onChange={(value, info) => {
                          setFieldValue('phone', info.numberValue)
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={(
                          <ErrorMessage
                            name="phone"
                            render={(message) => t(message, {ns: 'validation'})}
                          />
                        )}
                        margin="normal"
                        required
                        fullWidth
                        label={t('field.phone.label')}
                        placeholder={t('field.phone.placeholder')}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              right={(
                <>
                  <Typography variant="subtitle1" sx={{mt: {md: 6, xs: 0}, mb: 2}}>
                    {t('admins.content.section.access', {ns: 'pages'})}
                  </Typography>
                  <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        sx={styles.field}
                        type={visiblePassword ? 'text' : 'password'}
                        name="password"
                        value={values.password || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={(
                          <ErrorMessage
                            name="password"
                            render={(message) => t(message, {ns: 'validation', min: 8, max: 32})}
                          />
                        )}
                        margin="normal"
                        required={mode === 'create'}
                        fullWidth
                        label={t('field.password.label')}
                        placeholder={t('field.password.placeholder')}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                edge="start"
                                size="small"
                                aria-label="set generated password"
                                onClick={() => {
                                  const password = generatePassword();
                                  setFieldValue('password', password);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <SvgIcon
                                  fontSize="inherit"
                                  viewBox="0 0 24 24"
                                  component={KeyIcon}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                size="small"
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setVisiblePassword((value) => !value);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <SvgIcon
                                  fontSize="inherit"
                                  viewBox="0 0 24 24"
                                  component={visiblePassword ? EyeIcon : EyeCrossIcon}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        size="medium"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              actions={(
                <Actions
                  mode={mode}
                  buttons={{
                    submit: {
                      label: mode === 'create' ? t('add.admin') : t('edit.admin'),
                      disabled: !dirty && mode === 'edit',
                      loading: isSubmitting || submitting
                    },
                    ...(mode === 'edit' && onDelete ? {
                      delete: {
                        label: t('delete.value'),
                        disabled: false,
                        action: onDelete
                      }
                    } : {}),
                    cancel: {
                      label: t('cancel'),
                      disabled: !dirty,
                      action: () => (resetForm())
                    },
                  }}
                />
              )}
            >
            </Content>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles: SxPropsObject = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  field: {
  },
}

export default Form;
