import React from 'react';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import {Translation, useTranslation} from 'react-i18next';
import validationSchema from './validationSchema';
import {SxPropsObject} from '../../../../../../types';
import slices from '../../../../../../redux/notices/slice';
import {useDispatch, useSelector} from 'react-redux';
import {MuiTelInput} from 'mui-tel-input';
import {appSelector} from '../../../../../../redux/app/selectors';
import {MuiTelInputCountry} from 'mui-tel-input/dist/shared/constants/countries';
import { PREFERRED_COUNTRIES } from '../../../../../../constants';

type FormValues = {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: '',
}

const Form = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {countryCode} = useSelector(appSelector);

  const {
    noticeOpen,
  } =  slices.actions;

  const onSubmit = async (
    values: FormValues,
    helpers:FormikHelpers<FormValues>
  ): Promise<any> => {
    const { setSubmitting } = helpers;
    const {
      name,
      email,
      phone,
      message
    } = values;

    try {
      //@ts-ignore
      await Email.send({
        Host : "smtp.elasticemail.com",
        Username : "ionenkovladimir@gmail.com",
        Password : "D882F1186C0187204CB9B3010F9608F852B4",
        To : 'kolesnyk29@gmail.com',
        From : email,
        Subject : "Study care contact form",
        Body : `Name: ${name}; Email: ${email}; Phone: ${phone}, Message: ${message}`
      });

      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.contact.success')}
          </Translation>
        ),
        options: {
          key: `contact-success`,
          variant: 'success',
        },
      }))
    } catch (err) {
      dispatch(noticeOpen({
        message: (
          <Translation>
            {(t) => t('messages.contact.failed')}
          </Translation>
        ),
        options: {
          key: `contact-error`,
          variant: 'error',
        },
      }))
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => {

        return (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <TextField
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={(
                <ErrorMessage
                  name="name"
                  render={(message) => t(message, {ns: 'validation', min: 2, max: 255})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.name.label')}
              placeholder={t('field.name.placeholder')}
              InputLabelProps={{ shrink: true }}
              name="name"
              type="text"
              size="medium"
              autoComplete='off'
            />
            <TextField
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.email && errors.email)}
              helperText={(
                <ErrorMessage
                  name="email"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.email.label')}
              placeholder={t('field.email.placeholder')}
              InputLabelProps={{ shrink: true }}
              name="email"
              size="medium"
              autoComplete='off'
            />
            <MuiTelInput
              name="phone"
              defaultCountry={countryCode as MuiTelInputCountry}
              preferredCountries={PREFERRED_COUNTRIES}
              value={values.phone || ''}
              onChange={(value, info) => {
                setFieldValue('phone', info.numberValue)
              }}
              onBlur={handleBlur}
              error={Boolean(touched.phone && errors.phone)}
              helperText={(
                <ErrorMessage
                  name="phone"
                  render={(message) => t(message, {ns: 'validation'})}
                />
              )}
              margin="normal"
              fullWidth
              label={t('field.phone.label')}
              placeholder={t('field.phone.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
            />
            <TextField
              name="message"
              value={values?.message || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.message && errors?.message)}
              helperText={(
                <ErrorMessage
                  name="message"
                  render={(message) => t(message, {ns: 'validation', min: 12})}
                />
              )}
              margin="normal"
              required
              fullWidth
              label={t('field.message.label')}
              placeholder={t('field.message.placeholder')}
              InputLabelProps={{ shrink: true }}
              size="medium"
              autoComplete='off'
              multiline
              rows={4}
            />
            <Box sx={styles.actions}>
              <LoadingButton
                color="dark"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  borderRadius: 2,
                }}
              >
                {t('home.contact.button.contact', {ns: 'pages'})}
              </LoadingButton>
            </Box>
          </Box>
        )
      }}
    </Formik>
  );
};

const styles:SxPropsObject = {
  actions: (theme) => ({
    position: 'relative',
    zIndex: 10,
    display: 'inline-flex',
    marginX: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingX: theme.spacing(4),
    paddingY: theme.spacing(2),

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      opacity: 0.4,
      filter: 'blur(34px)',
      background: 'linear-gradient(90deg, #44FF9A -0.55%, #44B0FF 22.86%, #8B44FF 48.36%, #FF6644 73.33%, #EBFF70 99.34%)',
    }
  })
}

export default Form;
