import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('email.invalid')
    .required('required')
    .nullable(true),
  password: Yup.string()
    .min(8, 'password.min')
    .max(36, 'password.max')
    .required('required')
    .nullable(true),
});

export default validationSchema;
