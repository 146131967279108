import * as Yup from 'yup';
import { MIN_PARENT_AGE } from '../../../constants';
import {getAge} from '../../../utils';
import {password} from '../../../schemas';
import {matchIsValidTel} from 'mui-tel-input';

const validationSchema = (mode: 'create' | 'edit') => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, 'name.min')
      .max(255, 'name.max')
      .required('required')
      .nullable(true),
    surname: Yup.string().required('required').nullable(true),
    patronymic: Yup.string().nullable(true),
    email: Yup.string()
      .email('email.invalid')
      .nullable(true),
    phone: Yup.string()
      .test('phone', 'phone.invalid', function(value){
        return matchIsValidTel(value || '');
      })
      .required('required')
      .nullable(true),
    birthday: Yup.date()
      .test('age', (birthday, {createError, path}) => {
        if(!birthday) {
          return true;
        }
        const {value} = getAge(birthday || null) || {}
        const years = Number(value?.years || 0);
        if (years < MIN_PARENT_AGE) {
          return createError({
            path,
            message: 'age.min',
          })
        }
        return true;
      })
      .nullable(true),
    password: password(mode)
  })
};

export default validationSchema;
