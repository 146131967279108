//@ts-ignore
export const signInRequest = (state) => ({
  ...state,
  error: null,
  loading: true,
});

//@ts-ignore
export const signInSuccess = (state, action) => {
  const {user, expires, token, schools} = action.payload;
  return ({
    ...state,
    loading: false,
    error: null,
    user,
    expires,
    token,
    schools,
  })
};

//@ts-ignore
export const signInFail = (state, action) => ({
  ...state,
  loading: false,
  user: null,
  error: action.payload,
});

//@ts-ignore
export const signOutRequest = (state) => ({
  ...state,
  error: null,
  loading: true,
});

//@ts-ignore
export const signOutSuccess = (state) => ({
  ...state,
  error: null,
  loading: false,
  user: null,
  expires: null,
  token: null,
});

//@ts-ignore
 export const signOutFail = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

//@ts-ignore
export const setCurrentSchool = (state, action) => {
  const {schoolId} = action.payload;

  return {
    ...state,
    user: {
      ...state.user,
      schoolId
    },
  }
};
